/* eslint-disable no-underscore-dangle */
import {
  combineReducers,
  createStore,
  applyMiddleware,
  compose,
} from 'redux'
import thunk from 'redux-thunk'

import { authReducer } from './modules/auth'
import { loading } from './modules/loading'
import { consultations } from './modules/consultations'
import { passwordRecoveryReducer } from './modules/passwordRecovery'
import { notifications } from './modules/notifications'
import { healthRecords } from './modules/healthRecords'
import { doctors } from './modules/doctors'
import { content } from './modules/content'
import { settings } from './modules/settings'
import { medicines } from './modules/medicines'
import { reviewsReducer } from './modules/reviews'
import { localizationReducer } from './modules/localization'
import { chatsReducer, chatNotificationsReducer } from './modules/chats'
import { calendarReducer } from './modules/calendar'
import { signatureReducer } from './modules/signature'
import { notificationReducer } from './modules/notification'
import { callReducer } from './modules/call'
import { statusReducer } from './modules/status'

export const rootReducer = combineReducers({
  auth: authReducer,
  calendar: calendarReducer,
  call: callReducer,
  loading,
  passwordRecovery: passwordRecoveryReducer,
  consultations,
  notifications,
  healthRecords,
  doctors,
  content,
  settings,
  medicines,
  reviews: reviewsReducer,
  localization: localizationReducer,
  chats: chatsReducer,
  chatsNotifications: chatNotificationsReducer,
  signature: signatureReducer,
  notification: notificationReducer,
  status: statusReducer,
})

const composeEnhancers = (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  : compose


export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))
