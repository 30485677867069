import React, { useEffect } from 'react';
import PropTypes from 'prop-types'
import { compose, withStateHandlers, withProps } from 'recompose'
import { connect } from 'react-redux'
import styled from 'styled-components'
// import Tab from '@material-ui/core/Tab'
// import Tabs from '@material-ui/core/Tabs'
import { Colors } from 'constants/colors'
import { FormattedMessage, useIntl } from 'react-intl'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import queryString from 'query-string'

import { TermsAndPolicy } from '../TermsAndPolicy'
import { auth } from '../../../redux/modules/auth'
import { AuthDialog } from '../AuthDialog'
import { LoginForm } from '../LoginForm'
import { LoginHelper } from '../LoginHelper'
import { POLICY_HEADERS } from '../../../constants/termsAndPolicy'
import { history } from '../../../utils/history';

export const FormWrapper = styled.div`
  padding: 0 58px 0;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 ${Colors.azure};
  background-color: white;
  
  span > a {
    white-space: nowrap;
  }
`

const Title = styled.div`
  text-align: center;
  padding-top: 40px;
  font-size: 21px;
  line-height: 24px;
`

const Back = styled.a`
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: white;
  font-size: 18px;
  line-height: 21px;
  top: 44px;
  left: 26px;
  
  svg {
    margin-right: 8px;
  }
`

// const TabsStyled = styled(({ ...rest }) => (
//   <Tabs {...rest} classes={{ indicator: 'indicator' }} />
// ))`
//   color: ${Colors.dark};
//
//   & .indicator {
//     height: 4px;
//     border-radius: 2px;
//   }
// `
//
// const TabStyled = styled(({ ...rest }) => (
//   <Tab {...rest} classes={{ wrapper: 'wrapper' }} />
// ))`
//   & .wrapper {
//     border-bottom: 2px solid ${Colors.cloudyBlue30};
//     padding: 18px 0;
//     font-size: 20px; font-weight: 500;
//     text-transform: initial;
//   }
// `

const LoginComponent = ({
  registration,
  toggleForm,
  policy,
  togglePolicy,
  onSubmit,
  loading,
  match,
  location,
}) => {
  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })
  const query = queryString.parse(location.search)

  useEffect(() => {
    if (match.params.role === 'privacy-policy') {
      togglePolicy('gdpr.modal.privacy')
    }

    if (match.params.role === 'terms') {
      togglePolicy('gdpr.modal.terms')
    }
  }, [match.params.role])

  useEffect(() => {
    if (query.referral) {
      toggleForm()
    }
  }, [])

  return (
    <AuthDialog
      heading={
        policy
          ? f(POLICY_HEADERS[policy] || 'auth.policy')
          : `${registration ? f('auth.register') : f('auth.login')} ${f('auth.header.postfix')}`
      }
      backIcon={policy}
      onIconClick={policy ? () => togglePolicy(false) : undefined}
    >
      {!!policy && <TermsAndPolicy policy={policy} />}
      {
        !policy && (
          <FormWrapper>
            <Back onClick={() => history.push('/auth')}>
              <ArrowBackIcon />
              <FormattedMessage id='auth.back' />
            </Back>
            <Title>
              <FormattedMessage id='auth.selected.role' />
              {' '}
              <FormattedMessage id={`auth.${match.params.role}`} tagName='strong' />
            </Title>
            <LoginForm
              onSubmit={onSubmit(match.params.role === 'patient')}
              role={match.params.role}
              registration={registration}
              togglePolicy={togglePolicy}
              loading={loading}
              initialValues={query.referral ? { referral: query.referral } : undefined}
              toggleForm={toggleForm}
            />
          </FormWrapper>
        )
      }
      {(!policy) && <LoginHelper toggle={toggleForm} registration={registration} />}
    </AuthDialog>
  )
}

const enhance = compose(
  connect(state => ({ loading: state.loading.auth }), { auth }),
  withStateHandlers({
    value: 0,
    registration: false,
    policy: false,
  }, {
    changeValue: () => (e, value) => ({ value }),
    toggleForm: ({ registration }) => () => ({ registration: !registration }),
    togglePolicy: () => policy => ({ policy }),
  }),
  withProps(props => ({

    // TODO: change this
    user: localStorage.getItem('userRole') === 'ROLE_PATIENT' ? 'patient' : 'doctor',
    onSubmit: patient => values => props.auth(props.registration
      ? {
        ...values,
        confirmation: values.password,
        policy: 1,
      }
      : values,
    patient),
  })),
)

LoginComponent.propTypes = {
  registration: PropTypes.bool.isRequired,
  toggleForm: PropTypes.func.isRequired,
  policy: PropTypes.bool.isRequired,
  togglePolicy: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

export const Login = enhance(LoginComponent)
