import React, { useRef, useState } from 'react'
import { Paper } from 'common/UiKit/Paper'
import { Colors } from 'constants/colors'
import Muipaper from '@material-ui/core/Paper'
import Avatar from '@material-ui/core/Avatar'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Button from '@material-ui/core/Button'
import ListItemText from '@material-ui/core/ListItemText'
import TextField from '@material-ui/core/TextField'
import { FormattedMessage, useIntl } from 'react-intl'
import Popper from '@material-ui/core/Popper'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import axios from 'axios'
import styled from 'styled-components'
import idx from 'idx'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import moment from 'moment';
import defaultAvatar from '../../../../images/panel/default-avatar.svg'
import { CreatePatient, CheckboxWrapper } from './CreatePatient'
import { PatientList } from './PatientsList';

const PopperStyled = styled(Popper)`
  && {
    z-index: 20;
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid ${Colors.divider};
  }
`

const ValueWrapper = styled.div`
  display: flex;
  align-items: center;
  
  button {
    height: 38px;
    min-width: 150px;
  }
`

const ButtonWrapper = styled.div`
  margin-top: 12px;
  
  button {
    margin-left: 12px;
  }
`

const CheckboxContainer = styled(CheckboxWrapper)`
  text-align: left;
  padding-left: 24px;
`

const renderSuggestion = (suggestion, onClick) => {
  const rawDateBith = idx(suggestion, _ => _.attributes.date_of_birth);
  const formattedBirth = moment(rawDateBith);

  return (
    <ListItem button={!!onClick} onClick={onClick}>
      <ListItemAvatar>
        <Avatar src={idx(suggestion, _ => _.attributes.photo) || defaultAvatar} />
      </ListItemAvatar>
      {
      suggestion.type === 'HealthRecord'
        ? (
          <ListItemText>
            {idx(suggestion, _ => _.attributes.fullName) || (
              <>
                <FormattedMessage id='global.not.filled.name' />
                {' '}
                <FormattedMessage id='global.not.filled.lastName' />
              </>
            )}
          </ListItemText>
        )
        : (
          <ListItemText>
            {idx(suggestion, _ => _.attributes.first_name) || <FormattedMessage id='global.not.filled.name' />}
            {' '}
            {idx(suggestion, _ => _.attributes.last_name) || <FormattedMessage id='global.not.filled.lastName' />}
            {' '}
            ({rawDateBith ? formattedBirth.format('DD/MM/YYYY') : <FormattedMessage id='global.none' />})
          </ListItemText>
        )
    }
    </ListItem>
  ) 
}

export const PatientField = ({
  input, meta, access, toggleAccess, 
}) => {
  const [suggestions, setSuggestions] = useState([])
  const [inputValue, onInputChange] = useState('')
  const [isOpened, togglePopper] = useState(false)
  const [isPatientFormOpened, togglePatientForm] = useState(false)
  const [isPatientListFormOpened, togglePatientListForm] = useState(false)
  const { formatMessage } = useIntl()
  const inputRef = useRef(null)

  const onSearch = async (e) => {
    onInputChange(e.target.value)
    if (!isOpened) {
      togglePopper(true)
    }

    if (e.target.value.length > 0) {
      const response = await axios('/doctor/patient/list', { params: { name: e.target.value } })
      setSuggestions(response.data.data)
    } else {
      togglePopper(false)
    }
  }

  return (
    <Paper heading='concilium.select.patient'>
      {
        input.value
          ? (
            <>
              <ValueWrapper>
                {renderSuggestion(input.value)}
                <Button
                  color='primary'
                  size='small'
                  onClick={() => {
                    onInputChange('')
                    input.onChange(null)
                    toggleAccess(false)
                    setSuggestions([])
                    setTimeout(() => {
                      if (inputRef.current) {
                        inputRef.current.focus()
                      }
                    }, 100)
                  }}
                >
                  <FormattedMessage id='concilium.change' />
                </Button>
              </ValueWrapper>
              <CheckboxContainer>
                <FormControlLabel
                  control={(
                    <Checkbox
                      color='primary'
                      checked={access}
                      onChange={e => toggleAccess(e.target.checked)}
                      name='access'
                    />
                  )}
                  label={<FormattedMessage id='concilium.patient.access' />}
                />
              </CheckboxContainer>
            </>
          )
          : (
            <>
              <TextField
                fullWidth
                variant='outlined'
                InputProps={{
                  inputRef,
                  onFocus: () => setTimeout(() => togglePopper(true), 300),
                  onClick: () => setTimeout(() => togglePopper(true), 300),
                  // onBlur: () => togglePopper(false),
                }}
                value={inputValue}
                onChange={onSearch}
                placeholder={formatMessage({ id: 'concilium.search' })}
                error={!!(meta.touched && meta.error)}
                helperText={(meta.touched && meta.error) ? meta.error : ''}
              />
              <ButtonWrapper>
                {/*<FormattedMessage id='concilium.or' />*/}
                <Button color='primary' size='small' onClick={() => togglePatientListForm(true)}>
                  <FormattedMessage id='concilium.create.patient' />
                </Button>
              </ButtonWrapper>
              <ClickAwayListener onClickAway={() => isOpened && togglePopper(false)}>
                <PopperStyled
                  open={!!(suggestions.length && isOpened)}
                  anchorEl={inputRef.current}
                  key={suggestions.length}
                >
                  <Muipaper
                    square
                  >
                    <List>
                      {
                        suggestions.map(item => renderSuggestion(item, () => input.onChange(item)))
                      }
                    </List>
                  </Muipaper>
                </PopperStyled>
              </ClickAwayListener>
              <CreatePatient
                open={isPatientFormOpened}
                onClose={() => togglePatientForm(false)}
                afterSubmit={(patient) => {
                  input.onChange(patient)
                  toggleAccess(true)
                }}
              />
              <PatientList
                openCreatePatientModal={togglePatientForm}
                open={isPatientListFormOpened}
                onClose={() => togglePatientListForm(false)}
                afterSubmit={(patient) => {
                  input.onChange(patient)
                  toggleAccess(true)
                }}
              />
            </>
          )
      }
    </Paper>
  )
}
