import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Field } from 'react-final-form'
import axios from 'axios'
import styled from 'styled-components'

import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import { Checkbox, ListItemText } from '@material-ui/core'

// eslint-disable-next-line import/no-cycle
import { Line, Label } from './index'

export const Territory = ({ names }) => {
  const [regions, setRegions] = useState([])
  const [departments, setDepartments] = useState([])

  useEffect(() => {
    axios('/api/public/user/region')
      .then(res => setRegions(res.data.data.map(i => i.attributes.regionName)))
  }, [])

  const fetchDepartments = (region) => {
    if (!region || !region.length) {
      setDepartments([])
    } else {
      axios('/api/public/user/department', {
        params: {
          region,
        },
      })
        .then(res => setDepartments(res.data.data.map(i => i.attributes.department)))
    }
  }

  return (
      <>
        <Line>
          <Label>
            <FormattedMessage id='profile.general.region' />
          </Label>
          <Field
            name={names[0]}
            multiple
            render={props => (
              <Control>
                <Select
                  multiple
                  input={<InputStyled variant='outlined' id='q1' fullWidth />}
                  {...props.input}
                  onChange={(e) => {
                    props.input.onChange(e)
                    fetchDepartments(e.target.value)
                  }}
                  value={props.input.value || []}
                  renderValue={selected => (
                    <Chips>
                      {selected.map(value => (
                        <ChipStyled
                          key={value}
                          label={value}
                          onDelete={() => {
                            const newValue = props.input.value.filter(i => i !== value)
                            props.input.onChange(newValue)
                            fetchDepartments(newValue)
                          }}
                        />
                      ))}
                    </Chips>
                  )}
                >
                  {regions.map(name => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={props.input.value.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </Control>
            )}
          />
        </Line>
        <Line>
          <Label>
            <FormattedMessage id='profile.general.department' />
          </Label>

          <Field
            name={names[1]}
            multiple
            render={props => (
              <Control>
                <Select
                  multiple
                  input={<InputStyled variant='outlined' id='q1' fullWidth />}
                  {...props.input}
                  value={props.input.value || []}
                  renderValue={selected => (
                    <Chips>
                      {selected.map(value => (
                        <ChipStyled
                          key={value}
                          label={value}
                          onDelete={() => props.input
                            .onChange(props.input.value.filter(i => i !== value))}
                        />
                      ))}
                    </Chips>
                  )}
                >
                  {departments.map(name => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={props.input.value.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </Control>
            )}
          />
        </Line>
      </>
  )
}

const Chips = styled.div`
  display: flex;
  flex-flow: row wrap;
`

const ChipStyled = styled(Chip)`
  && {
    margin: 2px;
  }
`

const Control = styled(FormControl)`
  && {
    width: 100%;
  }
`

const InputStyled = styled(Input)`
  && {
    min-height: 40px;
    background-color: rgba(245,247,251,0.6);
    
    label {
      margin-left: 14px;
    }
    
    &:before {
      border-color: #e2e5ed;
    }
  }
`
