import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import DoneIcon from '@material-ui/icons/Done'
import { Colors, calendarColors } from 'constants/colors'

export const FreeTime = ({ isSelected }) => (
  <Wrapper>
    <FormattedMessage id='schedule.free' />
    {
      isSelected && (
        <SelectedOverlay>
          <DoneIcon />
        </SelectedOverlay>
      )
    }
  </Wrapper>
)

FreeTime.propTypes = {
  isSelected: PropTypes.bool.isRequired,
}

//#region Styled components
const Wrapper = styled.div`
  background-color: ${calendarColors.completedBg};
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-left: 3px solid ${calendarColors.completedBorder};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-left: 12px;
  color: ${calendarColors.freeText};
  font-weight: 500;
  text-transform: capitalize;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  font-size: 14px;
  padding-bottom: 16px;
  position: relative;
`

const SelectedOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: ${calendarColors.selectedBg};
  opacity: .5;
  display: flex;
  align-items: center;
  justify-content: center;
  
  svg {
    fill: ${Colors.white};
  }
`
//#endregion
