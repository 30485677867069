import React from 'react';
import get from 'lodash/get';
import styled from 'styled-components'
import { deleteMember } from '../../../../../../../helpers/Doctor/TeamHelper'

import manageTeamsStatic from '../../../../../../../images/panel/doctors/settings/roso/delete-icon.svg';

const MemberImage = styled.div`
    width: 36px;
    height: 36px;
    background: url(${props => props.photo})  center no-repeat;
    border-radius: 50%;
    overflow: hidden;
    background-size: cover;
    box-shadow: 0 2px 4px 0 rgba(0, 158, 255, 0.12);
    backgound-position: center;
`

const MemberRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 302px;
    margin: 0px auto;
    line-height: 69px;
`

const MemberName = styled.div`
    font-size: 16px;
    font-weight: bold;
    color: #21293e;
    margin-left: 14px;
`

const MemberNameImage = styled.div`
    display: flex;
    align-items: center;
`

export const MembersList = ({ members = [], refreshTeam }) => members.map((el) => {
  const firstName = get(el, 'relationships.doctor.data.attributes.first_name', '');
  const lastName = get(el, 'relationships.doctor.data.attributes.last_name', '');
  const photo = get(el, 'relationships.doctor.data.attributes.photo', '');
  const role = get(el, 'attributes.role', '');
  const fullName = `${firstName} ${lastName}`;

  return (
    <MemberRow key={el.id}>
      <MemberNameImage>
        <MemberImage photo={photo} />
        <MemberName>{fullName}</MemberName>
      </MemberNameImage>
      {role !== 'creator' ? (
        <div onClick={() => deleteMember({ member_id: el.id })
          .then(() => refreshTeam())
          .catch(err => console.log(err))}
        >
          <img src={manageTeamsStatic} alt='manageTeamsStatic' />
        </div>
      ) : ''}
    </MemberRow>
  )
})
