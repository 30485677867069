import React from 'react'
import styled from 'styled-components'
import { useIntl } from 'react-intl'
import { LinkCard } from 'common/UiKit/LinkCard'
import { DialogWindow } from 'common/UiKit/DialogWindow'
import { useSelector } from 'react-redux'
import { AboutDoctor } from './AboutDoctor'
import { Education } from './Education'
import { Diplomas } from './Diplomas'
import { Signature } from './Signature'
import { UserInfo } from '../../../../common/UserInfo'
import { Questions } from './Questions'

const Wrapper = styled.div`
  padding-bottom: 30px;

  & > div {
    margin-bottom: 16px;
  }
`

const LinkCardStyled = styled(LinkCard)`
  padding: 12px 16px 12px 24px;
  margin-bottom: 16px;
`

export const ProfileInfo = ({
  attributes: {
    user_id,
    date_of_birth,
    email,
    addition_email,
    country,
    experience,
    first_name,
    gender,
    language,
    last_name,
    photo,
    specialization,
    requisites,
    credentials,
    phoneNumber,
    price,
  },
  workPlace,
  education,
  diplomas,
  verified,
  dialogWindow,
  changeButtonPaths,
  addButtonPaths,
  onDiplomasClick,
  onAppointmentButtonClick,
  reviewsCount,
  reviewsLink,
  signatures,
  isPatient,
}) => {
  const { formatMessage } = useIntl()
  const isMe = useSelector(state => state.auth.attributes.user_id === user_id)

  return (
    <Wrapper>
      {
        dialogWindow && (
          <DialogWindow
            title='Your Data sent'
            content='Reply will be sent within 72 hours.'
            cacelButtonText='ok'
            open={!verified}
          />
        )
      }
      <UserInfo
        email={email}
        dateOfBirth={date_of_birth}
        firstName={first_name}
        lastName={last_name}
        photo={photo}
        gender={gender}
        changeButtonPath={verified ? changeButtonPaths.personal_data : ''}
        heading={formatMessage({ id: 'profile.personal' })}
        onAppointmentButtonClick={onAppointmentButtonClick}
        phoneNumber={phoneNumber}
        specialization={specialization}
      />
      {!isPatient && (
        <LinkCardStyled
          label={formatMessage({ id: 'profile.reviews' })}
          to={reviewsLink}
          length={reviewsCount}
          hideArrow
        />
      )}
      <AboutDoctor
        specialization={specialization}
        experience={experience}
        country={country}
        addition_email={addition_email}
        language={language}
        work_place={workPlace}
        changeButtonPath={verified ? changeButtonPaths.about_doctor : ''}
        credentials={credentials}
        requisites={requisites}
        price={price}
      />
      <Education
        education={education}
        addButtonPath={verified ? addButtonPaths.education : ''}
      />
      {
        isMe && (
          <Questions
            editBtnPath={verified ? changeButtonPaths.questions : ''}
          />
        )
      }
      {
        isMe && (
          <Diplomas
            onPaperClick={onDiplomasClick}
            diplomas={diplomas}
            changeButtonPath={verified ? changeButtonPaths.diplomas : ''}
          />
        )
      }
      {!!signatures && !!signatures.length && (
        <Signature
          signatures={signatures}
          changeButtonPath={verified ? changeButtonPaths.signature : ''}
        />
      )}
    </Wrapper>
  )
}
