import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose, mapProps } from 'recompose'
import { CoursesForm } from 'common/UiKit/CoursesForm'
import { EditPage } from 'common/UiKit/EditPage'
import { Field, Form } from 'react-final-form'
import { createCourses, deleteCourses, editCourses } from '../../../../../redux/modules/auth'
import { history } from '../../../../../utils/history'
import { externalFormSubmit } from '../../../../../helpers/externalFormSubmit'

const validate = (values) => {
  const errors = {}

  if (!values.from_year_courses_0) errors.from_year_courses_0 = 'Please enter a year'
  if (!values.to_year_courses_0) errors.to_year_courses_0 = 'Please enter a year'
  if (!values.course_0) errors.course_0 = 'Please enter a course'
  if (values.description_0 && values.description_0.length > 200) errors.description_0 = true
  if (+values.from_year_courses_0 > +values.to_year_courses_0) {
    errors.to_year_courses_0 = 'This year must be larger than previous'
  }

  return errors
}

const EditCoursesComponent = ({
  loading,
  location,
  onSubmit,
  initialValues,
  onButtonClick,
  onBackIconClick,
  onDeleteIconClick,
  id,
}) => (
  <EditPage
    label={location.hash ? 'profile.qualification' : 'profile.add.course'}
    onBackIconClick={onBackIconClick}
    buttonText={location.hash ? 'global.save' : 'global.save'}
    onDeleteIconClick={location.hash ? onDeleteIconClick : false}
    onButtonClick={onButtonClick}
    loading={loading}
    dialogTitle='Delete Course?'
    id={id}
  >
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      subscription={{}}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} id='add_courses'>
          <CoursesForm
            Field={Field}
            disableShadow
          />
        </form>
      )}
    />
  </EditPage>
)

EditCoursesComponent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  onBackIconClick: PropTypes.func.isRequired,
  onDeleteIconClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
}

const normalizeValues = (values) => {
  const course = {}

  Object.keys(values).forEach((key) => {
    if (key.includes('from_year')) course.from = values[key]
    if (key.includes('to_year')) course.to = values[key]
    if (key.includes('course_')) course.course = values[key]
    if (key.includes('description')) course.description = values[key]
  })

  return course
}

const normalizeInitialValues = (values = { attributes: {} }) => {
  const course = {}

  Object.keys(values.attributes).forEach((key) => {
    if (key.includes('from')) course.from_year_courses_0 = values.attributes[key]
    if (key.includes('to')) course.to_year_courses_0 = values.attributes[key]
    if (key.includes('course')) course.course_0 = values.attributes[key]
    if (key.includes('description')) course.description_0 = values.attributes[key]
  })

  return course
}

const enhance = compose(
  connect(
    state => ({
      courses: state.auth.relationships.qualificationCourses.data,
      loading: state.loading.courses,
    }),
    { createCourses, deleteCourses, editCourses },
  ),
  mapProps(props => ({
    ...props,
    onBackIconClick: () => history.push('/panel/profile'),
    onButtonClick: () => externalFormSubmit('add_courses'),
    onDeleteIconClick: id => (
      props.deleteCourses(id)
        .then(() => history.push('/panel/profile'))
    ),
    onSubmit: values => (props.location.hash
      ? props.editCourses(
        normalizeValues(values),
        props.courses[+props.location.hash.slice(1)].id,
      )
        .then(() => history.push('/panel/profile'))
      : props.createCourses({ qualificationCourses: [normalizeValues(values)] })
        .then(() => history.push('/panel/profile'))
    ),
    initialValues: props.location.hash
      ? normalizeInitialValues(props.courses[+props.location.hash.slice(1)])
      : {},
    id: props.location.hash && props.courses[+props.location.hash.slice(1)]
      ? props.courses[+props.location.hash.slice(1)].id
      : '0',
  })),
)

export const EditCourses = enhance(EditCoursesComponent)
