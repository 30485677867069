import React from 'react'
import { PropTypes } from 'prop-types'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { compose, withProps } from 'recompose'
import moment from 'moment'
import { changeLocale } from '../../../utils/changeLocale'

import { setLanguage } from '../../../redux/modules/localization'
import { editProfile, changeLanguage } from '../../../redux/modules/auth'
import { LANGUAGES } from '../../../constants/languages'
import { fetchSpecializations } from '../../../redux/modules/content'

const LanguageSelect = styled(Select)`
  && {
    margin-right: 8px;
    border-bottom: none;
    margin-top: 3px;
  }
  
  &&:before, &&:after {
    content: none;
  }
  
  && .select {
    border-radius: 30px;
    text-transform: uppercase;
    background-color: rgba(0,0,0,.1);
    color: #fff;
    cursor: pointer;
    opacity: .8;
    font-size: 14px;
    padding: 6px 32px 6px 16px;
  }
  
  && svg {
    color: #fff;
    opacity: .8;
    margin-right: 2px;
  }
`

const LanguageSwitcherComponent = ({ activeLanguage, setActiveLanguage }) => (
  <LanguageSelect
    value={activeLanguage}
    onChange={e => setActiveLanguage(e.target.value)}
    classes={{ select: 'select', root: 'root' }}
    renderValue={value => value}
  >
    {
      LANGUAGES.map(item => (
        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
      ))
    }
  </LanguageSelect>
)

LanguageSwitcherComponent.propTypes = {
  activeLanguage: PropTypes.string,
  setActiveLanguage: PropTypes.func.isRequired,
}

LanguageSwitcherComponent.defaultProps = {
  activeLanguage: 'en',
}

export const LanguageSwitcher = compose(
  connect(
    state => ({
      activeLanguage: state.localization,
      isAuthenticated: !!state.auth.id,
    }),
    {
      setLanguage, editProfile, fetchSpecializations, changeLanguage, 
    },
  ),
  withProps(props => ({
    setActiveLanguage: (value) => {
      if (typeof document !== 'undefined') {
        document.documentElement.lang = value;
      }
      props.setLanguage(value)
      changeLocale(value)
      localStorage.setItem('language', value)
      moment.locale(value)
      if (props.isAuthenticated) {
        props.changeLanguage({
          interface_language: value,
        }).then(() => {
          props.fetchSpecializations()
        })
      }
    },
  })),
)(LanguageSwitcherComponent)
