import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper'
import idx from 'idx'
import axios from 'axios';
import get from 'lodash/get';


const locationHelper = locationHelperBuilder({})

export const getVirtualPatients = filters => axios(`/api/doctor/virtualPatient${filters.name ? `?name=${filters.name}` : ''}`)
  .then(result => get(result, 'data.data', []))
  .catch(err => console.warn(err))

export const consultationIsRecieved = connectedRouterRedirect({
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/panel/consultations',
  authenticatedSelector: state => !!state.consultations.consultation.id,
  wrapperDisplayName: 'ConsultationIsRecieved',
  allowRedirectBack: false,
})

// TODO: kostyl'
export class MessageCreator {
  constructor(consultation, pushMessageAction) {
    this.consultation = consultation
    this.pushMessageAction = pushMessageAction
  }

  makeReportMessage() {
    const report = idx(this.consultation, _ => _.relationships.medicalReport.data.attributes)
    const drugs = idx(this.consultation, _ => _.relationships.drugs.data)

    if (!report || !report.diagnosis || !drugs) return ''

    if (drugs && drugs.length) {
      return JSON.stringify({
        type: 'report',
        user: 'doctor',
        time: Date.now(),
        diagnosis: report.diagnosis,
        medicines: drugs.map(({ attributes }) => (
          `${attributes.name}, ${idx(attributes, _ => _.type)}, ${attributes.quantity ? `${attributes.quantity} pills` : ''}, ${attributes.dosage} ${idx(attributes, _ => _.unitType)}, ${attributes.iteration} times, ${attributes.duration} weeks.`
        )),
      })
    }

    return JSON.stringify({
      type: 'report',
      user: 'doctor',
      time: Date.now(),
      diagnosis: report.diagnosis,
    })
  }

  makePrescriptionsMessage() {
    const drugs = idx(this.consultation, _ => _.relationships.drugs.data)

    if (!drugs) return ''

    if (drugs && drugs.length) {
      return JSON.stringify({
        type: 'prescription',
        user: 'doctor',
        time: Date.now(),
        medicines: drugs.map(({ attributes }) => (
          `${attributes.name}, ${idx(attributes, _ => _.type)}, ${attributes.dosage} ${idx(attributes, _ => _.unitType)}, ${attributes.iteration} times, ${attributes.duration} weeks.`
        )),
      })
    }

    return ''
  }

  makeAppointmentMessage() {
    return JSON.stringify({
      type: 'info',
      user: 'patient',
      time: Date.now(),
      heading: 'Appointmen confirmed',
      date: idx(this.consultation, _ => _.attributes.creation_time),
    })
  }

  makeSymptomsMessage() {
    return JSON.stringify({
      type: 'info',
      user: 'patient',
      time: Date.now(),
      heading: 'Symptoms',
      message: idx(this.consultation, _ => _.attributes.symptom_explanation),
    })
  }

  pushReportMessage() {
    const reportMessage = this.makeReportMessage()

    if (reportMessage) this.pushMessageAction(reportMessage)
  }

  pushPresciptionMessage() {
    const prescriptionMessage = this.makePrescriptionsMessage()

    if (prescriptionMessage) this.pushMessageAction(prescriptionMessage)
  }

  pushInitialMessages() {
    this.pushMessageAction(this.makeAppointmentMessage())
    this.pushMessageAction(this.makeSymptomsMessage())
    this.pushReportMessage()
    this.pushPresciptionMessage()
  }
}

export const getDoctorPrice = (attributes, type) => {
  let priceKey = type === 'urgent' ? 'urgent_price' : 'daily_price'
  if (type === 'weekend_price' || type === 'urgent_price') {
    priceKey = type;
  }
  // const weekDay = moment().isoWeekday()
  
  // if (weekDay === 6 || weekDay === 7) {
  //   priceKey = 'weekend_price'
  // }

  return +idx(attributes, _ => _.price[priceKey])
}

export const getMainReport = (state, doctorId) => idx(state, _ => _
  .consultations.consultation.relationships.medicalReport.data
  .find(item => item.attributes.doctor === (doctorId || +_
    .consultations.consultation.relationships.doctor.data.id)))

export const getAllParticipants = (consultation) => {
  const users = []

  const patient = idx(consultation, _ => _.relationships.patient.data)
  const doctor = idx(consultation, _ => _.relationships.doctor.data)

  users.push(doctor)
  users.push(patient)

  const additionalMembers = idx(consultation, _ => _.relationships.additionalMember.data)

  if (additionalMembers && additionalMembers.length) {
    additionalMembers.forEach(user => users.push({
      ...user,
      attributes: {
        ...user.attributes,
        ...(user.attributes.doctor || {}),
      },
    }))
  }

  return users
}

export const matchByName = search => (item) => {
  if (!search) return true
  const patientName = `${idx(item, _ => _.relationships.patient.data.attributes.first_name)} ${idx(item, _ => _.relationships.patient.data.attributes.last_name)}`.toLowerCase()
  const doctorName = `${idx(item, _ => _.relationships.doctor.data.attributes.first_name)} ${idx(item, _ => _.relationships.doctor.data.attributes.last_name)}`.toLowerCase()
  const additionalName = `${idx(item, _ => _.relationships.additionalMember.data[0].attributes.doctor.first_name)} ${idx(item, _ => _.relationships.additionalMember.data[0].attributes.doctor.last_name)}`.toLowerCase()

  return patientName.includes(search.toLowerCase()) 
  || doctorName.includes(search.toLowerCase())
  || additionalName.includes(search.toLowerCase())
}


export const deleteChatMessage = messageId => axios(`/api/chat/delete/${messageId}`, {
  method: 'DELETE',
})
  .catch(e => console.warn(e))
