import React, { useEffect } from 'react';
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import IconButton from '@material-ui/core/IconButton'
import Avatar from '@material-ui/core/Avatar'
import { FormattedMessage } from 'react-intl'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import MicIcon from '@material-ui/icons/Mic'
import MicOffIcon from '@material-ui/icons/MicOff'
import VideocamIcon from '@material-ui/icons/Videocam'
import VideocamOffIcon from '@material-ui/icons/VideocamOff'
import moment from 'moment'
import { OTPublisher } from 'opentok-react'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress } from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'
import defaultAvatar from '../../../images/panel/default-avatar.svg'
import { getAllParticipants } from '../../../helpers/consultations'
import { toggleAudioAccess, toggleVideoAccess } from '../../../redux/modules/consultations'
import { setCallAnswer } from '../../../redux/modules/content'

export const WaitingRoom = ({ onEnd, matchUrl }) => {
  const access = useSelector(state => state.consultations.mediaAccess)
  const consultation = useSelector(state => state.consultations.consultation)
  const myId = useSelector(state => state.auth.attributes.user_id)
  const users = getAllParticipants(consultation)
    .filter(item => ((item && item.attributes) ? item.attributes.user_id !== myId : false))
  const dispatch = useDispatch()
  const doctorAnswer = useSelector(state => (state.auth.type !== 'Doctor' ? state.content.callAnswer : ''))

  const onAudioToggle = () => dispatch(toggleAudioAccess(!access.audio))
  const onVideoToggle = () => dispatch(toggleVideoAccess(!access.video))

  useEffect(() => {
    if (!matchUrl) onEnd()
  }, [matchUrl])

  useEffect(() => () => dispatch(setCallAnswer(null)), [])

  return (
    <Wrapper>
      <Header>
        <Back onClick={onEnd}>
          <IconButton>
            <ArrowBackIcon />
          </IconButton>
          <FormattedMessage id='waiting.back' tagName='span' />
        </Back>
        <Date>
          {moment().format('dddd, MMMM DD')}
          {' | '}
          {moment().format('HH:mm')}
        </Date>
      </Header>
      <Content>
        <PublisherWrapper>
          <OTPublisher
            properties={{
              height: '342px',
              width: '600px',
              showControls: false,
              publishAudio: access.audio,
              publishVideo: access.video,
            }}
          />
          <Controls>
            <IconButton onClick={onAudioToggle}>
              {access.audio ? <MicIcon /> : <MicOffIcon />}
            </IconButton>
            <IconButton onClick={onVideoToggle}>
              {access.video ? <VideocamIcon /> : <VideocamOffIcon />}
            </IconButton>
          </Controls>
        </PublisherWrapper>
        <MeetingInfo>
          <FormattedMessage id='waiting.title' tagName='h4' />
          <div>
            {
              users.length
                ? users.map(item => (
                  <Photo
                    src={item.attributes.photo || defaultAvatar}
                    key={item.id}
                  />
                ))
                : null
            }
          </div>
          {
            users.length === 1 && (
              <Name>
                {users[0].attributes.first_name || ''}
                {' '}
                {users[0].attributes.last_name || ''}
              </Name>
            )
          }
          <Wait>
            <CircularProgress color='secondary' size={26} />
            <FormattedMessage id='waiting.wait' tagName='div' />
          </Wait>
          {
            doctorAnswer && (
              <Message>
                <ErrorIcon />
                <FormattedMessage id={doctorAnswer} defaultMessage={doctorAnswer} />
              </Message>
            )
          }
        </MeetingInfo>
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-flow: column nowrap;
  z-index: 10;
`

const Header = styled.div`
  padding: 16px;
  border-bottom: 2px solid ${Colors.grey};
  align-items: center;
  display: flex;
  justify-content: space-between;
`

const Back = styled.div`
  & > span {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 500;
    color: ${Colors.dark};
    margin-left: 12px;
  }
  
  display: flex;
  align-items: center;
  cursor: pointer;
`

const Date = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: #283044;
  text-transform: capitalize;
`

const Content = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  box-sizing: border-box;
`

const MeetingInfo = styled.div`
  margin-left: 72px;
  text-align: center;
  
  h4 {
    font-size: 32px;
    line-height: 38px;
    font-weight: 500;
    color: ${Colors.dark};
  }
  
  div {
    text-align: center;
  }
`

const Photo = styled(Avatar)`
  && {
    height: 44px;
    width: 44px;
    display: inline-block;
    margin: 0 -16px;
    border: 3px solid white;
  }
`

const Name = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.dark};
`

const Wait = styled.div`
  padding: 16px 24px;
  color: ${Colors.tealish};
  text-transform: uppercase;
  will-change: transform;
  font-weight: 500;
  font-size: 16px;
  margin-top: 32px;
  max-width: 220px;
  
  & > div {
    margin-top: 18px;
  }
`

const PublisherWrapper = styled.div`
  position: relative;
  
  & > div:first-child {
    &, & > div {
      @media (max-width: 1300px) {
        max-width: 30vw;
      }
    }
  }
`

const Controls = styled.div`
  position: absolute;
  bottom: 24px;
  width: 100%;
  text-align: center;
  z-index: 11;
  color: white;
  
  button {
    color: white;
    border: 1px solid white;
    margin: 0 12px;
  }
`

const Message = styled.div`
  background-color: #f7bd40;
  border-radius: 4px;
  color: white;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  align-items: flex-start;
  padding: 12px;
  max-width: 200px;
  text-align: left !important;
  margin: 0 auto;
  svg {
    margin-right: 12px;
  }
`
