import React from 'react'
import PropTypes from 'prop-types'
import idx from 'idx'
import styled from 'styled-components'
import {
  compose,
  withProps,
  withStateHandlers,
  lifecycle,
} from 'recompose'
import { connect } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import { AutocompleteField } from 'common/UiKit/AutocompleteField'
import { Colors } from 'constants/colors'
import { Paper } from 'common/UiKit/Paper'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Delete from '@material-ui/icons/Delete'
import { Page } from '../../../../common/Page'
import {
  fetchMedicines,
  checkMedicinesWithEachOther,
  clearCheckedMedicines,
} from '../../../../../redux/modules/medicines'
import { CheckedList } from './CheckedList'

const InputWrapper = styled.div`
  display: flex;
  align-items: flex-start;

  &>div:first-child {
    flex-grow: 1;
  }

  button {
    margin: 10px 0 0 20px;
    font-weight: 500;
  }
`

const List = styled.ol`
  counter-reset: myCounter;
  padding-left: 0;
  margin: 0;

  &>li {
    display: flex;
    align-items: center;
    position: relative;
    list-style: none;
    margin-bottom: 20px;
    color: ${Colors.dark80};
    font-weight: 500;

    button {
      margin-left: auto;
      padding: 3px;
    }
  }

  &>li:before {
    counter-increment: myCounter;
    content: counter(myCounter) ".";
    margin-right: 13px;
  }
`

const DrugIncompabilityComponent = ({
  onBackIconClick,
  // searchMedicines,
  options,
  value,
  onChange,
  addMedicine,
  medicines,
  deleteMedicine,
}) => {
  const { formatMessage: f } = useIntl()
  return (
    <Page
      label='hr.incompatibility'
      onBackIconClick={onBackIconClick}
    >
      <CheckedList />
      <Paper heading='hr.select.medicines'>
        <List>
          {/* eslint-disable */}
          {medicines.map((m, i) => (
            <li key={m.value + i}>
              {m.label}
              <IconButton
                color='primary'
                onClick={() => deleteMedicine(m.value)}
              >
                <Delete />
              </IconButton>
            </li>
          ))}
          {/* eslint-enable */}
        </List>
        <InputWrapper>
          <AutocompleteField
            label={f({ id: 'hr.medicaments' })}
            options={options}
            onChange={() => {}}
            input={{ value, onChange }}
            meta={{}}
          />
          <Button
            color='primary'
            disabled={!value}
            onClick={addMedicine}
          >
            <FormattedMessage id='hr.add' />
          </Button>
        </InputWrapper>
      </Paper>
    </Page>
  )
}

DrugIncompabilityComponent.defaultProps = {
  options: [],
}

DrugIncompabilityComponent.propTypes = {
  onBackIconClick: PropTypes.func.isRequired,
  // searchMedicines: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  onChange: PropTypes.func.isRequired,
  addMedicine: PropTypes.func.isRequired,
  deleteMedicine: PropTypes.func.isRequired,
  medicines: PropTypes.arrayOf(PropTypes.object).isRequired,
}

const enhance = compose(
  connect(
    state => ({
      options: idx(state, _ => _.medicines.list).map(m => ({
        label: m.attributes.name,
        value: m.id,
      })),
    }),
    { fetchMedicines, checkMedicinesWithEachOther, clearCheckedMedicines },
  ),
  lifecycle({
    componentWillUnmount() {
      this.props.clearCheckedMedicines()
    },
  }),
  withStateHandlers({
    value: '',
    medicines: [],
  }, {
    onChange: () => value => ({ value }),
    addMedicine: ({ medicines, value }, { checkMedicinesWithEachOther }) => () => {
      const newMedicines = [...medicines, { label: value }]

      checkMedicinesWithEachOther({ medicines: newMedicines })

      return ({
        medicines: newMedicines,
        value: '',
      })
    },
    deleteMedicine: ({ medicines }, { checkMedicinesWithEachOther }) => (id) => {
      const newMedicines = medicines.filter(m => m.value !== id)

      checkMedicinesWithEachOther({ medicines: newMedicines })

      return ({
        medicines: newMedicines,
      })
    },
  }),
  withProps(props => ({
    onBackIconClick: () => props.history.push('/panel/health-records'),
    searchMedicines: v => v.length > 1 && props.fetchMedicines(v),
  })),
)

export const DrugIncompability = enhance(DrugIncompabilityComponent)
