import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'
import DoneOutlineIcon from '@material-ui/icons/DoneOutline'

import { MonthDayConsultation } from './Consultation'
import { haveUrgent, isDayOff } from '../../../../../../helpers/calendar'

export const MonthDay = ({
  day,
  grey,
  onClick,
  data,
}) => {
  const isToday = useMemo(() => day.isSame(moment(), 'day'), [day])
  const isHoliday = useMemo(() => isDayOff(data), [data])
  const haveUrgentConsultations = useMemo(() => haveUrgent(data), [data])
  const consultations = useMemo(() => (data ? Object.keys(data).map((period) => {
    if (data[period].type === 'Consultation') {
      return data[period]
    }
    return undefined
  }).filter(item => !!item) : []), [data])
  const addition = consultations.length - 2

  return (
    <Wrapper blue={isToday} onClick={onClick}>
      <Title blue={isToday} grey={grey}>
        {day.format('DD')}
        {isHoliday && (
          <DayOff>
            <FormattedMessage id='schedule.day.off' />
          </DayOff>
        )}
        {
          (!isHoliday && haveUrgentConsultations) && (
            <Urgent>
              <DoneOutlineIcon />
              <FormattedMessage id='schedule.urgent' />
            </Urgent>
          )
        }
      </Title>
      <Content>
        {
          consultations.slice(0, 2).map(item => (
            <MonthDayConsultation
              key={item.date_time}
              time={moment(item.date_time).format('HH:mm')}
              consultation={item.data}
            />
          ))
        }
        {
          addition > 0 && (
            <Addition>
              +{addition} records
            </Addition>
          )
        }
      </Content>
    </Wrapper>
  )
}

MonthDay.defaultProps = {
  data: undefined,
}

MonthDay.propTypes = {
  day: PropTypes.object.isRequired,
  grey: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  data: PropTypes.object,
}

//#region Styled components
const Wrapper = styled.div`
  width: 14.28%;
  height: 240px;
  box-sizing: border-box;
  background-color: ${Colors.white};
  cursor: pointer;
  
  border: 1px solid ${props => (props.blue ? Colors.clearBlue : Colors.divider)};
  ${props => !props.blue && `
    border-left: none;
    border-top: none;
  `}
`

const Title = styled.div`
  padding: 18px 14px;
  font-size: 32px;
  font-weight: 700;
  color: ${(props) => {
    if (props.blue) return Colors.clearBlue
    if (props.grey) return Colors.disabledText
    return Colors.dark80
  }};
`

const Content = styled.div`

`

const Addition = styled.div`
  padding: 8px;
  font-size: 14px;
  color: ${Colors.dark80};
  font-weight: 700;
  text-decoration: underline;
`

const DayOff = styled.span`
  color: ${Colors.clearBlue};
  margin-left: 12px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  vertical-align: top;
`

const Urgent = styled.span`
  color: ${Colors.tealish};
  margin-left: 12px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  vertical-align: top;
  display: inline-flex;
  
  svg {
    width: 12px;
    height: 12px;
    margin-right: 6px;
  }
`
//#endregion
