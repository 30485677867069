import { handleActions, createAction } from 'redux-actions'
import { combineReducers } from 'redux'
import axios from 'axios'

//#region Actions
export const fetchDoctorsListRequest = createAction('FETCH_DOCTORS_LIST_REQUEST')
export const fetchDoctorsListSuccess = createAction('FETCH_DOCTORS_LIST_SUCCESS')
export const fetchDoctorsListFailure = createAction('FETCH_DOCTORS_LIST_FAILURE')

export const fetchPaginatorListSuccess = createAction('FETCH_PAGINATOR_LIST_SUCCESS')

export const fetchDoctors = (specId, isAdditional, filter = {}) => (dispatch, getStore) => {
  dispatch(fetchDoctorsListRequest(specId))

  const url = isAdditional ? '/doctor/consultation/addDoctor/search' : '/general/doctor'
  const spec = getStore().content.specializations.find(item => +item.id === specId)
  const { language } = getStore().auth.attributes
  const params = isAdditional 
    ? { specialization: spec ? spec.attributes.key : undefined, ...filter } : {
      include: 'workPlace',
      language,
      limit: 10,
      specialization: specId,
      ...filter,
    }

  return axios(url, {
    params,
  })
    .then((res) => {
      dispatch(fetchDoctorsListSuccess(res.data.data))
      dispatch(fetchPaginatorListSuccess(res.data.paginator))
    })
    .catch(e => dispatch(fetchDoctorsListFailure(e)))
}

export const fetchDoctorRequest = createAction('FETCH_DOCTOR_REQUEST')
export const fetchDoctorSuccess = createAction('FETCH_DOCTOR_SUCCESS')
export const fetchDoctorFailure = createAction('FETCH_DOCTOR_FAILURE')

export const fetchDoctor = id => (dispatch) => {
  dispatch(fetchDoctorRequest(id))

  return axios(`/general/doctor/${id}?include=workPlace,education,schedule,diploma,qualificationCourses`)
    .then(res => dispatch(fetchDoctorSuccess(res.data)))
    .catch(e => dispatch(fetchDoctorFailure(e)))
}

export const toggleFavoriteRequest = createAction('TOGGLE_FAVORITE_REQUEST')
export const toggleFavoriteSuccess = createAction('TOGGLE_FAVORITE_SUCCESS')
export const toggleFavoriteFailure = createAction('TOGGLE_FAVORITE_FAILURE')

export const toggleFavorite = (doctor, value) => (dispatch) => {
  dispatch(toggleFavoriteRequest({ doctor, value }))

  return axios(`/general/patient/favorite_doctor/${doctor}`, { method: value ? 'POST' : 'DELETE' })
    .then(response => dispatch(toggleFavoriteSuccess(response.data)))
    .catch((err) => {
      dispatch(toggleFavoriteFailure(err))
    })
}

export const fetchFavoriteRequest = createAction('FETCH_FAVORITE_REQUEST')
export const fetchFavoriteSuccess = createAction('FETCH_FAVORITE_SUCCESS')
export const fetchFavoriteFailure = createAction('FETCH_FAVORITE_FAILURE')

export const fetchFavorite = () => (dispatch) => {
  dispatch(fetchFavoriteRequest())

  return axios('/general/patient/favorite_doctor')
    .then(response => dispatch(fetchFavoriteSuccess(response.data)))
    .catch((err) => {
      dispatch(fetchFavoriteFailure(err))
    })
}

//#endregion

//#region Reducers
const paginator = handleActions({
  [fetchPaginatorListSuccess]: (state, action) => action.payload,
}, [])
const list = handleActions({
  //[fetchDoctorsListSuccess]: (state, action) => action.payload.data,
  [fetchDoctorsListSuccess]: (state, action) => action.payload,
  [toggleFavoriteSuccess]: (state, action) => state.map((item) => {
    if (item.id === action.payload.data.id) {
      return {
        ...item,
        attributes: { ...item.attributes, favorite: action.payload.data.attributes.favorite },
      }
    }

    return item
  }),
}, [])

const favorite = handleActions({
  [fetchFavoriteSuccess]: (state, action) => action.payload.data,
  [toggleFavoriteSuccess]: (state, action) => state.filter((item) => {
    if (!action.payload.data.attributes.favorite && item.id === action.payload.data.id) {
      return false
    }

    return true
  }),
}, [])

const doctor = handleActions({
  [fetchDoctorSuccess]: (state, action) => action.payload.data,
  [toggleFavoriteSuccess]: (state, action) => {
    if (action.payload.data.id === state.id) {
      return {
        ...state,
        attributes: { ...state.attributes, favorite: action.payload.data.attributes.favorite },
      }
    }

    return state
  },
}, {})

export const doctors = combineReducers({
  paginator,
  list,
  doctor,
  favorite,
})
//#endregion
