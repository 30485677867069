import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { Colors } from 'constants/colors'
import { FormattedMessage } from 'react-intl'

const LinkStyled = styled(Link)`
  text-decoration: none;
  width: ${({ table }) => (table ? 'calc(50% - 8px)' : '100%')};
  margin: 8px 4px;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  display: flex;
  font-family: Roboto, sans-serif;
  border-radius: 4px;
  background-color: ${Colors.white};
  box-shadow: 0 4px 8px 0 ${Colors.azure};

  & svg {
    color: ${Colors.cloudyBlue};
  }
`

const Content = styled.div`
  margin: 0 16px;
  flex-grow: 1;
  font-size: 14px;
`

const Text = styled.p`
  margin: 0;
  color: ${Colors.blueGrey};
`

const Label = styled.h1`
  margin: 0;
  font-size: 22px;
  font-weight: 500;
  color: ${Colors.dark};
  opacity: 0.8;
  word-wrap: break-word;
  line-height: 1;
`

export const LinkCard = ({
  label,
  text,
  to,
  isListView,
}) => (
  <LinkStyled to={to} table={!isListView || undefined}>
    <Wrapper>
      <Content>
        <Label>
          {
            label === 'Thérapeute'
              ? 'Médecin Généraliste'
              : <FormattedMessage id={label} defaultMessage={label} />
          }
        </Label>
        {!!text && (
          <Text>
            <FormattedMessage id={text} defaultMessage={text} />
          </Text>
        )}
      </Content>
      <KeyboardArrowRight />
    </Wrapper>
  </LinkStyled>
)
