import React, { useState } from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Form, Field } from 'react-final-form'
import { useDispatch } from 'react-redux';
import { EditPage } from '../../../../../common/EditPage';
import { history } from '../../../../../../utils/history'
import { TextField } from '../../../../../common/FinalFormFields'
import { MultilineTextField } from '../../../../../common/MultilineTextField';
import { dark } from '../../../../../../constants/colors';
import { externalFormSubmit } from '../../../../../../helpers/externalFormSubmit'
import { createTeam } from '../../../../../../redux/modules/settings';

const MultilineField = styled(MultilineTextField)`
  & .root {
    height: 88px;
  }
`

const Heading = styled.h1`
  margin-bottom: 32px;
  font-size: 24px;
  font-weight: 600;
  color: ${dark};
  font-family: Roboto, sans-serif;
`

export const CreateTeam = () => {
  const onBackIconClick = () => history.push('/panel/community/roso')

  const dispatch = useDispatch()

  const [error, setError] = useState(null)
  const id = 'create-team-form';
  const submit = (values) => {
    dispatch(createTeam(values))
      .then(() => history.push('/panel/community/roso'))
      .catch(err => setError(err.message))
  }

  console.log(error);

  const validate = (values = {}) => {
    const errors = {};

    if (!values.name) {
      errors.name = 'Name required!';
    }

    if (!values.description) {
      errors.description = 'Description required!';
    }

    return errors;
  }

  return (
    <EditPage
      label='roso.community.label'
      onBackIconClick={onBackIconClick}
      buttonText='global.save'
      cancelButtonText='global.cancel'
      onButtonClick={() => externalFormSubmit(id)}
      onCancelButtonClick={() => history.push('/panel/community/roso')}
      fullWidth
      confirmButtonsProps={{ color: 'primary' }}
      cancelButtonProps={{ variant: 'outlined', color: 'primary' }}
    >
      <Wrapper>
        <Heading>
          <span>
            <FormattedMessage id='roso.addTeamHeading' />
          </span>
        </Heading>
        <Form
          validate={validate}
          onSubmit={submit}
          render={({ handleSubmit, errors }) => (
            <form onSubmit={handleSubmit} id={id}>
              <Field
                name='name'
                component={TextField}
                label={<FormattedMessage id='roso.teamNamePlaceholder' />}
                autoFocus
                error={errors.name}
                //helperText={error}
              />
              <Field
                name='description'
                multiline
                rowsMax='6'
                component={MultilineField}
                label={<FormattedMessage id='roso.teamDescriptionPlaceholder' />}
                error={errors.description}
                //helperText={errors.description}
              />
            </form>
          )}
        />
      </Wrapper>
    </EditPage>
  )
}

const Wrapper = styled.div`
  max-width: 720px;
  min-width: 720px;
  margin: 24px auto;
`
