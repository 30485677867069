import React from 'react'
import { Form } from 'react-final-form'
import { compose, withStateHandlers, mapProps } from 'recompose'
import styled from 'styled-components'
import idx from 'idx'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { FormattedMessage } from 'react-intl'
import { Paper } from '../Paper'
import { MedicineForm } from './MedicineForm'

const ButtonStyled = styled(Button)`
  && {
    margin-left: 156px;
    font-weight: 500;
    font-size: 14px;
  }
`

const PrescriptionFormComponent = ({
  onSubmit,
  id,
  disableShadow,
  medicines,
  addMedicine,
  deleteMedicine,
  onChange,
  options,
}) => (
  <Form
    onSubmit={onSubmit}
    subscription={{ valid: true, values: true }}
    render={({ handleSubmit, valid, values }) => (
      <form onSubmit={handleSubmit} id={id}>
        <Paper disableShadow={disableShadow}>
          {
            medicines.map(i => (
              <MedicineForm
                key={i}
                id={i}
                onDeleteButtonClick={deleteMedicine}
                last={i !== 0 && medicines.length === i + 1}
                options={options}
                onChange={onChange}
                medicineType={idx(values, _ => _.medicationPrescribed[i].type)}
              />
            ))
          }
          <ButtonStyled
            color='primary'
            disabled={!valid}
            onClick={addMedicine}
          >
            <FormattedMessage id='consultations.add.medicine' />
          </ButtonStyled>
        </Paper>
      </form>
    )}
  />
)


// TODO: change after adding API
const normalizeValues = values => ({
  ...values,
})

const enhance = compose(
  mapProps(props => ({
    ...props,
    onSubmit: values => props
      .onSubmit({ ...normalizeValues(values) })
      .then(() => props.afterSubmit()),
  })),
  withStateHandlers({
    medicines: [0],
  }, {
    addMedicine: ({ medicines }) => () => ({ medicines: [...medicines, medicines.length] }),
    deleteMedicine: ({ medicines }) => () => ({ medicines: medicines.slice(0, -1) }),
  }),
)

PrescriptionFormComponent.defaultProps = {
  disableShadow: false,
  options: [],
}

PrescriptionFormComponent.propTypes = {
  medicines: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSubmit: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  disableShadow: PropTypes.bool,
  addMedicine: PropTypes.func.isRequired,
  deleteMedicine: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
}

export const PrescriptionForm = enhance(PrescriptionFormComponent)
