import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import { FormattedMessage } from 'react-intl'
import Tooltip from '@material-ui/core/Tooltip'

export const Copy = ({ link }) => {
  const inputRef = useRef(null)
  const [tooltip, toggleTooltip] = useState(false)

  const copyToClipboard = () => {
    if (inputRef.current) {
      inputRef.current.select()
      document.execCommand('copy')
      toggleTooltip(true)
      setTimeout(() => toggleTooltip(false), 1500)
    }
  }

  return (
    <Tooltip
      title={<TooltipTitle><FormattedMessage id='schedule.copied' /></TooltipTitle>}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      open={tooltip}
    >
      <Wrapper onClick={copyToClipboard}>
        <Input value={link} readOnly ref={inputRef} />
        <CopyLink>
          <FormattedMessage id='schedule.copy' />
          <FileCopyOutlinedIcon />
        </CopyLink>
      </Wrapper>
    </Tooltip>
  )
}

Copy.propTypes = {
  link: PropTypes.string.isRequired,
}

//#region Styled Components
const Input = styled.input`
  padding: 18px 14px 60px;
  border-radius: 6px;
  border: 2px solid ${Colors.disabledText};
  outline: none;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all .1s ease-out;
  
  &:hover {
    border-color: ${Colors.clearBlue};
    
    & + a {
      color: ${Colors.clearBlue};
    }
  }
`

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 16px;
  margin-top: 16px;
`

const CopyLink = styled.a`
  color: ${Colors.disabledText};
  text-decoration: underline;
  cursor: pointer;
  position: absolute;
  bottom: 12px;
  right: 12px;
  display: flex;
  align-items: center;
  transition: all .1s ease-out;
  
  &:hover {
    color: ${Colors.clearBlue};
  }
  
  svg {
    margin-left: 8px;
  }
`

const TooltipTitle = styled.span`
  font-size: 14px;
  padding: 6px;
  display: inline-block;
`
//#endregion
