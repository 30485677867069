import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import get from 'lodash/get';
import Button from '@material-ui/core/Button';
import { getTeamById } from '../../../../../../helpers/Doctor/TeamHelper'
import { Page } from '../../../../../common/Page'
import { history } from '../../../../../../utils/history'
import { blueGrey } from '../../../../../../constants/colors';
import { TeamInviteForm } from './TeamInviteForm';
import { externalFormSubmit } from '../../../../../../helpers/externalFormSubmit'
import { MembersList } from './MembersList';

import manageTeamsStatic from '../../../../../../images/panel/doctors/settings/roso/manageTeamsStatic.png';


const Wrapper = styled.div`
  max-width: 720px;
  min-width: 720px;
  margin: 24px auto;
`
const LeftTeamSide = styled(Grid)`
  padding-right: 8px;
`

const RightTeamSide = styled(Grid)`
  padding-left: 8px;
`

const TeamInfoHeading = styled.h1`
  font-size: 24px;
  text-align: center;
  color: #21293e;
  margin-bottom: 8px;
`

const ManageTeamImage = styled.div`
    display: inline-block;
    text-align: center;
    width: 100%;
    padding-top: 24px;
`

const LogoAddTeam = styled.img` 
    max-width: 150px;
`

const TeamInfoSubHeading = styled.div`
    font-size: 16px;
    color: #21293e;
    margin-bottom: 8px;
    text-align: center;
`

const TeamInfoStaticText = styled.div`
    max-width: 254px;
    margin: 0px auto;
    font-size: 16px;
    color: ${blueGrey};
    padding-bottom: 33px;
`

const Administrator = styled.div`
    
`
const AdministratorImage = styled.div`
    width: 36px;
    height: 36px;
    background: url(${props => props.photo})  center no-repeat;
    margin: 0px auto;
    border-radius: 50%;
    overflow: hidden;
    background-size: cover;
    box-shadow: 0 2px 4px 0 rgba(0, 158, 255, 0.12);
    backgound-position: center;
    margin-bottom: 6px;
`

const AdministratorName = styled.div`
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: #21293e;
    margin-bottom: 4px;
`
const AdministratorText = styled.div`
    font-size: 14px;
    text-align: center;
    color: ${blueGrey};
    padding-bottom: 24px;
`

const TeamDirectManagement = styled(Paper)`
    padding: 24px;
`
const InviteButton = styled(Button)`
    && {
      border-radius: 30px;
    }
`

const RightSideContainerText = styled.div`
    max-width: 254px;
    text-align: center;
    margin: 0px auto;
`

const LeftSideHeading = styled.div`
    font-size: 18px;
    font-weight: 500;
    color: #21293e;
    margin-bottom: 16px;
`

const Members = styled.div`
    margin-top: 36px;
`


export const ManageTeam = ({ match }) => {
  const onBackIconClick = () => history.push('/panel/community/roso')
  const id = get(match, 'params.id', null);
  const formId = 'join-team-form';
  const [team, setTeam] = useState([]);
  const refreshTeam = () => getTeamById(id).then(result => setTeam(result));

  useEffect(() => { refreshTeam() }, []);
  const adminItem = team.find(el => get(el, 'attributes.role', false) === 'creator');
  const admin = get(adminItem, 'relationships.doctor.data.attributes', {});
  const adminFirstName = get(admin, 'first_name', '');
  const adminLastName = get(admin, 'last_name', '');
  const specialization = get(admin, 'specialization', '');
  const fullName = `${adminFirstName} ${adminLastName}`;
  const adminPhoto = get(admin, 'photo', '');
  const teamInfo = get(adminItem, 'relationships.team.data.attributes', {});

  return (
    <Page
      label='roso.community.label'
      onBackIconClick={onBackIconClick}
      fullWidth
    >
      <Wrapper>
        <Grid container>
          <LeftTeamSide item xs={5}>
            <Paper>
              <ManageTeamImage>
                <LogoAddTeam src={manageTeamsStatic} alt='manageTeamsStatic' />
              </ManageTeamImage>
              <RightSideContainerText>
                <TeamInfoHeading>
                  {teamInfo.name}
                </TeamInfoHeading>
                <TeamInfoSubHeading>
                  {specialization}
                </TeamInfoSubHeading>
                <TeamInfoStaticText>
                  {teamInfo.description}
                </TeamInfoStaticText>
              </RightSideContainerText>
              <Administrator>
                <AdministratorImage photo={adminPhoto} />
                <AdministratorName>{fullName}</AdministratorName>
                <AdministratorText><FormattedMessage id='roso.create.administrator' /></AdministratorText>
              </Administrator>
            </Paper>
          </LeftTeamSide>
          <RightTeamSide item xs={7}>
            <TeamDirectManagement>
              <LeftSideHeading>
                <FormattedMessage id='roso.manage.membersOfTeam' />
              </LeftSideHeading>
              <TeamInviteForm id={formId} members={team} teamId={id} refreshTeam={refreshTeam} />
              <InviteButton onClick={() => externalFormSubmit(formId)} variant='outlined' color='primary'>
                <FormattedMessage id='roso.create.invite' />
              </InviteButton>
              <Members refreshTeam={refreshTeam}>
                <MembersList members={team} refreshTeam={refreshTeam} />
              </Members>
            </TeamDirectManagement>
          </RightTeamSide>
        </Grid>
      </Wrapper>
    </Page>
  ) 
};
