import { compose, withProps, lifecycle } from 'recompose'
import { connect } from 'react-redux'

import { ListComponent } from '../List'

import { fetchFavorite, fetchDoctor, toggleFavorite } from '../../../../../redux/modules/doctors'
import { inviteDoctor } from '../../../../../redux/modules/consultations'
import { history } from '../../../../../utils/history'

const enhance = compose(
  connect(
    state => ({
      doctors: state.doctors.favorite,
      favoriteToggle: state.loading.favoriteToggle,
      isDoctor: state.auth.type === 'Doctor',
    }),
    {
      fetchFavorite,
      fetchDoctor,
      toggleFavorite,
      inviteDoctor,
    },
  ),
  withProps(props => ({
    title: 'consultations.favorite',
    emptySubtitle: 'consultations.favorite.text',
    emptyTitle: 'consultations.no.favorite',
    onBackIconClick: () => history.push(props.isDoctor ? '/panel/concilium/specializations' : '/panel/doctors'),
    onButtonClick: id => (e, proposedDate) => { 
      e.stopPropagation()
      return props.fetchDoctor(id)
        .then(() => {
          if (props.match.params.consultationId && props.isDoctor) {
            props.inviteDoctor(id, props.match.params.consultationId)
            history.push('/panel/consultations/session')
          } else {
            history.push(props.isDoctor ? `/panel/concilium/create/${id}${proposedDate ? `?proposedDate=${proposedDate}` : ''}` : '/panel/doctors/appointment/create')
          }
        })
    },
    onAvatarClick: id => () => {
      if (props.match.params.consultationId) return null
      return props.fetchDoctor(id)
        .then(() => history.push(props.isDoctor ? `/panel/concilium/create/${id}` : '/panel/doctors/appointment/profile'))
    },
  })),
  lifecycle({
    componentDidMount() {
      this.props.fetchFavorite()
    },
  }),
)

export const Favorite = enhance(ListComponent)
