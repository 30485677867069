import React, { useEffect } from 'react';
import { Form, Field } from 'react-final-form'
import styled from 'styled-components'
import { withProps } from 'recompose'
import PropTypes from 'prop-types'
import { MultilineTextField } from 'common/UiKit/MultilineTextField'
import { TextField } from 'common/UiKit/TextField'
import { Paper } from 'common/UiKit/Paper'
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl'
import { Colors } from '../../../constants/colors'
import { RadioInputField } from '../RadioInputField';
import { SpecializationsSelect } from '../SpecializationsSelect';
import { fetchSpecializations } from '../../../redux/modules/content';

const MultilineField = styled(MultilineTextField)`
  & .root {
    height: 88px;
  }
`

const DiagnosisField = styled(MultilineTextField)`
  & .root {
    height: 168px;
  }
`

const RadioFieldWrapper = styled.div`
  & > div > div {
    box-shadow: none;
    padding-left: 0;
  }
  
  & > div > header, & > div > div > div {
    padding-left: 0;
  }
  
  div[role="button"]:first-of-type {
    margin-left: 0;
  }
`

const Line = styled.div`
  display: flex;
  flex-direction: column;
`

const Label = styled.div`
  width: 100%;
  min-width: 172px;
  margin-bottom: 24px;
  margin-right: 6px;
  opacity: 0.87;
  font-weight: bold;
  font-size: 16px;
  color: ${Colors.dark};
`

const PriceField = styled(TextField)`
  && {
    margin-top:16px;
    label {
      padding-left: 15px;
      padding-top: 2px;
    }
    & > div {
      padding-left: 20px;
    }
  }
  &&:before {
    content: '€';
    position: absolute;
    left: 5px;
    top: 25px;
    color: #949eb8;
  }
`
const validate = (values, isMergeEmployment, consiliumStatus, LEVEL_OPTIONS) => {
  const errors = {}
  const isConsilium = consiliumStatus && consiliumStatus.consiliumStatus;

  if (!isConsilium) {
    if (!values.consultation_price || values.consultation_price <= 0) {
      errors.consultation_price = true;
    }
  } else {
    if (!values.complexity || values.complexity === LEVEL_OPTIONS[0]) errors.complexity = true
    if (!values.diagnosis || values.diagnosis.length > 1000) errors.diagnosis = true
  }
  if (!values.needConsultationWithSpecialist || values.needConsultationWithSpecialist === '0') errors.needConsultationWithSpecialist = true
  if (values.restrictions && values.restrictions.length > 200) errors.restrictions = true
  if (isMergeEmployment && !values.doctor_type_employment) errors.doctor_type_employment = <FormattedMessage id='errors.required' />
  if (values.redirectToSpecialization && values.redirectToSpecialization !== '0' && !values.redirectSpecializationId) errors.redirectSpecializationId = <FormattedMessage id='errors.required' />
  return errors
}

// TODO: change after adding API
const normalizeValues = values => ({
  ...values,
})

const enhance = withProps(props => ({
  onSubmit: values => props.onSubmit({ ...normalizeValues(values) })
    .then(() => props.afterSubmit()),
}))

export const MedicalReportForm = enhance(({
  onSubmit,
  id,
  disableShadow,
  initialValues,
  consiliumStatus,
  employmentType,
}) => {
  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchSpecializations())
  }, [])

  const LEVEL_OPTIONS = [f('concilium.chooseact'), '1', '2'];

  return (
    <Form
      onSubmit={onSubmit}
      validate={values => validate(values, employmentType === 'merge', consiliumStatus, LEVEL_OPTIONS)}
      initialValues={{
        ...(initialValues || {}),
        redirectSpecializationId: null,
        redirectToSpecialization: '0',
        needConsultationWithSpecialist: 'no',
        ...(consiliumStatus.consiliumStatus ? { complexity: LEVEL_OPTIONS[0] } : {}),
      }}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit} id={id}>
          <Paper disableShadow={disableShadow}>
            <Field
              component={MultilineField}
              name='symptoms'
              label={`*${f('consultations.symptoms')}`}
              maxLength={200}
              parse={value => (value ? value.substr(0, 200) : '')}
              multiline
              rowsMax='3'
            />
            <Label>
              <FormattedMessage id='consultation.report.diagnostic' />
            </Label>
            <Field
              component={DiagnosisField}
              name='diagnosis'
              label={`${f('consultation.report.diagnostic.label')}`}
              maxLength={1000}
              multiline
              rowsMax='6'
            />
            <RadioFieldWrapper>
              <RadioInputField
                headingTransparent={f('consultation.redirect.question')}
                name='needConsultationWithSpecialist'
                id='needConsultationWithSpecialist'
                options={[
                  { label: f('global.yes-fast'), value: 'yes-fast' },
                  { label: f('global.yes'), value: 'yes' },
                  { label: f('global.not'), value: 'no' },
                ]}
              />
            </RadioFieldWrapper>
            <RadioFieldWrapper>
              <RadioInputField
                headingTransparent={f('consultation.another.redirect.question')}
                name='redirectToSpecialization'
                id='redirectToSpecialization'
                options={[
                  { label: f('global.not'), value: '0' },
                  { label: f('global.yes'), value: '1' },
                ]}
              />
            </RadioFieldWrapper>
            {
              (values && +values.redirectToSpecialization) ? (
                <Line>
                  <Label>
                    <FormattedMessage id='profile.specialization' />
                  </Label>
                  <Field
                    component={({ input, meta }) => (
                      <SpecializationsSelect
                        {...input}
                        meta={meta}
                        getOptionValue={option => option.attributes.key}
                      />
                    )}
                    name='redirectSpecializationId'
                  />
                </Line>
              ) : null
            }
            {consiliumStatus.consiliumStatus
              ? (
                <Field
                  component={TextField}
                  name='complexity'
                  options={LEVEL_OPTIONS.map(value => ({ value, label: value === LEVEL_OPTIONS[0] ? value : `TE${value}` }))}
                  label={<div>*<FormattedMessage id='consultations.difficultyLevel' /></div>}
                />
              )
              : (
                <Field
                  component={PriceField}
                  name='consultation_price'
                  parse={value => value && (+value || '')}
                  label={`*${f('consultations.cost')}`}
                />
              )
            }
            {
              employmentType === 'merge' && (
                <Field
                  name='doctor_type_employment'
                  component={TextField}
                  select
                  label={`*${f('profile.method')}`}
                  options={[
                    { label: <FormattedMessage id='profile.salary' />, value: 'hospital' },
                    { label: <FormattedMessage id='profile.liberal' />, value: 'private' },
                  ]}
                />
              )
            }
          </Paper>
        </form>
      )}
    />
  )
})

MedicalReportForm.propTypes = {
  afterSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  disableShadow: PropTypes.bool,
}
