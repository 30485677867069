import { createAction, handleActions } from 'redux-actions'
import idx from 'idx'
import axios from 'axios'
import { uniqBy } from 'lodash'
import { socket } from '../../../services/socket'

//#region Actions
export const CHAT_ACTIONS = {
  handleMessage: 'CHATS > HANDLE_MESSAGE',
  clearNotifications: 'CHATS > CLEAR NOTIFICATIONS',
  uploadFileRequest: 'CHATS > UPLOAD_FILE_REQUEST',
  uploadFileSuccess: 'CHATS > UPLOAD_FILE_SUCCESS',
  uploadFileFailure: 'CHATS > UPLOAD_FILE_FAILURE',
}

export const handleMessageAction = createAction(CHAT_ACTIONS.handleMessage)
export const clearNotificationsAction = createAction(CHAT_ACTIONS.clearNotifications)

export const uploadFileRequest = createAction(CHAT_ACTIONS.uploadFileRequest)
export const uploadFileSuccess = createAction(CHAT_ACTIONS.uploadFileSuccess)
export const uploadFileFailure = createAction(CHAT_ACTIONS.uploadFileFailure)
//#endregion

//#region Reducers
export const chatNotificationsReducer = handleActions({
  [clearNotificationsAction]: () => [],
  [handleMessageAction]: (state, { payload }) => {
    if (payload.action === 'MessageReceived' && payload.attributes) {
      return [...state, payload.attributes];
    }

    return state;
  },
}, [])

export const chatsReducer = handleActions({
  [handleMessageAction]: (state, { payload }) => {
    if (payload.code === 400) {
      socket.authorize(socket.authToken)
    }

    if (payload.action === 'MessageDelete') {
      const id = idx(payload, _ => _.id) || false;
      const keys = Object.keys(state);
      if (keys && keys[0] && state[keys[0]] && state[keys[0]].lastMessages && id) {
        const filteredLastMessages = state[keys[0]].lastMessages
          .filter(el => +el.id !== +id);
        return {
          ...state,
          [keys[0]]: {
            ...state[keys[0]],
            lastMessages: filteredLastMessages,
          },
        }
      }
    }

    if (payload.action === 'chatConnect') {
      const { relateId: id } = idx(payload, _ => _.attributes.chat) || {}
      if (!id) return state

      return {
        ...state,
        [id]: payload.attributes,
      }
    }

    if (payload.action === 'MessageReceived') {
      const chatId = payload.attributes.chat_id
      const consultationId = Object.keys(state).find(item => state[item].chat.id === chatId)
      if (!consultationId) return state

      const chat = state[consultationId]

      return {
        ...state,
        [consultationId]: {
          ...chat,
          lastMessages: [
            { id: payload.id, attributes: payload.attributes },
            ...uniqBy((chat.lastMessages || []), 'id'),
          ],
        },
      }
    }

    if (payload.action === 'loadMessage') {
      const chatId = idx(payload, _ => _.attributes[0].attributes.chat_id)
      if (!chatId) return state
      const consultationId = Object.keys(state).find(item => state[item].chat.id === chatId)
      const chat = state[consultationId]
      const newMessages = uniqBy([...chat.lastMessages, ...payload.attributes], 'id')

      return {
        ...state,
        [consultationId]: {
          ...chat,
          lastMessages: newMessages,
        },
      }
    }

    if (payload.action === 'ChatReload') {
      socket.connectChat(payload.attributes.chat.relateId)
    }

    if (payload.action === 'AdditionalDoctorJoin') {
      const chat = state[payload.attributes.consultationId]
      if (chat) {
        socket.notify(chat.chat.id)
      }
      return state
    }

    return state
  },
}, {})
//#endregion

//#region Thunks
export const uploadFile = (data, chatId, setLoadingProgress) => (dispatch) => {
  dispatch(uploadFileRequest())
  const formData = new FormData()

  formData.append('file', data.file)

  return axios('/api/attachment/upload', {
    method: 'POST',
    data: formData,
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      setLoadingProgress(percentCompleted)
    },
  })
    .then((response) => {
      dispatch(uploadFileSuccess(response.data))
      socket.sendFileMessage(chatId, response.data.data.id)
      setLoadingProgress(0)
    })
    .catch((err) => {
      setLoadingProgress(0)
      dispatch(uploadFileFailure(err))
    })
}
//#endregion
