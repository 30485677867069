import React, {
  useEffect, useMemo, useRef, useState, 
} from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field, Form } from 'react-final-form';
import styled from 'styled-components'
import { TextButton } from 'common/UiKit/TextButton';
import moment from 'moment'
import { useDispatch } from 'react-redux';
import isEqual from 'lodash/isEqual';
import last from 'lodash/last';
import { TextField } from '../../../../common/FinalFormFields';
import { addRepeat, generateTimePeriods, transformTime } from '../../../../../helpers/calendar';
import { editEvent } from '../../../../../redux/modules/calendar/list';
import { ConfirmModal } from '../../../../common/Modal/ConfirmModal';

const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  
  & > *:first-child {
    margin-right: 12px;
  }
  
  & > *:last-child {
    margin-left: 12px;
  }
`

const Button = styled(TextButton)`
  && {
    width: 100%;
    font-size: 14px;
    font-weight: 500;
  }
`

const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.55;
  color: #209dff;
`

const Label = styled.div`
  opacity: 0.8;
  font-size: 12px;
  color: #21293e;
  margin-bottom: 12px;
`

const RemoveButton = styled(Button)`
  && {
    background-color: red;
    margin-top: 15px;
    color: #fff;
  }

  &&:hover {
    background-color: red;
  }
` 

const getLastTime = periods => moment(last(periods).from, 'HH:mm').add(10, 'minutes').format('HH:mm')

export const DeleteDialog = ({ open, onClose, data }) => {
  const periods = useMemo(() => generateTimePeriods(), [])
  const resetForm = useRef(null)
  const dispatch = useDispatch()
  const [errorsState, setErrors] = useState({})
  const [showDeleteConfirmModal, setDeleteConfirmModal] = useState(false);

  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })

  useEffect(() => {
    if (resetForm.current) {
      resetForm.current()
    }
  }, [data])

  const normilizeDays = (days = []) => (
    days.filter(el => !!el).map(el => el[0])
  )

  const onSubmit = (values) => {
    const fromIndex = periods.findIndex(i => i.from === values.from)
    let toIndex = periods.findIndex(i => i.from === values.to)
    if (toIndex === -1) {
      toIndex = periods.length;
    }
    const days = periods.slice(fromIndex, toIndex).map(i => `${data.day} ${i.from.length < 5 ? `0${i.from}` : i.from}`)

    if (values.repeat !== undefined) {
      addRepeat({
        type: values.type,
        day: data.day,
        from: values.from,
        to: values.to, 
        dateRepeatFrom: values.dateRepeatFrom,
        dateRepeatTo: values.dateRepeatTo,
        days: normilizeDays(values.days),
      });
    }

    const oldValue = values.type === 'Available' ? 'Available' : null;

    return dispatch(editEvent(days, null, values.type, oldValue))
      .then(() => onClose())
  }

  const validate = () => {
    const errors = {};

    if (!isEqual(errors, errorsState)) {
      setErrors(errors);
    }

    return errors;
  }


  return (
    <Dialog open={open} onClose={onClose} maxWidth='xs'>
      <DialogTitle>
        <Title>
          <FormattedMessage id='global.delete' />
          {data !== null
            && (
              moment(data.day).local().format(' DD MMMM YYYY')
            )
          }
        </Title>
      </DialogTitle>
      <DialogContent>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          initialValues={data ? {
            type: 'Avaiable',
            from: transformTime(data.periodFrom),
            to: transformTime((periods[periods
              .findIndex(i => i.from === transformTime(data.periodFrom)) + 1] 
              || { from: getLastTime(periods) }).from),
          } : { type: 'Avaiable' }}
          destroyOnUnregister
          render={({
            handleSubmit, values, submitting, form,
          }) => {
            resetForm.current = form.reset

            const toOptions = periods.slice(values.from
              ? periods.findIndex(i => i.from === values.from) + 1
              : 0)

            return (
              <form onSubmit={handleSubmit}>
                <Label>
                  <FormattedMessage id='schedule.event.time' />
                </Label>
                <FieldWrapper>
                  <Field
                    name='from'
                    component={TextField}
                    label=' '
                    options={periods
                      .map(item => ({ label: item.from, value: item.from }))}
                  />
                  {' - '}
                  <Field
                    name='to'
                    component={TextField}
                    label=' '
                    options={[...(toOptions && toOptions.length
                      ? toOptions : [{ from: getLastTime(periods) }])]
                      .map(item => ({ label: item.from, value: item.from }))}
                  />
                </FieldWrapper>
                <RemoveButton 
                  onClick={() => setDeleteConfirmModal(true)}
                  disabled={submitting}
                  isFetching={submitting}
                >
                  <FormattedMessage id='global.delete' />
                </RemoveButton>
                <ConfirmModal
                  title={f('schedule.delete.confirmmodal.title')} 
                  text={f('schedule.delete.confirmmodal.text')} 
                  confirmButtonText={f('schedule.delete.confirmmodal.confirmbutton')}
                  cancelButtonText={f('schedule.delete.confirmmodal.cancelbutton')}
                  open={showDeleteConfirmModal} 
                  handleClose={() => setDeleteConfirmModal(false)}
                  handleSubmit={() => {
                    setDeleteConfirmModal(false);
                    return onSubmit({ ...values, type: 'Empty' });
                  }}
                />
              </form>
            )
          }}
        />
      </DialogContent>
    </Dialog>
  )
}
