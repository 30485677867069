import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { TextButton } from 'common/UiKit/TextButton'
import { Colors } from 'constants/colors'
import EditIcon from '@material-ui/icons/Edit'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import { DateFilter } from './Date'
import { changeFilter } from '../../../../../redux/modules/calendar/filters'
// import { CalendarTypeFilter } from './CalendarType'
//import { StatusFilter } from './Status'
//import { TypeFilter } from './Type'
import { Export } from './Export'
import { STATUS_OPTIONS, TYPES_OPTIONS } from '../../../../../constants/filters'
import { ExportModal } from './ExportModal'
import { RepeatModal } from '../RepeatModal';

const ButtonsBar = styled.div`
  display: flex;
`

export const CalendarFilters = ({
  editing,
  toggleEditing,
  showEditing,
}) => {
  const values = useSelector(state => state.calendar.filters.values)
  const dispatch = useDispatch()
  const onFilterChange = filter => data => dispatch(changeFilter(filter, data))
  const [modal, toggleModal] = useState(null)
  const [showRepeatModal, setShowRepeatModal] = useState(false)

  const onToggleEditing = (value) => {
    onFilterChange('status')(STATUS_OPTIONS.map(item => item.value))
    onFilterChange('type')(TYPES_OPTIONS.map(item => item.value))
    toggleEditing(value)
  }

  return (
    <Wrapper>
      <FiltersWrapper>
        <DateFilter
          value={values.date}
          onChange={onFilterChange('date')}
          calendarType={values.calendarType}
        />
        {/*<CalendarTypeFilter
          value={values.calendarType}
          onChange={onFilterChange('calendarType')}
        />
        <StatusFilter
          value={values.status}
          onChange={onFilterChange('status')}
        />
        <TypeFilter
          onChange={onFilterChange('type')}
          value={values.type}
        />*/}
        <Export onClick={toggleModal} />
      </FiltersWrapper>
      {
        showEditing && (
          !editing
            ? (
              <EditButton
                color='primary'
                variant='extended'
                size='small'
                onClick={() => onToggleEditing(true)}
              >
                <EditIcon />
                <FormattedMessage id='global.edit' />
              </EditButton>
            )
            : (
              <ButtonsBar>
                <ButtonsWrapper>
                  <EditButton
                    color='secondary'
                    variant='extended'
                    size='small'
                    onClick={() => setShowRepeatModal(true)}
                  >
                    <FormattedMessage id='calendar.editrepeats' />
                  </EditButton>
                </ButtonsWrapper>
                <ButtonsWrapper>
                  <EditButton
                    color='secondary'
                    variant='extended'
                    size='small'
                    onClick={() => onToggleEditing(false)}
                  >
                    <FormattedMessage id='calendar.btn.end_edit' />
                  </EditButton>
                </ButtonsWrapper>
              </ButtonsBar>
            )
        )
      }
      {!!modal && <ExportModal onClose={() => toggleModal(false)} data={modal} />}
      <RepeatModal close={() => setShowRepeatModal(false)} isOpen={showRepeatModal} />
    </Wrapper>
  )
}

CalendarFilters.propTypes = {
  editing: PropTypes.bool.isRequired,
  toggleEditing: PropTypes.func.isRequired,
  showEditing: PropTypes.bool.isRequired,
}

//#region Styled components
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  background-color: ${Colors.background};
  position: sticky;
  top: 0;
  z-index: 13;
`

const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
`

const EditButton = styled(TextButton)`
  && {
    width: auto;
    font-size: 14px;
    
    svg {
      width: 15px;
      height: 15px;
      margin-right: 6px;
      margin-top: -2px;
    }
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  
  & > *:first-of-type {
    margin-right: 12px;
  }
`
//#endregion
