import React, { useState } from 'react';
import { SignatureUpload } from '../Signature/Upload';
import { Signature } from '../Signature';

export const SignatureForm = ({ afterSubmit }) => {
  const [upload, toggleUpload] = useState(false)

  if (upload) {
    return (
      <SignatureUpload
        embedded
        onCancelClick={() => toggleUpload(false)}
        afterSubmit={afterSubmit}
      />
    )
  }
  return (
    <Signature 
      embedded 
      onCancelClick={() => toggleUpload(false)}
      onUploadClick={() => toggleUpload(true)}
    />
  )
}
