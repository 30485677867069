import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import DescriptionIcon from '@material-ui/icons/Description'

export const FileMessage = ({ message, my, onLoad }) => (
  <Wrapper my={my} href={message.url} target='_blank'>
    {
      message.type === 'images'
        ? (
          <>
            <Photo src={message.url} alt={message.name} onLoad={onLoad} />
            <Name>
              {message.custom_name || message.name}
            </Name>
          </>
        )
        : (
          <Document>
            <DocumentIcon>
              <DescriptionIcon />
            </DocumentIcon>
            <div>
              <DocumentName>{message.custom_name || message.name}</DocumentName>
              <DocumentSize>{Math.round(message.size / 1024)}Kb</DocumentSize>
            </div>
          </Document>
        )
    }
  </Wrapper>
)

FileMessage.defaultProps = {
  onLoad: undefined,
}

FileMessage.propTypes = {
  message: PropTypes.object.isRequired,
  my: PropTypes.bool.isRequired,
  onLoad: PropTypes.func,
}

const Wrapper = styled.a`
  height: auto;
  padding: 11px 18px;
  word-wrap: break-word;
  font-size: 16px;
  line-height: 1.5;
  color: ${Colors.dark};
  background-color: ${({ my }) => (my ? Colors.azure : Colors.paleGrey)};
  border-radius: 0 4px 4px 4px;
  max-width: 260px;
  text-align: center;
`

const Photo = styled.img`
  max-width: 260px;
  width: 260px;
  display: block;
  border-radius: 4px;
`

const Name = styled.div`
  margin-top: 8px;
  color: ${Colors.dark60};
  font-size: 14px;
`

const Document = styled.div`
  background-color: ${Colors.white};
  display: flex;
  align-items: center;
  color: ${Colors.dark60};
  padding: 6px;
  border: 1px solid ${Colors.lightGrey};
  border-radius: 4px;
  font-size: 14px;
  text-align: left;
  
  & > div:last-of-type {
    max-width: calc(100% - 56px);
  }
`

const DocumentName = styled.div`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 15px;
`

const DocumentSize = styled.div`
  font-size: 11px;
`

const DocumentIcon = styled.div`
  padding: 8px 12px 8px 0;
  
  svg {
    width: 32px;
    height: 32px;
  }
`
