import React from 'react'
import PropTypes from 'prop-types'
import {
  branch,
  compose,
  lifecycle,
  renderComponent,
} from 'recompose'
import { connect } from 'react-redux'
import { Route, Redirect, Switch } from 'react-router-dom'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import queryString from 'query-string'
import idx from 'idx'
import { Login } from './Login'
import { PasswordRecovery } from './PasswordRecovery'
import { TermsAndPolicy } from './TermsAndPolicy'
import { authByToken } from '../../redux/modules/auth'
import { userIsNotAuthenticated } from '../../utils/userIsAuthenticated'
import { Preloader } from '../common/Preloader'
import { PhoneConfirm } from './PhoneConfirm'
import { UserTypeSelect } from './UserTypeSelect'

const Layout = styled.div`
  background-color: ${Colors.blackBg};
  height: 100vh;
  overflow-y: auto;
  
  & > div {
    margin: 24px auto;
  }
`

const AuthComponent = ({ isLoggedIn }) => (
  <Layout>
    {isLoggedIn && <Redirect to='/auth/phone' />}
    <Switch>
      <Route exact path='/auth' component={UserTypeSelect} />
      <Route exact path='/auth/phone' component={PhoneConfirm} />
      <Route path='/auth/password-recovery' component={PasswordRecovery} />
      <Route path='/auth/terms-and-policy' component={TermsAndPolicy} />
      <Route path='/auth/:role' component={Login} />
    </Switch>
  </Layout>
)

AuthComponent.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
}

const enhance = compose(
  userIsNotAuthenticated,
  connect(state => ({
    authenticating: state.loading.authByToken,
    isLoggedIn: !!state.auth.id,
  }), { authByToken }),
  lifecycle({
    componentDidMount() {
      // TODO: change this
      if (idx(this, _ => _.props.location.pathname) !== '/auth/password-recovery/check') {
        const { token, type } = queryString.parse(this.props.location.search)

        this.props.authByToken({ token, type })
      }
    },
  }),
  branch(
    props => props.authenticating,
    renderComponent(() => <Preloader />),
  ),
)

export const Auth = enhance(AuthComponent)
