import Announcement from '@material-ui/icons/Announcement'
import Group from '@material-ui/icons/Group'
import Help from '@material-ui/icons/Help'
import Settings from '@material-ui/icons/Settings'
import Favorite from '@material-ui/icons/Favorite'
import Person from '@material-ui/icons/Person'
import LocalHospital from '@material-ui/icons/LocalHospital'
import EventNoteIcon from '@material-ui/icons/EventNote'
import ForumIcon from '@material-ui/icons/Forum'

export const doctorMenuButtons = [
  { icon: Group, label: 'consultation.title', path: '/panel/consultations' },
  { icon: ForumIcon, label: 'concilium.title', path: '/panel/concilium' },
  { icon: EventNoteIcon, label: 'schedule.title', path: '/panel/schedule' },
  { icon: Announcement, label: 'global.chat.with.admin', path: '/panel/chat-administrator' },
  { icon: Settings, label: 'global.settings', path: '/panel/settings' },
  { icon: Help, label: 'global.help', path: '/panel/help' },
]

export const patientMenuButtons = [
  { icon: LocalHospital, label: 'global.doctors', path: '/panel/doctors' },
  { icon: Group, label: 'consultation.my', path: '/panel/consultations' },
  { icon: Favorite, label: 'hr', path: '/panel/health-records' },
  { icon: Person, label: 'profile.title', path: '/panel/profile' },
]
