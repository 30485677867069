import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'
import { Colors } from 'constants/colors'
import { FormattedMessage } from 'react-intl'
import { ConsultationProfilePanel } from '../ConsultationProfilePanel'
import { Paper } from '../Paper'

const Content = styled(Paper)`
  padding: 20px 16px;
`

const Info = styled.div`
  margin-bottom: 40px;
`

const Line = styled.div`
  display: flex;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.43;
  color: ${Colors.dark80};

  & > :first-child {
    width: 60px;
    color: ${Colors.blueGrey};
  }
`

export const ConsultationReportPanel = ({
  onCloseIconClick,
  onDownloadIconClick,
  date,
  patientFirstName,
  patientLastName,
  doctorFirstName,
  doctorLastName,
  specialization,
  children,
  heading,
}) => (
  <ConsultationProfilePanel
    onCloseIconClick={onCloseIconClick}
    onDownloadIconClick={onDownloadIconClick}
    heading={heading}
  >
    <Content>
      <Info>
        <Line>
          <span>
            <FormattedMessage id='global.date' />
            :
          </span>
          <span>{moment(date).format('DD MMMM. HH:mm')}</span>
        </Line>
        <Line>
          <span>
            <FormattedMessage id='auth.patient' />
            :
          </span>
          <span>
            {patientFirstName || 'Nom'}
            &nbsp;
            {patientLastName || 'Prénom'}
          </span>
        </Line>
        <Line>
          <span>
            <FormattedMessage id='auth.doctor' />
            :
          </span>
          <span>
            {doctorFirstName || 'Name'}
            &nbsp;
            {doctorLastName || 'Surname'}
            ,&nbsp;
            {specialization || 'Specialization'}
          </span>
        </Line>
      </Info>
      {children}
    </Content>
  </ConsultationProfilePanel>
)

ConsultationReportPanel.propTypes = {
  onCloseIconClick: PropTypes.func.isRequired,
  onDownloadIconClick: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]).isRequired,
  date: PropTypes.string.isRequired,
  doctorFirstName: PropTypes.string.isRequired,
  doctorLastName: PropTypes.string.isRequired,
  patientFirstName: PropTypes.string.isRequired,
  patientLastName: PropTypes.string.isRequired,
  specialization: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  heading: PropTypes.string.isRequired,
}
