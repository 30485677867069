import React from 'react'
import { DatePicker, InlineDatePicker } from 'common/UiKit/DatePicker'
import { Input } from 'common/UiKit/Input'
import { FormHelperText } from '@material-ui/core'

export const DateField = ({
  input, meta, onlyYear, inline, ...rest
}) => {
  const Component = inline ? InlineDatePicker : DatePicker
  return (
    <div>
      <Component
        {...input}
        openToYearSelection
        format={onlyYear ? 'YYYY' : 'MMM DD, YYYY'}
        TextFieldComponent={Input}
        {...rest}
      />
      {(meta.touched && meta.error) && <FormHelperText error>{meta.error}</FormHelperText>}
    </div>
  )
}
