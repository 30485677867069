import React from 'react'
import { Route } from 'react-router-dom'

import { Email } from './Email'
import { NewPassword } from './NewPassword'

export const PasswordRecovery = () => (
  <>
    <Route exact path='/auth/password-recovery' component={Email} />
    <Route path='/auth/password-recovery/check' component={NewPassword} />
  </>
)
