import idx from 'idx'
import { CONSULTATION_TYPES } from '../../constants/consultation'

export const patientSelector = consultation => (state) => {
  const data = consultation || state.consultations.consultation

  if (idx(data, _ => _.attributes.type === CONSULTATION_TYPES.telexpertise)) {
    if (idx(data, _ => _.relationships.doctor.data.id === state.auth.id)) {
      return idx(data, _ => _.relationships.additionalMember.data[0].attributes.doctor)
    }

    return idx(data, _ => _.relationships.doctor.data.attributes)
  }

  return idx(data, _ => _.relationships.patient.data.attributes)
}
