export const makeYearOptions = (start, end) => {
  const result = []
  let i = +start - 1

  while (i < +end) {
    i += 1
    result.push({ label: i.toString(), value: i })
  }

  return result
}
