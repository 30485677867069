import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import get from 'lodash/get';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import { DeleteOverlay } from './DeleteOverlay'
import { FreeTime } from '../FreeTime'
import { MonthDayConsultation } from '../../../Month/Day/Consultation'
import { CONSULTATION_TYPES } from '../../../../../../../constants/consultation';
import { CELL_HEIGHT } from '../../../../../../../constants/calendar'
import { Colors } from '../../../../../../../constants/colors'


const getRightText = ({ isAvailableTE, isAvailableTC, both }) => {
  if (both) {
    return (<>
      <FormattedMessage key={1} id='schedule.time.off' />
      /
      <FormattedMessage key={2} id='schedule.teleconsultation' />
      </>)
  } if (isAvailableTE) {
    return <FormattedMessage id='schedule.time.off' />
  } if (isAvailableTC) {
    return <FormattedMessage id='schedule.teleconsultation' />
  }
  return '';
}

const colorsByType = (type) => {
  if (type === 'both') {
    return 'rgba(219, 244, 235, 0.8);'
  }
  if (type === 'isAvailableTC') {
    return '#209dff;'
  }
  if (type === 'isAvailableTE') {
    return 'rgba(219, 219, 244, 1);'
  }
  return '';
}


export const WeekDayCell = React.memo(({
  isAvailable,
  consultation,
  onClick,
  editing,
  disableEdit,
  onEdit,
  isPatient,
  isSelected,
  isInFuture,
  type: cellType,
  collection,
  dateTime,
  isNow,
  eventLength,
  isFree,
  current,
}) => {
  const isAvailableTE = get(current, 'data.actionAvailable.TE', false);
  const isAvailableTC = get(current, 'data.actionAvailable.TC', false);
  const both = isAvailableTC && isAvailableTE;
  let typeAvailable = '';

  if (isAvailableTE) {
    typeAvailable = 'isAvailableTE';
  }
  if (isAvailableTC) {
    typeAvailable = 'isAvailableTC';
  }
  if (both) {
    typeAvailable = 'both';
  }

  return (
    <Wrapper onClick={onClick} id={isNow ? 'current_cell' : undefined}>
      {((isAvailable || (collection && collection.length === 1
        && collection.data.type === CONSULTATION_TYPES.telexpertise))
        && isPatient && isInFuture) && <FreeTime isSelected={!!isSelected} />}
      {/*
        showConsultation
        && <ConsultationCell consultation={consultation} myId={myId} />
      */}
      {/*
        showCollection
        && collection.map((item, index) => (
          <MonthDayConsultation
            key={item.data.id}
            time={moment(dateTime).add(index * 10, 'minutes').format('HH:mm')}
            consultation={item.data}
            myId={myId}
            onClick={() => history.push(`/panel/consultations/${item.data.id}`)}
          />
        ))
      */}
      <DeleteOverlay
        current={current}
        disabled={disableEdit}
        empty={!consultation && isAvailable}
        show={editing}
        onClick={onEdit}
        isFree={isFree}
      />
      {
        (cellType === 'Consultation' && !isPatient) && (
          <MonthDayConsultation
            key={consultation.id}
            time={moment(dateTime).format('HH:mm')}
            consultation={consultation}
            myId={consultation.id}
          />
        )
      }
      {
        (cellType === 'Available' && !isPatient) && (
          <DisabledOverlay type={typeAvailable} length={eventLength}>
            {getRightText({ isAvailableTE, isAvailableTC, both })}
          </DisabledOverlay>
        )
      }
      {
        (cellType === 'Holiday' && !isPatient) && (
          <MyTimeOverlay length={eventLength}>
            <FormattedMessage id='schedule.my.time' />
          </MyTimeOverlay>
        )
      }
      {
        (cellType === 'Empty' && !isPatient && isInFuture && editing) && (
          <FreeOverlay length={eventLength} onClick={onEdit}>
            <StyledPlusButton style={{ color: '#11cb5f' }} />
          </FreeOverlay>
        )
      }
      {
        (cellType === 'FreeUrgent' && !isPatient && !consultation) && (
          <UrgentOverlay length={eventLength}>
            <FormattedMessage id='schedule.available' />
          </UrgentOverlay>
        )
      }
    </Wrapper>
  )
})

WeekDayCell.defaultProps = {
  consultation: undefined,
  onClick: () => {},
  disableEdit: true,
  isSelected: false,
  disabled: false,
  type: null,
  isInFuture: true,
}

WeekDayCell.propTypes = {
  isAvailable: PropTypes.bool.isRequired,
  consultation: PropTypes.object,
  onClick: PropTypes.func,
  disableEdit: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  isPatient: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  isInFuture: PropTypes.bool,
}

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: ${CELL_HEIGHT}px;
  border-bottom: 1px solid ${Colors.divider};
  border-right: 1px solid ${Colors.divider};
  background-color: ${Colors.background};
  padding: 8px 8px 8px 0;
  position: relative;
  padding-right: 0px;
`

const StyledPlusButton = styled(AddCircleIcon)`
  && {
    margin: 0px auto;
  }
`

const DisabledOverlay = styled.div`
  position: absolute;
  z-index: 9;
  top: 4px;
  left: 4px;
  right: 4px;
  height: calc(${({ length = 1 }) => CELL_HEIGHT * length}px - 8px);
  border-radius: 2px;
  opacity: 0.7;
  //cursor: not-allowed;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 6px;
  box-sizing: border-box;
  text-transform: uppercase;
  color: #2b31d8;
  background-color: ${({ type }) => colorsByType(type)};
  font-weight: 500;
  font-size: 9px;
  
  svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
`

const MyTimeOverlay = styled(DisabledOverlay)`
  color: #aaaaaa;
  background-color: rgba(235, 235, 235, 0.71);
`

const FreeOverlay = styled(DisabledOverlay)`
  color: ${Colors.tealish};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  z-index: 11;
  text-align: center;
  display: flex;
`

const UrgentOverlay = styled(DisabledOverlay)`
  color: ${Colors.clearBlue};
  background-color: ${Colors.blue(0.3)};
`
