import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { LinkCard } from 'common/UiKit/LinkCard'
import { Page } from '../../../common/Page'
import { logOut } from '../../../../redux/modules/auth'

const LinkCardStyled = styled(LinkCard)`
  padding: 12px 16px 12px 24px;
  margin-bottom: 16px
`

export const MainComponent = ({
  id,
  logOut,
}) => (
  <Page
    label='global.settings'
    onLogout={logOut}
    id={id}
  >
    <LinkCardStyled
      label='profile.notifications'
      to='/panel/settings/notifications'
    />
    <LinkCardStyled
      label='profile.language'
      to='/panel/settings/language'
    />
    <LinkCardStyled
      label='profile.change.password'
      to='/panel/settings/change-password'
    />
    <LinkCardStyled
      label='auth.phone.change'
      to='/panel/profile/phone/edit'
    />
    <LinkCardStyled
      label='global.privacy-policy'
      to='/panel/settings/privacy-policy'
    />
    <LinkCardStyled
      label='doctor.community.page'
      to='/panel/community'
    />
    {/*<LinkCardStyled*/}
    {/*  label='status'*/}
    {/*  to='/panel/status'*/}
    {/*/>*/}
  </Page>
)

MainComponent.propTypes = {
  id: PropTypes.string.isRequired,
  logOut: PropTypes.func.isRequired,
}

const enhance = connect(state => ({ id: state.auth.id }), { logOut })

export const Main = enhance(MainComponent)
