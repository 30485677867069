import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import EventBusyIcon from '@material-ui/icons/EventBusy'
import EventAvailableIcon from '@material-ui/icons/EventAvailable'
import moment from 'moment'

import { WeekDayAvailable } from '../Available'
import { ConsultationCell } from '../Consultation'
import { DeleteOverlay } from './DeleteOverlay'
import { FreeTime } from '../FreeTime'
import { MonthDayConsultation } from '../../../Month/Day/Consultation'
import { history } from '../../../../../../../utils/history'
import { CONSULTATION_TYPES } from '../../../../../../../constants/consultation';

export const WeekDayCell = React.memo(({
  isAvailable,
  isUrgent,
  consultation,
  onClick,
  editing,
  disableEdit,
  onEdit,
  isPatient,
  isSelected,
  isInFuture,
  type: cellType,
  myId,
  collection,
  dateTime,
  isNow,
  available,
}) => {
  const { status, type } = useSelector(state => state.calendar.filters.values)
  const showConsultation = consultation && !isPatient
    ? status.includes(consultation.status) && type.includes(consultation.type)
    : false
  const showCollection = collection && !isPatient
    ? collection
    : false

  const diffToCheckUrgent = moment().add(30, 'minutes').diff(moment(dateTime));
  const isReallyUrgent = diffToCheckUrgent > 0 && moment(dateTime).diff(moment()) > 0;

  let currentHTMLID = isNow ? 'current_cell' : undefined;
  const availableNOW = moment(available).isSame(dateTime);

  if (availableNOW) {
    currentHTMLID = 'nearest_available';
  }

  return (
    <Wrapper onClick={() => onClick(isReallyUrgent)} id={currentHTMLID}>
      {(isUrgent && !consultation && !isPatient) && <WeekDayAvailable />}
      {((isAvailable || (collection && collection.length === 1
        && collection[0].data.type === CONSULTATION_TYPES.telexpertise))
        && isPatient && isInFuture) 
        && <FreeTime isReallyUrgent={isReallyUrgent} isSelected={!!isSelected} />}
      {
        showConsultation
        && <ConsultationCell consultation={consultation} myId={myId} />
      }
      {
        showCollection
        && collection.map((item, index) => (
          <MonthDayConsultation
            key={item.date_time}
            time={moment(dateTime).add(index * 10, 'minutes').format('HH:mm')}
            consultation={item.data}
            myId={myId}
            onClick={() => history.push(`/panel/consultations/${item.data.id}`)}
          />
        ))
      }
      <DeleteOverlay
        disabled={disableEdit}
        empty={!consultation && isAvailable}
        show={editing}
        onClick={onEdit}
      />
      {
        (cellType === 'Consultation' && !isPatient) && (
          <DisabledOverlay>
            <EventBusyIcon />
            <FormattedMessage id='schedule.time.off' />
          </DisabledOverlay>
        )
      }
      {
        (cellType === 'Holiday' && !isPatient) && (
          <MyTimeOverlay>
            <EventAvailableIcon />
            <FormattedMessage id='schedule.my.time' />
          </MyTimeOverlay>
        )
      }
    </Wrapper>
  )
})

WeekDayCell.defaultProps = {
  consultation: undefined,
  onClick: () => {},
  disableEdit: true,
  isSelected: false,
  disabled: false,
  type: null,
  isInFuture: true,
}

WeekDayCell.propTypes = {
  isAvailable: PropTypes.bool.isRequired,
  isUrgent: PropTypes.bool.isRequired,
  consultation: PropTypes.object,
  onClick: PropTypes.func,
  disableEdit: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  isInFuture: PropTypes.bool,
}

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 47px;
  border-bottom: 1px solid ${Colors.divider};
  border-right: 1px solid ${Colors.divider};
  background-color: ${Colors.white};
  padding: 4px 4px 4px 0;
  position: relative;
`

const DisabledOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.7;
  cursor: not-allowed;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: ${Colors.clearBlue};
  background-color: rgba(0,0,0,0.015);
  font-weight: 700;
  font-size: 13px;
  
  svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
`

const MyTimeOverlay = styled(DisabledOverlay)`
  color: ${Colors.tealish};
`
