import { createGlobalStyle } from 'styled-components'
import { Colors } from 'constants/colors'
import { makeScrollbar } from '../helpers/makeScrollbar'

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif;
  }
  
  a {
    color: ${Colors.link};
    text-decoration: none;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 1.43;
    text-align: left;
    display: inline-block;
  }

  * {
    ${makeScrollbar()}
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    
    to {
      opacity: 1;
    }
  }
  
  @keyframes ripple {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(1.3);
      opacity: 0;
    }
  }
  
  @keyframes blinking {
    0% {
      transform: none;
    }
    
    50% {
      transform: scale(1.05);
    }
    
    100% {
      transform: none;
    }
  }
`;
