import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import styled from 'styled-components'
import axios from 'axios'
import get from 'lodash/get'
import { sendPolicy, declinePolicy } from '../../../../redux/modules/changeDecision'

const Label = styled.div`
    font-weight: 500;
    font-size: 13px;
    button {
        font-size: 10px;
        text-decoration: underline;
        line-heigth: 1;
        outline: none;
        background: none;
        color: #018cff;
        border: none;
        padding: 0;
        cursor: pointer;
    }
`


export const ModalChangeDecisionComponent = (props) => {
  const {
    open,
    handleClose,
    sendPolicy,
    declinePolicy,
  } = props;

  const [state, setState] = React.useState({
    terms_of_use: true,
    privacy_policy: true,
    payment_policy: false,
  });

  const handleChange = name => (event) => {
    setState({ ...state, [name]: event.target.checked });
    if (state.payment_policy) {
      declinePolicy({ policy: name })
    } else {
      sendPolicy({ policy: name })
    }
  };

  useEffect(() => {
    if (open) {
      axios('/api/gdpr/accepted')
        .then((result) => {
          const approved = get(result, 'data.data', []);
          const approvedList = approved.map(el => get(el, 'attributes.policy', false)).filter(el => el);
          const newState = approvedList.reduce((accum, el) => {
            const truly = accum;
            truly[el] = true;
            return accum;
          }, {})
          setState({ ...state, ...newState });
        })
        .catch(err => console.warn(err))
    }
  }, [open])

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => handleClose('modalChange', false)}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        <DialogTitle id='scroll-dialog-title'>
          <Typography variant='p' align='center'>
            <FormattedMessage id='gdpr.change.decision.title' />
          </Typography>
        </DialogTitle>
        <FormGroup column style={{ paddingLeft: '24px' }}>
          <FormControlLabel
            disabled
            control={<Checkbox checked value={state.payment_policy} />}
            label={(
              <Label>
                <div><FormattedMessage id='gdpr.iagree.terms.of.use' /></div>
                <button type='button' onClick={() => handleClose('terms', true)}>
                  <FormattedMessage id='gdpr.learn.more' />
                </button>
              </Label>
              )
            }
          />
          <FormControlLabel
            disabled
            control={<Checkbox checked value={state.privacy_policy} />}
            label={(
              <Label>
                <div><FormattedMessage id='gdpr.iagree.terms.of.policy' /></div>
                <button type='button' onClick={() => handleClose('privacy', true)}>
                  <FormattedMessage id='gdpr.learn.more' />
                </button>
              </Label>
              )
            }
          />
          <FormControlLabel
            control={(
              <Checkbox
                checked={state.payment_policy}
                onChange={handleChange('payment_policy')}
                value={state.payment_policy}
                color='primary'
              />
)}
            label={(
              <Label>
                <div><FormattedMessage id='gdpr.iagree.payment.of.policy' /></div>
                <button type='button' onClick={() => handleClose('payment', true)}>
                  <FormattedMessage id='gdpr.learn.more' />
                </button>
              </Label>
              )
            }
          />
        </FormGroup>
        <DialogActions>
          <Grid justify='center' direction='row' container>
            <Button onClick={() => handleClose('modalChange', false)} color='default'>
              <FormattedMessage id='global.cancel' />
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ModalChangeDecisionComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  sendPolicy: PropTypes.func.isRequired,
  declinePolicy: PropTypes.func.isRequired,
};

const enhance = compose(
  connect(
    state => ({
      policy: state.attributes,
    }),
    {
      sendPolicy,
      declinePolicy,
    },
  ),
)

export const ModalChangeDecision = enhance(ModalChangeDecisionComponent)
