import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Panel } from 'common/UiKit/Panel'
import { Colors } from 'constants/colors'

import { AuthDialog } from '../../AuthDialog'

const Wrapper = styled.div`
  padding: 14px 57px 0;
  margin-bottom: 288px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 ${Colors.azure};
`

const Text = styled.p`
  margin: 0;
  padding-bottom: 34px;
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.5;
  color: ${Colors.blueGrey};
`

export const Form = ({
  button,
  children,
  heading,
  loading,
  onButtonClick,
  onIconClick,
  text,
}) => (
  <AuthDialog heading={heading} onIconClick={onIconClick}>
    <Wrapper>
      <Panel
        button={button}
        buttonProps={{ isFetching: loading, onClick: onButtonClick }}
      >
        <Text>
          {text}
        </Text>
        {children}
      </Panel>
    </Wrapper>
  </AuthDialog>
)

Form.defaultProps = {
  loading: false,
  onIconClick: () => null,
}

Form.propTypes = {
  button: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  heading: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  onButtonClick: PropTypes.func.isRequired,
  onIconClick: PropTypes.func,
  text: PropTypes.string.isRequired,
}
