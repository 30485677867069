import React from 'react'
import styled, { css } from 'styled-components'
import { FormattedMessage } from 'react-intl'

import { TextButton } from 'common/UiKit/TextButton'
import { Colors } from 'constants/colors'

export const AuthPanel = ({
  buttonProps = {},
  children,
  className,
  registration,
  toggleForm,
  isFetching,
  disabled,
}) => {
  const onButtonClick = value => (e) => {
    if (registration !== value) {
      e.preventDefault()
      toggleForm(value)
    } else if (disabled) {
      e.preventDefault()
    }
  }

  return (
    <Wrapper className={className}>
      {children}
      {
        !(isFetching && registration) && (
          <Button
            color='primary'
            variant='extended'
            {...buttonProps}
            white={registration}
            type={registration ? 'button' : 'submit'}
            onClick={onButtonClick(false)}
            centered={isFetching}
          >
            <FormattedMessage id='auth.login' />
          </Button>
        )
      }
      {
        !(isFetching && !registration) && (
          <Button
            color='primary'
            variant='extended'
            {...buttonProps}
            white={!registration}
            type={!registration ? 'button' : 'submit'}
            onClick={onButtonClick(true)}
            centered={isFetching}
          >
            <FormattedMessage id='auth.register' />
          </Button>
        )
      }
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 36px 0;
  background-color: ${Colors.white};
  position: relative;
`

const Button = styled(TextButton)`
  && {
    height: 44px;
    width: 240px;
    position: absolute;
    bottom: -22px;
    transition: left .15s ease-out;
    
    &:first-of-type {
      left: 0;
    }
    
    &:last-of-type {
      right: 0;
    }
    
    ${props => (props.white && css`
      background-color: white;
      color: ${Colors.clearBlue};
      border: 1px solid ${Colors.clearBlue};
      
      &:hover {
        color: white;
        border: none;
      }
    `)}
    
    ${props => (props.centered && css`
      left: calc(50% - 120px) !important;
      right: auto !important;
    `)}
  }
`
