import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import get from 'lodash/get';
import moment from 'moment';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CloseIcon from '@material-ui/icons/Close';
import { getRepeats, deleteRepeat } from '../../../../../helpers/calendar'


const StyledRepeat = styled.div`
    display: flex;
    padding: 10px 5px;
    align-items: center;
    cursor: default;

    &:hover {
        background-color: #eee;
    }
`

const InfoRow = styled.div`
    display: flex;
    flex: 3;
`

const StyledDeleteIcon = styled(HighlightOffIcon)`
    color: 'red';
    cursor: pointer;
`

const CloseModal = styled(CloseIcon)`
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
`

const Wrapper = styled.div`
    min-width: 350px;
`

export const RepeatModal = ({ isOpen, close }) => {
  const [repeats, setRepeats] = useState([])
  // const { formatMessage } = useIntl()
  // const f = id => formatMessage({ id })

  const refereshRepeats = () => getRepeats()
    .then(result => setRepeats(result))
    .catch(err => console.warn(err))

  const deleteRepeatRequest = (repeatId) => {
    deleteRepeat(repeatId)
      .then(() => refereshRepeats())
  } 

  useEffect(() => {
    refereshRepeats()
  }, [])

  return (
    <Dialog open={isOpen}>
      <CloseModal onClick={() => close()} />
      <DialogTitle>
        <FormattedMessage id='calendar.editrepeats' />
      </DialogTitle>
      <DialogContent>
        <Wrapper>
          {
            repeats.map((el) => {
              const from = get(el, 'attributes.from', '');
              const to = get(el, 'attributes.to', '');
              const day = get(el, 'attributes.dayNumber', 0);
              const type = get(el, 'attributes.type', 'none');
              const textDay = moment().day(day).format('dddd');

              return (
                <StyledRepeat key={el.id}>
                  <InfoRow>{`${from} - ${to} (${textDay}) - ${type}`}</InfoRow>
                  <StyledDeleteIcon style={{ color: 'red' }} onClick={() => deleteRepeatRequest(el.id)} />
                </StyledRepeat>)
            })
          }
        </Wrapper>
      </DialogContent>
    </Dialog>
  ) 
}
