import { handleActions, combineActions } from 'redux-actions'
import { combineReducers } from 'redux'
import * as authActions from './auth'
import * as healthRecordsActions from './healthRecords'
import * as consultationsActions from './consultations'
import * as medicinesActions from './medicines'
import * as doctorsActions from './doctors'
import * as reviewsActions from './reviews'
import * as chatsActions from './chats'
import * as calendarActions from './calendar/list'

const createLoadingState = (requestActions, responseActions) => handleActions({
  [combineActions(...requestActions)]: () => true,
  [combineActions(...responseActions)]: () => false,
}, false)

const auth = createLoadingState(
  [authActions.authRequest],
  [authActions.authSuccess, authActions.authFailure],
)

const editProfile = createLoadingState(
  [authActions.editProfileRequest],
  [authActions.editProfileSuccess, authActions.editProfileFailure],
)

const workPlace = createLoadingState(
  [authActions.createWorkPlaceRequest],
  [authActions.createWorkPlaceSuccess, authActions.createWorkPlaceFailure],
)

const education = createLoadingState(
  [
    authActions.createEducationRequest,
    authActions.deleteEducationRequest,
    authActions.editEducationRequest,
  ],
  [
    authActions.createEducationFailure,
    authActions.createEducationSuccess,
    authActions.deleteEducationFailure,
    authActions.deleteEducationSuccess,
    authActions.editEducationFailure,
    authActions.editEducationSuccess,
  ],
)

const courses = createLoadingState(
  [
    authActions.createCoursesRequest,
    authActions.deleteCoursesRequest,
    authActions.editCoursesRequest,
  ],
  [
    authActions.createCoursesFailure,
    authActions.createCoursesSuccess,
    authActions.deleteCoursesFailure,
    authActions.deleteCoursesSuccess,
    authActions.editCoursesFailure,
    authActions.editCoursesSuccess,
  ],
)

const schedule = createLoadingState(
  [authActions.createScheduleRequest],
  [authActions.createScheduleSuccess, authActions.createScheduleFailure],
)

const diplomas = createLoadingState(
  [authActions.createDiplomasRequest, authActions.deleteDiplomaRequest],
  [
    authActions.createDiplomasFailure,
    authActions.createDiplomasSuccess,
    authActions.deleteDiplomaFailure,
    authActions.deleteDiplomaSuccess,
  ],
)

const passwordRecovery = createLoadingState(
  [authActions.passwordRecoveryRequest],
  [authActions.passwordRecoverySuccess, authActions.passwordRecoveryFailure],
)

const setNewPassword = createLoadingState(
  [authActions.setNewPasswordRequest],
  [authActions.setNewPasswordSuccess, authActions.setNewPasswordFailure],
)

const changePassword = createLoadingState(
  [authActions.changePasswordRequest],
  [authActions.changePasswordSuccess, authActions.changePasswordFailure],
)

const healthRecords = createLoadingState(
  [healthRecordsActions.editHealthRecordsRequest],
  [healthRecordsActions.editHealthRecordsSuccess, healthRecordsActions.editHealthRecordsFailure],
)

const healthRecordsAttachments = createLoadingState(
  [
    healthRecordsActions.createHealthRecordsAttachmentRequest,
    healthRecordsActions.deleteHealthRecordsAttachmentRequest,
  ],
  [
    healthRecordsActions.createHealthRecordsAttachmentSuccess,
    healthRecordsActions.deleteHealthRecordsAttachmentSuccess,
    healthRecordsActions.createHealthRecordsAttachmentFailure,
    healthRecordsActions.deleteHealthRecordsAttachmentFailure,
  ],
)
const createConsultation = createLoadingState(
  [
    consultationsActions.createUrgentConsultationRequest,
    consultationsActions.createAppointmentConsultationRequest,
  ],
  [
    consultationsActions.createUrgentConsultationSuccess,
    consultationsActions.createUrgentConsultationFailure,
    consultationsActions.createAppointmentConsultationSuccess,
    consultationsActions.createAppointmentConsultationFailure,
  ],
)

const editMedicalReport = createLoadingState(
  [
    consultationsActions.editMedicalReportRequest,
    consultationsActions.addPrescriptionRequest,
  ],
  [
    consultationsActions.editMedicalReportSuccess,
    consultationsActions.editMedicalReportFailure,
    consultationsActions.addPrescriptionSuccess,
    consultationsActions.addPrescriptionFailure,
  ],
)

const approveConsultation = createLoadingState(
  [consultationsActions.approveConsultationRequest, consultationsActions.refuseConsultationRequest],
  [
    consultationsActions.approveConsultationSuccess,
    consultationsActions.approveConsultationFailure,
    consultationsActions.refuseConsultationSuccess,
    consultationsActions.refuseConsultationFailure,
  ],
)

const medicinesCompability = createLoadingState(
  [
    medicinesActions.checkMedicineCompabilityWithProfileRequest,
    medicinesActions.checkMedicinesWithEachOtherRequest,
  ],
  [
    medicinesActions.checkMedicineCompabilityWithProfileSuccess,
    medicinesActions.checkMedicineCompabilityWithProfileFailure,
    medicinesActions.checkMedicinesWithEachOtherSuccess,
    medicinesActions.checkMedicinesWithEachOtherFailure,
  ],
)

const allergies = createLoadingState(
  [
    healthRecordsActions.addAllergyRequest,
    healthRecordsActions.deleteAllergyRequest,
  ],
  [
    healthRecordsActions.addAllergySuccess,
    healthRecordsActions.addAllergyFailure,
    healthRecordsActions.deleteAllergySuccess,
    healthRecordsActions.deleteAllergyFailure,
  ],
)

const favoriteToggle = handleActions({
  [doctorsActions.toggleFavoriteRequest]: (state, action) => action.payload.doctor,
  [doctorsActions.toggleFavoriteSuccess]: () => false,
  [doctorsActions.toggleFavoriteFailure]: () => false,
}, false)

const reviews = handleActions({
  [reviewsActions.fetchDoctorReviewsRequest]: (state, action) => [...state, action.payload],
  [combineActions(
    reviewsActions.fetchDoctorReviewsSuccess,
    reviewsActions.fetchDoctorReviewsFailure,
  )]: (state, action) => state.filter(doctor => doctor !== action.payload.doctor),
}, [])

const doctors = createLoadingState(
  [doctorsActions.fetchDoctorsListRequest],
  [
    doctorsActions.fetchDoctorsListSuccess,
    doctorsActions.fetchDoctorsListFailure,
  ],
)

const authByToken = createLoadingState(
  [authActions.authByTokenRequest],
  [authActions.authByTokenSuccess, authActions.authByTokenFailure],
)

const previous = createLoadingState(
  [consultationsActions.fetchPreviousConsultationsRequest],
  [
    consultationsActions.fetchPreviousConsultationsSuccess,
    consultationsActions.fetchPreviousConsultationsFailure,
  ],
)

const consultation = createLoadingState(
  [consultationsActions.fetchConsultationRequest],
  [consultationsActions.fetchConsultationFailure, consultationsActions.fetchConsultationSuccess],
)

const fileUploading = createLoadingState(
  [chatsActions.uploadFileRequest],
  [chatsActions.uploadFileSuccess, chatsActions.uploadFileFailure],
)

const weekCalendar = createLoadingState(
  [calendarActions.fetchCalendarByWeekRequest],
  [calendarActions.fetchCalendarByWeekSuccess, calendarActions.fetchCalendarByWeekFailure],
)

const enterPhone = createLoadingState(
  [authActions.sendPhoneRequest],
  [authActions.sendPhoneSuccess, authActions.sendPhoneFailure],
)

const enterCode = createLoadingState(
  [authActions.sendCodeRequest],
  [authActions.sendCodeSuccess, authActions.sendCodeFailure],
)

const payment = createLoadingState(
  [consultationsActions.paymentRequest],
  [consultationsActions.paymentSuccess, consultationsActions.paymentFailure],
)

const acceptConcilium = createLoadingState(
  [consultationsActions.toggleConciliumRequest],
  [consultationsActions.toggleConciliumSuccess, consultationsActions.toggleConciliumFailure],
)

const consultationsList = createLoadingState(
  [consultationsActions.fetchConsultationsListRequest],
  [
    consultationsActions.fetchConsultationsListSuccess,
    consultationsActions.fetchConsultationsListFailure,
  ],
)

export const loading = combineReducers({
  auth,
  passwordRecovery,
  setNewPassword,
  changePassword,
  editProfile,
  workPlace,
  education,
  courses,
  diplomas,
  schedule,
  healthRecords,
  healthRecordsAttachments,
  createConsultation,
  editMedicalReport,
  approveConsultation,
  medicinesCompability,
  allergies,
  favoriteToggle,
  reviews,
  doctors,
  authByToken,
  previous,
  consultation,
  fileUploading,
  weekCalendar,
  enterPhone,
  enterCode,
  payment,
  acceptConcilium,
  consultationsList,
})
