import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import { CircularProgress } from '@material-ui/core'
import { LanguageSwitcher } from '../LanguageSwitcher'
import { NotificationsButton } from './Notifications'
import { PanelBar } from '../PanelBar'

const Wrapper = styled.div`
  padding: 24px;
`

const Content = styled.div`
  width: ${props => props.width};
  margin: 0 auto;
  ${props => (props.width === '100%' && !props.withoutOverflow) && `
    overflow-x: auto;
  `}
`

const PageWrapper = styled.div`
  background-color: ${({ white }) => (white ? Colors.white : Colors.background)};
  min-height: 100%;
  position: relative;
`

const LoaderWrapper = styled.div`
  height: calc(100vh - 56px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Page = ({
  label,
  children,
  onBackIconClick,
  id,
  onLogout,
  Icon,
  onIconClick,
  isFetching,
  fullWidth,
  ...rest
}) => (
  <PageWrapper {...rest}>
    <PanelBar
      label={label}
      onBackIconClick={onBackIconClick}
      id={id}
      onLogout={onLogout}
      Icon={Icon}
      onIconClick={onIconClick}
      languageSwitcher={<LanguageSwitcher />}
      notification={<NotificationsButton />}
    />
    <Wrapper>
      <Content width={fullWidth ? '100%' : '720px'} withoutOverflow={rest.withoutOverflow}>
        {
          isFetching
            ? (
              <LoaderWrapper>
                <CircularProgress size={80} />
              </LoaderWrapper>
            )
            : children
        }
      </Content>
    </Wrapper>
  </PageWrapper>
)

Page.defaultProps = {
  id: false,
  onLogout: false,
  onBackIconClick: false,
  onIconClick: false,
  isFetching: false,
  fullWidth: false,
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onLogout: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onBackIconClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onIconClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  isFetching: PropTypes.bool,
  fullWidth: PropTypes.bool,
}
