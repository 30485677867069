import React, { useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Avatar from '@material-ui/core/Avatar'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import moment from 'moment'
import CallIcon from '@material-ui/icons/Call'
import CallEndIcon from '@material-ui/icons/CallEnd'
import { Colors } from 'constants/colors'

import ringtone from '../../../assets/sounds/ringtone.mp3'
import { resetCall } from '../../../redux/modules/call'
import { history } from '../../../utils/history'
import { fetchSettings } from '../../../redux/modules/settings';
//import { toggleCallAnswerModal } from '../../../redux/modules/content';

//#region Styles
const Content = styled.div`
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  min-width: 250px;
`

const Photo = styled(Avatar)`
  && {
    width: 96px;
    height: 96px;
    animation: blinking .6s ease-in-out infinite;
  }
`

const PhotoWrapper = styled.div`
  position: relative;
  margin: 16px 0 32px;
    
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid ${Colors.clearBlue};
    box-sizing: border-box;
    content: "";
    animation: ripple .5s ease-out infinite;
  }
`

const Title = styled(DialogTitle)`
  && {
    text-align: center;
  }
`

const AcceptButton = styled(IconButton)`
  && {
    background-color: #35D073;
    color: ${Colors.white};
    
    &:hover {
      background-color: #4BB462;
    }
  }
`

const DeclineButton = styled(IconButton)`
  && {
    background-color: #F85C50;
    color: ${Colors.white};
    
    &:hover {
      background-color: #EE3D48;
    }
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin-top: 24px;
`
//#endregion

export const CallModal = () => {
  const dispatch = useDispatch()
  const data = useSelector(state => state.call)
  const isCalling = useSelector(state => !!Object.keys(state.consultations.opentok.params).length)
  const isDoctor = useSelector(state => state.auth.type === 'Doctor')
  const myId = useSelector(state => state.auth.attributes.user_id)
  const audio = new Audio(ringtone)
  const settings = useSelector(state => state.settings.values)

  const onEnd = (isAccepted) => {
    if (isDoctor && !isAccepted) {
      //dispatch(toggleCallAnswerModal(data.consultation.id))
    }
    dispatch(resetCall())
  }

  const onAccept = () => {
    onEnd(true)
    history.push(`/panel/consultations/${data.consultation.id}?call=1`)
  }

  audio.addEventListener('ended', async () => {
    audio.currentTime = 0
    const isPlaying = audio.currentTime > 0 && !audio.paused && !audio.ended
      && audio.readyState > 2

    if (!isPlaying && settings && settings.web_incoming_calls_sound_enabled === '1') {
      await audio.play()
    }
  })

  useEffect(() => {
    dispatch(fetchSettings())
  }, [dispatch])

  useEffect(() => {
    const isPlaying = audio.currentTime > 0 && !audio.paused && !audio.ended
      && audio.readyState > 2

    if (data && !data.declined) {
      if (data.user.id === myId || isCalling) {
        onEnd(true)
        return () => null
      }

      if (!isPlaying && settings && settings.web_incoming_calls_sound_enabled === '1') {
        audio.play()
          .catch(e => console.warn(e))
      }
    } else {
      audio.currentTime = 0
      audio.pause()
    }

    return () => {
      audio.pause()
      audio.currentTime = 0
    }
  }, [data])

  if (!data || data.declined || isCalling) {
    return null
  }

  return (
    <Dialog open>
      <Title>
        <FormattedMessage id='consultation.incoming.call' />
      </Title>
      <DialogContent>
        <Content>
          <PhotoWrapper>
            <Photo src={data.user.photo} />
          </PhotoWrapper>
          <Typography variant='h5'>
            {data.user.fullName}
          </Typography>
          <Typography variant='caption' color='textSecondary' paragraph>
            <FormattedMessage id='consultation.at' />
            {' '}
            {moment(data.consultation.proposedTime).format('HH:mm, DD/MM/YYYY')}
          </Typography>
          <ButtonWrapper>
            <DeclineButton size='large' onClick={() => onEnd()}>
              <CallEndIcon />
            </DeclineButton>
            <AcceptButton size='large' onClick={onAccept}>
              <CallIcon />
            </AcceptButton>
          </ButtonWrapper>
        </Content>
      </DialogContent>
    </Dialog>
  )
}
