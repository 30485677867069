import React from 'react'
import { Route, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import idx from 'idx'
import { DiplomasGallery } from '../../common/DiplomasGallery'
import { Consultations } from './Consultations'
import { Profile } from './Profile'
import { FillForm } from './Profile/FillForm'
import { Edit } from './Profile/Edit'
import { Settings } from './Settings'
import { HelpPage } from '../../common/HelpPage'
import { AdministratorChat } from './AdministratorChat'
import { doctorFAQ } from '../../../constants/FAQ'
import { Reviews } from '../Patient/Doctors/Reviews'
import { PhoneEditPage } from '../../Auth/PhoneConfirm/PhoneEditPage'
import { ConciliumContainer } from './Concilium'
import { Notifications } from '../Notifications'
import { AnswerModal } from '../../common/CallModal/AnswerModal'
import { Status } from './Status'
import { Signature } from './Profile/Signature'
import { SignatureUpload } from './Profile/Signature/Upload'
import { CommunityPageComponent } from './Community';
import { ROSOPageList } from './Community/ROSO';
import { CreateTeam } from './Community/ROSO/CreateTeam';
import { ManageTeam } from './Community/ROSO/ManageTeam';
import { CalendarPage } from './CalendarNew';

const enhance = compose(
  withRouter,
  connect(state => ({
    diplomas: idx(state, _ => _.auth.relationships.diploma.data) || [],
  })),
)

export const Doctor = enhance(({ diplomas }) => (
  <>
    <AnswerModal />
    <Route path='/panel/consultations' component={Consultations} />

    <Route path='/panel/concilium' component={ConciliumContainer} />

    <Route path='/panel/chat-administrator' component={AdministratorChat} />
    <Route path='/panel/schedule' component={CalendarPage} />
    <Route path='/panel/settings' component={Settings} />
    <Route path='/panel/status' component={Status} />
    <Route path='/panel/help' render={({ ...props }) => <HelpPage {...props} faq={doctorFAQ} terms='gdpr.modal.terms' />} />
    <Route exact path='/panel/profile' component={Profile} />
    <Route path='/panel/profile/fill' component={FillForm} />
    <Route path='/panel/profile/edit' component={Edit} />
    <Route path='/panel/profile/diplomas' render={() => <DiplomasGallery diplomas={diplomas} />} />
    <Route path='/panel/profile/reviews' component={Reviews} />
    <Route path='/panel/profile/phone/edit' component={PhoneEditPage} />
    <Route path='/panel/profile/notifications' component={Notifications} />
    <Route path='/panel/profile/signature' component={Signature} exact />
    <Route path='/panel/profile/signature/upload' component={SignatureUpload} />

    <Route path='/panel/community/roso-add' component={CreateTeam} exact />
    <Route path='/panel/community/roso' component={ROSOPageList} exact />
    <Route path='/panel/community/roso/:id' component={ManageTeam} exact />
    <Route path='/panel/community' component={CommunityPageComponent} exact />
  </>
))

Doctor.propTypes = {
  diplomas: PropTypes.arrayOf(PropTypes.string),
}
