import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import { Colors } from 'constants/colors'
import { useIntl } from 'react-intl'

const TabsStyled = styled(({ ...rest }) => (
  <Tabs {...rest} classes={{ indicator: 'indicator' }} />
))`
  height: 48px;
  background-color: ${Colors.white};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  padding: 0 calc((100% - 720px)/2) 0;

  & .indicator {
    height: 6px;
    border-radius: 3px;
  }
`

const TabStyled = styled(({ ...rest }) => (
  <Tab {...rest} classes={{ wrapper: 'wrapper' }} />
))`
  & .wrapper {
    width: 240px;
    font-size: 14px;
    font-weight: 500;
  }
`

export const Picker = ({
  tab,
  changeTab,
}) => {
  const { formatMessage } = useIntl()

  return (
    <TabsStyled
      value={tab}
      onChange={changeTab}
      indicatorColor='primary'
      textColor='primary'
    >
      <TabStyled disableRipple label={formatMessage({ id: 'consultations.upcoming' })} />
      <TabStyled disableRipple label={formatMessage({ id: 'consultations.past' })} />
    </TabsStyled>
  )
}

Picker.propTypes = {
  tab: PropTypes.number.isRequired,
  changeTab: PropTypes.func.isRequired,
}
