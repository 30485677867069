import React from 'react'
import * as Sentry from '@sentry/browser'

export class ErrorBoundary extends React.Component {
  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo)
      Sentry.captureException(error)
    })
  }

  render() {
    const { children } = this.props
    return children
  }
}
