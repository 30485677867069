import React from 'react'
import { Form } from 'react-final-form'
import { withProps } from 'recompose'
import PropTypes from 'prop-types'
import idx from 'idx'
import { Switchers } from './Switchers'
import { Text } from './Text'
import { CheckboxInputField } from '../CheckboxInputField'
import { Time } from './Time'

const validate = /*questions => */ (values) => {
  const errors = {}

  /*questions.forEach((q, i) => {
    const { attributes } = q

    if (attributes.type === 'text' 
    && (!values[`questions.${i}`] || !values[`questions.${i}`].length > 200)) {
      errors[`questions.${i}`] = true
    }

    if (attributes.type === 'enum' && !values[`questions.${i}`].length) {
      errors[`questions.${i}`] = true
    }
  })*/

  if (!values.accessPolicy) errors.accessPolicy = true
  if (!values.proposedDate) errors.proposedDate = true
  if (!values.symptomExplanation || values.symptomExplanation.length > 200) {
    errors.symptomExplanation = true
  }

  return errors
}

const normalizeOptions = (question) => {
  const answers = idx(question, _ => _.attributes.answers)
  return Object.keys(answers).map(k => ({
    label: answers[k],
    value: answers[k],
  }))
}

const enhance = withProps(props => ({
  onSubmit: values => props.onSubmit(values)
    .then(() => props.afterSubmit()),
}))

export const AppointmentConsultationForm = enhance(({
  onSubmit,
  questions,
  id,
  onAccessError,
  TimeField,
  setTypeOfPrice,
  available,
}) => (
  <Form
    onSubmit={onSubmit}
    subscription={{ errors: true }}
    validate={validate}
    initialValues={{
      accessHealthRecord: false,
    }}
    render={({ handleSubmit, errors }) => (
      <form
        onSubmit={(e) => {
          if (Object.keys(errors).length === 1 && errors.accessPolicy) {
            onAccessError()
          }

          handleSubmit(e)
        }}
        id={id}
      >
        <Time available={available} setTypeOfPrice={setTypeOfPrice} Component={TimeField} />
        {questions.map((q, i) => {
          if (idx(q, _ => _.attributes.type) === 'text') {
            return (
              <Text
                key={q.id}
                title={q.attributes.title}
                //placeholder={q.attributes.placeholder}
                id={i}
              />
            )
          }

          if (idx(q, _ => _.attributes.type) === 'choice') {
            return (
              <CheckboxInputField
                key={q.id}
                headingTransparent={q.attributes.title}
                options={normalizeOptions(q)}
                name={`questions.${i}`}
                id={q.id}
              />
            )
          }

          return null
        })}
        <Switchers />
      </form>
    )}
  />
))

AppointmentConsultationForm.defaultProps = {
  questions: [],
}

AppointmentConsultationForm.propTypes = {
  afterSubmit: PropTypes.func.isRequired,
  questions: PropTypes.arrayOf(PropTypes.object),
  onSubmit: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  onAccessError: PropTypes.func.isRequired,
}
