import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import moment from 'moment'

import { generateTimePeriods, getDaysArray, groupCalendarData } from '../../../../../helpers/calendar'
import { TimeColumn } from './TImeColumn'
import { DayColumn } from './DayColumn'

export const WeekCalendar = React.memo(({
  confirm,
  editing,
  onFreeClick,
  selected,
  locale = moment.locale(),
  setEditMenuData,
  concilium,
  cellType,
  setEventModal,
  setDeleteModal,
}) => {
  const periods = useMemo(() => generateTimePeriods(), [])
  const {
    from: fromDate,
    to: toDate,
  } = useSelector(state => state.calendar.filters.values.date)
  const range = useMemo(() => getDaysArray(fromDate, toDate), [fromDate, toDate, locale])
  const data = useSelector(state => state.calendar.list.week)
  const [scrolledOnce, toggleScrolledState] = useState(false)
  const groupedData = useMemo(() => groupCalendarData(data), [data])

  useEffect(() => {
    const el = document.getElementById('current_cell')

    if (el && !scrolledOnce) {
      el.scrollIntoView({ behavior: 'smooth', block: 'start' })
      toggleScrolledState(true)
    }
  }, [data])

  const weekNumber = moment(fromDate, 'YYYY-MM-DD').week()

  return (
    <Wrapper>
      <TimeColumn periods={periods} weekNumber={weekNumber} />
      {
        range.map((item) => {
          const list = groupedData[item.format('YYYY-MM-DD')]
          const isDayOff = list ? Object.keys(list).every(key => list[key].type === 'Holiday') : false

          return (
            <DayColumn
              day={item}
              periods={periods}
              key={item.toString() + Math.random()}
              isDayOff={isDayOff}
              hoursTotal={0}
              confirm={confirm}
              data={list}
              editing={editing}
              onFreeClick={onFreeClick}
              selected={selected}
              setEditMenuData={setEditMenuData}
              setEventModal={setEventModal}
              setDeleteModal={setDeleteModal}
              concilium={concilium}
              cellType={cellType}
            />
          )
        })
      }
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
`
