import idx from 'idx'

export const checkStep = (data) => {
  if (
    !idx(data, _ => _.attributes.first_name)
    || !idx(data, _ => _.attributes.last_name)
    || !idx(data, _ => _.attributes.specialization)
  ) return 1
  if (!idx(data, _ => _.attributes.credentials.rpps)) return 2
  if (!idx(data, _ => _.relationships.education.data.length)) return 3
  if (!idx(data, _ => _.relationships.diploma.data.length)) return 5
  return 1
}
