import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import idx from 'idx'
import moment from 'moment'
import { connect } from 'react-redux'
import { compose, withProps, withStateHandlers } from 'recompose'
import { DialogWindow } from 'common/UiKit/DialogWindow'
import { history } from '../../../../../../utils/history'
import {
  pushMessage, fetchOpentokParams, fetchConsultation, toggleVideoAccess,
} from '../../../../../../redux/modules/consultations'
import { Chat as WebChat } from '../../../../Chat'
import { socket } from '../../../../../../services/socket'
import { ButtonsBar } from '../../../../Doctor/Consultations/Session/Chat/ButtonsBar'
import { ConsultationBar } from '../../../../../common/ConsultationBar'

const Wrapper = styled.div`
  height: 100%;
`

const ChatWrapper = styled.div`
  height: calc(100% - 57px${({ withButtons }) => (withButtons ? ' - 80px' : '')});
`

const ChatComponent = ({
  onBackIconClick,
  doctor,
  onVideoIconClick,
  online,
  dialogWindowText,
  onDialogWindowClose,
  isCall,
  isCallDisabled,
  isCalling,
  completed,
}) => {
  useEffect(() => {
    if (isCalling) {
      onVideoIconClick()
    }
  }, [isCalling])

  return (
    <Wrapper>
      <DialogWindow
        open={!!dialogWindowText}
        onClose={onDialogWindowClose}
        closeButtonText='ok'
      >
        {dialogWindowText}
      </DialogWindow>
      <ConsultationBar
        onBackIconClick={onBackIconClick}
        firstName={idx(doctor, _ => _.first_name)}
        lastName={idx(doctor, _ => _.last_name)}
        onCallIconClick={() => onVideoIconClick(true)}
        onVideoIconClick={() => onVideoIconClick(false)}
        online={online}
        isCall={isCall}
        isCallDisabled={isCallDisabled && false}
      />
      <ChatWrapper withButtons={completed}>
        <WebChat />
      </ChatWrapper>
      {completed && (<ButtonsBar isPatient medicalReportFilled />)}
    </Wrapper>
  )
}

ChatComponent.defaultProps = {
  doctor: {},
  isCall: false,
}

ChatComponent.propTypes = {
  doctor: PropTypes.object,
  onBackIconClick: PropTypes.func.isRequired,
  onVideoIconClick: PropTypes.func.isRequired,
  onDialogWindowClose: PropTypes.func.isRequired,
  online: PropTypes.bool.isRequired,
  dialogWindowText: PropTypes.string.isRequired,
  isCall: PropTypes.bool,
  isCallDisabled: PropTypes.bool.isRequired,
}

const enhance = compose(
  connect(
    state => ({
      doctor: idx(state, _ => _.consultations.consultation.relationships.doctor.data.attributes),
      userAvatar: idx(state, _ => _.auth.attributes.photo),
      messages: idx(state, _ => _.consultations.opentok.messages),
      user: idx(state, _ => _.auth.type).toLowerCase(),
      consultationId: idx(state, _ => _.consultations.consultation.id),
      consultationStatus: idx(state, _ => _.consultations.consultation.attributes.status),
      reportDownloadLink: idx(state, _ => _.consultations.reportDownloadLink.attributes.link),
      prescriptionDownloadLink:
        idx(state, _ => _.consultations.prescriptionDownloadLink.attributes.link),
      isCall: idx(state, _ => _.consultations.callStatus.attributes.callStatus),
      proposedDate: idx(state, _ => _.consultations.consultation.attributes.proposed_date),
      completed: idx(state, _ => _.consultations.consultation.attributes.status === 'completed'),
    }),
    {
      pushMessage,
      fetchOpentokParams,
      fetchConsultation,
      toggleVideoAccess,
    },
  ),
  withStateHandlers({
    dialogWindowText: '',
  }, {
    setDialogWindowText: () => dialogWindowText => ({ dialogWindowText }),
  }),
  withProps(props => ({
    onBackIconClick: () => history.push('/panel/consultations'),
    online: moment(props.doctor.available_till).unix() > moment().unix() || props.isCall,
    onDialogWindowClose: () => props.setDialogWindowText(''),
    onVideoIconClick: withoutVideo => props.fetchConsultation(props.consultationId)
      .then(() => {
        //if (props.consultationStatus === 'created')
        // props.setDialogWindowText('The consultation isn\'t approved')
        if (props.consultationStatus === 'completed') props.setDialogWindowText('The consultation is completed')
        if (props.consultationStatus === 'confirmed' || props.consultationStatus === 'created') {
          props.toggleVideoAccess(!withoutVideo)
          props.fetchOpentokParams()
          if (!props.isCalling) {
            socket.call(props.consultationId)
          }
        }
      }),
    isCallDisabled: moment.duration(
      Math.abs(moment(props.proposedDate).unix() - moment().unix()),
      'seconds',
    ).asHours() > 4 && !props.isCall,
  })),
)

export const Chat = enhance(ChatComponent)
