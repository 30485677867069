import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

export const ModalSuccess = (props) => {
  const {
    open,
    handleClose,
  } = props;

  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => handleClose(false)}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        <DialogTitle id='scroll-dialog-title'>
          <Typography variant='p' align='center'>
            {f('gdpr.success.modal')}
          </Typography>
        </DialogTitle>
      </Dialog>
    </div>
  );
};

ModalSuccess.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
