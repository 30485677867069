import React from 'react'
import styled from 'styled-components'
import Create from '@material-ui/icons/Create'
import IconButton from '@material-ui/core/IconButton'
import { Link } from 'react-router-dom'
import { Paper } from 'common/UiKit/Paper'
import { useIntl } from 'react-intl'
import moment from 'moment'

const PaperStyled = styled(Paper)`
  padding: ${props => (props.blank ? 0 : '24px 24px 1px 24px')};
`

const Wrapper = styled.div`
  line-height: 1.38;
  margin-bottom: 0;

  & > p {
    opacity: 0.8;
    margin: 0;
  }
`

const Line = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:first-child {
    opacity: 0.87;
  }
`

const ChangeButton = styled(IconButton)`
  && {
    padding: 2px;
    margin-right: -4px;
  }

  && svg {
    font-size: 18px;
  }
`

export const Education = ({
  education,
  addButtonPath,
}) => {
  const { formatMessage } = useIntl()
  return (
    <PaperStyled
      blank={!education.length}
      heading={formatMessage({ id: 'profile.education' })}
      addButtonPath={addButtonPath}
    >
      {education.map(({ attributes, id }, index) => (
        <Wrapper key={id}>
          <Line>
            <p>
              {`${attributes.name}`}, {attributes.receivedAt ? moment(attributes.receivedAt.date).format('YYYY') : ''}
            </p>
            {addButtonPath
            && (
              <Link to={`${addButtonPath}#${index}`}>
                <ChangeButton color='primary'>
                  <Create fontSize='inherit' />
                </ChangeButton>
              </Link>
            )
            }
          </Line>
        </Wrapper>
      ))}
    </PaperStyled>
  )
}
