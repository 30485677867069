import React from 'react'
import styled from 'styled-components'
import { DatePicker } from 'common/UiKit/DatePicker'
import { Colors } from 'constants/colors'
import PropTypes from 'prop-types'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Search } from '../../../../common/Search'
import { searchConsultations } from '../../../../../redux/modules/settings'

const ToDateTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#f47c28',
      },
    },
    MuiPickersDay: {
      day: {
        color: '#f47c28',
      },
      selected: {
        backgroundColor: '#f47c28',
        '&:hover': {
          backgroundColor: '#f47c28',
        },
      },
      current: {
        color: '#f4742f',
      },
      hover: {
        backgroundColor: '#f47c28',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#f47c28',
      },
    },
  },
  typography: {
    useNextVariants: true,
  },
})

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const HeaderItem = styled.div`
  display: flex;
  align-items: center;
  
  & > span {
    font-size: 14px;
    margin-right: 16px;
  }
  
  & > div {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${Colors.white};
    border-radius: 4px;
    border: 1px solid #c5cbdb;
    padding: 0 15px;
    font-size: 14px;
  }
`

const PeriodField = styled(HeaderItem)`
  & > div:not(:first-of-type) {
    display: none;
  }
`

const PeriodLabel = styled.div`
  color: ${Colors.clearBlue};
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
`

const UrgentLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: #f47c28;
`

const SearchWrapper = styled.div`
  flex-grow: 1;
  max-width: 400px;
  
  & > * {
    border: 1px solid #c5cbdb;
  }
`

export const Header = ({
  from,
  to,
  handleFromChange,
  handleToChange,
  nearestUrgent,
}) => {
  const fromRef = React.createRef()
  const toRef = React.createRef()
  const dispatch = useDispatch()
  const locale = useSelector(state => state.localization)
  const onFromChange = (value) => {
    handleFromChange(value)
    toRef.current.open()
  }

  const soonestNearest = moment(nearestUrgent.TE).isAfter(nearestUrgent.TC)
    ? nearestUrgent.TC : nearestUrgent.TE;

  return (
    <Wrapper key={locale}>
      <PeriodField>
        <span>
          <FormattedMessage id='global.date' />
        </span>
        <PeriodLabel onClick={() => fromRef.current.open()}>
          {from.locale(locale).format('DD/MM/YYYY')}
          &nbsp;-&nbsp;
          {to.locale(locale).format('DD/MM/YYYY')}
        </PeriodLabel>
        <DatePicker value={from.locale(locale)} onChange={onFromChange} ref={fromRef} />
        <MuiThemeProvider theme={ToDateTheme}>
          <DatePicker
            minDate={from}
            value={to.locale(locale)}
            onChange={handleToChange}
            ref={toRef}
          />
        </MuiThemeProvider>
      </PeriodField>
      <SearchWrapper>
        <Search onSearch={value => dispatch(searchConsultations(value))} />
      </SearchWrapper>
      <HeaderItem>
        <span>
          <FormattedMessage id='consultations.nearest' />
        </span>
        <UrgentLabel>
          {
            soonestNearest
              ? moment(soonestNearest).format('DD MMMM YYYY')
              : '-'
          }
        </UrgentLabel>
      </HeaderItem>
    </Wrapper>
  )
}

Header.defaultProps = {
  nearestUrgent: undefined,
}

Header.propTypes = {
  from: PropTypes.object.isRequired,
  to: PropTypes.object.isRequired,
  handleFromChange: PropTypes.func.isRequired,
  handleToChange: PropTypes.func.isRequired,
  nearestUrgent: PropTypes.string,
}
