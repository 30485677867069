import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'
import { connect } from 'react-redux'
import { compose, withProps, withStateHandlers } from 'recompose'
import { DialogWindow } from 'common/UiKit/DialogWindow'
import idx from 'idx'
import get from 'lodash/get';
import { history } from '../../../../../../utils/history'
import {
  pushMessage,
  fetchOpentokParams,
  fetchConsultation,
  toggleVideoAccess,
} from '../../../../../../redux/modules/consultations'
import { ButtonsBar } from './ButtonsBar'
import { Chat as WebChat } from '../../../../Chat'
import { CONSULTATION_TYPES } from '../../../../../../constants/consultation'
import { patientSelector } from '../../../../../../redux/selectors/patientSelector'
import { socket } from '../../../../../../services/socket'
import { ConsultationBar } from '../../../../../common/ConsultationBar'

const Wrapper = styled.div`
  height: 100%;
`

const ChatWrapper = styled.div`
  height: calc(100% - 95px${props => (props.hideButtons ? '' : ' - 80px')});
`

const ChatComponent = ({
  healthRecordsOpened,
  patient,
  onBackIconClick,
  onVideoIconClick,
  onPatientAvatarClick,
  medicalReportFilled,
  prescriptionFilled,
  online,
  dialogWindowText,
  onDialogWindowClose,
  isNotDoctor,
  isCalling,
  completed,
  loading,
  isPaymentCompleted,
  consultationStatus,
  location,
  consultationId,
}) => {
  useEffect(() => {
    if (isCalling && !loading && location.pathname.indexOf(consultationId) !== -1) {
      onVideoIconClick()
    }
  }, [isCalling, loading])

  return (
    <Wrapper>
      <DialogWindow
        open={!!dialogWindowText}
        onClose={onDialogWindowClose}
        closeButtonText='ok'
      >
        {dialogWindowText}
      </DialogWindow>
      <ConsultationBar
        healthRecords
        firstName={idx(patient, _ => _.first_name)}
        lastName={idx(patient, _ => _.last_name)}
        healthRecordsOpened={healthRecordsOpened}
        onBackIconClick={onBackIconClick}
        onCallIconClick={() => onVideoIconClick(true)}
        onVideoIconClick={() => onVideoIconClick(false)}
        onPatientClick={onPatientAvatarClick}
        online={online}
      />
      <ChatWrapper hideButtons={((isNotDoctor && !completed) || consultationStatus === 'cancelled')}>
        <WebChat onAvatarClick={onPatientAvatarClick} />
      </ChatWrapper>
      {((isNotDoctor && !completed) || consultationStatus === 'cancelled')
        ? null
        : (
          <ButtonsBar
            isPaymentCompleted={isPaymentCompleted}
            medicalReportFilled={medicalReportFilled || (isNotDoctor && completed)}
            prescriptionFilled={prescriptionFilled}
            isPatient={(isNotDoctor && completed) || false}
          />
        )
      }
    </Wrapper>
  )
}

ChatComponent.defaultProps = {
  patient: {},
}

ChatComponent.propTypes = {
  healthRecordsOpened: PropTypes.bool.isRequired,
  onPatientAvatarClick: PropTypes.func.isRequired,
  patient: PropTypes.object,
  onBackIconClick: PropTypes.func.isRequired,
  onVideoIconClick: PropTypes.func.isRequired,
  onDialogWindowClose: PropTypes.func.isRequired,
  medicalReportFilled: PropTypes.bool.isRequired,
  prescriptionFilled: PropTypes.bool.isRequired,
  online: PropTypes.bool.isRequired,
  dialogWindowText: PropTypes.string.isRequired,
}

const enhance = compose(
  connect(
    state => ({
      isPaymentCompleted: idx(state, _ => get(_, 'consultations.consultation.attributes.invoice.paid', false)),
      patient: patientSelector()(state),
      userAvatar: idx(state, _ => _.auth.attributes.photo),
      messages: idx(state, _ => _.consultations.opentok.messages),
      user: idx(state, _ => _.auth.type).toLowerCase(),
      consultationId: idx(state, _ => _.consultations.consultation.id),
      consultationStatus: idx(state, _ => _.consultations.consultation.attributes.status),
      loading: state.loading.consultation,
      disableInput: !idx(state, _ => _.consultations.opentok.session.id),
      isNotDoctor: idx(state, _ => (_
        .consultations.consultation.attributes.type === CONSULTATION_TYPES.telexpertise
        ? _.consultations.consultation.relationships.doctor.data.id !== _.auth.id
        : false)),
      completed: idx(state, _ => _.consultations.consultation.attributes.status === 'completed'),
      healthRecordsOpened: idx(
        state,
        _ => _.consultations.consultation.attributes.access_health_record,
      ),
      medicalReportFilled: !!idx(
        state,
        _ => _.consultations.consultation.relationships.medicalReport.data
          .find(item => item.attributes.doctor === +_.auth.id),
      ),
      prescriptionFilled: !!(
        idx(state, _ => _.consultations.consultation.relationships.drugs.data) || []
      ).length,
      additionalMemberAvailable: idx(state, _ => _.consultations
        .consultation.relationships.additionalMember.data[0].attributes.availableTill.date),
    }),
    {
      pushMessage,
      fetchOpentokParams,
      fetchConsultation,
      toggleVideoAccess,
    },
  ),
  withStateHandlers({
    dialogWindowText: '',
  }, {
    setDialogWindowText: () => dialogWindowText => ({ dialogWindowText }),
  }),
  withProps(props => ({
    onBackIconClick: () => history.push('/panel/consultations'),
    onPatientAvatarClick: () => history.push('/panel/consultations/session/menu'),
    online: moment.utc((idx(props, _ => _
      .patient.available_till) || props.additionalMemberAvailable)).unix() > moment().unix(),
    onDialogWindowClose: () => props.setDialogWindowText(''),
    onVideoIconClick: (withoutVideo) => {
      //if (props.consultationStatus === 'created')
      // props.setDialogWindowText('La consultation doit être approuvée')
      if (props.consultationStatus === 'completed') props.setDialogWindowText('The consultation is completed')
      if (props.consultationStatus === 'confirmed' || props.consultationStatus === 'created') {
        props.toggleVideoAccess(!withoutVideo)
        history.push('/panel/consultations/session')
        props.fetchOpentokParams()
        if (!props.isCalling) {
          socket.call(props.consultationId)
        }
      }
    },
  })),
)

export const Chat = enhance(ChatComponent)
