import React, { useState } from 'react'
import PropTypes from 'prop-types'
import 'react-phone-number-input/style.css'
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input'
import styled from 'styled-components'
import { FormattedMessage, useIntl } from 'react-intl'
import { Input } from 'common/UiKit/Input'
import { TextButton } from 'common/UiKit/TextButton'
import { Colors } from 'constants/colors'
import { useDispatch, useSelector } from 'react-redux'
import { sendCode, sendPhone } from '../../../../redux/modules/auth'

const InputComponent = React.forwardRef((props, ref) => <InputStyled {...props} inputRef={ref} />)

export const PhoneConfirmForm = ({ afterSubmit }) => {
  const [phone, onPhoneChange] = useState('')
  const [code, onCodeChange] = useState('')
  const [errors, setErrors] = useState({ phone: '', code: '' })
  const [showCodeInput, toggleCodeInput] = useState(false)
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const fetching = useSelector(state => state.loading.enterPhone)
  const codeFetching = useSelector(state => state.loading.enterCode)
  const isPhoneValid = phone && typeof phone === 'string' ? isPossiblePhoneNumber(phone) : false;
  const isCodeValid = code.length > 4

  const onSendPhoneClick = () => {
    let phoneToSend = phone;
    if (phoneToSend.length > 12) {
      phoneToSend = phoneToSend.replace(/^\+330/, '+33');
    }
    dispatch(sendPhone(phoneToSend))
      .then(() => {
        toggleCodeInput(true)
      })
      .catch(() => {
        setErrors({ ...errors, phone: 'auth.error.phone' })
      })
  }

  const onSendCodeClick = () => {
    dispatch(sendCode(code))
      .then(() => {
        if (afterSubmit) afterSubmit()
      })
      .catch(() => {
        setErrors({ ...errors, code: 'auth.error.code' })
      })
  }

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      if (!showCodeInput && isPhoneValid) {
        onSendPhoneClick()
      }

      if (showCodeInput && isCodeValid) {
        onSendCodeClick()
      }
    }
  }

  return (
    <Wrapper>
      <Label>
        <FormattedMessage id='auth.phone.enter' />
      </Label>
      <PhoneNumberInput
        international
        defaultCountry='FR'
        value={phone}
        onChange={onPhoneChange}
        disabled={showCodeInput || fetching}
        inputComponent={InputComponent}
        displayInitialValueAsLocalNumber
        numberInputProps={{
          autoFocus: true,
          color: 'primary',
          disabled: showCodeInput || fetching,
          inputProps: {
            disabled: showCodeInput || fetching,
          },
          error: !!errors.phone,
          helperText: errors.phone ? formatMessage({ id: errors.phone }) : '',
          onKeyDown,
        }}
        limitMaxLength
      />
      {!showCodeInput && (
        <SendButton
          color='primary'
          disabled={!isPhoneValid}
          onClick={onSendPhoneClick}
          isFetching={fetching}
        >
          <FormattedMessage id='auth.send.sms' />
        </SendButton>
      )}
      {
        showCodeInput && (
          <>
            <Label>
              <FormattedMessage id='auth.enter.code' />
            </Label>
            <CodeInput
              value={code}
              onChange={(e) => {
                onCodeChange(e.target.value)
                setErrors({ ...errors, code: '' })
              }}
              autoFocus
              disabled={codeFetching}
              error={!!errors.code}
              helperText={errors.code ? formatMessage({ id: errors.code }) : ''}
              inputProps={{
                onKeyDown,
              }}
            />
            <SendButton
              color='primary'
              disabled={!isCodeValid || codeFetching}
              onClick={onSendCodeClick}
              isFetching={codeFetching}
            >
              <FormattedMessage id='auth.confirm' />
            </SendButton>
          </>
        )
      }
    </Wrapper>
  )
}

PhoneConfirmForm.defaultProps = {
  afterSubmit: undefined,
}

PhoneConfirmForm.propTypes = {
  afterSubmit: PropTypes.func,
}

//#region SC
const Wrapper = styled.div`
  padding: 24px;
`

const Label = styled.div`
  font-weight: 700;
  margin-bottom: 12px;
  color: ${Colors.dark80}
`

const PhoneNumberInput = styled(PhoneInput)`
  margin-left: -39px;
  margin-bottom: 24px;
`

const InputStyled = styled(Input)`
  && {
    margin-bottom: 0;
  }
`

const CodeInput = styled(Input)`
  && {
  }
`

const SendButton = styled(TextButton)`
  && {
    display: block;
    margin: 16px auto 0;
    height: 42px;
    font-size: 14px;
    width: 180px;
  }
`
//#endregion
