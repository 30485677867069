import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose, withProps } from 'recompose'
import { connect } from 'react-redux'
import idx from 'idx'
import { UserInfo } from 'common/UiKit/UserInfo'
import { TextButton } from 'common/UiKit/TextButton'
import { FormattedMessage, useIntl } from 'react-intl'
import { history } from '../../../../../utils/history'

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  margin-left: 175px;
`

const ButtonStyled = styled(TextButton)`
  && {
    width: calc(50% - 8px);
    height: 36px;
    font-size: 14px;
    font-weight: 500;
  }
`

const InfoComponent = ({
  attributes,
  onEditButtonClick,
  onChangePasswordButtonClick,
}) => {
  const { formatMessage } = useIntl()
  return (
    <UserInfo
      firstName={attributes.first_name || formatMessage({ id: 'global.not.filled.name' })}
      lastName={attributes.last_name || formatMessage({ id: 'global.not.filled.lastName' })}
      gender={attributes.gender}
      email={attributes.email}
      photo={attributes.photo}
      dateOfBirth={attributes.date_of_birth}
      language={attributes.language}
      phoneNumber={attributes.phoneNumber}
      medicalInsurance={attributes.medical_insurance}
    >
      <Buttons>
        <ButtonStyled
          color='primary'
          onClick={onEditButtonClick}
          variant='extended'
        >
          <FormattedMessage id='profile.edit.information' />
        </ButtonStyled>
        <ButtonStyled
          color='primary'
          onClick={onChangePasswordButtonClick}
          variant='extended'
        >
          <FormattedMessage id='profile.change.password' />
        </ButtonStyled>
      </Buttons>
    </UserInfo>
  )
}

InfoComponent.propTypes = {
  attributes: PropTypes.object.isRequired,
  onEditButtonClick: PropTypes.func.isRequired,
  onChangePasswordButtonClick: PropTypes.func.isRequired,
}

const enhance = compose(
  connect(state => ({ attributes: idx(state, _ => _.auth.attributes) })),
  withProps({
    onEditButtonClick: () => history.push('/panel/profile/edit'),
    onChangePasswordButtonClick: () => history.push('/panel/profile/change-password'),
  }),
)

export const Info = enhance(InfoComponent)
