import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Colors, calendarColors } from 'constants/colors'
import { FormattedMessage } from 'react-intl'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import concilium from '../../../../../../../images/icons/concilium.svg'
import { CONSULTATION_TYPES } from '../../../../../../../constants/consultation'

export const ConsultationCell = ({
  consultation: {
    patient,
    status,
    type,
    doctor,
    consultation,
    consiliumStatus,
    creatorDoctor,
  },
  myId,
}) => {
  // eslint-disable-next-line no-nested-ternary
  const user = creatorDoctor ? (creatorDoctor.id === myId ? doctor : creatorDoctor) : patient
  return (
    <Wrapper
      status={type === CONSULTATION_TYPES.telexpertise ? CONSULTATION_TYPES.telexpertise : status}
    >
      <NameWrapper>
        <Name>
          {user ? user.name : <FormattedMessage id='hr.unknown' />}
        </Name>
        {(type === 'urgent' && status === 'completed') && <Urgent>Ur</Urgent>}
        {consiliumStatus && <ConciliumIcon src={concilium} alt='' />}
      </NameWrapper>
      <div>
        {status === 'cancelled' && (
          <Failed>
            <FormattedMessage id='schedule.failed' />
          </Failed>
        )}
        {status === 'created' && (
          <New>
            <FormattedMessage id='schedule.new' />
          </New>
        )}
        {status === 'confirmed' && (
          <Confirmed>
            <FormattedMessage id='schedule.confirmed' />
          </Confirmed>
        )}
        {status === 'completed' && (
          <Completed>
            {(consultation && consultation.medicalReportPDF) && (
              <ReportLink href={consultation.medicalReportPDF} target='_blank' onClick={e => e.stopPropagation()}>
                <AssignmentOutlinedIcon />
                Report
              </ReportLink>
            )}
            {doctor && doctor.review && (
              <Rate>
                {doctor.review}
                <StarBorderIcon />
              </Rate>
            )}
          </Completed>
        )}
      </div>
    </Wrapper>
  )
}

ConsultationCell.propTypes = {
  consultation: PropTypes.object.isRequired,
}

//#region Styled components
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  padding: 8px;
  display: flex;
  color: ${props => (props.status !== 'cancelled' ? Colors.dark80 : Colors.disabledText)};
  flex-flow: column nowrap;
  justify-content: center;
  background-color: ${(props) => {
    switch (props.status) {
      case 'cancelled':
        return calendarColors.cancelledBg
      case 'completed':
        return calendarColors.completedBg
      case CONSULTATION_TYPES.telexpertise:
        return calendarColors.conciliumBg
      default:
        return calendarColors.confirmedBg
    }
  }};
  
  border-left: 3px solid ${(props) => {
    switch (props.status) {
      case 'cancelled':
        return calendarColors.cancelledBorder
      case 'completed':
        return calendarColors.completedBorder
      case CONSULTATION_TYPES.telexpertise:
        return calendarColors.conciliumBorder
      default:
        return Colors.clearBlue
    }
  }};
`

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Name = styled.div`
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
`

const Urgent = styled.div`
  font-size: 10px;
  color: ${calendarColors.completedBorder};
  height: 14px;
  width: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  border-radius: 50%;
  border: 2px solid ${calendarColors.completedBorder};
`

const New = styled.div`
  color: ${Colors.white};
  padding: 3px 6px;
  border-radius: 4px;
  background-color: ${Colors.red};
  display: inline-block;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 4px;
`

const ConciliumIcon = styled.img`
  height: 24px;
`

const Failed = styled.div`
  margin-top: 8px;
  font-size: 12px;
`

const Confirmed = styled.div`
  margin-top: 8px;
  font-size: 12px;
  font-weight: 500;
`

const Completed = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  
  a, div {
    display: flex;
    align-items: center;
    
    svg {
      width: 18px;
      height: 18px;
    }
  }
`

const ReportLink = styled.a`
  color: ${Colors.dark80};
  font-size: 12px;
  font-weight: 700;
  
  &:hover {
    text-decoration: underline;
  }

  svg {
    margin-right: 4px;
  }
`

const Rate = styled.div`
  font-weight: 700;
  font-size: 12px;
`
//#endregion
