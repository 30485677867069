import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose, withStateHandlers, withProps } from 'recompose'
import idx from 'idx'
import { connect, useSelector } from 'react-redux'
import { Colors } from 'constants/colors'
import moment from 'moment'
import { fetchConsultation, deleteSessionData } from '../../../../../redux/modules/consultations'
import { Header } from './Header'
import { Item } from './Item'
import { history } from '../../../../../utils/history'
import { EmptyScreen } from './EmptyScreen'
import { CONSULTATION_TYPES } from '../../../../../constants/consultation'
import { fetchHealthRecords } from '../../../../../redux/modules/healthRecords'
import { Preloader } from '../../../../common/Preloader';
import { matchByName } from '../../../../../helpers/consultations'

const Wrapper = styled.div`
  padding: 22px 26px;
`

const List = styled.div`
  max-width: 720px;
  margin: 40px auto;
`

const Month = styled.div`
  margin: 6px 0 8px;
  font-size: 14px;
  font-weight: 500;
  color: ${Colors.dark};
  opacity: 0.87;
  line-height: 1.29;
`

const Loader = styled(Preloader)`
  width: 100%;
  height: calc(100vh - 170px);
`

// Checks date and adds month component if different months
const checkDate = (item, index, arr) => {
  const date = idx(item, _ => _.attributes.proposed_date)

  if (
    index === 0
    || !moment(date).isSame(idx(arr[index - 1], _ => _.attributes.proposed_date), 'day')
  ) {
    return <Month>{moment(date).format('dddd MMMM D')}</Month>
  }

  return null
}

const ConsultationsListComponent = ({
  from,
  to,
  handleFromChange,
  handleToChange,
  consultations,
  onConsultationClick,
  nearestUrgent,
  myId,
  loading,
  refreshConsultations,
  filters,
  auth,
}) => {
  const search = useSelector(state => state.settings.consultationSearch)
  const filteredList = consultations.filter(matchByName(search))

  return (
    <Wrapper>
      <Header
        from={from}
        to={to}
        handleFromChange={handleFromChange}
        handleToChange={handleToChange}
        nearestUrgent={nearestUrgent}
      />
      <List>
        {
            (!loading && filteredList.length)
              ? filteredList.map((item, index, arr) => (
                <div key={idx(item, _ => _.id)}>
                  {checkDate(item, index, arr)}
                  <Item
                    me={auth}
                    filters={filters}
                    refreshConsultations={refreshConsultations}
                    id={idx(item, _ => _.id)}
                    patient={idx(item, _ => _.relationships[_.attributes.type === CONSULTATION_TYPES.telexpertise ? 'doctor' : 'patient'].data.attributes)}
                    date={idx(item, _ => _.attributes.proposed_date)}
                    invoice={idx(item, _ => _.attributes.invoice)}
                    status={idx(item, _ => _.attributes.status)}
                    accessHealthRecords={idx(item, _ => _.attributes.access_health_record)}
                    onClick={onConsultationClick(item)}
                    urgent={!!idx(item, _ => _.attributes.type === 'urgent')}
                    isConcilium={item.attributes.consiliumStatus}
                    type={item.attributes.type}
                    additionalMember={idx(item, _ => _
                      .relationships.additionalMember.data[0].attributes.doctor)}
                    isMy={idx(
                      item,
                      _ => (_.attributes.type === CONSULTATION_TYPES.telexpertise
                                  && _.relationships.doctor.data.id === myId),
                    )}
                  />
                </div>
              ))
              : (
                    <>
                      {
                        loading
                          ? <Loader />
                          : <EmptyScreen />
                      }
                    </>
              )
          }

      </List>
    </Wrapper>
  )
}

ConsultationsListComponent.defaultProps = {
  nearestUrgent: undefined,
}

ConsultationsListComponent.propTypes = {
  from: PropTypes.object.isRequired,
  to: PropTypes.object.isRequired,
  handleFromChange: PropTypes.func.isRequired,
  handleToChange: PropTypes.func.isRequired,
  onConsultationClick: PropTypes.func.isRequired,
  consultations: PropTypes.arrayOf(PropTypes.object).isRequired,
  nearestUrgent: PropTypes.string,
}

const enhance = compose(
  connect(
    state => ({
      nearestUrgent: idx(state, _ => _.auth.attributes.calendar_nearest),
      myId: state.auth.id,
      auth: state.auth,
      loading: state.loading.consultationsList,
    }),
    { fetchConsultation, deleteSessionData, fetchHealthRecords },
  ),
  withStateHandlers({
    from: moment(),
    to: moment().add(7, 'days'),
  }, {
    handleFromChange: () => from => ({ from }),
    handleToChange: (state, props) => (to) => {
      if (props.fetchConsultations && props.filters) {
        props.fetchConsultations({
          ...props.filters,
          dateFrom: state.from.format('YYYY-MM-DD'),
          dateTo: to.format('YYYY-MM-DD'),
        })
      }

      return { to }
    },
    refreshConsultations: (state, props) => () => {
      if (props.fetchConsultations && props.filters) {
        props.fetchConsultations({
          ...props.filters,
          dateFrom: state.from.format('YYYY-MM-DD'),
        })
      }
    },
  }),
  withProps(() => ({
    // onConsultationClick: data => () => props.fetchConsultation(data.id, data.attributes.type)
    //   .then(() => {
    //     props.fetchHealthRecords(data.id)
    //     history.push('/panel/consultations/session')
    //   }),
    onConsultationClick: ({ id }) => () => history.push(`/panel/consultations/${id}`),
  })),
)

export const ConsultationsList = enhance(ConsultationsListComponent)
