import { createAction, handleActions } from 'redux-actions'
import get from 'lodash/get';
import axios from 'axios'

const arrayToObjectSet = settings => settings.reduce((accum, el) => {
  const toNewAccum = { ...accum };
  const key = get(el, 'attributes.key', false);
  const value = get(el, 'attributes.value', false);
  if (!key || !value) {
    return toNewAccum;
  }
  toNewAccum[key] = value;
  return toNewAccum;
}, {})

const defaultState = {
  notifications: { time: 'none', consultations: false, sound: false },
  consultationSearch: '',
  doctorSearch: '',
  language: 'English',
  values: {
    enabled: false,
    reminderTime: 0,
    web_incoming_calls_sound_enabled: 1,
  },
}

//#region Actions
export const changeNotificationsSettings = createAction('CHANGE_NOTIFICATIONS_SETTINGS')
export const changeLanguageSettings = createAction('CHANGE_LANGUAGE_SETTINGS')

export const fetchSettingsRequest = createAction('FETCH_SETTINGS_REQUEST')
export const fetchSettingsSuccess = createAction('FETCH_SETTINGS_SUCCESS')
export const fetchSettingsFailure = createAction('FETCH_SETTINGS_FAILURE')

export const updateSettingsRequest = createAction('UPDATE_SETTINGS_REQUEST')
export const updateSettingsSuccess = createAction('UPDATE_SETTINGS_SUCCESS')
export const updateSettingsFailure = createAction('UPDATE_SETTINGS_FAILURE')

export const searchConsultations = createAction('SEARCH_CONSULTATIONS')
export const searchDoctors = createAction('SEARCH_DOCTORS')
export const createTeamRequest = createAction('CREATE_TEAM_REQUEST');
//#endregion

//#region Reducers
export const settings = handleActions({
  [changeNotificationsSettings]: (state, action) => ({ ...state, notifications: action.payload }),
  [changeLanguageSettings]: (state, action) => ({ ...state, language: action.payload }),
  [fetchSettingsSuccess]: (state, action) => ({ ...state, values: action.payload }),
  [updateSettingsSuccess]: (state, action) => ({ ...state, values: action.payload }),
  [searchConsultations]: (state, action) => ({ ...state, consultationSearch: action.payload }),
  [searchDoctors]: (state, action) => ({ ...state, doctorSearch: action.payload }),
}, defaultState)
//#endregion

//#region Thunks
export const fetchSettings = () => (dispatch) => {
  dispatch(fetchSettingsRequest())

  return axios('/user/settings')
    .then(response => dispatch(fetchSettingsSuccess(arrayToObjectSet(response.data.data))))
    .catch(e => dispatch(fetchSettingsFailure(e)))
}

export const createTeam = values => (dispatch) => {
  dispatch(createTeamRequest())
  return axios.post('/api/doctor/team/create', values)
    .then(response => dispatch(updateSettingsSuccess(response.data.data.attributes)))
    .catch(e => dispatch(updateSettingsFailure(e)))
}
export const updateSettings = values => (dispatch) => {
  dispatch(updateSettingsRequest())
  const data = new FormData()

  data.append('notifications_enabled', Number(values.notifications_enabled))
  data.append('notifications_reminder_time', Number(values.notifications_reminder_time))
  data.append('web_incoming_calls_sound_enabled', Number(values.web_incoming_calls_sound_enabled))

  return axios.post('/user/settings', data)
    .then(response => dispatch(updateSettingsSuccess(response.data.data.attributes)))
    .catch(e => dispatch(updateSettingsFailure(e)))
}
//#endregion
