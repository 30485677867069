import React from 'react'
import { Link } from 'react-router-dom'
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Colors, LEVEL_COLORS } from 'constants/colors'
import { FormattedMessage } from 'react-intl'
import avatarImg from '../../../images/panel/default-avatar.svg'
import { history } from '../../../utils/history';

const Wrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  font-family: Roboto, sans-serif;
`

const Avatar = styled.div`
  width: 98px;
  height: 98px;
  margin-bottom: 14px;
  box-sizing: border-box;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 100%;
  border: 5px solid ${({ level }) => (level ? LEVEL_COLORS[level] || Colors.white : Colors.white)};
  background: url(${props => props.photo}) ${Colors.lightGrey} center no-repeat; 
  background-size: cover;
`

const LinkStyled = styled(Link)`
  width: 100%;
  margin-bottom: 2px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.2px;
  color: white;
  word-wrap: break-word;
  text-align: center;

  &:hover {
    text-decoration: underline;
  }
`

const Email = styled.p`
  margin: 0 0 25px;
  color: white;
  opacity: 0.5;
  font-size: 12px;
  font-weight: normal;
  line-height: 2;
`

export const MenuProfileInfoComponent = ({
  photo,
  email,
  firstName,
  lastName,
}) => {
  const level = useSelector(state => (state.auth.type === 'Doctor' ? state.auth.attributes.level : null))
  return (
    <Wrapper>
      <Avatar
        photo={photo && photo.includes('/file/') ? `http://backend-application.owndoctor.eu${photo}` : photo}
        level={level}
        onClick={() => history.push('/panel/profile')}
      />
      <LinkStyled to='/panel/profile'>
        {firstName}
        &nbsp;
        {lastName}
      </LinkStyled>
      <Email>{email}</Email>
    </Wrapper>
  )
}

MenuProfileInfoComponent.defaultProps = {
  photo: '',
}

MenuProfileInfoComponent.propTypes = {
  photo: PropTypes.string,
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
}

const enhance = connect(
  state => ({
    photo: state.auth.attributes.photo || avatarImg,
    email: state.auth.attributes.specialization,
    firstName: state.auth.attributes.first_name || <FormattedMessage id='global.not.filled.name' />,
    lastName: state.auth.attributes.last_name || <FormattedMessage id='global.not.filled.lastName' />,
  }),
)

export const MenuProfileInfo = enhance(MenuProfileInfoComponent)
