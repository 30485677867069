import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose, withProps, withStateHandlers } from 'recompose'
import { connect } from 'react-redux'
import { Colors } from 'constants/colors'
import { Picker } from './Picker'
import { Upcoming } from './Upcoming'
import { Past } from './Past'
import { history } from '../../../../../utils/history'
import { logOut } from '../../../../../redux/modules/auth'
import { LanguageSwitcher } from '../../../../common/LanguageSwitcher'
import { NotificationsButton } from '../../../../common/Page/Notifications'
import { PanelBar } from '../../../../common/PanelBar'

const Wrapper = styled.div`
  background-color: ${Colors.background};
  min-height: 100%;
  position: relative;
`

const Content = styled.div`
  width: 720px;
  margin: auto;
  padding: 24px 0;
`

const ListComponent = ({
  tab,
  changeTab,
  onBackIconClick,
  logOut,
}) => (
  <Wrapper>
    <PanelBar
      label='consultation.title'
      onBackIconClick={onBackIconClick}
      onLogout={logOut}
      notification={<NotificationsButton />}
      languageSwitcher={<LanguageSwitcher />}
    />
    <Picker
      tab={tab}
      changeTab={changeTab}
    />
    <Content>
      {tab === 0 && <Upcoming />}
      {tab === 1 && <Past />}
    </Content>
  </Wrapper>
)

ListComponent.propTypes = {
  tab: PropTypes.number.isRequired,
  changeTab: PropTypes.func.isRequired,
  onBackIconClick: PropTypes.func.isRequired,
  logOut: PropTypes.func.isRequired,
}

const enhance = compose(
  connect(() => ({}), { logOut }),
  withStateHandlers({
    tab: 0,
  }, {
    changeTab: () => (e, v) => ({ tab: v }),
  }),
  withProps({
    onBackIconClick: () => history.push('/panel/doctors'),
  }),
)

export const List = enhance(ListComponent)
