import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { Colors, calendarColors } from 'constants/colors'
import { useDispatch, useSelector } from 'react-redux'
import { approveConsultation, refuseConsultation } from '../../../../../redux/modules/consultations'

export const ConfirmDialog = ({ data, onClose, afterSubmit }) => {
  const [showRefuseForm, toggleRefuseForm] = useState(!!data.refuse)
  const [refuseReason, changeRefuseReason] = useState('')
  const dispatch = useDispatch()
  const loading = useSelector(state => state.loading.approveConsultation)
  const onConfirm = () => {
    dispatch(approveConsultation(data.id, data.type))
      .then(() => {
        afterSubmit()
        onClose()
      })
  }

  const onRefuse = () => {
    dispatch(refuseConsultation(data.id, refuseReason))
      .then(() => {
        afterSubmit()
        onClose()
      })
  }

  return (
    <Dialog open={!!data} onClose={onClose}>
      <DialogTitle>
        <Title><FormattedMessage id='schedule.new.request' /></Title>
      </DialogTitle>
      <DialogContent>
        <Info>
          <FormattedMessage id='schedule.date' />
          : {data.day.format('DD.MM.YYYY, dddd')}
        </Info>
        <Info>
          <FormattedMessage id='schedule.at' />
          {' '}
          {data.period.from}
        </Info>
        <Info>
          <FormattedMessage id='schedule.patient' />
          : {(data.patient || data.creatorDoctor).name}
        </Info>
        {
          showRefuseForm && (
            <Field
              value={refuseReason}
              onChange={e => changeRefuseReason(e.target.value)}
              variant='outlined'
              multiline
              rows={4}
              fullWidth
            />
          )
        }
        {
          showRefuseForm
            ? (
              <Buttons>
                <RefuseButton disabled={!refuseReason || loading} onClick={onRefuse}>
                  <FormattedMessage id='schedule.refuse' />
                </RefuseButton>
                <CancelButton
                  onClick={data.refuse ? onClose : () => toggleRefuseForm(false)}
                  disabled={loading}
                >
                  <FormattedMessage id='global.cancel' />
                </CancelButton>
              </Buttons>
            )
            : (
              <Buttons>
                <ConfirmButton onClick={onConfirm} disabled={loading}>
                  <FormattedMessage id='schedule.confirm' />
                </ConfirmButton>
                <RefuseButton onClick={() => toggleRefuseForm(true)} disabled={loading}>
                  <FormattedMessage id='schedule.refuse' />
                </RefuseButton>
                <Later>
                  <span onClick={onClose}>
                    <FormattedMessage id='schedule.later' />
                  </span>
                </Later>
              </Buttons>
            )
        }
      </DialogContent>
    </Dialog>
  )
}

ConfirmDialog.defaultProps = {
  data: null,
}

ConfirmDialog.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  afterSubmit: PropTypes.func.isRequired,
}

//#region Styled components
const Title = styled.div`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 32px;
`

const Info = styled.div`
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 8px;
`

const Buttons = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: flex-end;
`

const ConfirmButton = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  padding: 16px;
  border-radius: 12px;
  background-color: ${calendarColors.confirmButton};
  color: ${Colors.white};
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: .4px;
  font-size: 12px;
  transition: all .1s ease-out;
  margin-right: 12px;
  
  &:hover {
    background-color: ${calendarColors.confirmButtonHover};
  }
`

const RefuseButton = styled(ConfirmButton)`
  background-color: ${calendarColors.refuseButton};
  
  &:hover {
    background-color: ${calendarColors.refuseButtonHover};
  }
  
  &:disabled {
    background-color: ${fade(calendarColors.refuseButton, 0.15)};
  }
`

const CancelButton = styled(ConfirmButton)`
  background-color: ${Colors.textMuted};
  
  &:hover {
    background-color: ${Colors.steel};
  }
`

const Later = styled.div`
  flex-grow: 1;
  text-align: right;
  
  span {
    color: ${Colors.disabledText};
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    border-bottom: 1px solid ${Colors.disabledText};
  }
`

const Field = styled(TextField)`
  && {
    margin-top: 16px;
  }
`
//#endregion
