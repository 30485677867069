import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import DialogContent from '@material-ui/core/DialogContent'
import Avatar from '@material-ui/core/Avatar'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { TextButton } from 'common/UiKit/TextButton'
import { Colors } from 'constants/colors'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'

import { fetchCalendar } from '../../../../../../redux/modules/calendar/list'
import { DateFilter } from '../../../../Doctor/Calendar/Filters/Date'
import { changeFilter } from '../../../../../../redux/modules/calendar/filters'
import { WeekCalendar } from '../../../../Doctor/Calendar/Week'
import { getPeriodDate } from '../../../../../../helpers/calendar'

export const PatientCalendar = ({
  input,
  concilium,
  meta,
  cellType,
  setTypeOfPrice,
  available,
}) => {
  const [opened, toggle] = useState(false)
  const doctor = useSelector(state => state.doctors.doctor)
  const filters = useSelector(state => state.calendar.filters.values)
  const dispatch = useDispatch()

  const onDateChange = value => dispatch(changeFilter('date', value))

  const onFreeClick = (day, period, isReallyUrgent) => {
    const weekday = day.day();
    const isWeekend = (weekday === 6 || weekday === 0); 
    if (isWeekend) {
      setTypeOfPrice('weekend_price');
    } else {
      setTypeOfPrice('daily_price');
    }
    if (isReallyUrgent) {
      setTypeOfPrice('urgent_price');
    }
    return input.onChange(getPeriodDate(day, period).format('YYYY-MM-DD HH:mm'))
  }
  const value = input.value ? moment(input.value).format('DD MMMM YYYY, HH:mm') : ''

  useEffect(() => {
    dispatch(fetchCalendar(filters, doctor.id))
  }, [doctor.id, filters])

  useEffect(() => () => {
    dispatch(changeFilter('date', {
      from: moment().startOf('week').format('YYYY-MM-DD'),
      to: moment().endOf('week').format('YYYY-MM-DD'),
    })) 
  }, [])

  return (
    <div>
      <Input
        label={<><FormattedMessage id='consultation.time' />*</>}
        onClick={() => toggle(true)}
        fullWidth
        variant='filled'
        value={value}
        error={meta.touched && meta.error}
        helperText={meta.touched && meta.error}
      />
      <Dialog
        open={opened}
        onClose={() => toggle(false)}
        maxWidth='lg'
        fullWidth
        //keepMounted
      >
        <Header>
          <DoctorInfo>
            <Avatar src={doctor.attributes.photo} />
            <DoctorName>
              {doctor.attributes.first_name}
              {' '}
              {doctor.attributes.last_name}
            </DoctorName>
          </DoctorInfo>
          <DateFilter
            calendarType={filters.calendarType}
            onChange={onDateChange}
            value={filters.date}
          />
          <AppointmentButton
            color='secondary'
            size='small'
            disabled={!input.value}
            onClick={() => toggle(false)}
          >
            <FormattedMessage id='consultation.make.appointment' />
          </AppointmentButton>
        </Header>
        <DialogContent>
          <WeekCalendar
            available={available}
            editing={false}
            onFreeClick={onFreeClick}
            selected={input.value}
            concilium={concilium}
            cellType={cellType}
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}

PatientCalendar.defaultProps = {
  concilium: false,
}

PatientCalendar.propTypes = {
  input: PropTypes.object.isRequired,
  concilium: PropTypes.bool,
}

//#region Styled components
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background-color: white;
  padding: 24px;
`

const DoctorInfo = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;
`

const DoctorName = styled.div`
  font-weight: 700;
  font-size: 16px;
  margin-left: 12px;
  white-space: nowrap;
`

const AppointmentButton = styled(TextButton)`
  && {
    min-width: 260px;
  }
`

const Input = styled(TextField)`
  && {
  div {
    background-color: transparent;
  }
    
    input {
      background-color: ${Colors.paleGrey60};
    }
  }
`
//#endregion
