import React, { useState } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux';
import { editProfile } from '../../../../../redux/modules/auth'

const Wrapper = styled.div`
  margin-top: 12px;
`

export const EmploymentTypes = {
  private: 'private',
  hospital: 'hospital',
  merge: 'merge',
}

export const EmploymentTypeField = ({ disabled }) => {
  const type = useSelector(state => state.auth.attributes.type_employment)
  const [value, onValueChange] = useState(type)
  const dispatch = useDispatch()

  const onChange = (value) => {
    dispatch(editProfile({ type_employment: value }))
    onValueChange(value)
  }

  const handleChange = (e) => {
    const newValue = e.target.value

    if (value === EmploymentTypes.merge) {
      onChange(newValue === EmploymentTypes.hospital
        ? EmploymentTypes.private
        : EmploymentTypes.hospital)
    } else if (!value) {
      onChange(newValue)
    } else if (value !== newValue) {
      onChange(EmploymentTypes.merge)
    }
  }

  return (
    <Wrapper>
      <FormControlLabel
        control={(
          <Checkbox
            color='primary'
            value={EmploymentTypes.private}
            checked={value === EmploymentTypes.private || value === EmploymentTypes.merge}
            onChange={handleChange}
            disabled={disabled}
          />
        )}
        label={<FormattedMessage id='profile.liberal' />}
      />
      <FormControlLabel
        control={(
          <Checkbox
            color='primary'
            value={EmploymentTypes.hospital}
            checked={value === EmploymentTypes.hospital || value === EmploymentTypes.merge}
            onChange={handleChange}
            disabled={disabled}
          />
        )}
        label={<FormattedMessage id='profile.salary' />}
      />
    </Wrapper>
  )
}
