import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { Colors } from 'constants/colors'
import { FormattedMessage } from 'react-intl'

import { Wrapper, Heading } from '../Info'

const WrapperStyled = styled(Wrapper)`
  padding: 0;
`

const Content = styled.div`
  padding: 10px 12px;
`

const Buttons = styled.div`
  border-top: solid 1px ${Colors.cloudyBlue};
  display: flex;

  & > :first-child {
    border-right: solid 1px ${Colors.cloudyBlue};
  }

  * {
    flex-grow: 0.5;
  }

  button {
    font-weight: 500;
  }

  button:first-child {
    width: 100%;
  }
`

const SeeButton = styled(Button)`
  && {
    border-radius: 0 0 0 4px;
  }
`

const DownloadButton = styled(Button)`
  && {
    border-radius: 0 0 4px 0;
  }
`

export const Download = ({
  className,
  heading,
  children,
  seeButtonPath,
  downloadButtonPath,
}) => (
  <WrapperStyled className={className}>
    <Content>
      <Heading>
        <FormattedMessage id={heading} defaultMessage={heading} />
      </Heading>
      {children}
    </Content>
    <Buttons>
      <Link to={seeButtonPath}>
        <SeeButton color='primary'>
          <FormattedMessage id='consultations.see.more' />
        </SeeButton>
      </Link>
      <DownloadButton
        disabled={!downloadButtonPath}
        onClick={() => window.open(downloadButtonPath)}
        color='primary'
      >
        <FormattedMessage id='global.download' />
      </DownloadButton>
    </Buttons>
  </WrapperStyled>
)

Download.defaultProps = {
  seeButtonPath: '',
  downloadButtonPath: '',
}

Download.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  heading: PropTypes.string.isRequired,
  seeButtonPath: PropTypes.string,
  downloadButtonPath: PropTypes.string,
}
