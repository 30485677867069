import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose, withProps, withStateHandlers } from 'recompose'
import IconButton from '@material-ui/core/IconButton'
import AttachFile from '@material-ui/icons/AttachFile'
import Send from '@material-ui/icons/Send'
import { Colors } from 'constants/colors'
import { useIntl, FormattedMessage } from 'react-intl'
import Dropzone from 'react-dropzone'
import { useSelector } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'

const Wrapper = styled.div`
  display: flex;
`

const InputWrapper = styled.div`
  box-sizing: border-box;
  margin: 0 24px ${props => (props.focus ? '15px' : '16px')};
  padding-right: 3px;
  border-style: solid;
  border-color: ${props => (props.focus ? Colors.clearBlue : Colors.cloudyBlue)};
  border-width: ${props => (props.focus ? '2px' : '1px')};
  border-radius: 4px;
  background-color: ${props => (props.disabled ? 'rgb(235, 235, 228)' : 'white')};
  display: flex;
  align-items: center;
  flex: 1;
  color: ${Colors.blueGrey};
`

const Button = styled(IconButton)`
  && {
    padding: 5px;
    margin: ${props => (props.focus ? '0' : '1px')};
  }
`

const SendAttachment = styled(IconButton)`
  && {
    padding: 5px;
    margin: 0 19px 17px 0;
    color: #209dff;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    border-radius: 20px;
    padding-right: 5px;
    text-align: center;
    font-family: Roboto;
    font-weight: 500;
  }
`

const Input = styled.input`
  flex-grow: 1;
  padding: 9px 12px;
  border: none;
  font-size: 16px;
  border-left: inherit;
  color: ${Colors.dark80};

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${Colors.cloudyBlue};
  }
`

const InputMessageComponent = ({
  value,
  onChange,
  onKeyUp,
  focus,
  onFocus,
  onBlur,
  onClick,
  disabled,
  onDrop,
  fileLoadingProgress,
}) => {
  const { formatMessage } = useIntl()
  const fileUploading = useSelector(state => state.loading.fileUploading)

  return (
    <Wrapper>
      <InputWrapper
        focus={focus ? 'true' : undefined}
        disabled={disabled}
      >
        <Input
          placeholder={formatMessage({ id: 'consultations.chat.placeholder' })}
          type='text'
          value={value}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
        />
        {value && (
          <Button
            color='primary'
            focus={focus ? 'true' : undefined}
            onClick={onClick}
          >
            <Send />
          </Button>
        )}
      </InputWrapper>
      <Dropzone
        accept='.jpg,.png,.doc,.docx,.xls,.xlsx,.pdf'
        multiple
        onDrop={onDrop}
      >
        {
          ({ getRootProps, getInputProps }) => (
            <SendAttachment
              color='inherit'
              focus={focus ? 'true' : undefined}
              {...getRootProps()}
              disabled={fileUploading}
            >
              {
                fileUploading
                  ? <CircularProgress size={24} variant='static' value={fileLoadingProgress} />
                  : <AttachFile />
              }
              <input {...getInputProps()} />
              <FormattedMessage id='global.sendAttach' />
            </SendAttachment>
          )
        }
      </Dropzone>
    </Wrapper>
  )
}

InputMessageComponent.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyUp: PropTypes.func.isRequired,
  focus: PropTypes.bool.isRequired,
  onFocus: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  onFileSubmit: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  fileLoadingProgress: PropTypes.number.isRequired,
}

const enhance = compose(
  withStateHandlers({
    value: '',
    focus: false,
  }, {
    setValue: () => value => ({ value }),
    onFocus: () => () => ({ focus: true }),
    onBlur: () => () => ({ focus: false }),
  }),
  withProps(props => ({
    onKeyUp: (e) => {
      if (e.keyCode === 13 && props.value) {
        props.pushMessage(props.value)
        props.setValue('')
      }
    },
    onClick: () => {
      props.pushMessage(props.value)
      props.setValue('')
    },
    onChange: e => props.setValue(e.target.value),
    onDrop: async (files) => {
      if (!files.length) return
      await files.forEach(async (file) => {
        await props.onFileSubmit({ file })
      })
    },
  })),
)

export const InputMessage = enhance(InputMessageComponent)
