import React from 'react'
import styled from 'styled-components'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

const PaginationItems = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const PaginationButton = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding: 0 10px;
  align-items: center;
`
const PaginationItem = styled.div`
  padding: 0 16px;
  cursor: pointer;
  font-size: 14px;
`

const ActiveItem = styled.div`
  color: blue;
  padding: 0 10px;
  cursor: pointer;
  font-size: 14px;
  background-color: #209dff;
  border-radius: 50%;
  color: #fff;
  padding: 8px 12.07px;
`

export const Pagination = ({
  count, onChange, currentPage, limit, pagesToView,
}) => {
  const pagesCount = Math.ceil(count / limit);
  const formPagi = pagesToView > pagesCount ? pagesCount : pagesToView;
  const center = Math.floor(pagesToView / 2);
  const pagiBetwen = Array.from(Array(formPagi).keys())
    .map(el => el + (currentPage - center <= 0 ? currentPage : currentPage - center))
    .filter(el => el <= pagesCount);
  const leftPages = currentPage > pagesToView ? [1, '...'] : [];
  const rightPages = pagesCount > pagesToView && currentPage + 1 < pagesCount ? ['...', pagesCount] : [];
  const pages = [...leftPages, ...pagiBetwen, ...rightPages];
  const showPrevious = currentPage > 1;
  const showNext = currentPage !== pagesCount;

  return (
    <Wrapper>
      <PaginationButton onClick={showPrevious ? () => onChange(currentPage - 1) : () => {}}><ChevronLeftIcon style={showPrevious ? {} : { color: '#b6b8bf' }} /></PaginationButton>
      <PaginationItems>
        {pages.map(el => (currentPage !== el 
          ? <PaginationItem key={el} onClick={() => onChange(el)}>{el}</PaginationItem> 
          : <ActiveItem key={el}>{el}</ActiveItem>))}
      </PaginationItems>
      <PaginationButton onClick={showNext ? () => onChange(currentPage + 1) : () => {}}><ChevronRightIcon style={showNext ? {} : { color: '#b6b8bf' }} /></PaginationButton>
    </Wrapper>
  ) 
}
