import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Colors } from 'constants/colors'
import { LanguageSwitcher } from '../../common/LanguageSwitcher'

const Wrapper = styled.div`
  margin: 84px 0 0;
  text-align: center;
  font-size: 14px;
  font-family: Roboto, sans-serif;
  color: ${Colors.blueGrey};
`

const SwitcherWrapper = styled.div`
    
  .select.select {
    color: ${Colors.clearBlue};
    font-weight: 500;
    background-color: transparent;
    box-shadow: 0 4px 8px 0 ${Colors.azure};
  }
    
  input + svg {
    fill: ${Colors.clearBlue};
    color: ${Colors.clearBlue};
  }
`

export const LoginHelper = ({
  registration,
}) => (
  <Wrapper>
    <FormattedMessage id={`auth.${registration ? 'register' : 'login'}.with`} />
    <SwitcherWrapper>
      <LanguageSwitcher />
    </SwitcherWrapper>
  </Wrapper>
)

LoginHelper.propTypes = {
  registration: PropTypes.bool.isRequired,
}
