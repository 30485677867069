import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose, withProps } from 'recompose'
import { connect } from 'react-redux'
import { TextButton } from 'common/UiKit/TextButton'
import { Colors } from 'constants/colors'
import { Button } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { PersonalDataForm } from '../../../../common/PersonalDataForm';

import { history } from '../../../../../utils/history'
import { externalFormSubmit } from '../../../../../helpers/externalFormSubmit'
import { editProfile } from '../../../../../redux/modules/auth'

const Save = styled.div`
  position: fixed;
  bottom: 0;
  background-color: ${Colors.white};
  right: 0;
  left: 280px;
  padding: 10px 24px;
  text-align: right;
  z-index: 2;
  
  button {
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    width: 120px;
  }
`

const CloseButton = styled(Button)`
  && {
    margin-right: 8px;
  
    &:hover {
      background-color: transparent;
    }
  }
`

const EditComponent = ({
  initialValues,
  editProfile,
  onSaveButtonClick,
  loading,
}) => {
  const afterSubmit = () => {
    history.block(() => true);
    history.push('/panel/profile');
  }

  useEffect(() => {
    if (
      !initialValues.first_name 
        || !initialValues.last_name
        || !initialValues.date_of_birth
        || !initialValues.medical_insurance
        || initialValues.gender === 'unknown') {
      history.block(() => false);
    }
  }, [])

  return (
  <>
    <PersonalDataForm
      initialValues={initialValues}
      onSubmit={editProfile}
      id='edit_patient_profile'
      afterSubmit={afterSubmit}
      showInsurance
    />
    <Save>
      <CloseButton
        color='secondary'
        variant='text'
        onClick={() => history.push('/panel/profile')}
      >
        <FormattedMessage id='global.cancel' />
      </CloseButton>
      <TextButton
        color='secondary'
        variant='extended'
        onClick={onSaveButtonClick}
        isFetching={loading}
      >
        <FormattedMessage id='global.save' />
      </TextButton>
    </Save>
  </>
  ) 
}

EditComponent.propTypes = {
  initialValues: PropTypes.object.isRequired,
  editProfile: PropTypes.func.isRequired,
  afterSubmit: PropTypes.func.isRequired,
  onSaveButtonClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

const enhance = compose(
  connect(
    state => ({
      initialValues: {
        date_of_birth: state.auth.attributes.date_of_birth,
        first_name: state.auth.attributes.first_name,
        last_name: state.auth.attributes.last_name,
        gender: state.auth.attributes.gender,
        photo: state.auth.attributes.photo,
        language: state.auth.attributes.language,
        medical_insurance: state.auth.attributes.medical_insurance,
      },
      loading: state.loading.editProfile,
    }),
    { editProfile },
  ),
  withProps({
    onSaveButtonClick: () => externalFormSubmit('edit_patient_profile'),
  }),
)

export const Edit = enhance(EditComponent)
