import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'
import styled from 'styled-components'

import { PAYMENT_METHODS } from '../../../../../../../constants/paymentMethods'
import { Method } from './Method'
import { pay } from '../../../../../../../redux/modules/consultations'

export const MethodSelectDialog = ({
  open,
  onClose,
}) => {
  const [selected, select] = useState('card')
  const loading = useSelector(state => state.loading.payment)
  const id = useSelector(state => state.consultations.consultation.id)
  const dispatch = useDispatch()

  const onConfirmClick = () => dispatch(pay(id)).then(() => onClose())

  return (
    <Dialog open={open} onClose={onClose}>
      {loading && (
        <SpinnerWrapper>
          <CircularProgress />
        </SpinnerWrapper>
      )}
      <DialogTitle>
        <FormattedMessage id='payments.select.method' />
      </DialogTitle>
      <DialogContent>
        {
          PAYMENT_METHODS.map(item => (
            <Method
              onClick={() => select(item.value)}
              icon={item.icon}
              key={item.value}
              selected={selected === item.value}
            />
          ))
        }
      </DialogContent>
      <DialogActions>
        <Button size='small' color='primary' onClick={onClose}>
          <FormattedMessage id='global.cancel' />
        </Button>
        <Button size='small' color='primary' onClick={onConfirmClick}>
          <FormattedMessage id='payments.confirm' />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

MethodSelectDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`
