import React, {
  useEffect, useMemo, useRef, useState, 
} from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field, Form } from 'react-final-form';
import styled from 'styled-components'
import { TextButton } from 'common/UiKit/TextButton';
import moment from 'moment'
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import isEqual from 'lodash/isEqual';
import last from 'lodash/last';
import { KeyboardDatePicker } from '@material-ui/pickers'
import { TextField } from '../../../../common/FinalFormFields';
import { addRepeat, generateTimePeriods, transformTime } from '../../../../../helpers/calendar';
import { editEvent } from '../../../../../redux/modules/calendar/list';
import { CheckboxField } from '../../../../common/CheckboxField';

const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  
  & > *:first-child {
    margin-right: 12px;
  }
  
  & > *:last-child {
    margin-left: 12px;
  }
`

const Button = styled(TextButton)`
  && {
    width: 100%;
    font-size: 14px;
    font-weight: 500;
  }
`

const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.55;
  color: #209dff;
`

const Label = styled.div`
  opacity: 0.8;
  font-size: 12px;
  color: #21293e;
  margin-bottom: 12px;
`

const Checkbox = styled(CheckboxField)`
  && {
    height: 24px;
  }
`

const RepeatSettings = styled.div`
  width: 100%;
`

const StyledDateField = styled(Field)`
  width: 100% !important;
`

const RepeatDaysLabel = styled.div`
  opacity: 0.8;
  font-size: 12px;
  color: #21293e;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
`

export const EventDialog = ({ open, onClose, data }) => {
  const periods = useMemo(() => generateTimePeriods(), [])
  const resetForm = useRef(null)
  const dispatch = useDispatch()
  const [errorsState, setErrors] = useState({})

  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })

  useEffect(() => {
    if (resetForm.current) {
      resetForm.current()
    }
  }, [data])

  const normilizeArray = (elms = []) => (
    elms.filter(el => !!el).map(el => el[0])
  )

  const onSubmit = (values) => {
    const fromIndex = periods.findIndex(i => i.from === values.from)
    let toIndex = periods.findIndex(i => i.from === values.to)
    if (toIndex === -1) {
      toIndex = periods.length;
    }
    const days = periods.slice(fromIndex, toIndex).map(i => `${data.day} ${i.from.length < 5 ? `0${i.from}` : i.from}`)

    if (values.repeat !== undefined) {
      addRepeat({
        te_available: get(values, 'te_available[0]', null),
        tc_available: get(values, 'tc_available[0]', null),
        type: values.type,
        day: data.day,
        from: values.from,
        to: values.to, 
        dateRepeatFrom: values.dateRepeatFrom,
        dateRepeatTo: values.dateRepeatTo,
        days: normilizeArray(values.days),
      });
    }

    return dispatch(
      editEvent(
        days,
        null,
        values.type,
        null,
        { te_available: values.te_available || 0, tc_available: values.tc_available || 0 },
      ),
    )
      .then(() => onClose())
  }

  const renderCheckBoxes = () => {
    const days = [1, 2, 3, 4, 5, 6, 7]

    return days.map((el, i) => {
      const textDay = moment().day(el).format('dddd');
      return (
        <Field
          key={textDay}
          name={`days[${i}]`}
          component={Checkbox}
          color='primary'
          type='checkbox'
          value={el}
          label={textDay}
        />
      ) 
    })
  }

  const validate = (values) => {
    const errors = {};
    const repeatMode = get(values, 'repeat[0]', false);

    // if (repeatMode && !values.dateRepeatFrom) {
    //   errors.dateRepeatFrom = 'Required for repeat'
    // }
    // if (repeatMode && !values.dateRepeatTo) {
    //   errors.dateRepeatTo = 'Required for repeat'
    // }

    if (!get(values, 'te_available[0]', false) && !get(values, 'tc_available[0]', false)) {
      errors.tc_available = f('schedule.oneoftyperequired');
    }

    if (repeatMode) {
      const days = normilizeArray(values.days);
      if (!Array.isArray(days) || days.filter(el => !!el).length === 0) {
        errors.daysError = f('schedule.onedayrequired');
      }
    }

    if (repeatMode && values.dateRepeatTo < values.dateRepeatFrom) {
      errors.dateRepeatTo = 'Must be bigger then from'
    }

    if (!isEqual(errors, errorsState)) {
      setErrors(errors);
    }

    return errors;
  }

  const getLastTime = periods => moment(last(periods).from, 'HH:mm').add(10, 'minutes').format('HH:mm')


  return (
    <Dialog open={open} onClose={onClose} maxWidth='xs'>
      <DialogTitle>
        <Title>
          <FormattedMessage id='schedule.event.title' />
          {data !== null
            && (
              moment(data.day).local().format(' DD MMMM YYYY')
            )
          }
        </Title>
      </DialogTitle>
      <DialogContent>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          initialValues={data ? {
            type: 'Available',
            from: transformTime(data.periodFrom),
            to: transformTime((periods[periods
              .findIndex(i => i.from === transformTime(data.periodFrom)) + 1]
              || { from: getLastTime(periods) }).from),
          } : { type: 'Available' }}
          destroyOnUnregister
          render={({
            handleSubmit, values, submitting, form,
          }) => {
            resetForm.current = form.reset

            const toOptions = periods.slice(values.from
              ? periods.findIndex(i => i.from === values.from) + 1
              : 0)

            const showRepeatSettings = get(values, 'repeat[0]', false);
            const formState = form.getState();

            return (
              <form onSubmit={handleSubmit}>
                <Field
                  name='te_available'
                  component={Checkbox}
                  color='primary'
                  type='checkbox'
                  value='1'
                  label={(<FormattedMessage id='schedule.time.off' />)}
                />
                <br />
                <Field
                  name='tc_available'
                  component={Checkbox}
                  color='primary'
                  type='checkbox'
                  value='1'
                  label={(<FormattedMessage id='schedule.teleconsultation' />)}
                />
                <Label>
                  <FormattedMessage id='schedule.event.time' />
                </Label>
                <FieldWrapper>
                  <Field
                    name='from'
                    component={TextField}
                    label=' '
                    options={periods
                      .map(item => ({ label: item.from, value: item.from }))}
                  />
                  {' - '}
                  <Field
                    name='to'
                    component={TextField}
                    label=' '
                    options={[...(toOptions && toOptions.length
                      ? toOptions : [{ from: getLastTime(periods) }])]
                      .map(item => ({ label: item.from, value: item.from }))}
                  />
                </FieldWrapper>

                <Field
                  name='repeat'
                  component={Checkbox}
                  color='primary'
                  type='checkbox'
                  value='1'
                  label={(<FormattedMessage id='schedule.event.repeat' />)}
                />

                {
                  showRepeatSettings 
                    ? (
                      <RepeatSettings>
                        <RepeatDaysLabel>
                          <FormattedMessage id='calendar.editrepeats' />
                          <Tooltip title={f('calendar.repeatinfo')} placement='bottom-end'>
                            <HelpIcon size='small' />
                          </Tooltip>
                        </RepeatDaysLabel>
                        <div style={errorsState.daysError && formState.submitFailed ? { border: '1px solid red' } : {}}>
                          {renderCheckBoxes()}
                        </div>
                        {formState.submitFailed ? (
                          <div style={{ color: 'red' }}>
                            {errorsState.daysError}
                          </div>
                        ) : ''}
                        <StyledDateField
                          name='dateRepeatFrom'
                          label={f('calendar.daterepeatfrom')}
                          minDate={moment()}
                          margin='normal'
                        >
                          {
                          props => (
                            <KeyboardDatePicker
                              {...props}
                              name={props.input.name}
                              value={get(props, 'input.value', null)}
                              onChange={props.input.onChange}
                              inputValue={props.meta.dirty ? null : f('global.dd/mm/yyyy')}
                              {...(props.meta.dirty ? {} : { helperText: '', error: false })}
                              autoOk
                              format='DD/MM/YYYY'
                              disableToolbar
                              variant='inline'
                              disablePast
                            />
                          )
                        }
                        </StyledDateField>
                        <StyledDateField
                          name='dateRepeatTo'
                          label={f('calendar.daterepeatto')}
                          minDate={moment()}
                          margin='normal'
                        >
                          {
                          props => (
                            <KeyboardDatePicker 
                              {...props}
                              name={props.input.name}
                              value={get(props, 'input.value', null)}
                              inputValue={props.meta.dirty ? null : f('global.dd/mm/yyyy')}
                              {...(props.meta.dirty ? {} : { helperText: '', error: false })}
                              autoOk
                              onChange={props.input.onChange}
                              format='DD/MM/YYYY'
                              disableToolbar
                              variant='inline'
                              disablePast
                            />
                          )
                        }
                        </StyledDateField>
                      </RepeatSettings>
                    )
                    : ''
                }

                <Button color='primary' type='submit' disabled={submitting} isFetching={submitting}>
                  <FormattedMessage id='schedule.event.add' />
                </Button>
              </form>
            )
          }}
        />
      </DialogContent>
    </Dialog>
  )
}
