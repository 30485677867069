import React from 'react'
import { Field } from 'react-final-form'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Cancel from '@material-ui/icons/Cancel'
import IconButton from '@material-ui/core/IconButton'
// import { TextField } from '../TextField'
// import { AutocompleteField } from '../AutocompleteField'
import { Colors } from 'constants/colors'
// import { MEDICINE_TYPES, MEDICINE_UNITS } from '../../constants/medicineTypes'
import { FormattedMessage, useIntl } from 'react-intl'
// import { TextField } from '../TextField'
import { MultilineTextField } from '../MultilineTextField'

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
`

const Fields = styled.div`
  flex-grow: 1;
`

const MultilineField = styled(MultilineTextField)`
  & .root {
    height: 112px;
  }
`

// const DropdownArea = styled.div`
//   display: flex;
//   justify-content: space-between;
// `

const Label = styled.div`
  display: flex;
  align-items: center;
  height: 54px;
  width: 172px;
  padding-top: 7px;
  min-width: 172px;
  opacity: 0.87;
  font-weight: bold;
  font-size: 16px;
  color: ${Colors.dark};
`

const IconButtonStyled = styled(IconButton)`
  && {
    margin-left: 15px;
  }
`

// const DropdownField = styled(Field)`
//   && {
//     width: 232px;
//   }
// `

// const TIMES = [
//   { label: '1 time per day', value: '1' },
//   { label: '2 times per day', value: '2' },
//   { label: '3 times per day', value: '3' },
//   { label: '4 times per day', value: '4' },
// ]

// const DURATION = [
//   { label: '1 week', value: '1' },
//   { label: '2 weeks', value: '2' },
//   { label: '3 weeks', value: '3' },
//   { label: '4 weeks', value: '4' },
//   { label: '5 weeks', value: '5' },
//   { label: '6 weeks', value: '6' },
//   { label: '7 weeks', value: '7' },
//   { label: '8 weeks', value: '8' },
// ]

// const validateMedicine = (value) => {
//   if (!value) return 'Please enter a medicine'
//   if (typeof value === 'string') return 'Please select medicine from list'
// }

export const MedicineForm = ({
  id,
  last,
  onDeleteButtonClick,
  //options,
  //onChange,
  //medicineType,
}) => {
  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })
  return (
    <Wrapper>
      <Label>
        <FormattedMessage id='consultations.medicine' />
        &nbsp;
        {id + 1}
        {
          last
          && (
            <IconButtonStyled color='primary' onClick={onDeleteButtonClick}>
              <Cancel />
            </IconButtonStyled>
          )
        }
      </Label>
      <Fields>
        <Field
          name={`medicationPrescribed.${id}.medicine`}
          component={MultilineField}
          label={`*${f('consultations.medicine.name')}`}
          validate={value => (value ? undefined : <FormattedMessage id='errors.required' />)}
          multiline
          rows={3}
        />
        {/*<Field*/}
        {/*  component={AutocompleteField}*/}
        {/*  options={options}*/}
        {/*  name={`medicationPrescribed.${id}.medicine`}*/}
        {/*  label={`*${f('consultations.medicine.name')}`}*/}
        {/*  validate={validateMedicine}*/}
        {/*  onChange={onChange}*/}
        {/*/>*/}
        {/* <DropdownArea>
          <DropdownField
            component={TextField}
            name={`medicationPrescribed.${id}.type`}
            label={`*${f('consultations.type')}`}
            options={MEDICINE_TYPES}
            validate={value => !value && 'Please enter type'}
          />
          <DropdownField
            component={TextField}
            name={`medicationPrescribed.${id}.dosage`}
            label={`*${f('consultations.dosage')}`}
            options={MEDICINE_UNITS[medicineType]}
            disabled={!medicineType}
            validate={value => !value && 'Please enter dosage'}
          />
        </DropdownArea> */}
        {/*<DropdownArea>*/}
        {/*  <DropdownField*/}
        {/*    component={TextField}*/}
        {/*    name={`medicationPrescribed.${id}.iteration`}*/}
        {/*    label={`*${f('consultations.times.taken')}`}*/}
        {/*    options={TIMES}*/}
        {/*    validate={value => !value && 'Please enter times'}*/}
        {/*  />*/}
        {/*  <DropdownField*/}
        {/*    component={TextField}*/}
        {/*    name={`medicationPrescribed.${id}.duration`}*/}
        {/*    label={`*${f('consultations.duration')}`}*/}
        {/*    options={DURATION}*/}
        {/*    validate={value => !value && 'Please enter duration'}*/}
        {/*  />*/}
        {/*</DropdownArea>*/}
        {/*{medicineType === 'tablet' && (*/}
        {/*  <DropdownArea>*/}
        {/*    <DropdownField*/}
        {/*      component={TextField}*/}
        {/*      name={`medicationPrescribed.${id}.quantity`}*/}
        {/*      label={`*${f('consultations.quantity')}`}*/}
        {/*      options={MEDICINE_UNITS.pill}*/}
        {/*      validate={value => !value && 'Please enter quantity'}*/}
        {/*    />*/}
        {/*  </DropdownArea>*/}
        {/*)}*/}
      </Fields>
    </Wrapper>
  )
}

MedicineForm.defaultProps = {
  last: false,
  onDeleteButtonClick: () => null,
  //options: [],
  //medicineType: '',
}

MedicineForm.propTypes = {
  id: PropTypes.number.isRequired,
  last: PropTypes.bool,
  onDeleteButtonClick: PropTypes.func,
  //onChange: PropTypes.func.isRequired,
  // options: PropTypes.arrayOf(PropTypes.shape({
  //   label: PropTypes.string,
  //   value: PropTypes.string,
  // })),
  //medicineType: PropTypes.string,
}
