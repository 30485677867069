import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Button, Paper } from '@material-ui/core'
import { Page } from '../../../../common/Page'
import { getDoctorTeams } from '../../../../../helpers/Doctor/TeamHelper'
import { history } from '../../../../../utils/history'
import { ItemComponent } from './Item'

const CreateBlockStyle = {
  padding: '25px',
  width: '100%',
  boxShadow: '0 4px 8px 0 rgba(6, 173, 255, 0.1)',
}

const CreateBtnStyle = {
  marginTop: '30px',
  padding: '10px 26px',
  background: '#fff',
  border: '1px solid #209dff',
  color: '#209dff',
  boxShadow: 'none',
  borderRadius: '35px',
  width: '200px',
  cursor: 'pointer',
}

export const ROSOPageList = ({ location }) => {
  const onBackIconClick = () => history.push('/panel/community')

  const [teams, setTeams] = useState([]);
  const refreshMyTeams = () => getDoctorTeams().then(result => setTeams(result));
  useEffect(() => {
    refreshMyTeams();
  }, [location]);

  return (
    <Page
      label='roso.community.label'
      onBackIconClick={onBackIconClick}
      fullWidth
    >
      <Wrapper>
        <Content>
          <Paper style={CreateBlockStyle}>
            <CreateBlockTitle>
              {teams && teams.length ? <FormattedMessage id='roso.create.titleCreated' /> : <FormattedMessage id='roso.create.title' />}
            </CreateBlockTitle>
            <CreateBlockDescription>
              <FormattedMessage id='roso.create.description' />
            </CreateBlockDescription>
            <Button onClick={() => history.push('/panel/community/roso-add')} variant='contained' color='primary' style={CreateBtnStyle}>
              <FormattedMessage id='roso.create.btn' />
            </Button>
          </Paper>
        </Content>

        {teams.map(team => (
          <ItemComponent key={team.id} refreshMyTeams={refreshMyTeams} team={team} />
        ))}
      </Wrapper>
    </Page>
  )
}

//#region Styled components
const CreateBlockTitle = styled.div`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  color: #21293e;
  margin-bottom: 30px;
`

const CreateBlockDescription = styled.div`
  font-family: Roboto;
  font-size: 16px;
  color: #959fb8;
  font-weight: 100;
`
const Wrapper = styled.div`
  max-width: 720px;
  min-width: 720px;
  margin: 24px auto;
`

const Content = styled.div`
  display: flex;
  margin-top: 16px;
`

//#endregion
