import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import DoneIcon from '@material-ui/icons/Done'
import { Colors, calendarColors } from 'constants/colors'
import { useSelector } from 'react-redux';

const getTextByTypeUrgent = (isRealPatient) => {
  if (isRealPatient) {
    return <FormattedMessage id='schedule.urgentconsultation' /> 
  } 
  return <FormattedMessage id='schedule.urgentteleexpertise' />
}

const getTextByType = (isRealPatient) => {
  if (isRealPatient) {
    return <FormattedMessage id='schedule.teleconsultation' /> 
  } 
  return <FormattedMessage id='schedule.teleexpertize' />
}

export const FreeTime = ({ isSelected, isReallyUrgent }) => {
  const isRealPatient = useSelector(state => state.auth.type === 'Patient');
  return (
    <Wrapper isReallyUrgent={isReallyUrgent}>
      {isReallyUrgent ? getTextByTypeUrgent(isRealPatient) : getTextByType(isRealPatient)}
      {
      isSelected && (
        <SelectedOverlay isReallyUrgent={isReallyUrgent}>
          <DoneIcon />
        </SelectedOverlay>
      )
    }
    </Wrapper>
  ) 
}

FreeTime.propTypes = {
  isSelected: PropTypes.bool.isRequired,
}

//#region Styled components
const Wrapper = styled.div`
  background-color: ${({ isReallyUrgent }) => (isReallyUrgent ? 'rgba(254, 236, 194, 0.5)' : calendarColors.completedBg)};
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-left: 3px solid ${calendarColors.completedBorder};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-left: 12px;
  color: ${({ isReallyUrgent }) => (isReallyUrgent ? '#e0a400' : calendarColors.freeText)};
  font-weight: 500;
  text-transform: capitalize;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  font-size: 14px;
  position: relative;
  word-spacing: 30px;
`

const SelectedOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: ${({ isReallyUrgent }) => (isReallyUrgent ? '#fef197' : calendarColors.selectedBg)};
  opacity: .5;
  display: flex;
  align-items: center;
  justify-content: center;
  
  svg {
    fill: ${Colors.white};
  }
`
//#endregion
