import React from 'react'
import PropTypes from 'prop-types'
import { compose, mapProps } from 'recompose'
import { connect } from 'react-redux'
import { SignatureForm } from 'common/UiKit/SignatureForm'
import { EditPage } from 'common/UiKit/EditPage'
import styled from 'styled-components'

import { history } from '../../../../../utils/history'
import { PROFILE_FILL_STEPS } from '../../../../../constants/ui'
import { createSignature } from '../../../../../redux/modules/signature'

const Wrapper = styled.div`
  div {
    box-shadow: none;
  }
`

const EditSignatureComponent = ({
  signatures,
  loading,
  createSignature,
}) => {
  if (true) {
    history.push('/panel/profile/signature')
    return null
  }

  return (
    <EditPage
      label='profile.signature'
      onBackIconClick={() => history.push('/panel/profile')}
      buttonText='global.save'
      onButtonClick={() => history.push('/panel/profile')}
      loading={loading}
    >
      <Wrapper>
        <SignatureForm
          id={PROFILE_FILL_STEPS[4]}
          createSignature={createSignature}
          signatures={signatures}
          acceptedExtensions='.jpg,.png,.docx,.doc,.xls,.xlsx'
          onSubmit={() => {}}
        />
      </Wrapper>
    </EditPage>
  )
}

EditSignatureComponent.propTypes = {
  signatures: PropTypes.arrayOf(PropTypes.object).isRequired,
  createSignature: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

const enhance = compose(
  connect(
    state => ({
      signatures: [state.signature],
      loading: state.loading.diplomas,
    }),
    { createSignature },
  ),
  mapProps(props => ({
    ...props,
    onDrop: values => props.createDiplomas({ file: values.diplomas }),
    signatures: props.signatures.map(item => ({
      id: item.id,
      url: item.url,
      extension: item.extension,
    })),
  })),
)

export const EditSignature = enhance(EditSignatureComponent)
