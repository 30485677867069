import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import idx from 'idx'
import { connect } from 'react-redux'
import { compose, withProps } from 'recompose'
import { EditPage } from 'common/UiKit/EditPage'
import { Language as LanguageForm } from '../../../common/Settings'
import { externalFormSubmit } from '../../../../helpers/externalFormSubmit'
import { history } from '../../../../utils/history'
import { changeLanguageSettings } from '../../../../redux/modules/settings'

const Wrapper = styled.div`
  margin-top: 24px;
`

const LanguageComponent = ({
  onSubmit,
  onButtonClick,
  onBackIconClick,
  initialValues,
}) => (
  <EditPage
    label='profile.language'
    buttonText='global.save.language'
    onBackIconClick={onBackIconClick}
    onButtonClick={onButtonClick}
    background
  >
    <Wrapper>
      <LanguageForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        afterSubmit={onBackIconClick}
      />
    </Wrapper>
  </EditPage>
)

LanguageComponent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  onBackIconClick: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
}

const enhance = compose(
  connect(
    state => ({
      initialValues: { language: idx(state, _ => _.settings.language) },
    }),
    { changeLanguageSettings },
  ),
  withProps(props => ({
    onSubmit: async values => props.changeLanguageSettings(values.language),
    onButtonClick: () => externalFormSubmit('language'),
    onBackIconClick: () => history.push('/panel/settings'),
  })),
)

export const Language = enhance(LanguageComponent)
