import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import Videocam from '@material-ui/icons/Videocam'
import VideocamOff from '@material-ui/icons/VideocamOff'
import Mic from '@material-ui/icons/Mic'
import MicOff from '@material-ui/icons/MicOff'
import UnfoldLess from '@material-ui/icons/UnfoldLess'
import UnfoldMore from '@material-ui/icons/UnfoldMore'
import Chat from '@material-ui/icons/Chat'
import CallEnd from '@material-ui/icons/CallEnd'
import { Colors } from '../../../constants/colors'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 24px;
  font-family: Roboto, sans-serif;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  opacity: 0;
  position: absolute;
  z-index: 3;

  button {
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
  }

  ${({ fullSize }) => !fullSize && `
    padding: 8px;

    button {
        width: 36px;
        height: 36px;
        padding: 9px;
    }

    svg {
      font-size: 18px;
    }
  `}

  &:hover {
    opacity: 1;
  }
`

const Bar = styled.div`
  display: flex;
  align-items: center;
`

const Info = styled.div`
  flex-grow: 1;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  opacity: ${({ fullSize }) => !fullSize && '0'}
`

const Name = styled.h1`
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.33;
`

const Status = styled.div`
  font-size: 14px;
  line-height: 1.71;
`

const PrimaryButton = styled(IconButton)`
  && {
    background-color: ${Colors.clearBlue};
  }
`

const RedButton = styled(IconButton)`
  && {
    background-color: ${Colors.watermelon};
  }
`

const ButtonsArea = styled.div`
  display: flex;
  justify-content: center;

  && > * {
    margin-right: ${({ fullSize }) => (fullSize ? '24px' : '16px')};
  }

  & > :last-child {
    margin-left: ${({ fullSize }) => (fullSize ? '48px' : '16px')};
    margin-right: 0;
  }
`

export const ControlPanel = ({
  firstName,
  lastName,
  duration,
  onResizeButtonClick,
  onVideocamButtonClick,
  onMicrophoneButtonClick,
  onChatButtonClick,
  onEndButtonClick,
  fullSize,
  publishAudio,
  publishVideo,
  matchConsultationSession,
}) => (
  <Wrapper fullSize={fullSize}>
    <Bar>
      <Info fullSize={fullSize}>
        <Name>
          {firstName}
          &nbsp;
          {lastName}
        </Name>
        <Status>
          {duration.format('m:ss')}
        </Status>
      </Info>
      <PrimaryButton
        onClick={onResizeButtonClick}
        disabled={!matchConsultationSession}
      >
        {fullSize ? <UnfoldLess /> : <UnfoldMore />}
      </PrimaryButton>
    </Bar>
    <ButtonsArea fullSize={fullSize}>
      <IconButton onClick={onVideocamButtonClick}>
        {publishVideo ? <Videocam /> : <VideocamOff />}
      </IconButton>
      <IconButton onClick={onMicrophoneButtonClick}>
        {publishAudio ? <Mic /> : <MicOff />}
      </IconButton>
      {
        fullSize && (
          <PrimaryButton onClick={onChatButtonClick}>
            <Chat />
          </PrimaryButton>
        )
      }
      <RedButton onClick={onEndButtonClick}>
        <CallEnd />
      </RedButton>
    </ButtonsArea>
  </Wrapper>
)

ControlPanel.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  duration: PropTypes.object.isRequired,
  onResizeButtonClick: PropTypes.func.isRequired,
  onVideocamButtonClick: PropTypes.func.isRequired,
  onMicrophoneButtonClick: PropTypes.func.isRequired,
  onChatButtonClick: PropTypes.func.isRequired,
  onEndButtonClick: PropTypes.func.isRequired,
  fullSize: PropTypes.bool.isRequired,
  publishAudio: PropTypes.bool.isRequired,
  publishVideo: PropTypes.bool.isRequired,
  matchConsultationSession: PropTypes.bool.isRequired,
}
