import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import { Input } from 'common/UiKit/Input'
import { Paper } from 'common/UiKit/Paper'
import { DatePicker } from 'common/UiKit/DatePicker'
import get from 'lodash/get'
import { Colors } from '../../../../../../constants/colors'

const AddLink = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: ${props => (props.disabled ? Colors.blueGrey : Colors.clearBlue)};
  letter-spacing: 0.5px;
  cursor: pointer;

  ${props => !props.disabled && '&:hover { text-decoration: underline }'}
`

const Block = styled.div`
  margin-bottom: 16px;
`

const DateInput = styled(DatePicker)`
  width: 100%;
`

export const EducationForm = ({
  input,
  meta,
  ...rest
}) => {
  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })

  return (
    <Paper {...rest}>
      {
        input.value.map((i, idx) => (
          <Block>
            <Input
              value={input.value[idx].name}
              label={`*${f('profile.degree.field')}`}
              helperText={(meta.dirty || meta.submitFailed) ? get(meta, `error.${idx}`, '') : ''}
              error={(meta.dirty || meta.submitFailed) && !!get(meta, `error.${idx}`, '')}
              onChange={e => input.onChange(input.value
                .map((val, index) => (index === idx ? { ...val, name: e.target.value } : val)))}
            />
            <DateInput
              views={['year']}
              format='YYYY'
              openToYearSelection
              InputLabelProps={{ shrink: true }}
              value={input.value[idx].receivedAt}
              label={`*${f('profile.formation.date')}`}
              //helperText={meta.touched && meta.error}
              onChange={e => input.onChange(input.value
                .map((val, index) => {
                  console.log(e);
                  return (index === idx
                    ? { ...val, receivedAt: e.format('YYYY-MM-DD') }
                    : val)
                }))}
            />
          </Block>
        ))
      }
      <AddLink onClick={() => input.onChange([...input.value, { name: '', receivedAt: '2000-01-01' }])}>
        <FormattedMessage id='profile.add.education' />
      </AddLink>
    </Paper>
  )
}


EducationForm.defaultProps = {
  onAdd: () => null,
  id: 0,
  last: false,
  isAddActive: false,
}

EducationForm.propTypes = {
  onAdd: PropTypes.func,
  id: PropTypes.number,
  last: PropTypes.bool,
  isAddActive: PropTypes.bool,
  Field: PropTypes.func.isRequired,
}
