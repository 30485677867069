import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import idx from 'idx'
import { compose, lifecycle } from 'recompose'
import { FormattedMessage, useIntl } from 'react-intl'

import { logOut } from '../../../../redux/modules/auth'
import profileLogo from '../../../../images/panel/profile/profile-logo.svg'
import { FillProfileStub } from '../FillProfileStub'
import { profileChangePaths } from '../../../../constants/profileChangePaths'
import { profileAddPaths } from '../../../../constants/profileAddPaths'
import { history } from '../../../../utils/history'
import { fetchDoctorReviews } from '../../../../redux/modules/reviews'
import { LanguageSwitcher } from '../../../common/LanguageSwitcher'
import { NotificationsButton } from '../../../common/Page/Notifications'
import { EmploymentTypeField } from './EmploymentTypeField'
import { PanelBar } from '../../../common/PanelBar'
import { ProfileInfo } from './ProfileInfo'

const Content = styled.div`
  width: 720px;
  margin: 0 auto;
  ${props => (props.filled ? 'padding-top: 24px;' : '')}
`

const PageWrapper = styled.div`
  ${props => (props.filled ? `background-color: ${Colors.background};` : '')}
  min-height: 100%;
  position: relative;
  font-family: Roboto, sans-serif;
`

const Warning = styled.div`
  padding: 16px 24px;
  font-size: 14px;
  font-weight: 500;
  color: ${Colors.yellowOchre};
  background-color: ${Colors.offWhite};
  border-radius: 4px;
`

const ProfileComponent = ({
  filled,
  logOut,
  id,
  attributes,
  workPlace,
  education,
  diplomas,
  verified,
  reviews,
  signatures,
}) => {
  const { formatMessage } = useIntl()

  return (
    <PageWrapper filled={filled}>
      <PanelBar
        label={formatMessage({ id: 'profile.title' })}
        onLogout={logOut}
        id={id}
        notification={<NotificationsButton />}
        languageSwitcher={<LanguageSwitcher />}
        onBackIconClick={() => history.goBack()}
      />
      {!verified && filled
      && (
        <Warning>
          <FormattedMessage id='profile.warning' />
        </Warning>)}
      <Content filled={filled}>
        {
          filled
            ? (
              <>
                <ProfileInfo
                  attributes={attributes}
                  workPlace={workPlace}
                  education={education}
                  diplomas={diplomas}
                  verified={verified}
                  addButtonPaths={profileAddPaths}
                  changeButtonPaths={profileChangePaths}
                  onDiplomasClick={() => history.push('/panel/profile/diplomas')}
                  reviewsCount={reviews.length}
                  reviewsLink='/panel/profile/reviews'
                  isPatient
                  signatures={signatures}
                  methodField={<EmploymentTypeField disabled />}
                />
              </>
            )
            : <FillProfileStub image={profileLogo} heading={formatMessage({ id: 'profile.title' })} />
        }
      </Content>
    </PageWrapper>
  )
}

ProfileComponent.defaultProps = {
  reviews: [],
}

ProfileComponent.propTypes = {
  id: PropTypes.string.isRequired,
  filled: PropTypes.bool.isRequired,
  logOut: PropTypes.func.isRequired,
  attributes: PropTypes.object.isRequired,
  workPlace: PropTypes.object.isRequired,
  education: PropTypes.arrayOf(PropTypes.object).isRequired,
  diplomas: PropTypes.arrayOf(PropTypes.object).isRequired,
  verified: PropTypes.bool.isRequired,
  reviews: PropTypes.array,
  signatures: PropTypes.array.isRequired,
}

const enhance = compose(
  connect(
    state => ({
      id: state.auth.id,
      attributes: state.auth.attributes,
      workPlace: state.auth.relationships.workPlace.data.length
        && [...state.auth.relationships.workPlace.data].pop().attributes,
      education: state.auth.relationships.education.data,
      diplomas: idx(state, _ => _.auth.relationships.diploma.data) || [],
      filled: !!state.auth.attributes.credentials.rpps,
      reviews: state.reviews[state.auth.id],
      signatures: [state.signature],

      // TODO change this
      //verified: !!state.auth.attributes.verified,
      verified: true,
    }),
    { logOut, fetchDoctorReviews },
  ),
  lifecycle({
    componentDidMount() {
      if (!this.props.reviews) {
        this.props.fetchDoctorReviews(this.props.id)
      }
    },
  }),
)

export const Profile = enhance(ProfileComponent)
