import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'
import idx from 'idx'
import { compose, withProps, withState } from 'recompose'
import { connect } from 'react-redux'
import { Colors } from 'constants/colors'

import { Card } from './Card'
import { EmptyScreen } from './EmptyScreen'
import { fetchConsultation, deleteSessionData } from '../../../../../../redux/modules/consultations'
import { fetchDoctorReviews, sendReview } from '../../../../../../redux/modules/reviews'
import { fetchDoctor } from '../../../../../../redux/modules/doctors'
import { history } from '../../../../../../utils/history'
import { RateModal } from './RateModal'
import { Preloader } from '../../../../../common/Preloader';

const Month = styled.div`
  margin: 6px 0 8px;
  font-size: 14px;
  font-weight: 500;
  color: ${Colors.dark};
  opacity: 0.87;
  line-height: 1.29;
`
const Loader = styled(Preloader)`
  width: 100%;
  height: calc(100vh - 170px);
`

// Checks date and adds month component if different months
const checkDate = (item, index, arr) => {
  const date = idx(item, _ => _.attributes.proposed_date)

  if (
    index === 0
    || !moment(date).isSame(idx(arr[index - 1], _ => _.attributes.proposed_date), 'month')
  ) {
    return <Month>{moment(date).format('MMMM')}</Month>
  }

  return null
}

export const ConsultationsListComponent = ({
  consultations,
  onConsultationClick,
  reviews,
  reviewsFetching,
  fetchDoctorReviews,
  onRepeatClick,
  rateModal,
  setRateModal,
  sendReview,
  loading,
}) => (
  <>
    {
      (!loading && consultations.length)
        ? consultations.map((item, index, arr) => {
          const isCompleted = idx(item, _ => _.attributes.status) === 'completed'
          const doctorId = idx(item, _ => _.relationships.doctor.data.id)
          const completedProps = isCompleted ? {
            reviews: reviews[doctorId],
            reviewsFetching: reviewsFetching.includes(doctorId),
            fetchDoctorReviews: () => fetchDoctorReviews(doctorId),
            onRepeatClick: () => onRepeatClick(doctorId),
            onRateClick: () => setRateModal({ doctor: doctorId, consultation: item.id }),
          } : {}

          return (
            <div key={item.id}>
              {checkDate(item, index, arr)}
              <Card
                firstName={idx(item, _ => _.relationships.doctor.data.attributes.first_name)}
                lastName={idx(item, _ => _.relationships.doctor.data.attributes.last_name)}
                photo={idx(item, _ => _.relationships.doctor.data.attributes.photo)}
                specialization={idx(
                  item,
                  _ => _.relationships.doctor.data.attributes.specialization,
                )}
                language={idx(item, _ => _.relationships.doctor.data.attributes.language)}
                date={idx(item, _ => _.attributes.proposed_date)}
                urgent={idx(item, _ => _.attributes.type) === 'urgent'}
                created={idx(item, _ => _.attributes.status) === 'created'}
                onClick={onConsultationClick(idx(item, _ => _.id))}
                isCompleted={isCompleted}
                id={item.id}
                isConcilium={item.attributes.consiliumStatus}
                {...completedProps}
              />
            </div>
          )
        })
        : (
          <>
            {
              loading ? <Loader /> : <EmptyScreen />
            }
          </>
        )
    }
    {
      !!rateModal.consultation && (
        <RateModal
          onClose={() => setRateModal({ doctor: null, consultation: null })}
          onSubmit={values => sendReview(rateModal.doctor, rateModal.consultation, values)
            .then(() => setRateModal({ doctor: null, consultation: null }))}
        />
      )
    }
  </>
)

ConsultationsListComponent.defaultProps = {
  consultations: [],
}

ConsultationsListComponent.propTypes = {
  consultations: PropTypes.arrayOf(PropTypes.object),
  onConsultationClick: PropTypes.func.isRequired,
  reviews: PropTypes.object.isRequired,
  reviewsFetching: PropTypes.array.isRequired,
  fetchDoctorReviews: PropTypes.func.isRequired,
  onRepeatClick: PropTypes.func.isRequired,
  rateModal: PropTypes.object.isRequired,
  setRateModal: PropTypes.func.isRequired,
  sendReview: PropTypes.func.isRequired,
}

const enhance = compose(
  connect(
    state => ({
      reviews: state.reviews,
      reviewsFetching: state.loading.reviews,
      loading: state.loading.consultationsList,
    }),
    {
      fetchConsultation,
      deleteSessionData,
      fetchDoctor,
      fetchDoctorReviews,
      sendReview,
    },
  ),
  withProps(props => ({
    onConsultationClick: id => () => props.fetchConsultation(id)
      .then(() => props.afterConsultationClick()),
    onRepeatClick: id => props.fetchDoctor(id)
      .then(() => history.push('/panel/doctors/appointment/create')),
  })),
  withState('rateModal', 'setRateModal', { doctor: null, consultation: null }),
)

export const ConsultationsList = enhance(ConsultationsListComponent)
