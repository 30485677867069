import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Field } from 'react-final-form'
import { MultilineTextField } from 'common/UiKit/MultilineTextField'
import { Paper } from 'common/UiKit/Paper'

const Wrapper = styled(Paper)`
  margin-bottom: 16px;
  padding: 24px;
`

const MultilineField = styled(MultilineTextField)`
  & .root {
    height: 112px;
  }
`

export const Text = ({
  title,
  //placeholder,
  //id,
}) => (
  <Wrapper headingTransparent={title}>
    <Field
      component={MultilineField}
      name='symptomExplanation'
      //      name={`questions.${id}`}
      maxLength={200}
      //placeholder={placeholder}
      multiline
      rowsMax='4'
    />
  </Wrapper>
)

Text.propTypes = {
  title: PropTypes.string.isRequired,
}
