import React from 'react'
import styled from 'styled-components'
import idx from 'idx'
import { FormattedMessage, useIntl } from 'react-intl'
import { Paper } from 'common/UiKit/Paper'
import { useSelector } from 'react-redux'
import get from 'lodash/get';

const PaperStyled = styled(Paper)`
  padding: 10px 24px;
`

const Line = styled.div`
  padding: 8.5px 0;
  display: flex;
  opacity: 0.87;
`

const LanguagesLine = styled(Line)`
  text-transform: capitalize;
`

const Name = styled.span`
  font-weight: bold;
  width: 172px;
  margin-right: 6px;
`

const normalizeLanguage = languages => languages.map((l) => {
  if (l === 'ru') return 'Russian'
  if (l === 'de') return 'German'
  if (l === 'fr') return 'Français'
  if (l === 'es') return 'Español'
  if (l === 'ar') return 'Arabe'
  return 'English'
})

export const AboutDoctor = ({
  specialization,
  country,
  addition_email,
  language,
  work_place,
  changeButtonPath,
  requisites,
  credentials,
  price,
}) => {
  const { formatMessage } = useIntl()
  const employmentType = useSelector(state => state.auth.attributes.type_employment)
  const isPrivate = employmentType === 'merge' || employmentType === 'private'
  const isSalary = employmentType === 'merge' || employmentType === 'hospital'
  return (
    <PaperStyled
      heading={formatMessage({ id: 'profile.about' })}
      changeButtonPath={changeButtonPath}
    >
      <Line>
        <Name>
          <FormattedMessage id='profile.specialization' />
        </Name>
        {specialization}
      </Line>
      <LanguagesLine>
        <Name>
          <FormattedMessage id='profile.language' />
        </Name>
        {normalizeLanguage(language).join(', ')}
      </LanguagesLine>
      {country && (
      <Line>
        <Name>
          <FormattedMessage id='profile.country' />
        </Name>
        {`${country}`}
      </Line>
      )}
      {addition_email && addition_email[0] && (
      <Line>
        <Name>
          <FormattedMessage id='profile.addition_email' />
        </Name>
        {`${addition_email[0]}`}
      </Line>
      )
      }

      <Line>
        <Name>
          <FormattedMessage id='profile.workplace' />
        </Name>
        {idx(work_place, _ => _.name)}


        ,&nbsp;
        {idx(work_place, _ => _.street)}


        ,&nbsp;
        {idx(work_place, _ => _.city)}


        ,&nbsp;
        {idx(work_place, _ => _.country)}


        ,&nbsp;
        {idx(work_place, _ => _.postcode)}
      </Line>
      {
        credentials && (
          <>
            <Line>
              <Name>
                <FormattedMessage id='profile.rpps' />
              </Name>
              {credentials.rpps}
            </Line>
            <Line>
              <Name>
                <FormattedMessage id='profile.number' />
              </Name>
              {credentials.orderNumber}
            </Line>
          </>
        )
      }
      {
        requisites && (
          <>
            {
              (requisites.private.bic && isPrivate) && (
                <Line>
                  <Name>
                    <FormattedMessage id='profile.bic' />
                    {' ('}
                    <FormattedMessage id='profile.liberal' />
                    )
                  </Name>
                  {requisites.private.bic}
                </Line>
              )
            }
            {
              (requisites.private.iban && isPrivate) && (
                <Line>
                  <Name>
                    <FormattedMessage id='profile.iban' />
                    {' ('}
                    <FormattedMessage id='profile.liberal' />
                    )
                  </Name>
                  {requisites.private.iban}
                </Line>
              )
            }
            {
              (requisites.hospital.bic && isSalary) && (
                <Line>
                  <Name>
                    <FormattedMessage id='profile.bic' />
                    {' ('}
                    <FormattedMessage id='profile.salary' />
                    )
                  </Name>
                  {requisites.hospital.bic}
                </Line>
              )
            }
            {
              (requisites.hospital.iban && isSalary) && (
                <Line>
                  <Name>
                    <FormattedMessage id='profile.iban' />
                    {' ('}
                    <FormattedMessage id='profile.salary' />
                    )
                  </Name>
                  {requisites.hospital.iban}
                </Line>
              )
            }
            
            <Line>
              <Name>
                <FormattedMessage id='profile.urgent.price' />
              </Name>
              {`${get(price, 'daily_price', 0)} €`}
            </Line>
            <Line>
              <Name>
                <FormattedMessage id='profile.weekend' />
              </Name>
              {`${get(price, 'weekend_price', 0)} €`}
            </Line>
            <Line>
              <Name>
                <FormattedMessage id='profile.urgent' />
              </Name>
              {`${get(price, 'urgent_price', 0)} €`}
            </Line>
          </>
        )
      }
    </PaperStyled>
  )
}
