import React from 'react'
import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux';
import { EditPage } from 'common/UiKit/EditPage'
import { editProfile } from '../../../../../redux/modules/auth'
import { history } from '../../../../../utils/history'
import { PROFILE_FILL_STEPS } from '../../../../../constants/ui'
import { externalFormSubmit } from '../../../../../helpers/externalFormSubmit'
import { PersonalDataForm } from '../FillForm/PersonalDataForm'

const EditPersonalDataComponent = ({
  initialValues,
  editProfile,
  loading,
}) => {
  const lang = useSelector(state => state.localization)
  return (
    <EditPage
      label='profile.personal'
      onBackIconClick={() => history.push('/panel/profile')}
      buttonText='global.save'
      onButtonClick={() => externalFormSubmit(PROFILE_FILL_STEPS[0])}
      loading={loading}
    >
      <PersonalDataForm
        initialValues={initialValues}
        onSubmit={values => editProfile({ ...values, interface_language: lang })}
        id={PROFILE_FILL_STEPS[0]}
        afterSubmit={() => history.push('/panel/profile')}
        disableShadow
        isDoctor
      />
    </EditPage>
  )
}

EditPersonalDataComponent.propTypes = {
  initialValues: PropTypes.object.isRequired,
  editProfile: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

const enhance = connect(
  state => ({
    initialValues: {
      date_of_birth: state.auth.attributes.date_of_birth,
      first_name: state.auth.attributes.first_name,
      last_name: state.auth.attributes.last_name,
      gender: state.auth.attributes.gender,
      photo: state.auth.attributes.photo,
      language: state.auth.attributes.language,
      specialization_id: state.auth.attributes.specialization_id,
    },
    loading: state.loading.editProfile,
  }),
  { editProfile },
)

export const EditPersonalData = enhance(EditPersonalDataComponent)
