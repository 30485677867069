import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose, mapProps } from 'recompose'
import { EditPage } from 'common/UiKit/EditPage'
import { Paper } from 'common/UiKit/Paper'
import { Field, Form } from 'react-final-form'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import { createEducation, deleteEducation, editEducation } from '../../../../../redux/modules/auth'
import { history } from '../../../../../utils/history'
import { externalFormSubmit } from '../../../../../helpers/externalFormSubmit'
import { TextField, DateField } from '../../../../common/FinalFormFields'

const validate = (values) => {
  const errors = {}

  if (!values.name) errors.name = <FormattedMessage id='errors.required' />
  if (!values.receivedAt) errors.receivedAt = <FormattedMessage id='errors.required' />

  return errors
}

const EditEducationComponent = ({
  loading,
  location,
  onSubmit,
  initialValues,
  onButtonClick,
  onBackIconClick,
  onDeleteIconClick,
  id,
}) => (
  <EditPage
    label={location.hash ? 'profile.education' : 'profile.add.education'}
    onBackIconClick={onBackIconClick}
    buttonText='global.save'
    onDeleteIconClick={location.hash ? onDeleteIconClick : false}
    onButtonClick={onButtonClick}
    loading={loading}
    dialogTitle='Delete Education?'
    id={id}
  >
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      subscription={{}}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} id='add_education'>
          <Paper>
            <Field
              name='name'
              component={TextField}
              label={<FormattedMessage id='profile.education' />}
            />
            <Field
              name='receivedAt'
              component={DateField}
              views={['year']}
              InputLabelProps={{ shrink: true }}
              onlyYear
              label={<FormattedMessage id='profile.formation.date' />}
            />
          </Paper>
        </form>
      )}
    />
  </EditPage>
)

EditEducationComponent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  onBackIconClick: PropTypes.func.isRequired,
  onDeleteIconClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
}

const normalizeValues = (values) => {
  const education = {}

  Object.keys(values).forEach((key) => {
    if (key.includes('name')) education.name = values[key]
    if (key.includes('receivedAt')) education.receivedAt = moment(values[key]).format('YYYY-MM-DD')
  })

  return education
}

const normalizeInitialValues = (values = { attributes: {} }) => {
  const education = {}

  Object.keys(values.attributes).forEach((key) => {
    if (key.includes('name')) education.name = values.attributes[key]
    education.receivedAt = values.attributes.receivedAt ? moment(values.attributes.receivedAt.date).format('YYYY-MM-DD') : '2000-01-01'
  })

  return education
}

const enhance = compose(
  connect(
    state => ({
      education: state.auth.relationships.education.data,
      loading: state.loading.education,
    }),
    { createEducation, deleteEducation, editEducation },
  ),
  mapProps(props => ({
    ...props,
    onBackIconClick: () => history.push('/panel/profile'),
    onButtonClick: () => externalFormSubmit('add_education'),
    onDeleteIconClick: id => (
      props.deleteEducation(id)
        .then(() => history.push('/panel/profile'))
    ),
    onSubmit: values => (props.location.hash
      ? props.editEducation(
        normalizeValues(values),
        props.education[+props.location.hash.slice(1)].id,
      )
        .then(() => history.push('/panel/profile'))
      : props.createEducation({ education: [normalizeValues(values)] })
        .then(() => history.push('/panel/profile'))
    ),
    initialValues: props.location.hash
      ? normalizeInitialValues(props.education[+props.location.hash.slice(1)])
      : { receivedAt: '2000-01-01' },
    id: props.location.hash && props.education[+props.location.hash.slice(1)]
      ? props.education[+props.location.hash.slice(1)].id
      : '0',
  })),
)

export const EditEducation = enhance(EditEducationComponent)
