import React from 'react'
import PropTypes from 'prop-types'
import { compose, mapProps } from 'recompose'
import { EditPage } from 'common/UiKit/EditPage'
import { AnimationForm } from '../FillForm/AnimationForm'
import { history } from '../../../../../utils/history';
import { saveQuestions } from '../../../../../helpers/Doctor/QuestionHelper';
import { externalFormSubmit } from '../../../../../helpers/externalFormSubmit';
import { PROFILE_FILL_STEPS } from '../../../../../constants/ui';

const EditQuestionsComponent = ({
  onButtonClick, onBackIconClick, onSubmit, btnSaveClick,
}) => (
  <EditPage
    label='profile.questions'
    onBackIconClick={onBackIconClick}
    buttonText='global.save'
    onButtonClick={btnSaveClick}
  >
    <AnimationForm
      onSubmit={onSubmit}
      afterSubmit={onButtonClick}
      editMode='true'
      disableShadow
    />
  </EditPage>
)

EditQuestionsComponent.propTypes = {
  onButtonClick: PropTypes.func.isRequired,
  onBackIconClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  btnSaveClick: PropTypes.func.isRequired,
}

const enhance = compose(
  mapProps(props => ({
    ...props,
    onBackIconClick: () => history.push('/panel/profile'),
    onButtonClick: () => history.push('/panel/profile'),
    btnSaveClick: () => externalFormSubmit(PROFILE_FILL_STEPS[4]),
    onSubmit: (values, afterSubmit, isGeneralist) => saveQuestions(
      values, afterSubmit, isGeneralist,
    ),
  })),
)

export const EditQuestions = enhance(EditQuestionsComponent)
