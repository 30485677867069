import React from 'react'
import PropTypes from 'prop-types'
// import { IconsPanel } from 'common/UiKit/IconsPanel'
import { withProps, compose, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import Bookmark from '@material-ui/icons/Bookmark'
// import { SYMPTOMS_ICONS } from '../../../../../constants/doctors'
import { Page } from '../../../../common/Page'
import { history } from '../../../../../utils/history'
import { Urgent } from './Urgent'
import { Specialists } from './Specialists'
import { logOut } from '../../../../../redux/modules/auth'
import { fetchSpecializations } from '../../../../../redux/modules/content'

const MainComponent = ({ onIconClick, logOut, specializations }) => (
  <Page
    label='consultations.new'
    Icon={Bookmark}
    onIconClick={onIconClick}
    onLogout={logOut}
  >
    <Urgent specializations={specializations} />
    {/*<IconsPanel icons={SYMPTOMS_ICONS} specializations={specializations} />*/}
    <Specialists specializations={specializations} />
  </Page>
)

const enhance = compose(
  connect(state => ({
    specializations: state.content.specializations,
  }), { logOut, fetchSpecializations }),
  withProps({
    onIconClick: () => history.push('/panel/doctors/favorite'),
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchSpecializations()
    },
  }),
)

MainComponent.propTypes = {
  onIconClick: PropTypes.func.isRequired,
  logOut: PropTypes.func.isRequired,
  specializations: PropTypes.array.isRequired,
}

export const Main = enhance(MainComponent)
