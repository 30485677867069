import React, { useEffect } from 'react'
import { TextButton } from 'common/UiKit/TextButton'
import { LinkCard } from 'common/UiKit/LinkCard'
import styled, { css } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { readNotification } from '../../../redux/modules/notification'
import concilium from '../../../images/icons/concilium.svg'
import { toggleConcilium } from '../../../redux/modules/consultations'
import { history } from '../../../utils/history'

const Unread = styled(LinkCard)`
  h1 {
    margin-bottom: 16px;
  }
  
  ${props => (props.unread ? css`
    h1 {
      position: relative;
      
      &:before {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        left: -15px;
        top: 3px;
        border-radius: 50%;
        background-color: #fa4464;
      }
    }  
  ` : '')}
`

const Wrapper = styled.div`
  position: relative;
`

const ButtonWrapper = styled.div`
  position: absolute;
  right: 64px;
  top: 27px;
  display: flex;
  align-items: center;
  
  button {
    font-size: 14px;
    min-width: 150px;
    height: 38px;
    margin-right: 16px;
  }
`

export const NotificationItem = ({ data }) => {
  const dispatch = useDispatch()
  const loading = useSelector(state => state.loading.acceptConcilium)

  useEffect(() => {
    if (!data.attributes.read) {
      dispatch(readNotification(data.id))
    }
  }, [dispatch])

  const isConciliumInvite = !!data.attributes.links.accept && data.attributes.links.accept.includes('/addDoctor/accept/')

  const onAccept = () => dispatch(toggleConcilium(data.attributes.links.accept))
    .then(() => history.push('/panel/consultations'))

  return (
    <Wrapper>
      <Unread
        label={data.attributes.title}
        text={data.attributes.message}
        unread={!data.attributes.read}
        to='/panel/consultations'
      />
      {
        isConciliumInvite && (
          <ButtonWrapper>
            <TextButton
              color='primary'
              onClick={onAccept}
              isFetching={loading}
            >
              <FormattedMessage id='concilium.confirm' />
            </TextButton>
            <img src={concilium} alt='' />
          </ButtonWrapper>
        )
      }
    </Wrapper>
  )
}
