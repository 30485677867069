import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  .diagRoot .MuiBackdrop-root {
    backdrop-filter: blur(5px);
  }
`

const StyledDialogContent = styled(DialogContent)`
  padding: 0px !important;
  width: 900px;
  display: flex;
`

const Video = styled.video`
  width: 100%    !important;
  height: auto   !important;
`

export const VideoModal = ({ open, handleClose }) => (
  <div>
    <GlobalStyle />
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='md'
      classes={{
        root: 'diagRoot',
      }}
    >
      <StyledDialogContent>
        <Video width='480' controls>
          <source src={open} type='video/mp4' />
          <track src='captions_en.vtt' kind='captions' srcLang='en' label='english_captions' />
        Your browser doesnt support HTML5 video tag.
        </Video>
      </StyledDialogContent>
    </Dialog>
  </div>
)
