import React from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import { withHandlers, lifecycle, compose } from 'recompose'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import isEqual from 'lodash/isEqual'
import { FormattedMessage } from 'react-intl'
import { FormHelperText } from '@material-ui/core'
import { Colors } from '../../../constants/colors'
import DefaultAvatar from '../../../images/panel/default-avatar.svg'

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
`

const StyledButton = styled(Button)`
  && {
    font-size: 14px;
    font-weight: 500;
    margin-top: 16px;
  }
`

const PhotoWrapper = styled.div`
  background-size: 100% 100%;
  width: 126px;
  height: 126px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.lightGrey};
  border-radius: 50%;
  border: 7px solid white;
  box-shadow: 0 4px 8px 0 rgba(6, 173, 255, 0.1);
  cursor: pointer;
`

const Photo = styled.div`
  background: url(${props => props.photo})  center no-repeat;
  border-radius: 50%;
  background-size: cover;
  width: ${props => (props.empty ? '83px' : '100%')};
  height: ${props => (props.empty ? '94px' : '100%')};
`

const DropzoneWrapper = styled.div`
  outline: none;
  margin-right: 32px;
`

const Error = styled(FormHelperText)`
  && {
    text-align: center;
  }
`

const AvatarUploadComponent = ({
  onDrop,
  getPreview,
  input,
  meta,
}) => (
  <Dropzone onDrop={onDrop} accept='image/*'>
    {
      ({ getRootProps, getInputProps }) => (
        <DropzoneWrapper
          {...getRootProps()}
        >
          <Wrapper>
            <PhotoWrapper>
              <Photo photo={getPreview()} empty={!input.value} />
            </PhotoWrapper>
            <StyledButton variant='text' color='primary'>
              <FormattedMessage id='profile.upload.image' />
            </StyledButton>
            {meta.error && <Error error>{meta.error}</Error>}
          </Wrapper>
          <input {...getInputProps()} />
        </DropzoneWrapper>
      )
    }
  </Dropzone>
)

const enhance = compose(
  lifecycle({
    shouldComponentUpdate(nextProps) {
      if (isEqual(nextProps.input.value, this.props.input.value)) {
        return false
      }

      return true
    },
  }),
  withHandlers({
    onDrop: props => async (files) => {
      props.input.onChange(files[0])
    },
    getPreview: ({ input: { value } }) => () => {
      if (value && typeof value === 'string') {
        return value.includes('/file/') ? `http://backend-application.owndoctor.eu${value}` : value
      }
      if (value) {
        return URL.createObjectURL(value)
      }
      return DefaultAvatar
    },
  }),
)

AvatarUploadComponent.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]),
  }).isRequired,
  onDrop: PropTypes.func.isRequired,
  getPreview: PropTypes.func.isRequired,
}

export const AvatarUpload = enhance(AvatarUploadComponent)
