import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import { FormattedMessage } from 'react-intl'
import logo from '../../../../images/panel/doctors/empty-screen.svg'

const Wrapper = styled.div`
  width: 380px;
  height: calc(100vh - 48px - 56px - 24px * 2 - 80px);
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: Roboto, sans-serif;
`

const Logo = styled.img`
  margin-bottom: 36px;
  width: 168px;
  height: 168px;
`

const Heading = styled.h1`
  margin: 0 0 16px;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  color: ${Colors.dark};
`

const Subtitle = styled.p`
  color: ${Colors.blueGrey};
  font-size: 14px;
  text-align: center;
`

export const EmptyScreen = ({ subtitle, title }) => (
  <Wrapper>
    <Logo src={logo} />
    <Heading>
      <FormattedMessage id={title || 'consultation.no.doctors'} defaultMessage='No Doctors found' />
    </Heading>
    {subtitle && (
      <Subtitle>
        <FormattedMessage id={subtitle} defaultMessage={subtitle} />
        {subtitle}
      </Subtitle>
    )}
  </Wrapper>
)

EmptyScreen.defaultProps = {
  subtitle: '',
  title: '',
}

EmptyScreen.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string,
}
