import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Colors } from 'constants/colors'
import { FormattedMessage, useIntl } from 'react-intl'
import { Paper } from '../Paper'
import { TextField } from '../TextField'
import { MultilineTextField } from '../MultilineTextField'
import { makeYearOptions } from '../../../../utils/makeYearOptions'

const FirstLine = styled.div`
  display: flex;
  justify-content: space-between;
`

const YearField = styled(TextField)`
  && {
    width: 324px;
  }
`

const MultilineField = styled(MultilineTextField)`
  & .root {
    height: 88px;
  }
`

const AddLink = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: ${props => (props.disabled ? Colors.blueGrey : Colors.clearBlue)};
  letter-spacing: 0.5px;
  cursor: pointer;

  ${props => !props.disabled && '&:hover { text-decoration: underline }'}
`

const years = makeYearOptions(1980, new Date().getFullYear() + 5)

export const CoursesForm = ({
  onAdd,
  id,
  last,
  isAddActive,
  Field,
  ...rest
}) => {
  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })

  return (
    <Paper {...rest}>
      <FirstLine>
        <Field
          component={YearField}
          name={`from_year_courses_${id}`}
          label={`*${f('profile.from')}`}
          options={years}
        />
        <Field
          component={YearField}
          name={`to_year_courses_${id}`}
          label={`*${f('profile.to')}`}
          options={years}
        />
      </FirstLine>
      <Field
        component={TextField}
        name={`course_${id}`}
        label={`*${f('profile.course')}`}
      />
      <Field
        component={MultilineField}
        name={`description_${id}`}
        label={f('profile.description')}
        maxLength={200}
        multiline
        rowsMax='3'
      />
      {
        last
        && (
          <AddLink disabled={!isAddActive} onClick={isAddActive ? onAdd : () => null}>
            <FormattedMessage id='profile.add.course' />
          </AddLink>
        )
      }
    </Paper>
  )
}

CoursesForm.defaultProps = {
  onAdd: () => null,
  id: 0,
  last: false,
  isAddActive: false,
}

CoursesForm.propTypes = {
  onAdd: PropTypes.func,
  id: PropTypes.number,
  last: PropTypes.bool,
  isAddActive: PropTypes.bool,
  Field: PropTypes.func.isRequired,
}
