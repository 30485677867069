import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { TextButton } from 'common/UiKit/TextButton'
import { Colors } from 'constants/colors'
import image from '../../images/panel/error.svg'
import { history } from '../../utils/history'

export const ErrorPage = () => (
  <Wrapper>
    <Content>
      <Text>
        <Title>
          <FormattedMessage id='error.page.title' />
        </Title>
        <Subtitle>
          <FormattedMessage id='error.page.subtitle' />
        </Subtitle>
        <Button
          color='primary'
          margin='dense'
          onClick={() => {
            history.goBack()
            setTimeout(() => window.location.reload(), 500)
          }}
        >
          <FormattedMessage id='error.page.button' />
        </Button>
      </Text>
      <Image>
        <img src={image} alt='' />
      </Image>
    </Content>
  </Wrapper>
)

//region Styled Components
const Wrapper = styled.div`
  background-color: rgba(181,189,208,0.24);
  min-height: 100vh;
  padding: 100px 130px;
  box-sizing: border-box;
  height: 100%;
  position: absolute;
  width: 100%;
`

const Content = styled.div`
  position: relative;
  top: 50%;
  max-width: 1140px;
  margin: 0 auto;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
`

const Text = styled.div`
  padding: 32px;
  box-sizing: border-box;
`

const Title = styled.div`
  color: ${Colors.dark};
  font-size: 34px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 40px;
  margin-bottom: 12px;
`

const Subtitle = styled.div`
  color: ${Colors.dark};
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  margin-bottom: 48px;
`

const Button = styled(TextButton)`
  && {
    height: 40px;
    min-width: 160px;
    font-weight: 400;
    box-shadow: none;
  }
`

const Image = styled.div`
  max-width: 50%;
  
  img {
    max-width: 100%;
  }
`
//#endregion
