import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Switch from '@material-ui/core/Switch'
import styled from 'styled-components'
import { Paper } from 'common/UiKit/Paper'
import { Colors } from 'constants/colors'
import { FormattedMessage } from 'react-intl'

const Access = styled(Paper)`
  width: 328px;
  padding: 16px 0px 2px 16px;

  & > p {
    margin: 0;
    width: 242px;
    font-size: 14px;
    color: ${Colors.blueGrey};
    line-height: 1.43;
  }
`

const Label = styled.label`
  opacity: 0.8;
  font-weight: 500;
  font-size: 16px;
  color: ${Colors.dark};
  line-height: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const AccessToggle = ({
  isOpened,
  toggle,
  onClick,
  access,
  toggleAccess,
}) => (
  <Dialog open={isOpened} onClose={() => toggle(false)}>
    <Access>
      <Label>
        <FormattedMessage id='hr.access' />
        <Switch
          name='access'
          color='primary'
          type='checkbox'
          checked={access}
          onChange={(e, v) => toggleAccess(v)}
        />
      </Label>
      <p>
        <FormattedMessage id='hr.access.text' />
      </p>
      <DialogActions>
        <Button
          color='primary'
          size='small'
          variant='contained'
          onClick={onClick}
        >
          <FormattedMessage id='global.continue' />
        </Button>
      </DialogActions>
    </Access>
  </Dialog>
)
