import { handleActions, createAction } from 'redux-actions'
import { combineReducers } from 'redux'
import axios from 'axios'
import idx from 'idx'
import moment from 'moment'
import { MessageCreator } from '../../helpers/consultations'
import { socket } from '../../services/socket'
import { handleMessageAction } from './chats'
import { history } from '../../utils/history'
import { CONSULTATION_TYPES } from '../../constants/consultation'

const opentokDefaultState = {
  params: {},
  session: {},
  messages: [],
}

//#region Actions
export const pushMessageRequest = createAction('PUSH_MESSAGE_REQUEST')
export const pushMessageSuccess = createAction('PUSH_MESSAGE_SUCCESS')
export const pushMessageFailure = createAction('PUSH_MESSAGE_FAILURE')
export const deleteMessages = createAction('DELETE_MESSAGES')

export const pushMessage = data => (dispatch, getStore) => {
  const { session } = getStore().consultations.opentok

  // TODO: add notification

  dispatch(pushMessageRequest(data))

  return (
    session.signal({
      type: 'msg',
      data: JSON.stringify(data),
    },
    e => e && pushMessageFailure(e))
  )
}

export const sendQuestionsRequest = createAction('SEND_QUESTION_REQUEST')
export const sendQuestionsSuccess = createAction('SEND_QUESTION_SUCCESS')
export const sendQuestionsFailure = createAction('SEND_QUESTION_FAILURE')

export const sendQuestions = (consultationId, data) => (dispatch, getStore) => {
  dispatch(sendQuestionsRequest())
  const questions = getStore().content.consultationQuestions
  const answers = data.map((answer, idx) => ({ id: (questions[idx] || {}).id, answer }))
    .filter(item => !!item)

  return axios(`/general/consultation/${consultationId}/question`, {
    method: 'POST',
    data: {
      question: answers,
    },
  })
    .then(() => dispatch(sendQuestionsSuccess()))
    .catch((e) => {
      dispatch(sendQuestionsFailure(e))
    })
}


export const fetchConsultationsListRequest = createAction('FETCH_CONSULTATIONS_LIST_REQUEST')
export const fetchConsultationsListSuccess = createAction('FETCH_CONSULTATIONS_LIST_SUCCESS')
export const fetchConsultationsListFailure = createAction('FETCH_CONSULTATIONS_LIST_FAILURE')

export const fetchConsultations = params => (dispatch, getStore) => {
  dispatch(fetchConsultationsListRequest(params))

  const { userType } = localStorage

  return axios(`/${userType}/consultation?include=patient,doctor,additionalMember`, { params })
    .then((res) => {
      dispatch(fetchConsultationsListSuccess(res.data))
      const { chats } = getStore()
      res.data.data.forEach((consultation) => {
        if (!chats[consultation.id]) {
          socket.connectChat(consultation.id)
        }
      })
    })
    .catch((e) => {
      dispatch(fetchConsultationsListFailure(e))
    })
}

export const fetchConsultationRequest = createAction('FETCH_CONSULTATION_REQUEST')
export const fetchConsultationSuccess = createAction('FETCH_CONSULTATION_SUCCESS')
export const fetchConsultationFailure = createAction('FETCH_CONSULTATION_FAILURE')

export const fetchConsultation = (consultationId, type, silent) => (dispatch, getStore) => {
  const id = consultationId || getStore().consultations.consultation.id
  if (!silent) {
    dispatch(fetchConsultationRequest({
      consultationId, type, silent, id,
    }))
  }

  // TODO: kostyl
  dispatch(deleteMessages())

  const params = {
    include: type === CONSULTATION_TYPES.telexpertise
      ? 'doctor,patient,medicalReport,drugs,additionalMember,healthRecord.attachment,healthRecord.contraindication'
      : 'doctor,patient,healthRecord.attachment,medicalReport,drugs,additionalMember,healthRecord.contraindication',
  }

  return axios(`/general/consultation/${id}`, { params })
    // TODO: kostyl'
    .then((res) => {
      dispatch(fetchConsultationSuccess(res.data))
      new MessageCreator(res.data.data, msg => dispatch(pushMessageSuccess(msg)))
        .pushInitialMessages()
    })
    .catch((e) => {
      dispatch(fetchConsultationFailure(e))
    })
}

export const doctorConnectRequest = createAction('DOCTOR_CONNECT_REQUEST')
export const doctorConnectSuccess = createAction('DOCTOR_CONNECT_SUCCESS')
export const doctorConnectFailure = createAction('DOCTOR_CONNECT_FAILURE')

export const doctorConnect = () => (dispatch, getStore) => {
  dispatch(doctorConnectRequest())

  const id = idx(getStore(), _ => _.consultations.consultation.id)

  if (!id) return doctorConnectFailure()

  return axios(`/doctor/consultation/connect/${id}`)
    .then(() => dispatch(doctorConnectSuccess()))
    .catch((e) => {
      dispatch(doctorConnectFailure(e))
    })
}

export const doctorDisconnectRequest = createAction('DOCTOR_DISCONNECT_REQUEST')
export const doctorDisconnectSuccess = createAction('DOCTOR_DISCONNECT_SUCCESS')
export const doctorDisconnectFailure = createAction('DOCTOR_DISCONNECT_FAILURE')

export const doctorDisconnect = () => (dispatch, getStore) => {
  dispatch(doctorDisconnectRequest())

  const id = idx(getStore(), _ => _.consultations.consultation.id)

  if (!id) return doctorDisconnectFailure()

  return axios(`/doctor/consultation/disconnect/${id}`)
    .then(() => dispatch(doctorDisconnectSuccess()))
    .catch((e) => {
      dispatch(doctorDisconnectFailure(e))
    })
}

export const fetchOpentokParamsRequest = createAction('FETCH_OPENTOK_PARAMS_REQUEST')
export const fetchOpentokParamsSuccess = createAction('FETCH_OPENTOK_PARAMS_SUCCESS')
export const fetchOpentokParamsFailure = createAction('FETCH_OPENTOK_PARAMS_FAILURE')

export const fetchOpentokParams = () => (dispatch, getStore) => {
  dispatch(fetchOpentokParamsRequest())

  const { id } = getStore().consultations.consultation

  if (!id) return dispatch(fetchOpentokParamsFailure)

  return axios(`/general/consultation/${id}/session`)
    .then(res => dispatch(fetchOpentokParamsSuccess(res.data)))
    .then(() => dispatch(doctorConnect()))
    .catch((e) => {
      dispatch(fetchOpentokParamsFailure(e))
    })
}

export const opentokSessionCreated = createAction('OPENTOK_SESSION_CREATED')
export const finishOpentokSession = createAction('FINISH_OPENTOK_SESSION')
export const deleteSessionData = createAction('DELETE_SESSION_DATA')

export const createAppointmentConsultationRequest = createAction('CREATE_APPOINTMENT_CONSULTATION_REQUEST')
export const createAppointmentConsultationSuccess = createAction('CREATE_APPOINTMENT_CONSULTATION_SUCCESS')
export const createAppointmentConsultationFailure = createAction('CREATE_APPOINTMENT_CONSULTATION_FAILURE')

export const createAppointmentConsultation = values => (dispatch, getStore) => {
  dispatch(createAppointmentConsultationRequest(values))

  const doctorId = idx(getStore(), _ => _.doctors.doctor.id)
  const formData = new FormData()
  const data = {
    ...values,
    doctorId,
    specializationId: '0',
  }

  Object.keys(data).forEach((item) => {
    // TODO: kostyl'
    if (item === 'questions') return
    formData.append(item, data[item])
  })

  return axios('/patient/consultation', {
    method: 'POST',
    data: formData,
  })
    .then((res) => {
      dispatch(createAppointmentConsultationSuccess(res.data))
      dispatch(sendQuestions(res.data.data.id, data.questions))
    })
    .catch(e => dispatch(createAppointmentConsultationFailure(e)))
}

export const createUrgentConsultationRequest = createAction('CREATE_URGENT_CONSULTATION_REQUEST')
export const createUrgentConsultationSuccess = createAction('CREATE_URGENT_CONSULTATION_SUCCESS')
export const createUrgentConsultationFailure = createAction('CREATE_URGENT_CONSULTATION_FAILURE')

export const createUrgentConsultation = values => (dispatch) => {
  dispatch(createUrgentConsultationRequest(values))
  const formData = new FormData()
  const data = {
    ...values,
  }

  // TODO: kostyl'
  if (!data.proposedDate) data.proposedDate = moment().add('day', 1).format('YYYY-MM-DD hh:mm:ss')

  Object.keys(data).forEach((item) => {
    formData.append(item, data[item])
  })

  return axios('/patient/consultation', {
    method: 'POST',
    data: formData,
  })
    .then(res => dispatch(createUrgentConsultationSuccess(res.data)))
    .catch(e => dispatch(createUrgentConsultationFailure(e)))
}

export const editMedicalReportRequest = createAction('EDIT_MEDICAL_REPORT_REQUEST')
export const editMedicalReportSuccess = createAction('EDIT_MEDICAL_REPORT_SUCCESS')
export const editMedicalReportFailure = createAction('EDIT_MEDICAL_REPORT_FAILURE')

export const editMedicalReport = values => (dispatch, getStore) => {
  dispatch(editMedicalReportRequest(values))
  const { id } = getStore().consultations.consultation
  const type = getStore().auth.attributes.type_employment
  const data = values
  const formData = new FormData()

  if (!data.doctor_type_employment) {
    data.doctor_type_employment = type
  }

  if (!id) return dispatch(editMedicalReportFailure)

  Object.keys(data).forEach((key) => {
    formData.append(key, data[key] || '');
  })

  return axios(`/general/consultation/${id}/medicalReport`, {
    method: 'POST',
    data: formData,
  })
    // TODO: kostyl'
    .then((res) => {
      dispatch(editMedicalReportSuccess(res.data))
      dispatch(fetchConsultation(id))
      new MessageCreator(res.data.data, msg => dispatch(pushMessageSuccess(msg)))
        .pushReportMessage()
    })
    .catch((e) => {
      dispatch(editMedicalReportFailure(e))
    })
}

export const addPrescriptionRequest = createAction('ADD_PRESCRIPTION_REQUEST')
export const addPrescriptionSuccess = createAction('ADD_PRESCRIPTION_SUCCESS')
export const addPrescriptionFailure = createAction('ADD_PRESCRIPTION_FAILURE')

export const addPrescription = values => (dispatch, getStore) => {
  dispatch(addPrescriptionRequest(values))
  const { id } = getStore().consultations.consultation
  const data = {
    drugs: values.medicationPrescribed ? values.medicationPrescribed.map(m => ({
      // api_id: m.medicine.value,
      // dosage: m.dosage,
      // iteration: m.iteration,
      // duration: m.duration,
      // type: m.type,
      // quantity: m.quantity || 0,
      name: m.medicine,
      api_id: '123',
      // dosage: '123',
      iteration: '123',
      duration: '123',
      // type: '123',
      quantity: '123',
    })) : [],
  }

  if (!data.drugs.length) return dispatch(addPrescriptionFailure)

  if (!id) return dispatch(addPrescriptionFailure)

  return axios(`/general/consultation/${id}/medicalReportDrug`, {
    method: 'POST',
    data,
  })
    .then((res) => {
      dispatch(addPrescriptionSuccess(res.data))
      dispatch(fetchConsultation(id))
      new MessageCreator(res.data.data, msg => dispatch(pushMessageSuccess(msg)))
        .pushPresciptionMessage()
    })
    .catch((e) => {
      dispatch(addPrescriptionFailure(e))
    })
}

export const approveConsultationRequest = createAction('APPROVE_CONSULTATION_REQUEST')
export const approveConsultationSuccess = createAction('APPROVE_CONSULTATION_SUCCESS')
export const approveConsultationFailure = createAction('APPROVE_CONSULTATION_FAILURE')

export const approveConsultation = (consultationId, type) => (dispatch, getStore) => {
  dispatch(approveConsultationRequest())
  const store = getStore()

  const id = consultationId || idx(store, _ => _.consultations.consultation.id)
  const chatId = idx(store, _ => _.chats[id].chat.id)

  if (!id) return approveConsultationFailure()

  return axios.post(`/doctor/consultation/${id}/approve?include=patient`)
    .then(() => dispatch(approveConsultationSuccess()))
    .then(() => {
      dispatch(fetchConsultation(id, type))
      socket.notify(chatId)
    })
    .catch((e) => {
      dispatch(approveConsultationFailure(e))
    })
}

export const removeDownloadLink = createAction('REMOVE_DOWNLOAD_LINK')

export const getCallStatusRequest = createAction('GET_CALL_STATUS_REQUEST')
export const getCallStatusSuccess = createAction('GET_CALL_STATUS_SUCCESS')
export const getCallStatusFailure = createAction('GET_CALL_STATUS_FAILURE')

export const getCallStatus = () => (dispatch, getStore) => {
  dispatch(getCallStatusRequest())

  const id = idx(getStore(), _ => _.consultations.consultation.id)

  if (!id) return getCallStatusFailure()

  return axios(`/patient/consultation/callStatus/${id}`)
    .then(res => dispatch(getCallStatusSuccess(res.data)))
    .catch((e) => {
      dispatch(getCallStatusFailure(e))
    })
}

export const fetchPreviousConsultationsRequest = createAction('FETCH_PREVIOUS_CONSULTATIONS_REQUEST')
export const fetchPreviousConsultationsSuccess = createAction('FETCH_PREVIOUS_CONSULTATIONS_SUCCESS')
export const fetchPreviousConsultationsFailure = createAction('FETCH_PREVIOUS_CONSULTATIONS_FAILURE')

export const fetchPreviousConsultations = (patient, params = {}) => (dispatch) => {
  dispatch(fetchPreviousConsultationsRequest(params))

  return axios('/doctor/consultation', {
    params: {
      ...params,
      patient,
    },
  })
    .then(res => dispatch(fetchPreviousConsultationsSuccess(res.data)))
    .catch((e) => {
      dispatch(fetchPreviousConsultationsFailure(e))
    })
}

export const refuseConsultationRequest = createAction('REFUSE_CONSULTATION_REQUEST')
export const refuseConsultationSuccess = createAction('REFUSE_CONSULTATION_SUCCESS')
export const refuseConsultationFailure = createAction('REFUSE_CONSULTATION_FAILURE')

export const refuseConsultation = (id, message) => (dispatch) => {
  dispatch(refuseConsultationRequest(id))
  const data = new FormData()
  data.append('message', message)

  return axios(`/doctor/consultation/${id}/cancelled`, {
    method: 'POST',
    data,
  })
    .then(() => dispatch(refuseConsultationSuccess()))
    .catch(() => dispatch(refuseConsultationFailure()))
}


export const paymentRequest = createAction('PAYMENT_REQUEST')
export const paymentSuccess = createAction('PAYMENT_SUCCESS')
export const paymentFailure = createAction('PAYMENT_FAILURE')

export const pay = id => (dispatch) => {
  dispatch(paymentRequest(id))
  const data = new FormData()

  const host = process.env.REACT_APP_HOST

  data.append('payment_type', 'PAYMENT_CONSULTATION')
  data.append('object_id', id)
  data.append('success_url', `${host}/panel/consultations/${id}?payment_status=1`)
  data.append('fail_url', `${host}/panel/consultations/${id}?payment_status=0`)

  return axios('/payment/transaction/pay', {
    method: 'POST',
    data,
  })
    .then((response) => {
      dispatch(paymentSuccess(response.data))
      const url = idx(response, _ => _.data.data.attributes.transactionURL)

      if (url) {
        window.open(url, '_blank');
        //window.location = url
      }
    })
    .catch(() => dispatch(paymentFailure()))
}

export const inviteDoctorRequest = createAction('INVITE_DOCTOR_REQUEST')
export const inviteDoctorSuccess = createAction('INVITE_DOCTOR_SUCCESS')
export const inviteDoctorFailure = createAction('INVITE_DOCTOR_FAILURE')

export const inviteDoctor = (doctorId, consultationId) => (dispatch, getStore) => {
  dispatch(inviteDoctorRequest(doctorId, consultationId))
  const chatId = idx(getStore(), _ => _.chats[consultationId].chat.id)

  return axios(`/doctor/consultation/addDoctor/${consultationId}/${doctorId}`, { method: 'POST' })
    .then(() => {
      if (chatId) {
        socket.notify(chatId)
      }
      dispatch(inviteDoctorSuccess())
    })
    .catch(err => dispatch(inviteDoctorFailure(err)))
}

export const toggleConciliumRequest = createAction('TOGGLE_CONCILIUM_REQUEST')
export const toggleConciliumSuccess = createAction('TOGGLE_CONCILIUM_SUCCESS')
export const toggleConciliumFailure = createAction('TOGGLE_CONCILIUM_FAILURE')

export const toggleConcilium = url => (dispatch) => {
  dispatch(toggleConciliumRequest())

  return axios(url, { method: 'POST' })
    .then(() => dispatch(toggleConciliumSuccess()))
    .catch(err => dispatch(toggleConciliumFailure(err)))
}

export const toggleAccessRequest = createAction('TOGGLE_ACCESS_REQUEST')
export const toggleAccessSuccess = createAction('TOGGLE_ACCESS_SUCCESS')
export const toggleAccessFailure = createAction('TOGGLE_ACCESS_FAILURE')

export const toggleAccess = (id, value, consultationId) => (dispatch, getStore) => {
  dispatch(toggleAccessRequest())
  const chatId = idx(getStore(), _ => _.chats[consultationId].chat.id)

  return axios(`/doctor/consultation/addDoctor/patient/data/${id}`, {
    method: 'POST',
    data: {
      access: +value,
    },
  })
    .then(() => {
      if (chatId) {
        socket.notify(chatId)
      }
      dispatch(toggleAccessSuccess())
    })
    .catch(err => dispatch(toggleAccessFailure(err)))
}

export const createConciliumRequest = createAction('CREATE_CONCILIUM_REQUEST')
export const createConciliumSuccess = createAction('CREATE_CONCILIUM_SUCCESS')
export const createConciliumFailure = createAction('CREATE_CONCILIUM_FAILURE')

export const createConcilium = (values, isUrgent) => (dispatch) => {
  dispatch(createConciliumRequest(values))
  const data = new FormData()

  if (values.proposedDate) {
    data.append('proposedDate', values.proposedDate)
  }

  data.append('symptomExplanation', values.symptomExplanation)
  data.append('accessPolicy', values.accessPolicy)
  data.append('doctorId', values.doctorId)

  if (values.patient) {
    data.append(values.patient.type === 'HealthRecord' ? 'healthRecordId' : 'patientId', values.patient.id)
  }

  if (values.files) {
    values.files.forEach(file => data.append('files[]', file))
  }

  return axios('/doctor/telexpertise', {
    method: 'POST',
    params: isUrgent ? { priority: 'urgent' } : undefined,
    data,
  })
    .then(() => {
      dispatch(createConciliumSuccess())
      history.push('/panel/consultations')
    })
    .catch(err => dispatch(createConciliumFailure(err)))
}

export const setTab = createAction('SET_CONSULTATIONS_TAB')
export const toggleVideoAccess = createAction('TOGGLE_VIDEO_ACCESS')
export const toggleAudioAccess = createAction('TOGGLE_AUDIO_ACCESS')

export const sendUsefulRequest = createAction('SEND_USEFUL_REQUEST')
export const sendUsefulSuccess = createAction('SEND_USEFUL_SUCCESS')
export const sendUsefulFailure = createAction('SEND_USEFUL_FAILURE')

export const sendUseful = (data, consultationId) => (dispatch) => {
  dispatch(sendUsefulRequest())
  return axios(`/general/consultation/${consultationId}/useful`, {
    method: 'POST',
    data,
  })
    .then(() => {
      dispatch(sendUsefulSuccess())
    })
    .catch(err => dispatch(sendUsefulFailure(err)))
}
//#endregion

//#region Reducers
const list = handleActions({
  [fetchConsultationsListSuccess]: (state, action) => action.payload.data
    .sort((a, b) => {
      const time1 = moment(idx(a, _ => _.attributes.proposed_date)).unix()
      const time2 = moment(idx(b, _ => _.attributes.proposed_date)).unix()

      return time2 - time1
    }),
}, [])

const consultation = handleActions({
  [fetchConsultationSuccess]: (state, action) => action.payload.data,
  [handleMessageAction]: (state, action) => {
    if (action.payload.action === 'AdditionalDoctorJoin') {
      if (idx(action, _ => _.payload.attributes.consultationId) === state.id) {
        return {
          ...state,
          relationships: {
            ...state.relationships,
            additionalMember: {
              data: [
                action.payload,
              ],
            },
          },
        }
      }
    }

    return state
  },
}, {})

const previous = handleActions({
  [fetchPreviousConsultationsRequest]: () => [],
  [fetchPreviousConsultationsSuccess]: (state, action) => action.payload.data
    .sort((a, b) => {
      const time1 = moment(idx(a, _ => _.attributes.proposed_date)).unix()
      const time2 = moment(idx(b, _ => _.attributes.proposed_date)).unix()

      return time2 - time1
    }),
}, [])

const opentok = handleActions({
  [fetchOpentokParamsSuccess]: (state, action) => ({ ...state, params: action.payload.data }),
  [opentokSessionCreated]: (state, action) => ({ ...state, session: action.payload }),
  [pushMessageSuccess]: (state, action) => ({
    ...state,
    messages: [...state.messages, JSON.parse(action.payload)],
  }),
  [finishOpentokSession]: state => ({ ...opentokDefaultState, messages: state.messages }),
  [deleteSessionData]: () => opentokDefaultState,
  [deleteMessages]: state => ({ ...state, messages: [] }),
}, opentokDefaultState)

const callStatus = handleActions({
  [getCallStatusSuccess]: (state, action) => action.payload.data,
}, {})

const tab = handleActions({
  [setTab]: (state, action) => action.payload,
}, 0)

const mediaAccess = handleActions({
  [toggleVideoAccess]: (state, action) => ({ ...state, video: action.payload }),
  [toggleAudioAccess]: (state, action) => ({ ...state, audio: action.payload }),
}, {
  video: true,
  audio: true,
})

export const consultations = combineReducers({
  list,
  consultation,
  opentok,
  callStatus,
  previous,
  tab,
  mediaAccess,
})
//#endregion
