import React from 'react'
import PropTypes from 'prop-types'
import idx from 'idx'
import { connect } from 'react-redux'
import { compose, withProps } from 'recompose'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import { history } from '../../../../../../../utils/history'
import { Item } from './Item'
import { EmptyStub } from '../EmptyStub'
import stubImage from '../../../../../../../images/panel/doctors/empty-screen.svg'
import { PanelBar } from '../../../../../../common/PanelBar'

const Wrapper = styled.div`
  height: 100%;
  overflow: auto;
`

const Content = styled.div`
  box-sizing: border-box;
  min-height: calc(100% - 56px);
  padding: 16px;
  background: ${Colors.paleGrey};
  background-size: 100% 180px;
  font-family: Roboto, sans-serif;
`

const AttachmentsComponent = ({
  attachments,
  onBackIconClick,
}) => (
  <Wrapper>
    <PanelBar
      label='profile.attachments'
      onBackIconClick={onBackIconClick}
    />
    <Content>
      {(attachments && attachments.length)
        ? attachments.map(a => <Item attachment={a} key={idx(a, _ => _.id)} />)
        : (
          <EmptyStub
            image={stubImage}
            heading='profile.no.attachments'
          />
        )
      }
    </Content>
  </Wrapper>
)

AttachmentsComponent.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.object).isRequired,
  onBackIconClick: PropTypes.func.isRequired,
}

const enhance = compose(
  connect(state => ({
    attachments: idx(
      state,
      _ => _.consultations
        .consultation.relationships.healthRecord.data.relationships.attachment.data,
    ),
  })),
  withProps({
    onBackIconClick: () => history.push('/panel/consultations/session/menu'),
  }),
)

export const Attachments = enhance(AttachmentsComponent)
