import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import { FormattedMessage } from 'react-intl';
import { CELL_HEIGHT } from '../../../../../../constants/calendar'

export const TimeColumn = React.memo(({ periods, weekNumber, withoutHeader }) => (
  <Wrapper>
    {!withoutHeader
      && (
        <FirstCell className='calendar__first-cell'>
          <FormattedMessage id='schedule.weekNumber' /> {weekNumber}
        </FirstCell>
      )
    }
    {
      periods.map(item => (
        <Cell key={item.from}>
          {item.from.length === 4 ? `0${item.from}` : item.from}
          {/*{' - '}*/}
          {/*{item.to}*/}
        </Cell>
      ))
    }
  </Wrapper>
))

TimeColumn.defaultProps = {
  withoutHeader: false,
}

TimeColumn.propTypes = {
  periods: PropTypes.array.isRequired,
  weekNumber: PropTypes.number.isRequired,
  withoutHeader: PropTypes.bool,
}

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 12.5%;
`

const Cell = styled.div`
  width: 100%;
  height: ${CELL_HEIGHT}px;
  background-color: ${Colors.lightGrey};
  border: 1px solid ${Colors.divider};
  border-top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.textMuted};
  font-size: 12px;
  font-weight: 500;
  box-sizing: border-box;
`

const FirstCell = styled(Cell)`
  height: 100px;
  border-top: 1px solid ${Colors.divider};
  position: sticky;
  top: 0;
  font-size: 16px;
`
