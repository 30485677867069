import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose, withProps } from 'recompose'
import idx from 'idx'
import { DiplomasForm } from 'common/DiplomasForm'

import { ConsultationsList } from '../../Consultations/List/ConsultationsList'
import { Page } from '../../../../common/Page'
import { history } from '../../../../../utils/history'
import {
  createHealthRecordsAttachment,
  deleteHealthRecordsAttachment,
} from '../../../../../redux/modules/healthRecords'

const ListComponent = ({
  consultations,
  afterConsultationClick,
  onBackIconClick,
  diplomas,
  onSubmit,
  onCreateImage,
  deleteHealthRecordsAttachment,
}) => (
  <Page
    label='hr.reports'
    onBackIconClick={onBackIconClick}
  >
    <DiplomasForm
      diplomas={diplomas}
      id='health-records-images'
      onSubmit={onSubmit}
      createDiplomas={onCreateImage}
      deleteDiploma={deleteHealthRecordsAttachment}
      acceptedExtensions='.jpg,.png,.docx,.doc,.xls,.xlsx'
      withFilenames
    />
    <ConsultationsList
      consultations={consultations}
      afterConsultationClick={afterConsultationClick}
    />
  </Page>
)

ListComponent.propTypes = {
  consultations: PropTypes.arrayOf(PropTypes.object).isRequired,
  afterConsultationClick: PropTypes.func.isRequired,
  onBackIconClick: PropTypes.func.isRequired,
  diplomas: PropTypes.arrayOf(PropTypes.object).isRequired,
  onCreateImage: PropTypes.func.isRequired,
  deleteHealthRecordsAttachment: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

const enhance = compose(
  connect(
    state => ({
      consultations: idx(state, _ => _.consultations.list).filter(item => item.attributes.status === 'completed'),
      diplomas: idx(state, _ => _.healthRecords.attachment.medical_reports) || [],
      loading: state.loading.healthRecordsAttachments,
    }),
    { createHealthRecordsAttachment, deleteHealthRecordsAttachment },
  ),
  withProps(props => ({
    onBackIconClick: () => history.push('/panel/health-records'),
    afterConsultationClick: () => history.push('/panel/health-records/medical-reports/report'),
    onSubmit: () => history.push('/panel/health-records'),
    onCreateImage: values => props.createHealthRecordsAttachment({
      ...values,
      type: 'medical_reports',
    }),
    diplomas: props.diplomas.map(item => (item.attributes
      ? {
        id: item.id,
        file: item.attributes.url,
        extension: idx(item, _ => _.attributes.extension),
        custom_name: item.attributes.custom_name,
      }
      : { id: '0', file: '' }
    )),
  })),
)

export const List = enhance(ListComponent)
