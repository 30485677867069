import React from 'react'
import styled from 'styled-components'
import { CircularProgress } from '@material-ui/core'

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Preloader = ({ ...rest }) => (
  <Wrapper {...rest}>
    <CircularProgress size={60} />
  </Wrapper>
)
