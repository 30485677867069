import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, CircularProgress } from '@material-ui/core'
import { Colors } from 'constants/colors'

const Wrapper = styled(Button)`
  && {
    border-radius: 30px;
    min-width: 100px;
    margin: 0 6px 0 0;
    border-color: ${Colors.disabled};
    height: 35px;
  }
`

export const ActionButton = ({
  isFetching,
  children,
  onClick,
  ...rest
}) => (
  <Wrapper
    variant='outlined'
    color='primary'
    size='small'
    disabled={isFetching}
    onClick={onClick}
    {...rest}
  >
    {isFetching ? <CircularProgress size={19} color='primary' /> : children}
  </Wrapper>
)

ActionButton.defaultProps = {
  isFetching: false,
}

ActionButton.propTypes = {
  isFetching: PropTypes.bool,
  children: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired,
}
