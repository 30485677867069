import React, { useEffect, useRef, useState } from 'react'
import get from 'lodash/get'
import NotificationsIcon from '@material-ui/icons/Notifications'
import IconButton from '@material-ui/core/IconButton'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import Badge from '@material-ui/core/Badge'
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl'
import { history } from '../../../utils/history'
import notification from '../../../assets/sounds/notification.mp3'
import { fetchNotification, readNotification } from '../../../redux/modules/notification'

const RingButton = styled(IconButton)`
  && {
    color: white;
    margin-right: 12px;
    
    span > span > span {
      background-color: #fa4464;
    }
  }
`

const NotificationsContainer = styled(Paper)`
  width: 366px;
  height: 424px;
  position: absolute;
  right: 190px;
  z-index: 99;
  display: flex;
  flex-direction: column;
`

const ViewAllButton = styled(Button)`
  && {
    align-self: bottom;
    border-top: 1px solid #eee;
  }
`

const NotificationsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
  padding-top: 21px;
`

const NotificationsTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.25px;
  color: #21293e;
  display: flex;
  align-items: center;
`

const MarkAllReadButton = styled(Button)`
  && {
    font-size: 10px;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.89px;
    text-align: center;
    color: #0097ff;
    line-height: 1;
  }
`

const NotificationsList = styled.div`
  display: inline-block;
  overflow-y: scroll;
  height: 340px;

  &::-webkit-scrollbar {
    padding-left: 100px;
  }
`

const NotificationItem = styled.div`
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 21px;
  align-items: center;
  background-color: ${({ isNew }) => (isNew ? '#f5f7fb' : '#fff')};
  border-bottom: 2px solid #fff;
`

// const Avatar = styled.div` 
//   background-image: url(${({ photo }) => photo});
//   width: 42px;
//   height: 42px;
//   border: 2px solid #eee;
//   border-radius: 50%;
//   display: flex;
//   background-size: 100%;
// `

const NotificationItemDescription = styled.div`
  display: flex;
  flex: 1;
  /*margin-left: 21px;*/
  flex-direction: column;
`

const DoctorName = styled.div`
  font-weight: 500;
  color: #21293e;
  font-size: 14px;
`

const Description = styled.div`
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: 0.13px;
  color: #7a7f8b;
`

const CounterInContainer = styled.div`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.12px;
  color: #ffffff;
  border-radius: 50px;
  background-color: #ea3a42;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 1.45;
`

const Title = styled.div`
  display: flex;
  padding-right: 6px;
`

export const NotificationsButton = () => {
  const dispatch = useDispatch()
  const count = useSelector(state => state.notification.count)
  const notifications = useSelector(state => state.notification)
  const [toogleView, setToogleView] = useState(false)
  const audio = new Audio(notification)
  const prevCount = useRef()
  const list = get(notifications, 'list.data', []);

  audio.addEventListener('ended', () => {
    audio.currentTime = 0
  })

  useEffect(() => {
    prevCount.current = count
  }, [])

  useEffect(() => {
    dispatch(fetchNotification())
  }, [dispatch])

  useEffect(() => {
    if (count > prevCount.current) {
      audio.play()
        .catch(e => console.warn(e))
    }

    if (prevCount.current !== count) {
      prevCount.current = count
    }
  }, [count])

  const idsInCurrentList = list.map(el => el.id);

  return (
    <div>
      <RingButton size='small' onClick={() => setToogleView(!toogleView)}>
        <Badge badgeContent={count} color='primary' invisible={!count}>
          <NotificationsIcon />
        </Badge>
      </RingButton>
      {toogleView ? (
        <NotificationsContainer>
          <NotificationsHeader>
            <NotificationsTitle>
              <Title>
                <FormattedMessage id='concilium.notifications' />
              </Title>
              {count ? <CounterInContainer>{count}</CounterInContainer> : null}
            </NotificationsTitle>
            <MarkAllReadButton onClick={() => dispatch(readNotification(idsInCurrentList))} color='primary'>
              <FormattedMessage id='global.markasread' />
            </MarkAllReadButton>
          </NotificationsHeader>
          <NotificationsList>
            {
              list.map((el) => {
                //const image = '/static/media/default-avatar.b887eb6a.svg';
                const isRead = get(el, 'attributes.read', false);
                return (
                  <NotificationItem key={el.id} isNew={!isRead}>
                    {/*<Avatar photo={image} />*/}
                    <NotificationItemDescription>
                      <DoctorName>
                        {get(el, 'attributes.title', '')}
                      </DoctorName>
                      <Description>
                        {get(el, 'attributes.message', '')}
                      </Description>
                    </NotificationItemDescription>
                  </NotificationItem>
                )
              })
            }
          </NotificationsList>
          <ViewAllButton onClick={() => history.push('/panel/profile/notifications')} color='primary'>
            <FormattedMessage id='global.viewall' />
          </ViewAllButton>
        </NotificationsContainer>
      ) : null}
    </div>
  )
}
