import React from 'react'
import { ConsultationReportPanel } from 'common/UiKit/ConsultationReportPanel'
import { TextButton } from 'common/UiKit/TextButton'
import { Colors } from 'constants/colors'
import { useSelector } from 'react-redux'
import idx from 'idx'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { history } from '../../../../../../../utils/history'

export const Payment = () => {
  const consultation = useSelector(state => state.consultations.consultation)
  const price = idx(consultation, _ => _.attributes.invoice.price)
  const pdf = idx(consultation, _ => _.attributes.invoice.invoicePDF)
  return (
    <ConsultationReportPanel
      onCloseIconClick={() => history.push('/panel/consultations/session')}
      onDownloadIconClick={() => window.open(pdf)}
      heading='payments.payment.title'
      patientFirstName={idx(consultation, _ => _.relationships.patient.data.attributes.first_name)}
      patientLastName={idx(consultation, _ => _.relationships.patient.data.attributes.last_name)}
      doctorFirstName={idx(consultation, _ => _.relationships.doctor.data.attributes.first_name)}
      doctorLastName={idx(consultation, _ => _.relationships.doctor.data.attributes.last_name)}
      specialization={idx(consultation, _ => _.relationships.doctor.data.attributes.specialization)}
      date={idx(consultation, _ => _.attributes.creation_time)}
    >
      <Sum>
        <FormattedMessage id='consultation.total.sum' tagName='div' />
        <div>
          {price.toFixed(0)}€
        </div>
      </Sum>
      <Link href={pdf} target='_blank'>
        <Button color='primary' fullWidth>
          <FormattedMessage id='payments.download' />
        </Button>
      </Link>
    </ConsultationReportPanel>
  )
}

const Sum = styled.div`
  color: ${Colors.dark80};
  font-weight: 500;
  
  & > div:first-child {
    font-size: 16px;
    margin-bottom: 8px;
    text-transform: uppercase;
  }
  
  & > div:last-child {
    font-size: 32px;
    color: ${Colors.clearBlue}
  }
`

const Button = styled(TextButton)`
  && {
    width: 100%;
    margin-top: 24px;
  }
`

const Link = styled.a`
  width: 100%;
`
