import React from 'react'
import PropTypes from 'prop-types'
import { compose, withProps } from 'recompose'
import { connect } from 'react-redux'
import { PrescriptionForm as Form } from 'common/UiKit/PrescriptionForm'
import { EditPage } from 'common/UiKit/EditPage'
import idx from 'idx'
import { history } from '../../../../../utils/history'
import { externalFormSubmit } from '../../../../../helpers/externalFormSubmit'
import { addPrescription } from '../../../../../redux/modules/consultations'
import { fetchMedicines } from '../../../../../redux/modules/medicines'
import { socket } from '../../../../../services/socket'

const PrescriptionFormComponent = ({
  addPrescription,
  afterSubmit,
  onButtonClick,
  loading,
  searchMedicines,
  options,
}) => (
  <EditPage
    label='consultations.fill.prescription'
    onBackIconClick={afterSubmit}
    buttonText='global.save'
    onButtonClick={onButtonClick}
    loading={loading}
    cancelButtonText='global.skip'
    onCancelButtonClick={afterSubmit}
  >
    <Form
      onSubmit={addPrescription}
      id='prescription'
      afterSubmit={afterSubmit}
      disableShadow
      onChange={searchMedicines}
      options={options}
    />
  </EditPage>
)

PrescriptionFormComponent.propTypes = {
  addPrescription: PropTypes.func.isRequired,
  afterSubmit: PropTypes.func.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  searchMedicines: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
}

// TODO add loading state
const enhance = compose(
  connect(
    state => ({
      loading: state.loading.editMedicalReport,
      chatId: idx(state, _ => _.chats[state.consultations.consultation.id].chat.id),
      options: idx(state, _ => _.medicines.list).map(m => ({
        label: m.attributes.name,
        value: m.id,
      })),
    }),
    { addPrescription, fetchMedicines },
  ),
  withProps(props => ({
    afterSubmit: () => {
      socket.notify(props.chatId)
      history.push('/panel/consultations/session')
    },
    onButtonClick: () => externalFormSubmit('prescription'),
    searchMedicines: v => v.length > 1 && props.fetchMedicines(v),
  })),
)

export const PrescriptionForm = enhance(PrescriptionFormComponent)
