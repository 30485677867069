import React from 'react'
import styled from 'styled-components'
import { LinkCard } from 'common/UiKit/LinkCard'
import { Page } from '../../../common/Page'

const LinkCardStyled = styled(LinkCard)`
  padding: 12px 16px 12px 24px;
  margin-bottom: 16px
`

export const CommunityPageComponent = () => (
  <Page
    label='doctor.community.page'
  >
    <LinkCardStyled
      label='roso.community.label'
      to='/panel/community/roso'
    />
    <LinkCardStyled
      label='rips.community.label'
      to='/panel/concilium/favorite'
    />
    <LinkCardStyled
      label='status'
      to='/panel/status'
    />
  </Page>
)

export const Main = CommunityPageComponent
