import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { List } from './List'
import { Main } from './Main'
import { UrgentConsultation } from './UrgentConsultation'
import { AppointmentConsultation } from './AppointmentConsultation'
import { Profile } from './Profile'
import { Favorite } from './Favorite'
import { Reviews } from './Reviews'

export const Doctors = () => (
  <Switch>
    <Route path='/panel/doctors/urgent/create' component={UrgentConsultation} />
    <Route path='/panel/doctors/appointment/create' component={AppointmentConsultation} />
    <Route path='/panel/doctors/:consultationType/profile' component={Profile} />
    <Route path='/panel/doctors/:doctorId/reviews' component={Reviews} />
    <Route exact path='/panel/doctors/:consultationType/:doctorType' component={List} />
    <Route path='/panel/doctors/favorite' component={Favorite} />
    <Route path='/panel/doctors' component={Main} />
  </Switch>
)
