import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Paper } from 'common/UiKit/Paper'
import { Colors } from 'constants/colors'
import { FormattedMessage } from 'react-intl'
//import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import Grid from '@material-ui/core/Grid'
import get from 'lodash/get'
import { Page } from '../Page'
import { FAQItem } from './FAQItem'
import { AppInfo } from './AppInfo'
import { logOut } from '../../../redux/modules/auth'
import { VideoModal } from './VideoModal'
import { getTutorialList } from '../../../helpers/doctors'

const PaperStyled = styled(Paper)`
  padding: 8px 24px;
  margin-bottom: 16px;
`

const PaperStyled2 = styled(Paper)`
  padding: 0px;
  margin-bottom: 16px;
`

const LinkStyled = styled(Link)`
  line-height: 3;
  color: ${Colors.clearBlue};
  font-size: inherit;
  width: 100%;
`

// const AStyled = styled.div`
//   line-height: 3;
//   color: ${Colors.clearBlue};
//   font-size: inherit;
//   width: 100%;
//   display: flex;
//   align-items: center;
// `

// const PlayIcon = styled(PlayCircleFilledIcon)`
//   margin-right: 8px;
// `

const Links = styled.div`
  a {
    margin-bottom: 10px;
  }
`

const FAQ = styled.div`
  margin: 32px 0 12px 24px;
  font-size: 16px;
  font-weight: 500;
  color: ${Colors.dark};
  opacity: 0.8;
`

const TitleTutorial = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #3e4557;
  padding-bottom: 12px;
  padding-left: 23px;
`

const NameOfVideo = styled.div` 
  font-size: 18px;
  font-weight: bold;
  line-height: 1.11;
  color: #21293e;
  padding-left: 22px;
  padding-right: 22px;
  padding-bottom: 8px;
`

const Video = styled.video`
  width: 100%    !important;
  height: auto   !important;
  cursor: pointer;
`

//https://occimed-my.sharepoint.com/:v:/g/personal/pchevallier_conexsante_com/Ea4noy7h4EFAg-oFsiJOc5kBSUnWFvTjlZyGI5NBI9Epmg
//https://occimed-my.sharepoint.com/:v:/g/personal/pchevallier_conexsante_com/Eff3zhuL9G5LgyjW7AHzHzUBLfNSBufuVwNUlWniR8GooA
//https://occimed-my.sharepoint.com/:v:/g/personal/pchevallier_conexsante_com/EXS4QPUgsFNJvszqcEa0QssBOR8FhzHC8lYIud1z2mQVqA

export const MainPageComponent = ({
  id,
  logOut,
  faq,
  match,
  onBackIconClick,
}) => {
  const [videoModalOpen, setVideoModalOpen] = useState('');
  const [tutorials, setTutorials] = useState([]);

  useEffect(() => {
    getTutorialList()
      .then(result => setTutorials(result))
      .catch(err => console.log(err))
  }, [])

  return (
    <Page
      label='global.help'
      onLogout={logOut}
      id={id}
      onBackIconClick={onBackIconClick}
    >
      <AppInfo />
      <PaperStyled>
        <LinkStyled to={`${match.path}/terms`}>
          <FormattedMessage id='auth.policy' />
        </LinkStyled>
      </PaperStyled>

      <TitleTutorial>
        Tutorial videos
      </TitleTutorial>
      <Grid container spacing={2}>
        {tutorials.map((el) => {
          const custom_name = get(el, 'attributes.custom_name', '');
          const url = get(el, 'attributes.url', '');
          return (
            <Grid item key={custom_name} sm={4}>
              <PaperStyled2>
                <Video onClick={() => setVideoModalOpen(url)} width='229'>
                  <source src={url} type='video/mp4' />
                  <track src='captions_en.vtt' kind='captions' srcLang='en' label='english_captions' />
                Your browser doesnt support HTML5 video tag.
                </Video>
                <NameOfVideo>{custom_name}</NameOfVideo>
              </PaperStyled2>
            </Grid>
          )
        })}
      </Grid>
      {/*<PaperStyled>*/}
      {/*  <LinkStyled to={`${match.path}/protection`}>Protection of personal data</LinkStyled>*/}
      {/*</PaperStyled>*/}
      <FAQ>FAQ</FAQ>
      <Links>
        {faq.map((item, id) => (
          <FAQItem
            key={item.heading}
            heading={item.heading}
            text={item.text}
            id={id}
            path={match.path}
          />
        ))}
      </Links>
      <VideoModal
        open={videoModalOpen}
        handleClose={() => setVideoModalOpen(false)}
      />
    </Page>
  ) 
}

MainPageComponent.propTypes = {
  id: PropTypes.string.isRequired,
  logOut: PropTypes.func.isRequired,
  faq: PropTypes.arrayOf(PropTypes.object).isRequired,
  match: PropTypes.object.isRequired,
  onBackIconClick: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]).isRequired,
}

const enhance = connect(state => ({ id: state.auth.id }), { logOut })

export const MainPage = enhance(MainPageComponent)
