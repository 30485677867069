import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { Preloader } from '../../../../common/Preloader'
import { SessionComponent } from '../Session'
import { fetchConsultation } from '../../../../../redux/modules/consultations'

export const CurrentConsultation = ({ match, location, ...props }) => {
  const { id } = match.params
  const dispatch = useDispatch()
  const isFetching = useSelector(state => state.loading.consultation)
  const consultation = useSelector(state => state.consultations.consultation)

  useEffect(() => {
    dispatch(fetchConsultation(id, null))
  }, [id, dispatch])

  if (isFetching || !consultation.id) {
    return <Loader />
  }

  return <SessionComponent match={match} {...props} location={location} />
}

const Loader = styled(Preloader)`
  width: calc(100vw - 280px);
`
