import React from 'react'
import styled from 'styled-components'
import { Button, Paper } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import PeopleAltIcon from '@material-ui/icons/People'
import WatchLaterRoundedIcon from '@material-ui/icons/WatchLaterRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import { FormattedMessage } from 'react-intl'
import { history } from '../../../../../utils/history'
import { getStatus, confirmJoin } from '../../../../../helpers/Doctor/TeamHelper'

const PaperStyle = {
  marginTop: '15px',
  padding: '15px',
  boxShadow: 'rgba(6, 173, 255, 0.1) 0px 4px 8px 0px',
}

const StatusStyles = {
  textAlight: 'left',
}

const StatusHolder = styled.div` 
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 40px;
`

const StatusText = styled.div`
  font-size: 14px;
`
const ModerationIcon = styled(WatchLaterRoundedIcon)`
  font-size: 12px !important;
`

const RejectedIcon = styled(HighlightOffRoundedIcon)`
  font-size: 12px !important;
`

const JoinButton = styled(Button)`
  && {
    border-radius: 30px;
  }
`

const EqualHeightGrid = styled(Grid)`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const ItemComponent = ({ team, refreshMyTeams }) => {
  const teamData = team.attributes;
  const textStatus = getStatus(teamData.teamStatus);

  return (
    <Paper style={PaperStyle}>
      <Grid container>
        <Grid onClick={() => history.push(`/panel/community/roso/${teamData.team_id}`)} item xs={8}>
          <Name>{teamData.name}</Name>
          <Creator><FormattedMessage id='roso.creator' />: {teamData.creator}</Creator>
        </Grid>
        <Grid item xs={2}>
          <Members>
            <PeopleAltIcon /> <br />
            <FormattedMessage id='roso.members' /> {teamData.memberCount}
          </Members>
        </Grid>
        <EqualHeightGrid item xs={2} style={StatusStyles}>
          {textStatus !== 'verified' ? (
            <StatusHolder style={textStatus === 'moderated' ? { color: '#f5a623' } : { color: 'red' }}>
              {textStatus === 'moderated' ? <ModerationIcon /> : <RejectedIcon />}
              <StatusText><FormattedMessage id={`roso.status.${textStatus}`} /></StatusText>
            </StatusHolder>
          ) : ''}
          {!teamData.memberStatus
          && <JoinButton onClick={() => confirmJoin({ member_id: teamData.id }).then(() => refreshMyTeams())} color='primary' variant='contained'> Join </JoinButton>
          }
        </EqualHeightGrid>
      </Grid>
    </Paper>
  )
}

const Name = styled.div`
  opacity: 0.8;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  color: #21293e;
  cursor: pointer;
`

const Creator = styled.div`
  margin-top: 10px;
  color: #959fb8;
  font-size: 13px;
  font-weight: 100;
`
const Members = styled.div`
  margin-top: 10px;
  color: #959fb8;
  font-size: 13px;
  font-weight: 100;
`
