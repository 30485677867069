export const EDIT_OPTIONS = [
  {
    label: 'schedule.urgent',
    value: 'FreeUrgent',
  },
  {
    label: 'schedule.time.off',
    value: 'Available',
  },
  {
    label: 'schedule.my.time',
    value: 'Holiday',
  },
]

export const getExperienceOptions = () => {
  let i = 1970
  const options = []

  while (i < new Date().getFullYear()) {
    options.push({ label: i, value: i })
    i += 1
  }

  return options
}

export const CELL_HEIGHT = 40
