import React from 'react'
import moment from 'moment'
import 'moment/locale/fr'
import 'moment/locale/de'
// import 'moment/locale/ru'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Colors } from 'constants/colors'

export const MonthCalendarHeader = () => {
  const lang = useSelector(state => state.localization)
  moment.locale(lang)
  const days = moment.localeData().weekdays(true)

  return (
    <Wrapper>
      {days.map(day => <Day key={day}>{day}</Day>)}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
`

const Day = styled.div`
  width: 14.28%;
  padding: 12px;
  background-color: ${Colors.white};
  border-bottom: 1px solid ${Colors.divider};
  border-right: 1px solid ${Colors.divider};
  border-top: 1px solid ${Colors.divider};
  box-sizing: border-box;
  font-size: 14px;
  text-transform: capitalize;
  
  &:first-of-type {
    border-left: 1px solid ${Colors.divider};
  }
`
