import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { FormattedMessage } from 'react-intl';

export const CheckboxField = ({ input, meta, ...rest }) => (
  <FormControl error={meta.touched && meta.error}>
    <FormControlLabel
      control={(
        <Checkbox
          {...input}
          {...rest}
          // value prop have to be a string
          value={input.value ? 'true' : 'false'}
        />
      )}
      label={rest.label}
    />
    {meta.touched && meta.error && (
      <FormHelperText>
        <FormattedMessage id={meta.error} defaultMessage={meta.error} />
      </FormHelperText>
    )}
  </FormControl>
)
