import React from 'react'
import { Form, Field } from 'react-final-form'
import styled from 'styled-components'
import { mapProps } from 'recompose'
import { FormattedMessage, useIntl } from 'react-intl'
import Isemail from 'isemail'
import { Colors } from 'constants/colors'
import { Paper } from 'common/UiKit/Paper'
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import { TextField } from '../../../../../common/FinalFormFields/TextField';

const Line = styled.div`
  display: flex;
  align-items: ${({ start }) => (start ? 'flex-start' : 'center')};

  .location {
    flex-grow: 1;
  }

  .location-input {
    display: flex;
    justify-content: space-between;
    
    & > *:first-child {
      margin-right: 12px;
    }
  }
`

const Label = styled.div`
  width: 172px;
  min-width: 172px;
  margin-bottom: 24px;
  margin-right: 6px;
  opacity: 0.87;
  font-weight: bold;
  font-size: 16px;
  color: ${Colors.dark};
`

const LocationField = styled(Field)`
  && {
    width: 152px;
  }
`

const MethodWrapper = styled.div`
  margin-bottom: 12px;
  margin-top: -16px;
`

const StyledHelpIcon = styled(HelpIcon)`
  padding-bottom: 25px;
  padding-left: 15px;
`

const PricesBlock = styled.div`
  padding-left: 30px;
`

const COUNTRIES = [
  { label: 'France', value: 'France' },
]

const validate = (values) => {
  const errors = {}

  // eslint-disable-next-line no-restricted-globals
  if (!values.hospital_name) errors.hospital_name = <FormattedMessage id='error.hospital' />
  if (!values.street) errors.street = <FormattedMessage id='error.street' />
  if (!values.country) errors.country = <FormattedMessage id='error.country' />
  if (!values.city) errors.city = <FormattedMessage id='error.city' />
  if (!values.postcode) errors.postcode = <FormattedMessage id='error.postcode' />
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(values.postcode)) errors.postcode = <FormattedMessage id='error.number' />
  if (values.postcode && values.postcode.toString().length < 5) errors.postcode = <FormattedMessage id='error.postcode' />
  if (values.addition_email && !Isemail.validate(values.addition_email)) {
    errors.addition_email = <FormattedMessage id='error.email' />
  }
  if (!values.credentials_rpps) {
    errors.credentials_rpps = <FormattedMessage id='errors.required' />
  }

  if (values.credentials_rpps && values.credentials_rpps.length !== 11) {
    errors.credentials_rpps = <FormattedMessage id='error.rpps_11' />
  }

  if (!values.credentials_order_number) errors.credentials_order_number = <FormattedMessage id='errors.required' />

  if (values.credentials_order_number && values.credentials_order_number.length !== 9) {
    errors.credentials_order_number = <FormattedMessage id='error.field_9' />
  }

  return errors
}

const normalizeValues = values => ({
  addition_email: new Array(values.addition_email),
  work_place: {
    name: values.hospital_name,
    country: values.country,
    city: values.city,
    street: values.street,
    postcode: values.postcode,
  },
  credentials_rpps: values.credentials_rpps,
  credentials_order_number: values.credentials_order_number,
  requisites_private_bic: values.requisites_private_bic || '',
  requisites_private_iban: values.requisites_private_iban || '',
  requisites_hospital_bic: values.requisites_hospital_bic || '',
  requisites_hospital_iban: values.requisites_hospital_iban || '',
  daily_price: values.daily_price || '',
  weekend_price: values.weekend_price || '',
  urgent_price: values.urgent_price || '',
})

const normalizeInitialValues = values => ({
  addition_email: values.addition_email ? values.addition_email[0] : undefined,
  hospital_name: values.work_place.name,
  country: values.work_place.country,
  city: values.work_place.city,
  street: values.work_place.street,
  postcode: values.work_place.postcode,
  credentials_rpps: values.credentials_rpps,
  credentials_order_number: values.credentials_order_number,
  requisites_private_bic: values.requisites_private_bic || '',
  requisites_private_iban: values.requisites_private_iban || '',
  requisites_hospital_bic: values.requisites_hospital_bic || '',
  requisites_hospital_iban: values.requisites_hospital_iban || '',
  daily_price: values.daily_price || '',
  weekend_price: values.weekend_price || '',
  urgent_price: values.urgent_price || '',
})

const enhance = mapProps(props => ({
  ...props,
  onSubmit: values => props.onSubmit({ ...normalizeValues(values) })
    .then(() => props.afterSubmit()),
}))

export const AboutDoctorForm = enhance(({
  onSubmit,
  id,
  initialValues,
  disableShadow,
  methodField,
  employmentType,
}) => {
  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      subscription={{}}
      keepDirtyOnReinitialize
      initialValues={initialValues && normalizeInitialValues(initialValues)}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} id={id}>
          <Paper disableShadow={disableShadow}>
            <Line>
              <Label>
                <FormattedMessage id='profile.rpps' />
              </Label>
              <Field
                component={TextField}
                type='number'
                name='credentials_rpps'
                label={`*${f('profile.rpps')}`}
              />
            </Line>
            <Line>
              <Label>
                <FormattedMessage id='profile.number' />
              </Label>
              <Field
                type='number'
                component={TextField}
                name='credentials_order_number'
                label={`*${f('profile.number')}`}
              />
              <Tooltip title={f('profile.numberhint')} placement='top'>
                <StyledHelpIcon size='small' />
              </Tooltip>
            </Line>
            {
              methodField && (
              <Line>
                <Label>
                  <FormattedMessage id='profile.method' />
                </Label>
                <MethodWrapper>
                  {methodField}
                </MethodWrapper>
              </Line>
              )
            }
            <Line start={1}>
              <Label>
                <FormattedMessage id='profile.workplace' />
              </Label>
              <div className='location'>
                <Field
                  component={TextField}
                  name='hospital_name'
                  label={`*${f('profile.hospital.name')}`}
                />
                <Field
                  component={TextField}
                  name='street'
                  label={`*${f('profile.street')}`}
                />
                <div className='location-input'>
                  <LocationField
                    component={TextField}
                    name='postcode'
                    label={`*${f('profile.postcode')}`}
                    parse={value => value && (value[0] === '0' ? value : (+value || ''))}
                  />
                  <LocationField
                    component={TextField}
                    name='city'
                    label={`*${f('profile.city')}`}
                  />
                  <LocationField
                    component={TextField}
                    name='country'
                    label={`*${f('profile.country')}`}
                    options={COUNTRIES}
                  />
                </div>
              </div>
            </Line>
            <Line>
              <Label>
                <FormattedMessage id='profile.addition_email' />
              </Label>
              <Field
                component={TextField}
                name='addition_email'
                label={`${f('profile.addition_email')}`}
                type='email'
              />
            </Line>
            <Line>
              <Label>
                <FormattedMessage id='profile.tarifs' />:
              </Label>
            </Line>
            <PricesBlock>
              {
                (employmentType === 'private' || employmentType === 'merge') && (
                <Line>
                  <Label>
                    <FormattedMessage id='profile.requisites.liberal' />
                  </Label>
                  <div className='location'>
                    <div className='location-input'>
                      <Field
                        component={TextField}
                        name='requisites_private_bic'
                        label='BIC'
                      />
                      <Field
                        component={TextField}
                        name='requisites_private_iban'
                        label='IBAN'
                      />
                    </div>
                  </div>
                </Line>
                )
              }
              {
                (employmentType === 'hospital' || employmentType === 'merge') && (
                <Line>
                  <Label>
                    <FormattedMessage id='profile.requisites.salary' />
                  </Label>
                  <div className='location'>
                    <div className='location-input'>

                      <Field
                        component={TextField}
                        name='requisites_hospital_bic'
                        label='BIC'
                      />
                      <Field
                        component={TextField}
                        name='requisites_hospital_iban'
                        label='IBAN'
                      />
                    </div>
                  </div>
                </Line>
                )
              }
              <Line>
                <Label>
                  <FormattedMessage id='profile.urgent.price' />
                </Label>
                <Field
                  component={TextField}
                  name='daily_price'
                  label={`*${f('profile.urgent.price')}`}
                />
              </Line>
              <Line>
                <Label>
                  <FormattedMessage id='profile.weekend' />
                </Label>
                <Field
                  component={TextField}
                  name='weekend_price'
                  label={`*${f('profile.weekend')}`}
                />
              </Line>
              <Line>
                <Label>
                  <FormattedMessage id='profile.urgent' />
                </Label>
                <Field
                  component={TextField}
                  name='urgent_price'
                  label={`*${f('profile.urgent')}`}
                />
              </Line>
            </PricesBlock>
          </Paper>
        </form>
      )}
    />
  )
})
