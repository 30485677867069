/* eslint-disable jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */
import React from 'react'
import styled from 'styled-components'
import Chip from '@material-ui/core/Chip'
import { Typography } from '@material-ui/core'

import { Colors } from '../../../constants/colors'

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Input = styled.input`
  position: absolute;
  opacity: 0;
`

const ChipStyled = styled(props => (
  <Chip {...props} classes={{ colorSecondary: 'secondary' }} />
))`
  && {
    height: 36px;
    margin: 8px;
    padding: 0 10px;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.4px;
  }

  &&.secondary {
    background-color: ${Colors.paleGrey};
    color: ${Colors.blueGrey};
  }
`

export const CheckboxInput = ({
  id,
  onChange,
  options,
  value,
  className,
  error,
  touched,
}) => (
    <>
      <Wrapper className={className}>
        {
          options.map(option => (
            <span key={option.value}>
              <Input
                checked={value.includes(option.value)}
                id={id + option.value}
                onChange={e => onChange(
                  value.includes(e.target.value)
                    ? value.filter(item => item !== e.target.value)
                    : [...value, e.target.value],
                )}
                type='checkbox'
                value={option.value}
              />
              <label htmlFor={id + option.value}>
                <ChipStyled
                  clickable
                  color={value.includes(option.value) ? 'primary' : 'secondary'}
                  label={option.label}
                />
              </label>
            </span>
          ))
        }
      </Wrapper>
      {touched && error && <div><Typography color='error' variant='caption'>{error}</Typography></div>}
    </>
)
