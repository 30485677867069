import React from 'react'
import PropTypes from 'prop-types'
import { compose, mapProps } from 'recompose'
import { connect } from 'react-redux'
import { DiplomasForm } from 'common/DiplomasForm'
import { EditPage } from 'common/UiKit/EditPage'
import idx from 'idx'
import { createDiplomas, deleteDiploma } from '../../../../../redux/modules/auth'
import { history } from '../../../../../utils/history'
import { PROFILE_FILL_STEPS } from '../../../../../constants/ui'
import { externalFormSubmit } from '../../../../../helpers/externalFormSubmit'

const EditDiplomasComponent = ({
  diplomas,
  loading,
  createDiplomas,
  deleteDiploma,
}) => (
  <EditPage
    label='profile.diplomas'
    onBackIconClick={() => history.push('/panel/profile')}
    buttonText='global.save'
    onButtonClick={() => externalFormSubmit(PROFILE_FILL_STEPS[2])}
    loading={loading}
  >
    <DiplomasForm
      diplomas={diplomas}
      id={PROFILE_FILL_STEPS[2]}
      onSubmit={() => history.push('/panel/profile')}
      disableShadow
      createDiplomas={createDiplomas}
      deleteDiploma={deleteDiploma}
      acceptedExtensions='.jpg,.png,.pdf'
    />
  </EditPage>
)

EditDiplomasComponent.propTypes = {
  diplomas: PropTypes.arrayOf(PropTypes.object).isRequired,
  createDiplomas: PropTypes.func.isRequired,
  deleteDiploma: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

const enhance = compose(
  connect(
    state => ({
      diplomas: idx(state, _ => _.auth.relationships.diploma.data) || [],
      loading: state.loading.diplomas,
    }),
    { createDiplomas, deleteDiploma },
  ),
  mapProps(props => ({
    ...props,
    onDrop: values => props.createDiplomas({ file: values.diplomas }),
    diplomas: props.diplomas.map(item => (item.attributes
      ? {
        id: item.id,
        file: item.attributes.url,
        extension: idx(item, _ => _.attributes.extension),
      }
      : { id: '0', file: '' }
    )),
  })),
)

export const EditDiplomas = enhance(EditDiplomasComponent)
