import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { pure, mapProps, compose } from 'recompose'
import { Form, Field } from 'react-final-form'
import moment from 'moment'
import { FormattedMessage, useIntl } from 'react-intl'
import { TextField } from 'common/UiKit/TextField'
import { Paper } from 'common/UiKit/Paper'
import { AvatarUpload } from '../AvatarUpload'
import { CheckboxInput } from '../CheckboxInput'
import { Colors } from '../../../constants/colors'
import { LANGUAGES } from '../../../constants/languages'
// import { COUNTRIES as NATIVE_COUNTRIES } from '../../constants/countries'

const FirstLine = styled.div`
  display: flex;
  
  & > div:first-child {
    margin-right: 24px;
  }
`

const CheckboxLine = styled.div`
  margin: 0 0 24px;
`

const CheckboxLabel = styled.div`
  margin: 0 12px 8px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: ${Colors.blueGrey};
`

const CheckboxInputStyled = styled(CheckboxInput)`
  margin-left: -8px;
`

const Wrapper = styled(Paper)`
  padding: 24px;
  display: flex;
  
  & > div:last-child {
    flex: 1
  }
`

const GENDER_OPTIONS = ['male', 'woman']

const validate = (values, showInsurance) => {
  const errors = {}
  
  if (showInsurance) {
    if (!values.medical_insurance 
      || values.medical_insurance.toString().length !== 15 
      || /[^A-B0-9]/.test(values.medical_insurance.toString())) {
      errors.medical_insurance = <FormattedMessage id='error.patient.insurance_13' />
    }
  }
  if (!values.first_name) errors.first_name = <FormattedMessage id='error.first.name' />
  if (!values.last_name) errors.last_name = <FormattedMessage id='error.last.name' />
  if (!values.date_of_birth) errors.date_of_birth = <FormattedMessage id='error.birth.date' />
  // if (!values.photo) errors.photo = <FormattedMessage id='error.photo' />
  if (!values.gender || values.gender === 'unknown') errors.gender = <FormattedMessage id='error.gender' />
  // if (!values.country_id) errors.country_id = <FormattedMessage id='error.country' />
  return errors
}

const enhance = compose(
  pure,
  mapProps(props => ({
    ...props,
    onSubmit: values => props.onSubmit({ ...values }).then(() => props.afterSubmit()),
  })),
)

export const PersonalDataForm = enhance(({
  onSubmit,
  initialValues,
  showInsurance,
  id,
  disableShadow,
}) => {
  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })
  const [initialValuesWithoutUpdate, setInitialValuesWithoutUpdate] = useState({});

  useEffect(() => {
    setInitialValuesWithoutUpdate(initialValues)
  }, [])

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        ...initialValuesWithoutUpdate,
        date_of_birth: initialValuesWithoutUpdate.date_of_birth
          ? moment(initialValuesWithoutUpdate.date_of_birth).format('YYYY-MM-DD')
          : null,
      }}
      validate={values => validate(values, showInsurance)}
      subscription={{}}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} id={id}>
          <Wrapper disableShadow={disableShadow}>
            <Field name='photo' component={AvatarUpload} />
            <div>
              <FirstLine>
                <Field name='first_name' component={TextField} label={`*${f('profile.first.name')}`} />
                <Field name='last_name' component={TextField} label={`*${f('profile.last.name')}`} />
              </FirstLine>
              <Field
                name='date_of_birth'
                component={TextField}
                type='date'
                label={`*${f('profile.birth.date')}`}
                minDate={moment('1950', 'YYYY)')}
                maxDate={moment('2000', 'YYYY)')}
                parse={value => value && moment(value).format('YYYY-MM-DD')}
                invalidLabel=''
                styleError={{ marginTop: '-18px', marginBottom: '18px ' }}
              />
              {showInsurance && (
              <Field
                name='medical_insurance'
                component={TextField}
                label={`*${f('profile.insurance')}`}
                type='text'
              />
              )}
              <Field
                name='gender'
                component={TextField}
                label={`*${f('profile.gender')}`}
                options={GENDER_OPTIONS.map(value => ({ value, label: f(`profile.${value}`) }))}
              />
              {/*<Field*/}
              {/*  name='country_id'*/}
              {/*  component={TextField}*/}
              {/*  label={`*${f('profile.country')}`}*/}
              {/*  options={NATIVE_COUNTRIES}*/}
              {/*/>*/}
              {
                !!initialValues.language && (
                  <CheckboxLine>
                    <CheckboxLabel>


                      *
                      <FormattedMessage id='profile.languages' />
                    </CheckboxLabel>
                    <Field
                      component={CheckboxInputStyled}
                      name='language'
                      id='language'
                      options={LANGUAGES}
                    />
                  </CheckboxLine>
                )
              }
            </div>
          </Wrapper>
        </form>
      )}
    />
  )
})

PersonalDataForm.defaultProps = {
  disableShadow: false,
  showInsurance: false,
  initialValues: {},
}

PersonalDataForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    birth_date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    // country_id: PropTypes.string,
    gender: PropTypes.string,
  }),
  id: PropTypes.string.isRequired,
  disableShadow: PropTypes.bool,
  showInsurance: PropTypes.bool,
}
