import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import { FormattedMessage } from 'react-intl'

const Wrapper = styled.div`
  display: flex;
  border-radius: 4px;
  border: 1px solid ${Colors.clearBlue};
`

const Tab = styled.button`
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  flex-grow: ${({ tabs }) => 1 / tabs};
  color: ${({ checked }) => (checked ? Colors.white : Colors.clearBlue)};
  background-color: ${({ checked }) => (checked ? Colors.clearBlue : Colors.white)};
  border: 1px solid ${Colors.clearBlue};
  cursor: pointer;
  transition: all 0.4s;
`

export const TabsFilled = ({ tabs, checkedTab, onClick }) => (
  <Wrapper>
    {tabs.map((tab, id) => (
      <Tab
        tabs={tabs.length}
        checked={id === checkedTab}
        key={tab}
        value={id}
        onClick={onClick}
      >
        <FormattedMessage id={tab} defaultMessage={tab} />
      </Tab>
    ))}
  </Wrapper>
)

TabsFilled.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  checkedTab: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
}
