import { combineReducers } from 'redux'
import { handleActions, createAction } from 'redux-actions'
import moment from 'moment'
import { getDiffDays } from '../../../../helpers/calendar'
import { CONSULTATION_TYPES } from '../../../../constants/consultation'

//region Actions
export const FILTERS_ACTIONS = {
  changeFilterAction: 'CHANGE_FILTER_ACTION',
}

export const changeFilterAction = createAction(FILTERS_ACTIONS.changeFilterAction)
//#endregion

//#region Reducers
moment.locale('en', {
  week: {
    dow: 1,
  },
})

const initialValues = {
  date: {
    from: moment().startOf('week').format('YYYY-MM-DD'),
    to: moment().endOf('week').format('YYYY-MM-DD'),
  },
  calendarType: 'week',
  status: ['created', 'confirmed', 'completed', 'cancelled'],
  type: ['urgent', 'scheduled', CONSULTATION_TYPES.telexpertise],
}

const values = handleActions({
  [changeFilterAction]: (state, action) => ({
    ...state,
    [action.payload.filter]: action.payload.data,
  }),
}, initialValues)

export const calendarFiltersReducer = combineReducers({
  values,
})
//#endregion

//#region Thunks
export const changeFilter = (filter, data) => (dispatch, getStore) => {
  const { values } = getStore().calendar.filters
  if (filter === 'calendarType') {
    const newToDate = moment(values.date.from).add(getDiffDays(data), 'days').format('YYYY-MM-DD')
    dispatch(changeFilterAction({
      filter: 'date',
      data: {
        from: values.date.from,
        to: newToDate,
      },
    }))
  }
  dispatch(changeFilterAction({ filter, data }))
}
//#endregion
