import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import BeenhereIcon from '@material-ui/icons/Beenhere'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import { useDispatch, useSelector } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'
import { history } from '../../../../../../utils/history'
import { pay } from '../../../../../../redux/modules/consultations'

export const PaymentStatusDialog = ({ onClose, status }) => {
  const loading = useSelector(state => state.loading.payment)
  const id = useSelector(state => state.consultations.consultation.id)
  const doctorId = useSelector(state => state
    .consultations.consultation.relationships.doctor.data.id)
  const dispatch = useDispatch()

  const onRetryClick = () => dispatch(pay(id))

  return (
    <Dialog open onClose={onClose}>
      {loading && (
        <SpinnerWrapper>
          <CircularProgress />
        </SpinnerWrapper>
      )}
      <DialogTitle>
        <FormattedMessage id='payments.status.title' />
      </DialogTitle>
      <DialogContent>
        {
          status
            ? (
              <SuccessContent>
                <BeenhereIcon />
                <FormattedMessage id='payments.success' />
              </SuccessContent>
            )
            : (
              <ErrorContent>
                <HighlightOffIcon />
                <FormattedMessage id='payments.error' />
              </ErrorContent>
            )
        }
      </DialogContent>
      <DialogActions>
        <Button color='primary' size='small' onClick={onClose}>
          <FormattedMessage id='payments.close' />
        </Button>
        {
          status
            ? (
              <Button
                color='primary'
                size='small'
                onClick={() => {
                  history.push(`/panel/consultations/session/menu/report/${doctorId}`)
                  onClose()
                }}
              >
                <FormattedMessage id='payments.see.report' />
              </Button>
            )
            : (
              <Button color='primary' size='small' onClick={onRetryClick}>
                <FormattedMessage id='payments.retry' />
              </Button>
            )
        }
      </DialogActions>
    </Dialog>
  )
}

PaymentStatusDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  status: PropTypes.bool.isRequired,
}

//#region SC
const SuccessContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 500;
  color: ${Colors.tealish};
  
  svg {
    margin-right: 12px;
    width: 32px;
    height: 32px;
  }
`

const ErrorContent = styled(SuccessContent)`
  color: ${Colors.dark70};
  text-transform: none;
  font-size: 16px;

  svg {
    fill: ${Colors.red};
    width: 44px;
    height: 44px;
  }
`

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`
//#endregion
