import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import idx from 'idx'
import { Waypoint } from 'react-waypoint'
import { FormattedMessage } from 'react-intl'

import moment from 'moment';
import { socket } from '../../../services/socket'
import { InputMessage } from './InputMessage'
import { Preloader } from '../../common/Preloader'
import { Message } from './Message'
import { uploadFile, clearNotificationsAction } from '../../../redux/modules/chats'
import { CONSULTATION_TYPES } from '../../../constants/consultation'

export const Chat = ({ onAvatarClick, isAdminChat }) => {
  const dispatch = useDispatch()
  const [fileLoadingProgress, setLoadingProgress] = useState(0)
  const bottomRef = useRef(null)
  const doctorId = useSelector(state => idx(state, _ => _
    .consultations.consultation.relationships.doctor.data.attributes.user_id))

  const consultation = useSelector(state => state.consultations.consultation)
  const chat = useSelector(state => state.chats[isAdminChat ? state.auth.id : consultation.id])
  const myPhoto = useSelector(state => state.auth.attributes.photo)
  const isDoctor = useSelector(state => state.auth.type === 'Doctor')
  const isConcilium = consultation && consultation.attributes
    ? consultation.attributes.type === CONSULTATION_TYPES.telexpertise
    : false

  const additionalDoctor = useSelector(state => idx(state, _ => _
    .consultations.consultation.relationships.additionalMember.data[0]))
  const userType = (!isDoctor || isConcilium) ? 'doctor' : 'patient'
  const userPhoto = useSelector(state => idx(state, _ => (isAdminChat ? '' : _.consultations.consultation
    .relationships[userType].data.attributes.photo)))

  const doctorPhoto = useSelector(state => idx(state, _ => _.consultations.consultation
    .relationships.doctor.data.attributes.photo))

  const messages = [...((chat && chat.lastMessages) ? chat.lastMessages : [])].reverse()
  const userId = localStorage.getItem('userId') || 0
  const lastMessageId = idx(messages, _ => _[messages.length - 1].id)
  const isPaymentCompleted = !!idx(consultation, _ => _.attributes.invoice.paid)
  const price = idx(consultation, _ => _.attributes.invoice.price)
  const invoice = idx(consultation, _ => _.attributes.invoice.invoicePDF)
  const status = idx(consultation, _ => _.attributes.status)
  const proposedDate = idx(consultation, _ => _.attributes.proposed_date);
  const m = moment.parseZone(proposedDate);
  const proposedDatePlus10 = moment(m).subtract(10, 'minutes');

  const scrollToBottom = () => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  useEffect(() => {
    dispatch(clearNotificationsAction());
    setTimeout(() => scrollToBottom(), 1000)
  }, [])

  useEffect(() => {
    scrollToBottom()
  }, [lastMessageId])

  useEffect(() => {
    if (!chat || !chat.chat.id) {
      setTimeout(() => socket.connectChat(consultation.id), 1000)
    }
  }, [chat, consultation])

  if (!chat) {
    return <StyledPreloader />
  }

  return (
    <Wrapper>
      <Content>
        <Waypoint
          onEnter={(options) => {
            if (options.previousPosition) {
              socket.loadHistory(chat.chat.id, messages[0].id)
            }
          }}
        />
        {
          messages.map((item, index) => {
            const my = item.attributes.sender === +userId
            let photo = my ? myPhoto : userPhoto
            const isAdditionalDoctor = additionalDoctor
              && item.attributes.sender === idx(additionalDoctor, _ => _.attributes.doctor.id)

            if (isAdditionalDoctor) {
              photo = idx(additionalDoctor, _ => _.attributes.doctor.photo)
            }

            if (item.attributes.sender === doctorId) {
              photo = doctorPhoto
            }

            return (
              <Message
                time={item.attributes.date}
                type={item.attributes.type}
                chatId={consultation.id}
                //chatIdReal={item.attributes.chat_id}
                messageId={item.id}
                key={item.id ? item.id : idx}
                message={item.attributes.message}
                onAvatarClick={(my || isConcilium) ? undefined : onAvatarClick}
                scrollToBottom={(index === messages.length - 1) ? scrollToBottom : undefined}
                photo={photo}
                my={my}
                isPaymentCompleted={isPaymentCompleted}
                price={price}
                invoice={invoice}
                isDoctor={isDoctor}
                isAdditionalDoctor={isAdditionalDoctor}
                isConcilium={isConcilium}
              />
            )
          })
        }
        <span ref={bottomRef} />
      </Content>
      { (moment().utc(true).isAfter(proposedDatePlus10) || isDoctor) ? (
        <InputMessage
          disabled={false}
          pushMessage={(message) => {
            if (!message.trim().length) return
            socket.sendTextMessage(chat.chat.id, message)
          }}
          onFileSubmit={data => dispatch(uploadFile(data, chat.chat.id, setLoadingProgress))}
          fileLoadingProgress={fileLoadingProgress}
        />
      ) : (<>
        {status !== 'completed' 
        && status !== 'cancelled' 
          ? (
            <InfoBar>
              <FormattedMessage id='consultation.cantwriteuntilconfirm' />
            </InfoBar>)
          : ''}
        </>
      )}
    </Wrapper>
  )
}

Chat.defaultProps = {
  onAvatarClick: undefined,
  isAdminChat: undefined,
}

Chat.propTypes = {
  onAvatarClick: PropTypes.func,
  isAdminChat: PropTypes.bool,
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  max-height: 100%;
`
const Content = styled.div`
  padding: 36px 27px 0px;
  font-family: Roboto, sans-serif;
  flex-grow: 1;
  overflow: auto;

  & > * {
    margin-bottom: 16px;
  }
`

const InfoBar = styled.div`
  text-align: center;
  padding: 20px;
  background-color: #eee;
`

const StyledPreloader = styled(Preloader)`
  height: 100%;
  width: calc(100vw - 285px);
`
