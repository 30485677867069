import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Colors } from 'constants/colors'
import { useIntl } from 'react-intl'
import { MenuButton } from '../MenuButton'

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 280px;
  min-width: 280px;
  height: 100%;
  padding: 28px 16px;
  background-color: ${Colors.navyBlue};
  display: flex;
  flex-direction: column;
  overflow: auto;
`

export const Menu = ({
  children,
  menuButtons,
}) => {
  const { formatMessage } = useIntl()
  return (
    <Wrapper>
      {children}
      {menuButtons.map(button => (
        <MenuButton
          fullWidth
          icon={<button.icon />}
          key={button.label}
          label={formatMessage({ id: button.label })}
          path={button.path}
        />
      ))}
    </Wrapper>
  )
}

Menu.defaultProps = {
  children: null,
}

Menu.propTypes = {
  children: PropTypes.node,
  menuButtons: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  })).isRequired,
}
