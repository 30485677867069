import { createMuiTheme } from '@material-ui/core/styles'
import { Colors } from 'constants/colors'

export const theme = createMuiTheme({
  // overrides: {
  //   MuiFab: {
  //     extended: {
  //       height: '40px',
  //     },
  //   },
  // },
  palette: {
    primary: {
      main: Colors.clearBlue,
      contrastText: Colors.white,
      dark: Colors.clearBlue,
    },
    secondary: {
      main: Colors.tealish,
      contrastText: Colors.white,
    },
    error: {
      main: Colors.red,
    },
    action: {
      disabledBackground: Colors.cloudyBlue,
    },
  },
  typography: {
    button: {
      fontSize: 16,
      fontWeight: 'bold',
      letterSpacing: 0.8,
    },
    useNextVariants: true,
  },
});
