import React from 'react'
//import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Colors } from 'constants/colors'
import { TextButton } from '../TextButton'

const Wrapper = styled.div`
  padding: 36px 0;
  background-color: ${Colors.white};
  position: relative;
`

const Button = styled(TextButton)`
  && {
    height: 44px;
    width: 280px;
    position: absolute;
    bottom: -22px;
    left: calc(100%/2 - 280px/2);
  }
`

export const Panel = ({
  button,
  buttonProps = {},
  children,
  className,
}) => (
  <Wrapper className={className}>
    {children}
    {
      !!button
      && (
      <Button color='primary' variant='extended' {...buttonProps}>
        {button}
      </Button>
      )
    }
  </Wrapper>
)

// Panel.propTypes = {
//   button: PropTypes.string,
//   buttonProps: PropTypes.object,
//   children: PropTypes.node,
//   className: PropTypes.string,
// }
