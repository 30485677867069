import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { compose, withProps, lifecycle } from 'recompose'
import styled from 'styled-components'
import idx from 'idx'
import get from 'lodash/get'
import { connect, useDispatch, useSelector } from 'react-redux';
import BookmarkIcon from '@material-ui/icons/Bookmark'
import Pagination from '@material-ui/lab/Pagination';
import { DoctorCard } from '../../../common/DoctorCard'

import { EmptyScreen } from './EmptyScreen'
import {
  fetchDoctors,
  fetchDoctor,
  toggleFavorite,
} from '../../../../redux/modules/doctors'
import { inviteDoctor } from '../../../../redux/modules/consultations'
import { Page } from '../../../common/Page'
import { history } from '../../../../utils/history'
import { getDoctorPrice } from '../../../../helpers/consultations'
import { UrgentToggle } from '../../Doctor/Concilium/UrgentToggle'
import { TeamFilter } from './TeamFilter'
import { Search } from '../../../common/Search';
import { searchDoctors } from '../../../../redux/modules/settings';
import { Colors } from '../../../../constants/colors';

const Content = styled.div`
  width: 720px;
  margin: 0 auto;

  & > * {
    margin-bottom: 24px;
  }
  
`

const TopAnkor = styled.div`
  position: absolute;
  top: 0;
`

const StyledPagination = styled(Pagination)`
  & > ul {
    align-items: center;
    justify-content: center;
  }
`

const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding: 0 10px;
  
  & > div {
    margin-left: auto;
  }
  
  & > div:nth-of-type(1) {
    margin: 0 32px 0 0;
    border: 1px solid ${Colors.grey};
    height: 48px;
    flex-grow: 1;
  }
  
  & > div:nth-of-type(2) > div {
    margin-bottom: 0;
  }
  
  & > label {
    width: 25%;
    margin: 0;
  }
`

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const ListComponent = ({
  onBackIconClick,
  onButtonClick,
  onIconClick,
  onAvatarClick,
  doctors,
  favoriteToggle,
  toggleFavorite,
  title,
  emptySubtitle,
  emptyTitle,
  isFetching,
  match,
  isDoctor,
  withUrgentToggle,
  onToggleUrgent,
  onlyUrgent,
  //onFilterChange,
  fetchDoctors,
  paginator,
}) => {
  const [checked, toggle] = useState(false)
  const [team, onTeamChange] = useState(null)
  const [lazyFetching, setLazyFetching] = useState(false)
  const [page, setPage] = useState(1)
  const search = useSelector(state => state.settings.doctorSearch)
  const dispatch = useDispatch()
  const filter = { name: search, team, page };
  const isFirstRun = useRef(true)
  const prevPage = usePrevious(page)
  const ankor = useRef(null)
  const executeScroll = () => ankor.current.scrollIntoView(true)   


  useEffect(() => () => dispatch(searchDoctors('')), []);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    } 
    const notPageChanged = prevPage === page;
    setLazyFetching(!!notPageChanged, 
      fetchDoctors(+match.params.doctorType, false, { 
        ...filter,
        ...(checked ? { immediate: 1 } : {}),
        ...(notPageChanged ? { page: 1 } : {}), 
      }))
  }, [team, search, page])

  return (
    <Page
      label={title || 'global.doctors'}
      onBackIconClick={onBackIconClick}
      Icon={BookmarkIcon}
      onIconClick={onIconClick}
      isFetching={isFetching && !lazyFetching}
      fullWidth
    >
      <TopAnkor ref={ankor} />
      <FiltersWrapper>
        {withUrgentToggle && (
          <UrgentToggle
            checked={checked}
            onChange={(e) => {
              toggle(e.target.checked)
              onToggleUrgent(e.target.checked)
            }}
          />
        )}
        <Search onSearch={v => dispatch(searchDoctors(v))} />
        {
          onlyUrgent && (
            <UrgentToggle
              checked
              disabled
            />
          )
        }
        <TeamFilter
          value={team || ''}
          specialization={get(match, 'params.doctorType', 0)}
          onChange={e => onTeamChange(e.target.value)}
        />
      </FiltersWrapper>
      {
        doctors.length
          ? (
            <Content>
              {doctors.map(({ attributes, id, relationships }) => (
                <DoctorCard
                  key={id}
                  onButtonClick={onButtonClick(id, false)}
                  onAvatarClick={onAvatarClick(id)}
                  firstName={attributes.first_name}
                  lastName={attributes.last_name}
                  experience={attributes.experience}
                  specialization={attributes.specialization}
                  photo={attributes.photo}
                  language={attributes.language}
                  location={idx(relationships, _ => _.workPlace.data.slice(-1)[0].attributes)}
                  available={idx(attributes, _ => _.calendar_nearest.TC)}
                  isFavorite={attributes.favorite}
                  isFavoriteFetching={favoriteToggle === id}
                  toggleFavorite={value => toggleFavorite(id, value)}
                  buttonText={isDoctor ? 'concilium.select' : undefined}
                  teams={
                      team 
                        ? attributes.simpleTeams.sort(el => (el && el.id === Number(team) ? -1 : 1))
                        : attributes.simpleTeams}
                  onTeamChange={onTeamChange}
                  isDoctor={isDoctor}
                  calendarNearestTE={get(attributes, 'calendar_nearest.TE', false)}

                  /* TODO: add attributes to doctor in API */
                  price={getDoctorPrice(attributes, match.params.consultationType)}
                />
              ))}
            </Content>
          )
          : <EmptyScreen subtitle={emptySubtitle} title={emptyTitle} />
      }
      <StyledPagination 
        onChange={(e, page) => {
          setPage(page)
          executeScroll()
        }}
        color='primary'
        page={get(paginator, 'currentPage', 1)}
        count={get(paginator, 'totalPage', 0)}
      />
    </Page>
  )
}

ListComponent.defaultProps = {
  onIconClick: null,
  title: '',
  emptySubtitle: '',
  emptyTitle: '',
}

ListComponent.propTypes = {
  doctors: PropTypes.arrayOf(PropTypes.object).isRequired,
  onBackIconClick: PropTypes.func.isRequired,
  onIconClick: PropTypes.func,
  onButtonClick: PropTypes.func.isRequired,
  onAvatarClick: PropTypes.func.isRequired,
  favoriteToggle: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
  toggleFavorite: PropTypes.func.isRequired,
  title: PropTypes.string,
  emptySubtitle: PropTypes.string,
  emptyTitle: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  isDoctor: PropTypes.bool.isRequired,
}

const enhance = compose(
  connect(
    (state, props) => ({
      paginator: state.doctors.paginator,
      doctors: props.withUrgentToggle
        ? state.doctors.list.filter(doctor => doctor.id !== state.auth.id)
        : state.doctors.list,
      favoriteToggle: state.loading.favoriteToggle,
      isFetching: state.loading.doctors,
      isDoctor: state.auth.type === 'Doctor',
    }),
    {
      fetchDoctors,
      fetchDoctor,
      toggleFavorite,
      inviteDoctor,
    },
  ),
  withProps(props => ({
    onBackIconClick: () => {
      let url = !props.isDoctor ? '/panel/doctors' : '/panel/concilium/specializations'
      if (props.match.params.consultationId) {
        url = `/panel/concilium/${props.match.params.consultationId}/specializations`
      }
      return history.push(url)
    },
    onIconClick: () => {
      const doctorUrl = props.match.params.consultationId
        ? `/panel/concilium/${props.match.params.consultationId}/favorite`
        : '/panel/concilium/favorite'
      history.push(props.isDoctor ? doctorUrl : '/panel/doctors/favorite')
    },
    onButtonClick: (id, isUrgent) => (e, proposedDate) => {
      const url = !props.isDoctor
        ? `/panel/doctors/${props.match.params.consultationType}/create${proposedDate ? `?proposedDate=${proposedDate}` : ''}`
        : `/panel/concilium/create/${id}${isUrgent ? '?urgent=1' : ''}${proposedDate ? `${isUrgent ? '&' : '?'}proposedDate=${proposedDate}` : ''}`
      e.stopPropagation()

      if (props.isDoctor) {
        if (props.match.params.consultationId) {
          props.inviteDoctor(id, props.match.params.consultationId)
          return history.push('/panel/consultations/session')
        }

        return history.push(url)
      }

      return props.fetchDoctor(id)
        .then(() => history.push(url))
    },
    onAvatarClick: () => () => {},
    // onAvatarClick: id => () => {
    //   const link = props.isDoctor
    //     ? '/panel/concilium/doctor/profile'
    //     : `/panel/doctors/${props.match.params.consultationType}/profile`
    //   props.fetchDoctor(id)
    //     .then(() => history.push(link))
    // },
    onToggleUrgent: (value) => {
      const isAdditional = !!props.match.params.consultationId
      props.fetchDoctors(+props.match.params.doctorType,
        isAdditional, value ? { immediate: 1 } : {})
    },
    // onFilterChange: (params) => {
    //   const type = props.match.params.consultationType
    //   const isAdditional = !!props.match.params.consultationId
    //   props.fetchDoctors(+props.match.params.doctorType,
    //     isAdditional, { ...params, ...(type === 'urgent' ? { immediate: 1 } : {}) })
    //   
    // },
  })),
  lifecycle({
    componentDidMount() {
      const type = this.props.match.params.consultationType
      const isAdditional = !!this.props.match.params.consultationId
      this.props.fetchDoctors(+this.props.match.params.doctorType, isAdditional, 
        { random: 1, ...(type === 'urgent' ? { immediate: 1 } : {}) })
    },
  }),
)

export const List = enhance(ListComponent)
