import React, { useState } from 'react';
import { Form, Field } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'
import get from 'lodash/get'
import { TextField } from '../../../../../../common/FinalFormFields'
import { sendInvite } from '../../../../../../../helpers/Doctor/TeamHelper'


export const TeamInviteForm = ({
  teamId, id, refreshTeam, members, 
}) => {
  const [error, setError] = useState(null)
  const existedEmails = members.map(member => get(member, 'relationships.doctor.data.attributes.email', '').replace(/\s/g, ''));
  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })

  const submit = values => values && values.email && sendInvite({ ...values, team_id: teamId })
    .then(() => refreshTeam())
    .catch(() => setError(<FormattedMessage id='roso.memberNotFound' />))

  const validate = (values) => {
    const errors = {}
    if (existedEmails.includes(values.email)) {
      errors.email = f('error.email.existteam');
    }
    return errors;
  }

  return (
    <Form
      validate={validate}
      onSubmit={submit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} id={id}>
          <Field
            name='email'
            component={TextField}
            label='Email'
            autoFocus
            error={!!error}
            helperText={error}
          />
        </form>
      )}
    />
  )
}
