import React from 'react'
import PropTypes from 'prop-types'
import { compose, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import idx from 'idx'
import { useIntl } from 'react-intl'

import { Page } from '../../../../common/Page'

import { fetchDoctorReviews } from '../../../../../redux/modules/reviews'
import { Review } from './Review'
import { EmptyScreen } from '../EmptyScreen'

export const ReviewsComponent = ({ reviews, history, isFetching }) => {
  const { formatMessage } = useIntl()
  return (
    <Page
      label='consultation.reviews'
      onBackIconClick={() => history.goBack()}
      isFetching={isFetching}
    >
      {
        reviews.length
          ? reviews.map(item => (
            <Review
              photo={idx(item, _ => _.relationships.patient.data.attributes.photo)}
              firstName={idx(item, _ => _.relationships.patient.data.attributes.first_name)}
              lastName={idx(item, _ => _.relationships.patient.data.attributes.last_name)}
              text={item.attributes.text}
              date={item.attributes.date_create}
              rating={item.attributes.rating}
              key={item.id}
            />
          ))
          : (
            <EmptyScreen
              title='consultation.no.reviews'
              subtitle={formatMessage({ id: 'consultation.no.reviews.text' })}
            />)
      }
    </Page>
  )
}

ReviewsComponent.defaultProps = {
  reviews: [],
}

ReviewsComponent.propTypes = {
  reviews: PropTypes.array,
  isFetching: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
}

const enhance = compose(
  connect(
    (state, ownProps) => ({
      id: state.auth.id,
      reviews: state.reviews[ownProps.match.params.doctorId || state.auth.id],
      isFetching: state.loading.reviews.includes(ownProps.match.params.doctorId),
    }),
    { fetchDoctorReviews },
  ),
  lifecycle({
    componentDidMount() {
      if (!this.props.reviews) {
        this.props.fetchDoctorReviews(this.props.match.params.doctorId || this.props.id)
      }
    },
  }),
)

export const Reviews = enhance(ReviewsComponent)
