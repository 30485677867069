import { createAction, handleActions } from 'redux-actions'
import { combineReducers } from 'redux'
import axios from 'axios'
import idx from 'idx'
import { handleMessageAction } from '../chats'

//#region Actions
export const fetchNotificationRequest = createAction('FETCH_NOTIFICATION_REQUEST')
export const fetchNotificationSuccess = createAction('FETCH_NOTIFICATION_SUCCESS')
export const fetchNotificationFailure = createAction('FETCH_NOTIFICATION_FAILURE')

export const fetchNotificationCountRequest = createAction('FETCH_NOTIFICATION_COUNT_REQUEST')
export const fetchNotificationCountSuccess = createAction('FETCH_NOTIFICATION_COUNT_SUCCESS')
export const fetchNotificationCountFailure = createAction('FETCH_NOTIFICATION_COUNT_FAILURE')

export const readNotificationRequest = createAction('READ_NOTIFICATION_REQUEST')
export const readNotificationSuccess = createAction('READ_NOTIFICATION_SUCCESS')
export const readNotificationFailure = createAction('READ_NOTIFICATION_FAILURE')
//#endregion

//#region Reducer
const list = handleActions({
  [fetchNotificationSuccess]: (state, action) => action.payload,
  [handleMessageAction]: (state, action) => {
    if (action.payload.route === 'User\\Notification' && action.payload.action === 'send') {
      return [
        { id: action.payload.id, attributes: action.payload.attributes },
        ...(state && Array.isArray(state) ? state : []),
      ]
    }

    return state
  },
}, [])

const count = handleActions({
  [fetchNotificationCountSuccess]: (state, action) => action.payload,
  [readNotificationSuccess]: state => Math.max(state - 1, 0),
  [handleMessageAction]: (state, action) => {
    if (action.payload.route === 'User\\Notification' && action.payload.action === 'send') {
      return state + 1
    }

    return state
  },
}, 0)

export const notificationReducer = combineReducers({
  list,
  count,
})

//#endregion

//#region Thunks
export const fetchNotification = (page = 1) => (dispatch) => {
  dispatch(fetchNotificationRequest())
  return axios(`/general/user/notification?page=${page}`)
    .then(res => dispatch(fetchNotificationSuccess(res.data)))
    .catch((err) => {
      dispatch(fetchNotificationFailure(err))
    })
}

export const fetchNotificationCount = () => (dispatch) => {
  dispatch(fetchNotificationCountRequest())

  return axios('/general/user/notification/count')
    .then(res => dispatch(fetchNotificationCountSuccess(idx(res, _ => _
      .data.data.attributes.count))))
    .catch((err) => {
      dispatch(fetchNotificationCountFailure(err))
    })
}
export const readNotification = ids => (dispatch) => {
  dispatch(readNotificationRequest())

  return axios('/general/user/notification/read', { method: 'POST', data: { ids } })
    .then(() => dispatch(readNotificationSuccess()))
    .catch((err) => {
      dispatch(readNotificationFailure(err))
    })
}
//#endregion
