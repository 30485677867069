import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import CancelIcon from '@material-ui/icons/Cancel'

export const DeleteOverlay = ({
  disabled,
  empty,
  show,
  onClick,
}) => (
  <Wrapper disabled={disabled} empty={empty} show={show} onClick={onClick}>
    <CancelIcon />
  </Wrapper>
)

DeleteOverlay.defaultProps = {
  onClick: () => {},
}

DeleteOverlay.propTypes = {
  disabled: PropTypes.bool.isRequired,
  empty: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
}

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${Colors.white15};
  display: ${props => (props.show ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
  
  svg {
    width: 42px;
    height: 42px;
    fill: ${Colors.red};
    opacity: .6;
  }
  
  ${props => props.disabled && `
    background-color: ${Colors.grey};
    opacity: .2;
    cursor: not-allowed;
    
    svg {
      display: none;
    }
  `}
  
  ${props => props.empty && `
    svg {
      display: none;
    }
  `}
`
