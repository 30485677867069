import get from 'lodash/get';

const chatRoutes = {
  auth: 'User\\Auth',
  chat: 'Chat\\Message',
  call: 'Consultation\\Call',
  keepAllive: 'Core\\Connection',
}

class ChatSocket {
  constructor() {
    this.token = null
    this.socket = {}
    this.reconnectAttempts = 0
    this.handleMessage = undefined

    this.connecting = false
    this.authorizedValue = false
    this.timeToSocketReconnect = false
    this.connect = this.connect.bind(this)
    this.onMessage = this.onMessage.bind(this)
    this.reconnect = this.reconnect.bind(this)

    this.queue = []
    //window.socket = this
  }

  get authorized() {
    return this.authorizedValue
  }

  set authorized(value) {
    this.authorizedValue = value
    if (value) {
      this.handleQueue()
    }
  }

  initialize(token, handleMessage) {
    this.token = token
    this.handleMessage = handleMessage

    if (!this.connecting && get(this, 'socket.readyState', false) !== 1) {
      this.connect()
    }
  }

  handleQueue() {
    this.queue.forEach(callback => callback())
    this.queue = []
  }

  reconnect() {
    console.log('socket reconnecting...');
    if (this.reconnectAttempts < 3) {
      this.reconnectAttempts += 1
      this.timeToSocketReconnect = 3000
      const event = new CustomEvent('socketClosed', { detail: this.timeToSocketReconnect / 1000 });
      document.dispatchEvent(event);
      clearTimeout(this.timerReconnect);
      this.timerReconnect = setTimeout(() => {
        this.connecting = false
        this.connect()
      }, 3000)
    }
  }

  connect(callback) {
    if (!this.connecting && get(this, 'socket.readyState', false) !== 1) {
      try {
        this.connecting = true
        this.socket = new WebSocket(process.env.REACT_APP_SOCKET_URL)
        this.socket.onopen = () => {
          const eventConnected = new CustomEvent('socketConnected');
          document.dispatchEvent(eventConnected);
          this.timeToSocketReconnect = false;
          console.log('Socket connected!');
          this.socket.onerror = this.reconnect
          this.onclose = this.reconnect
          this.socket.onmessage = this.onMessage
          this.reconnectAttempts = 0
          this.authorize(this.token)

          if (typeof callback !== 'undefined' && callback instanceof Function) {
            callback()
          }

          this.handleQueue()
        }
      } catch (err) {
        console.error('Socket error -', err);
        this.reconnect()
      } finally {
        this.connecting = false
      }
    } else if (typeof callback !== 'undefined' && callback instanceof Function) {
      this.queue = [...this.queue, callback]
    }
  }

  detectBrokenConnection() {
    clearTimeout(this.keepAlliveTimeout)
    this.keepAlliveTimeout = setTimeout(() => {
      this.socket.close();
      this.reconnect();
    }, 40000)
  }

  onMessage(message) {
    this.detectBrokenConnection();
    const data = JSON.parse(message.data)
    if (this.handleMessage) {
      this.handleMessage(data)
    }

    if (data && data.attributes && data.attributes.auth) {
      this.authorized = true
    }
  }

  sendMessage(route, action, data, unauthorized) {
    if (this.socket.send && get(this, 'socket.readyState', false) === 1 && (this.authorized || unauthorized)) {
      this.socket.send(JSON.stringify(({ route, action, data })))
    } else {
      this.connect(() => this.sendMessage(route, action, data))
    }
  }

  authorize(token = this.token) {
    this.sendMessage(chatRoutes.auth, 'authorization', { token }, true)
  }

  keepAllive() {
    this.sendMessage(chatRoutes.keepAllive, 'healthlife', 'healthlife', true)
  }

  connectChat(id, isAdminChat) {
    this.sendMessage(chatRoutes.chat, 'chatConnect', {
      type: isAdminChat ? 'support' : 'consultation',
      id,
    })
  }

  sendTextMessage(chatId, message) {
    this.sendMessage(chatRoutes.chat, 'send', {
      chat_id: chatId,
      type: 'Text',
      message,
    })
  }

  sendFileMessage(chatId, fileId) {
    this.sendMessage(chatRoutes.chat, 'send', {
      chat_id: chatId,
      type: 'File',
      message: {
        attachment_id: fileId,
      },
    })
  }

  loadHistory(chatId, last) {
    this.sendMessage(chatRoutes.chat, 'loadMessage', {
      chat_id: chatId,
      last,
    })
  }

  notify(chatId) {
    this.sendMessage(chatRoutes.chat, 'newMessage', {
      chat_id: chatId,
    })
  }

  call(consultation) {
    this.sendMessage(chatRoutes.call, 'call', {
      consultation,
    })
  }

  cancelCall(consultation, message) {
    this.sendMessage(chatRoutes.call, 'cancel', {
      consultation,
      message: message || ' ',
    })
  }
}

export const socket = new ChatSocket()
