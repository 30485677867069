import React, { useEffect } from 'react';
import { Form, Field } from 'react-final-form'
import styled from 'styled-components'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Isemail from 'isemail'
import { FormattedMessage, useIntl } from 'react-intl'
import { TextField } from 'common/UiKit/TextField'
import { Colors } from 'constants/colors'
import { AuthPanel } from '../AuthPanel'

const Panel = styled(AuthPanel)`
  text-align: right;
  
  a {
    width: 140px;
  }
`

const CheckboxWrapper = styled.div`
  text-align: left;
`

const CheckboxLabel = styled.span`
  &, & a {
    font-size: 13px;
    line-height: 1.31;
  }
  cursor: default;
  color: ${Colors.textMuted};
  
  a {
    cursor: pointer;
  }
`

const validate = (values) => {
  const errors = {}

  if (!values.email) {
    errors.email = <FormattedMessage id='error.email.required' />
  } else if (!Isemail.validate(values.email)) {
    errors.email = <FormattedMessage id='error.email' />
  }

  if (!values.password) {
    errors.password = <FormattedMessage id='error.password.required' />
  } else if (values.password.length < 6) {
    errors.password = <FormattedMessage id='error.password.length' />
  }

  return errors
}

export const AuthForm = ({
  children,
  onSubmit,
  loading,
  registration,
  togglePolicy,
  toggleForm,
  initialValues,
  role,
}) => {
  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })

  const [state, setState] = React.useState({
    check1: false,
    check2: false,
  })

  const handleChange = name => (event) => {
    setState({ ...state, [name]: event.target.checked })
  }

  useEffect(() => {

  }, [])

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({ handleSubmit, hasValidationErrors }) => (
        <form onSubmit={handleSubmit}>
          <Panel
            buttonProps={{ type: 'submit', isFetching: loading }}
            toggleForm={toggleForm}
            registration={registration}
            disabled={registration
              ? (hasValidationErrors || !state.check1 || !state.check2)
              : hasValidationErrors}
            isFetching={loading}
          >
            <Field
              component={TextField}
              name='email'
              label={`*${f('auth.email')}`}
              type='email'
              required
            />
            <Field
              component={TextField}
              name='password'
              label={`*${f(registration ? 'auth.register.password' : 'auth.password')}`}
              password
              required
            />
            {
              (registration && role === 'doctor') && (
                <Field
                  component={TextField}
                  name='referral'
                  label={f('status.codes.personal')}
                />
              )
            }
            {
              registration
              && <>
                <CheckboxWrapper>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={state.check1}
                        onChange={handleChange('check1')}
                        value={state.check1}
                        name='check1'
                        color='primary'
                      />
                      )}
                    label={(
                      <CheckboxLabel>
                        <FormattedMessage id='auth.agree' values={{ suffix: <FormattedMessage id='global.les' defaultMessage='' /> }} />
                        {' '}
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a onClick={() => togglePolicy('gdpr.modal.terms')}>
                          <FormattedMessage id='auth.policy' />
                        </a>
                      </CheckboxLabel>
                  )}
                  />
                </CheckboxWrapper>
                <CheckboxWrapper>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={state.check2}
                        onChange={handleChange('check2')}
                        value={state.check2}
                        name='check2'
                        color='primary'
                      />
                     )}
                    label={(
                      <CheckboxLabel>
                        <FormattedMessage id='auth.agree' values={{ suffix: <FormattedMessage id='global.la' defaultMessage='' /> }} />
                        {' '}
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a onClick={() => togglePolicy('gdpr.modal.privacy')}>
                          <FormattedMessage id='auth.privacy.policy' />
                        </a>
                      </CheckboxLabel>
                )}
                  />
                </CheckboxWrapper>
            </>
            }
            {children}
          </Panel>
        </form>
      )}
    />
  )
}
