import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Specializations } from './Specializations'
import { List } from '../../Patient/Doctors/List'
import { CreateConciliumPage } from './CreatePage'
import { Favorite } from '../../Patient/Doctors/Favorite'
import { Profile } from '../../Patient/Doctors/Profile';

const Wrapper = styled.div`
  z-index: 10;
  position: absolute;
  width: 100%;
  height: 100%;
`

export const ConciliumContainer = ({ match }) => (
  <Wrapper>
    <Switch>
      <Redirect from={match.path} exact to={`${match.path}/specializations`} />
      <Route path={`${match.path}/specializations`} exact component={Specializations} />
      <Route path={`${match.path}/favorite`} component={Favorite} />
      <Route path={`${match.path}/specializations/:doctorType`} render={props => <List {...props} withUrgentToggle />} />
      <Route path={`${match.path}/create/:doctorId`} component={CreateConciliumPage} />
      <Route path={`${match.path}/doctor/profile`} component={Profile} />
      <Route path={`${match.path}/:consultationId/specializations`} exact component={Specializations} />
      <Route path={`${match.path}/:consultationId/specializations/:doctorType`} render={props => <List {...props} onlyUrgent />} />
      <Route path={`${match.path}/:consultationId/favorite`} component={Favorite} />
      <Route path={`${match.path}/:consultationId/add/:doctorId`} component={CreateConciliumPage} />
    </Switch>
  </Wrapper>
)

ConciliumContainer.propTypes = {
  match: PropTypes.object.isRequired,
}
