import React from 'react'
import styled from 'styled-components'
import { DiplomasForm } from 'common/DiplomasForm'
import { Paper } from 'common/UiKit/Paper'

const PaperStyled = styled(Paper)`
  padding: 0;
`

export const Signature = ({ signatures, changeButtonPath }) => (
  <PaperStyled
    heading='profile.signature'
    changeButtonPath={changeButtonPath}
  >
    <DiplomasForm
      diplomas={signatures.map(item => ({
        id: item.id,
        file: item.url,
        extension: item.extension,
        image: item.url,
      }))}
      id='profile_diplomas'
      afterSubmit={() => null}
      onSubmit={() => null}
      disableEdit
      onPaperClick={() => {}}
    />
  </PaperStyled>
)
