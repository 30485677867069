import React from 'react'
import styled from 'styled-components'
import { TextButton } from 'common/UiKit/TextButton'
import { Colors } from 'constants/colors'
import { Paper } from 'common/UiKit/Paper'
import { FormattedMessage } from 'react-intl'
import appLogo from '../../../images/panel/logo.png'

const Wrapper = styled(Paper)`
  padding: 32px 24px 26px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
`

const Logo = styled.img`
  width: 128px;
`

const Content = styled.div`
  margin-left: 30px;
`

const Heading = styled.h1`
  margin: 0;
  font-size: 24px;
  font-weight: 500;
  color: ${Colors.dark};
`

const Copy = styled.p`
  margin: 8px 0 0;
  font-size: 16px;
  opacity: 0.8;
  color: ${Colors.blueGrey};
`

const Button = styled(TextButton)`
  && {
    width: 200px;
    height: 35px;
    font-size: 14px;
    margin-top: 16px;
    padding-top: 3px;
  }
`

export const AppInfo = () => (
  <Wrapper>
    <Logo src={appLogo} alt='logo' />
    <Content>
      <Heading>
        <FormattedMessage id='global.own.doctor' />
      </Heading>
      <Copy>
        &copy; 2020, Conex Sante.
        {' '}
        <FormattedMessage id='global.copyright' />
      </Copy>
      <Button variant='extended' color='primary' href='mailto:support@occimed.fr'>
        <FormattedMessage id='global.contact.us' />
      </Button>
    </Content>
  </Wrapper>
)
