import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { Paper } from '../Paper'
import { MultilineTextField } from '../MultilineTextField'

const MultilineField = styled(MultilineTextField)`
  & .root {
    height: 112px;
  }
`

export const AdditionalInformationForm = ({ Field, ...rest }) => {
  const { formatMessage } = useIntl()

  return (
    <Paper {...rest}>
      <Field
        component={MultilineField}
        name='additional_information'
        label={formatMessage({ id: 'profile.additional' })}
        maxLength={300}
        multiline
        rowsMax='4'
      />
    </Paper>
  )
}

AdditionalInformationForm.propTypes = { Field: PropTypes.func.isRequired }
