import { createAction, handleActions } from 'redux-actions'
import { handleMessageAction } from '../chats'

export const resetCall = createAction('RESET_CALL')

export const callReducer = handleActions({
  [handleMessageAction]: (state, action) => {
    if (action.payload.action === 'call') {
      return action.payload.attributes
    }

    if (action.payload.action === 'cancel') {
      return null
    }
    return state
  },
  [resetCall]: state => ({ ...state, declined: true }),
}, null)
