import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import get from 'lodash/get';
import CircularProgress from '@material-ui/core/CircularProgress'
import { fetchNotification } from '../../../redux/modules/notification'
import { Page } from '../../common/Page'
import { history } from '../../../utils/history'
import { NotificationItem } from './Item'
import { EmptyScreen } from '../Doctor/Consultations/List/EmptyScreen'
import { Pagination } from '../../common/Pagination';

const Content = styled.div`
  & > div {
    margin-bottom: 16px;
  }
`

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 56px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`

export const Notifications = () => {
  const dispatch = useDispatch()
  const notifications = useSelector(state => state.notification.list)
  const list = notifications.data || [];
  const count = get(notifications, 'paginator.total', 0);
  const currentPage = get(notifications, 'paginator.currentPage', 0);
  const limit = get(notifications, 'paginator.limit', 0);
  const elementToScroll = useRef(null);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    dispatch(fetchNotification())
  }, [dispatch])

  useEffect(() => {
    setLoading(false);
  }, [list])

  const handleChange = (el) => {
    setLoading(true);
    dispatch(fetchNotification(el));
    if (elementToScroll && elementToScroll.current && elementToScroll.current.scrollIntoView) {
      elementToScroll.current.scrollIntoView();
    }
  }

  return (
    <Page label='concilium.notifications' onBackIconClick={() => history.push('/panel/consultations')}>
      {loading ? (
        <SpinnerWrapper>
          <CircularProgress />
        </SpinnerWrapper>
      )
        : (
          <Content ref={elementToScroll}>
            {
          !list.length && <EmptyScreen title='concilium.no.notifications' text='concilium.no.notifications.text' />
        }
            {
          list.map(item => <NotificationItem data={item} key={item.id} />)
        }
          </Content>
        )}
      <Pagination 
        limit={limit}
        count={count} 
        onChange={el => handleChange(el)} 
        pagesToView={3}
        currentPage={currentPage}
      />
    </Page>
  )
}
