import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux'
import { compose, mapProps } from 'recompose';
import idx from 'idx';
import { fetchRestrict } from '../../../../redux/modules/content';

export const ModalRestrictUsageComponent = (props) => {
  const {
    open,
    handleClose,
    onSubmit,
  } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => handleClose('restrict', false)}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        <DialogTitle id='scroll-dialog-title'>
          <Typography variant='p' align='center'>
            <FormattedMessage id='gdpr.usage.restrictions.title' />
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id='scroll-dialog-description'
            tabIndex={-1}
          >
            <FormattedMessage id='gdpr.modal.restrict' />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid justify='center' direction='row' container>
            <Button
              onClick={onSubmit}
              color='primary'
            >
              <FormattedMessage id='global.send' />
            </Button>
            <Button onClick={() => handleClose('restrict', false)} color='default'>
              <FormattedMessage id='global.cancel' />
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ModalRestrictUsageComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const enhance = compose(
  connect(
    state => ({
      restrict: idx(state, _ => _.content.restrictData),
    }),
    { fetchRestrict },
  ),
  mapProps(props => ({
    ...props,
    onSubmit: () => props.fetchRestrict()
      .then(() => {
        props.handleClose('restrict', false)
        props.openSuccessModal()
      }),
  })),
);

export const ModalRestrictUsage = enhance(ModalRestrictUsageComponent);
