import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormattedMessage } from 'react-intl';

export const ConfirmModal = ({
  open, handleSubmit, handleClose, title = 'Confirm ?', text = '',
  confirmButtonText, cancelButtonText, titleProps = {},
  ...rest
}) => (
  <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby='alert-dialog-title'
    aria-describedby='alert-dialog-description'
    {...rest}
  >
    <DialogTitle {...titleProps} id='alert-dialog-title'>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id='alert-dialog-description'>
        {text}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color='secondary'>
        {cancelButtonText || <FormattedMessage id='global.cancel' />}
      </Button>
      <Button onClick={handleSubmit} color='primary' autoFocus>
        {confirmButtonText || <FormattedMessage id='global.continue' />}
      </Button>
    </DialogActions>
  </Dialog>
)
