import React, { useEffect } from 'react';
import {
  withStateHandlers,
  compose,
  mapProps,
  lifecycle,
} from 'recompose'
import styled from 'styled-components'
import { connect, useDispatch, useSelector } from 'react-redux';
import idx from 'idx'
import { TextButton } from 'common/UiKit/TextButton'
import { Colors } from 'constants/colors'
import { useIntl, FormattedMessage } from 'react-intl'
import get from 'lodash/get';
import { DiplomasForm } from '../../../../common/DiplomasForm';

import { checkStep } from '../../../../../helpers/fillProfile'
import { EducationAndCourses } from './EducationAndCourses'
import { Page } from '../../../../common/Page'
import { FillFormStepper } from './Stepper'
import { PROFILE_FILL_STEPS } from '../../../../../constants/ui'
import { history } from '../../../../../utils/history'
import { fetchSpecializations } from '../../../../../redux/modules/content'
import {
  editProfile,
  createWorkPlace,
  createEducation,
  createCourses,
  createPrices,
  createDiplomas,
  deleteDiploma,
  logOut,
} from '../../../../../redux/modules/auth'

import { createSignature } from '../../../../../redux/modules/signature'
import { EmploymentTypeField } from '../EmploymentTypeField'
import { AboutDoctorForm } from './AboutDoctorForm'
import { SignatureForm } from './SignatureForm';
// import { ReferralForm } from './ReferralForm'
import { PersonalDataForm } from './PersonalDataForm'
import { AnimationForm } from './AnimationForm';
import { saveQuestions } from '../../../../../helpers/Doctor/QuestionHelper';

const Continue = styled.div`
  position: fixed;
  bottom: 0;
  background-color: ${Colors.white};
  right: 0;
  left: 280px;
  padding: 10px 24px;
  text-align: right;
  
  button {
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    width: 120px;
  }
`

const Screen = styled(Page)`
  padding-bottom: 60px;
  min-height: calc(100% - 60px);
`

const FillFormComponent = ({
  step,
  onContinueClick,
  editProfile,
  loading,
  onFormSubmit,
  onBackClick,
  onAboutInfoSubmit,
  onEducationSubmit,
  deleteDiploma,
  createDiplomas,
  diplomas,
  personalData,
  aboutDoctor,
  id,
  logOut,
  employmentType,
  onSubmitQuestions,
  auth,
}) => {
  const { formatMessage } = useIntl()
  const lang = useSelector(state => state.localization)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchSpecializations())
  }, [dispatch, step])

  useEffect(() => {
    history.block(() => {
      if (step >= 6 || get(auth, 'relationships.diploma.data.length', false)) {
        return true
      }
      return false
    });
  }, [step])

  return (
    <Screen
      label={formatMessage({ id: 'profile.fill' })}
      onBackIconClick={onBackClick}
      id={id}
      onLogout={logOut}
    >
      <FillFormStepper step={Math.max(0, step - 1)} />
      {/*{*/}
      {/*  step === 0 && (*/}
      {/*    <ReferralForm afterSubmit={onFormSubmit} />*/}
      {/*  )*/}
      {/*}*/}
      {
        (step === 1 || step === 0) && (
          <PersonalDataForm
            id={PROFILE_FILL_STEPS[1]}
            onSubmit={values => editProfile({ ...values, interface_language: lang })}
            afterSubmit={onFormSubmit}
            initialValues={personalData}
            isDoctor
          />
        )
      }
      {
        step === 2 && (
          <AboutDoctorForm
            id={PROFILE_FILL_STEPS[2]}
            onSubmit={onAboutInfoSubmit}
            afterSubmit={onFormSubmit}
            initialValues={aboutDoctor}
            methodField={<EmploymentTypeField />}
            employmentType={employmentType}
          />
        )
      }
      {
        step === 3 && (
          <EducationAndCourses onSubmit={onEducationSubmit} afterSubmit={onFormSubmit} />
        )
      }
      {
        step === 4 && <AnimationForm afterSubmit={onFormSubmit} onSubmit={onSubmitQuestions} />
      }
      {
        step === 5 && (
          <DiplomasForm
            diplomas={diplomas}
            id={PROFILE_FILL_STEPS[5]}
            onSubmit={onFormSubmit}
            createDiplomas={createDiplomas}
            deleteDiploma={deleteDiploma}
          />
        )
      }
      {
        step === 6 && (
          <SignatureForm afterSubmit={onFormSubmit} />
        )
      }
      {
        step !== 6 && (
          <Continue>
            <TextButton color='secondary' variant='extended' onClick={onContinueClick} isFetching={loading}>
              <FormattedMessage id='global.continue' />
            </TextButton>
          </Continue>
        )
      }
    </Screen>
  )
}

const enhance = compose(
  connect(state => ({
    auth: state.auth,
    id: state.auth.id,
    diplomas: idx(state, _ => _.auth.relationships.diploma.data) || [],
    signatures: [idx(state, _ => _.signature)],
    personalData: {
      date_of_birth: state.auth.attributes.date_of_birth,
      first_name: state.auth.attributes.first_name,
      last_name: state.auth.attributes.last_name,
      gender: state.auth.attributes.gender,
      photo: state.auth.attributes.photo,
    },
    aboutDoctor: {
      specialization_id: state.auth.attributes.specialization_id,
      medical_degree: state.auth.attributes.medical_degree,
      experience: state.auth.attributes.experience || '',
      language: state.auth.attributes.language,
      work_place: state.auth.relationships.workPlace.data.length
        && state.auth.relationships.workPlace.data[0].attributes,
    },
    employmentType: state.auth.attributes.type_employment,
    specializations: idx(state, _ => _.content.specializations)
      .filter(el => el && el.attributes && el.id)
      .map(({ attributes: a, id }) => ({ value: id, label: a.name })),
    loading: [
      state.loading.editProfile,
      state.loading.workPlace,
      state.loading.education,
      state.loading.courses,
      state.loading.diplomas,
      state.loading.schedule,
    ].some(v => v),
  }), {
    editProfile,
    createWorkPlace,
    createEducation,
    createCourses,
    createPrices,
    createDiplomas,
    createSignature,
    deleteDiploma,
    logOut,
    fetchSpecializations,
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchSpecializations()
    },
  }),
  withStateHandlers(props => ({
    step: checkStep(props.auth),
  }), {
    onBackClick: ({ step }) => () => ({
      step: step === 0
        ? history.push('/panel/consultations') || 0
        : step - 1,
    }),
    // Step submit handlers here
    onFormSubmit: ({ step }, { auth }) => () => {
      if (step === 6) return history.push('/panel/profile')
      if (step === 0) return { step: 1 }
      if (step === 3) return { step: 4 }
      if (step === 4) return { step: 5 }
      if (step === 5) return { step: 6 }
      return { step: checkStep(auth) }
    },
    onContinueClick: ({ step }) => () => document
      .getElementById(PROFILE_FILL_STEPS[step === 0 ? 1 : step])
      .dispatchEvent(new Event('submit', { cancelable: true })),
  }),
  mapProps(props => ({
    ...props,
    diplomas: props.diplomas.map(item => (item.attributes
      ? {
        id: item.id,
        file: item.attributes.url,
        extension: idx(item, _ => _.attributes.extension),
      }
      : { id: '0', file: '' }
    )),
    onSubmitQuestions: (values, afterSubmit, isGeneralist) => saveQuestions(
      values, afterSubmit, isGeneralist,
    ),
    onAboutInfoSubmit: values => props.createWorkPlace({ workPlace: [values.work_place] })
      .then(() => props.editProfile(values)),
    onEducationSubmit: values => props.createEducation({ education: values.education }),
    onPricesSubmit: values => props.createPrices({
      daily_price: values.daily_price,
      weekend_price: values.weekend_price,
      urgent_price: values.urgent_price,
      requisites_private_bic: values.requisites_private_bic || '',
      requisites_private_iban: values.requisites_private_iban || '',
      requisites_hospital_bic: values.requisites_hospital_bic || '',
      requisites_hospital_iban: values.requisites_hospital_iban || '',
    }),
  })),
)

export const FillForm = enhance(FillFormComponent)
