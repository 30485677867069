import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import CancelIcon from '@material-ui/icons/Cancel'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import moment from 'moment';
import get from 'lodash/get';
import { MonthDayConsultation } from '../../../../Month/Day/Consultation'

export const DeleteOverlay = ({
  disabled,
  empty,
  show,
  onClick,
  isFree,
  current,
  ...rest
}) => {
  const consultation = get(current, 'data.data', {});
  const isConsultation = current && current.type === 'Consultation';

  const renderIcon = isFree => (isFree
    ? <AddCircleIcon style={{ color: '#11cb5f' }} />
    : <CancelIcon style={{ color: Colors.red, position: isConsultation ? 'absolute' : 'relative' }} />)

  return (
    <Wrapper 
      disabled={isConsultation ? false : disabled} 
      empty={empty} 
      show={isConsultation ? true : show} 
      onClick={onClick} 
      {...rest}
    >
      {isConsultation
        ? (
          <MonthDayConsultation
            key={consultation.id}
            time={moment(current.date_time).format('HH:mm')}
            consultation={consultation}
            myId={consultation.id}
          />
        ) : ''
    }
      {show && !disabled && renderIcon(isFree)}
    </Wrapper>
  )
}

DeleteOverlay.defaultProps = {
  onClick: () => {},
}

DeleteOverlay.propTypes = {
  disabled: PropTypes.bool.isRequired,
  empty: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
}

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${Colors.white15};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 12;
  margin-right: 2px;
  
  svg {
    width: 16px;
    height: 16px;
    opacity: .6;
  }
  
  ${props => props.disabled && `
    background-color: ${Colors.grey};
    opacity: .2;
    
    svg {
      display: none;
    }
  `}
  
  ${props => props.empty && `
    svg {
      display: none;
    }
  `}
`
