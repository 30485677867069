import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'
import BookmarkIcon from '@material-ui/icons/Bookmark'
import CircularProgress from '@material-ui/core/CircularProgress'

import { Colors } from '../../../constants/colors'
import CheckmarkIcon from '../../../images/panel/doctors/checkmark.svg'

const Button = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;
  position: relative;
  margin-left: 6px;
  
  svg {
    fill: ${Colors.disabled};
  }
  
  ${props => (props.checked
    ? css`
      svg {
        fill: #ff5eb8;
      }

      &:after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        top: 4px;
        left: 6px;
        background-image: url(${CheckmarkIcon});
        background-size: contain;
      }
    `
    : css`
      &:after {
        content: "+";
        position: absolute;
        width: 8px;
        height: 8px;
        left: 8px;
        color: ${Colors.white};
        font-size: 12px;
        top: 4px;
        font-weight: bold;
      }
    `
  )}
  
  ${props => (props.loading && css`
    &:after {
      content: none !important;
    }
  `)}
`

const ProgressWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 7px;
  color: white;
  
  svg {
    color: white;
  }
  
  & > div {
    height: auto !important;
  }
`

export const FavoriteButton = ({ checked, isFetching, onClick }) => (
  <Button checked={checked} loading={isFetching} onClick={onClick}>
    <BookmarkIcon />
    {isFetching && <ProgressWrapper><CircularProgress size={10} thickness={6} /></ProgressWrapper>}
  </Button>
)

FavoriteButton.propTypes = {
  checked: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}
