import React from 'react'
import { Switch, Route } from 'react-router-dom'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import { ChangePassword } from '../../../common/Settings'
import { Main } from './Main'
import { Language } from './Language'
import { Notifications } from './Notifications'
import { PrivacyPolicy } from '../../../common/PrivacyPolicy'
import { HistoryPage } from '../../../common/History'
import { history } from '../../../../utils/history'

export const Settings = () => (
  <Switch>
    <Route
      path='/panel/settings/change-password'
      render={props => <ChangePassword {...props} to='/panel/settings' />}
    />
    <Route
      path='/panel/settings/language'
      component={Language}
    />
    <Route
      path='/panel/settings/notifications'
      component={Notifications}
    />
    <Route
      path='/panel/settings/privacy-policy'
      exact
      render={props => <Wrapper><PrivacyPolicy {...props} /></Wrapper>}
    />
    <Route
      path='/panel/settings/privacy-policy/history'
      render={({ ...props }) => (
        <Wrapper>
          <HistoryPage
            onBackIconClick={() => history.push('/panel/settings/privacy-policy')}
            {...props}
          />
        </Wrapper>
      )}
    />
    <Route
      path='/panel/settings'
      component={Main}
    />
  </Switch>
)

const Wrapper = styled.div`
  background-color: ${Colors.background};
  min-height: 100%;
`
