import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withStateHandlers } from 'recompose'
import ArrowBack from '@material-ui/icons/ArrowBack'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVert from '@material-ui/icons/MoreVert'
import { capitalize } from 'lodash'
import { FormattedMessage, useIntl } from 'react-intl'

import { Colors, LEVEL_COLORS } from 'constants/colors'
import { useSelector, useDispatch } from 'react-redux';
import BookmarkIcon from '@material-ui/icons/Bookmark'
import { withRouter } from 'react-router-dom'
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import { logOut } from '../../../redux/modules/auth'
import { NotificationsButton } from '../Page/Notifications'
import { LanguageSwitcher } from '../LanguageSwitcher'

const Wrapper = styled.div`
  height: 56px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 24px;
  background-color: ${Colors.clearBlue};
  color: ${Colors.white};
  display: flex;
  align-items: center;
  font-family: Roboto, sans-serif;
  border-bottom: ${({ level, hideBorder }) => (level && !hideBorder ? `4px solid ${LEVEL_COLORS[level]}` : 'none')};
`

const Header = styled.p`
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  flex-grow: 1;
`

const IconButtonStyled = styled(IconButton)`
  && { margin-left: -20px; }
`

const LogoutButtonStyled = styled(IconButton)`
  && {
    width: 28px;
    height: 28px;
    margin-left: 16px;
    color: ${Colors.white};
    padding: 0;
  }

  &&:hover {
    background-color: ${Colors.white10};
  }
`

const DeleteButtonStyled = styled(IconButton)`
  && {
    margin-right: -16px;
    color: ${Colors.white};
  }

  &&:hover {
    background-color: ${Colors.white10};
  }
`

const TestAccountLabel = styled.div`
  background-color: #00c9a8;
  color: #fff;
  padding: 0 24px;
  display: flex;
  align-items: center;
  padding-right: 40px;
  height: 32px;
`

const TestAccountMessage = styled.div`
  padding-left: 8px;
  color: #fff;
  flex-grow: 2;
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
`

const HeaderDangerIcon = styled(WarningIcon)`
  font-size: 17px !important;
`

const HeaderInfoIcon = styled(InfoIcon)`
  font-size: 17px !important;
`

// const Id = styled.div`
//   height: 28px;
//   padding: 0 18px;
//   border-radius: 18px;
//   background-color: ${Colors.white10};
//   color: ${Colors.white};
//   font-size: 12px;
//   font-weight: 500;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `

const PanelBarComponent = ({
  label,
  onBackIconClick,
  location,
  //onLogout,
  handleMenu,
  handleClose,
  anchorEl,
  //id,
  //Icon,
  //onIconClick,
  //languageSwitcher,
  //notification,
  match,
  history,
}) => {
  const { formatMessage: f } = useIntl()
  const dispatch = useDispatch()
  const userLevel = useSelector(state => (state.auth.type === 'Doctor' ? state.auth.attributes.level : undefined))
  const isDoctor = useSelector(state => state.auth.type === 'Doctor')
  const status = useSelector(state => state.auth.attributes.status)
  const isTestUser = status === 'TEST';
  const topAnkor = useRef();

  useEffect(() => {
    topAnkor.current.scrollIntoView(true);
  }, [location])

  const onLogout = () => dispatch(logOut())
  const onIconClick = () => {
    const doctorUrl = match.params.consultationId
      ? `/panel/concilium/${match.params.consultationId}/favorite`
      : '/panel/concilium/favorite'
    history.push(isDoctor ? doctorUrl : '/panel/doctors/favorite')
  }

  return (
    <>
      <Wrapper ref={topAnkor} hideBorder={isTestUser} level={userLevel}>
        {onBackIconClick && <IconButtonStyled color='inherit' onClick={onBackIconClick}><ArrowBack /></IconButtonStyled>}
        <Header>
          {capitalize(f({ id: label, defaultMessage: label }))}
        </Header>
        <NotificationsButton />
        <LanguageSwitcher />
        {
          <DeleteButtonStyled onClick={() => onIconClick()}>
            <BookmarkIcon />
          </DeleteButtonStyled>
      }
        {
        onLogout && (
          <>
            {/*id && (
              <Id>
                ID:&nbsp;
                {id}
              </Id>
            )*/}
            <LogoutButtonStyled
              aria-owns={anchorEl ? 'menu-appbar' : undefined}
              aria-haspopup='true'
              onClick={handleMenu}
            >
              <MoreVert />
            </LogoutButtonStyled>
            <Menu
              id='menu-appbar'
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={!!anchorEl}
              onClose={handleClose}
            >
              <MenuItem onClick={onLogout}>
                <FormattedMessage id='global.logout' />
              </MenuItem>
            </Menu>
          </>
        )
      }
      </Wrapper>
      {isTestUser ? (
        <TestAccountLabel>
          <HeaderDangerIcon />
          <TestAccountMessage>You have a TEST profile. </TestAccountMessage>
          <HeaderInfoIcon />
        </TestAccountLabel>
      ) : null}
    </>
  )
}

PanelBarComponent.defaultProps = {
  onBackIconClick: false,
  //onLogout: false,
  //onIconClick: false,
  //id: false,
  anchorEl: null,
}

PanelBarComponent.propTypes = {
  label: PropTypes.string.isRequired,
  onBackIconClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  handleClose: PropTypes.func.isRequired,
  handleMenu: PropTypes.func.isRequired,
  //onLogout: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  //onIconClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  //id: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  anchorEl: PropTypes.object,
}

const enhance = withStateHandlers({
  anchorEl: null,
}, {
  handleMenu: () => e => ({ anchorEl: e.target }),
  handleClose: () => () => ({ anchorEl: null }),
})

export const PanelBar = withRouter(enhance(PanelBarComponent))
