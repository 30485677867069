import React from 'react'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import { FormattedMessage } from 'react-intl'
import logo from '../../../../../images/panel/doctors/consultations-empty-screen.svg'

const Wrapper = styled.div`
  width: 380px;
  height: calc(100vh - 56px - 42px - 22px * 2 - 40px * 2);
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: Roboto, sans-serif;
`

const Logo = styled.img`
  margin-bottom: 36px;
  width: 168px;
  height: 168px;
`

const Heading = styled.h1`
  margin: 0 0 16px;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  color: ${Colors.dark};
  text-align: center;
`

const Text = styled.p`
  margin: 0;
  opacity: 0.8;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  color: ${Colors.blueGrey};
  text-align: center;
`

export const EmptyScreen = ({ title, text }) => (
  <Wrapper>
    <Logo src={logo} />
    <Heading>
      <FormattedMessage id={title || 'consultations.no'} />
    </Heading>
    <Text>
      <FormattedMessage id={text || 'consultations.no.text'} />
    </Text>
  </Wrapper>
)
