import React from 'react'
import PropTypes from 'prop-types'
import idx from 'idx'
import { compose, withProps } from 'recompose'
import { connect } from 'react-redux'
import { EditPage } from 'common/UiKit/EditPage'
import { history } from '../../../../../utils/history'
import { externalFormSubmit } from '../../../../../helpers/externalFormSubmit'
import { editMedicalReport } from '../../../../../redux/modules/consultations'
import { socket } from '../../../../../services/socket'
import { CONSULTATION_TYPES } from '../../../../../constants/consultation'
import { MedicalReportForm as Form } from '../../../../common/MedicalReportForm'

const MedicalReportFormComponent = ({
  editMedicalReport,
  afterSubmit,
  onButtonClick,
  loading,
  onBackIconClick,
  initialValues,
  consiliumStatus,
  employmentType,
}) => (
  <EditPage
    label='consultations.fill.report'
    onBackIconClick={onBackIconClick}
    buttonText='global.save'
    onButtonClick={onButtonClick}
    loading={loading}
  >
    <Form
      onSubmit={editMedicalReport}
      id='report'
      afterSubmit={afterSubmit}
      initialValues={initialValues}
      consiliumStatus={consiliumStatus}
      employmentType={employmentType}
      disableShadow
    />
  </EditPage>
)

MedicalReportFormComponent.propTypes = {
  editMedicalReport: PropTypes.func.isRequired,
  afterSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  onBackIconClick: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  consiliumStatus: PropTypes.object.isRequired,
}

// TODO add loading state
const enhance = compose(
  connect(
    state => ({
      loading: state.loading.editMedicalReport,
      employmentType: state.auth.attributes.type_employment,
      chatId: idx(state, _ => _.chats[state.consultations.consultation.id].chat.id),
      initialValues: {
        symptoms: idx(state, _ => _.consultations.consultation.attributes.symptom_explanation),
      },
      consiliumStatus: {
        consiliumStatus: idx(state, (_) => {
          const status = _.consultations.consultation.attributes.consiliumStatus
          if (_.consultations.consultation.relationships.doctor.data.id === _.auth.id
            && _.consultations.consultation.attributes.type !== CONSULTATION_TYPES.telexpertise) {
            return false
          }
          return status
        }),
      },
    }),
    { editMedicalReport },
  ),
  withProps(props => ({
    afterSubmit: () => {
      socket.notify(props.chatId)
      history.push('/panel/consultations/session/prescription')
    },
    onBackIconClick: () => history.push('/panel/consultations/session'),
    onButtonClick: () => externalFormSubmit('report'),
  })),
)

export const MedicalReportForm = enhance(MedicalReportFormComponent)
