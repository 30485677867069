import React from 'react'
import { Input } from './Input'

export const TextField = ({
  input, meta, InputLabelProps, ...rest 
}) => (
  <Input
    {...input} 
    InputLabelProps={InputLabelProps}
    errorMessage={meta.touched && meta.error ? meta.error : null}
    {...rest}
  />
)
