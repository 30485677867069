import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import { Form } from 'react-final-form'
import { Button, DialogActions } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { sendUseful } from '../../../../../../redux/modules/consultations';

export const UsefulModal = ({
  open,
  onClose,
  consultationId,
}) => {
  const dispatch = useDispatch()
  const [selected, select] = useState(null)
  const onSubmit = values => dispatch(sendUseful({ ...values, useful: selected }, consultationId))
    .then(() => onClose())

  return (
    <Dialog open={open} maxWidth='xs'>
      <DialogTitle>
        <FormattedMessage id='consultation.useful.title' />
      </DialogTitle>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <>
              <DialogContent>
                <ButtonWrapper>
                  <ModalButton
                    color={Colors.tealish}
                    active={selected === 1}
                    onClick={() => select(1)}
                    type='button'
                  >
                    <FormattedMessage id='global.yes' />
                  </ModalButton>
                  <ModalButton
                    color={Colors.red}
                    active={selected === 0}
                    onClick={() => select(0)}
                    type='button'
                  >
                    <FormattedMessage id='global.not' />
                  </ModalButton>
                </ButtonWrapper>
              </DialogContent>
              <DialogActions>
                <Button
                  color='primary'
                  onClick={onClose}
                >
                  <FormattedMessage id='consultation.review.later' />
                </Button>
                <Button
                  color='primary'
                  type='submit'
                  disabled={submitting || selected === null}
                >
                  <FormattedMessage id='consultation.review.done' />
                </Button>
              </DialogActions>
            </>
          </form>
        )}
      />
    </Dialog>
  )
}

const ModalButton = styled.button`
  outline: none;
  border: 1px solid ${({ color }) => color};
  border-radius: 30px;
  background-color: ${({ active, color }) => (active ? color : '#fff')};
  color: ${({ active, color }) => (active ? '#fff' : color)};
  text-transform: uppercase;
  font-size: 16px;
  font-weight: ${({ active }) => (active ? 500 : 600)};
  padding: 10px 40px;
  cursor: pointer;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`
