import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withProps } from 'recompose'
import { Field, Form } from 'react-final-form'
import { FormattedMessage } from 'react-intl'
import { DialogWindow } from 'common/UiKit/DialogWindow'
import { externalFormSubmit } from '../../../../../helpers/externalFormSubmit'
import { history } from '../../../../../utils/history'
import { SpecializationsSelect } from '../../../../common/SpecializationsSelect';

const UrgentDialogComponent = ({
  open,
  onClose,
  onSubmit,
  onButtonClick,
}) => (
  <DialogWindow
    open={open}
    title={<FormattedMessage id='consultation.choose' />}
    buttonText='OK'
    closeButtonText={<FormattedMessage id='global.cancel' />}
    onButtonClick={onButtonClick}
    onClose={onClose}
  >
    <Form
      onSubmit={onSubmit}
      initialValues={{ specialization: 'Therapist' }}
      subscription={{}}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} id='specialization'>
          <Wrapper>
            <Field
              component={({ input, ...rest }) => <SpecializationsSelect {...input} {...rest} />}
              name='specialization'
              menuIsOpen
            />
          </Wrapper>
        </form>
      )}
    />
  </DialogWindow>
)

UrgentDialogComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  specializations: PropTypes.array.isRequired,
}

const enhance = withProps({
  onSubmit: values => history.push(`/panel/doctors/urgent/${values.specialization.toLowerCase()}`),
  onButtonClick: () => externalFormSubmit('specialization'),
})

export const UrgentDialog = enhance(UrgentDialogComponent)


const Wrapper = styled.div`
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: -24px;
  width: 360px;
  height: 360px;
  overflow: hidden;
  
  & > div > div:last-of-type > div {
    position: static !important;
    box-shadow: none;
  }
`
