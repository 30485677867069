import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import moment from 'moment'
import { Colors } from 'constants/colors'

import { generateTimePeriods } from '../../../../../helpers/calendar'
import { TimeColumn } from '../Week/TImeColumn'
import { DayColumn } from '../Week/DayColumn'

export const DayCalendar = React.memo(({ confirm, editing }) => {
  const periods = useMemo(() => generateTimePeriods(), [])
  const fromDate = useSelector(state => state.calendar.filters.values.date.from)
  const day = useMemo(() => moment(fromDate), [fromDate])
  const data = useSelector(state => state.calendar.list.day)

  return (
    <Wrapper>
      <TimeColumn periods={periods} withoutHeader />
      <DayColumn
        periods={periods}
        hoursTotal={5}
        day={moment(fromDate)}
        isDayOff={day.day() === 6}
        key={day}
        withoutHeader
        confirm={confirm}
        data={data[day.format('YYYY-MM-DD')]}
        editing={editing}
      />
    </Wrapper>
  )
})

DayCalendar.propTypes = {
  confirm: PropTypes.func.isRequired,
}

const Wrapper = styled.div`
  display: flex;
  border-top: 1px solid ${Colors.divider};
`
