import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'
import get from 'lodash/get'
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import defaultAvatar from '../../../../images/panel/default-avatar.svg'
import { MESSAGE_TYPES } from '../../../../constants/messageTypes'
import { TextMessage } from './Text'
import { InfoMessage } from './Info'
import { FileMessage } from './File'
import { ReportMessage } from './Report'
import { PrescriptionMessage } from './Prescription'
import { CompletePaymentMessage } from './Payments/Complete'
import { SuccessMessage } from './Payments/Success'
import { deleteChatMessage } from '../../../../helpers/consultations';

export const Message = ({
  time,
  type,
  my,
  message,
  photo,
  onAvatarClick,
  scrollToBottom,
  chatId,
  isPaymentCompleted,
  price,
  invoice,
  isDoctor,
  isAdditionalDoctor,
  isConcilium,
  messageId,
  //chatIdReal,
}) => {
  const [showDeleteContextMenu, setShowDeleteContextMenu] = useState(false);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowDeleteContextMenu(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);


  const contextMenuHandler = (e) => {
    if (my) {
      setShowDeleteContextMenu(true);
      e.preventDefault();
    }
  }

  const deleteMessageAction = () => {
    deleteChatMessage(messageId);
    setShowDeleteContextMenu(false);
  }

  return (
    <Wrapper ref={wrapperRef} onContextMenu={contextMenuHandler}>
      <Avatar photo={photo} onClick={onAvatarClick} />
      <Content>
        {
          showDeleteContextMenu ? (
            <ChatContextMenu>
              <MenuList>
                <MenuItem onClick={deleteMessageAction}>
                  <FormattedMessage id='global.delete' />
                </MenuItem>
              </MenuList>
            </ChatContextMenu>
          ) : ''
        }
        {type === MESSAGE_TYPES.text && <TextMessage my={my} message={message} />}
        {type === MESSAGE_TYPES.file && (
        <FileMessage my={my} message={message} onLoad={scrollToBottom} />
        )}
        {type === MESSAGE_TYPES.symptom && <InfoMessage message={message} heading='consultations.symptoms' />}
        {type === MESSAGE_TYPES.question && <InfoMessage questions={message} heading='consultation.questions' />}
        {(type === MESSAGE_TYPES.report && message.medicalReport) && (
          isPaymentCompleted
            ? (
            <>
              {!isAdditionalDoctor && (
                <SuccessMessage
                  pdf={invoice}
                  price={price}
                />
              )}
              <ReportMessage
                diagnosis={message.medicalReport.diagnosis}
                restrictions={message.medicalReport.restrictions}
                symptoms={message.medicalReport.symptoms}
                redirectToSpecialization={!!message.medicalReport.redirectToSpecialization}
                needConsultationWithSpecialist={message
                  .medicalReport.needConsultationWithSpecialist}
                redirectSpecializationId={message.medicalReport.redirectSpecializationId}
                medicines={message.drugs ? message.drugs.map(item => item.name) : []}
                pdf={message.medicalReport.pdf}
                doctor={message.medicalReport.doctor}
              />
            </>
            )
            : (
            <>
              {
                (!isDoctor && !isAdditionalDoctor && !isConcilium)
                  ? <CompletePaymentMessage price={price} />
                  : (
                    <ReportMessage
                      diagnosis={message.medicalReport.diagnosis}
                      restrictions={message.medicalReport.restrictions}
                      referral={message.medicalReport.redirectSpecializationId}
                      redirectToSpecialization={!!message.medicalReport.redirectToSpecialization}
                      needConsultationWithSpecialist={
                        message.medicalReport.needConsultationWithSpecialist
                      }
                      redirectSpecializationId={message.medicalReport.redirectSpecializationId}
                      medicines={message.drugs ? message.drugs.map(item => item.name) : []}
                      pdf={message.medicalReport.pdf}
                      doctor={message.medicalReport.doctor}
                      symptoms={message.medicalReport.symptoms}
                    />
                  )
              }
            </>
            )
        )}
        {(type === MESSAGE_TYPES.prescription && message.drugs) && (
        <>
          {(isPaymentCompleted || isDoctor || isAdditionalDoctor || isConcilium) ? (
            <PrescriptionMessage
              medicines={message.drugs.map(item => item.name)}
              chatId={chatId}
            />
          ) : (
            <TextMessage my={my} system message={(<FormattedMessage id='consultation.medical_report.need_payment_message' />)} />
          )}
        </>
        )}
        {type === MESSAGE_TYPES.approve && (
        <InfoMessage date={message.consultationDate} heading='consultation.approved' approve />
        )}
        {message && message.cancelled && (
        <InfoMessage date={message.consultationDate} message={get(message, 'message', '')} heading='consultation.canceledv2' canceled approve />
        )}
        <Time>
          {
          /**
           * @todo REWRITE BACKEND DATE SYSTEM AND CLEAN THIS 🤦
           */
          process.env.REACT_APP_HOST === 'https://app.occimed.fr'
            ? moment.utc(time).local().subtract(1, 'hour').format('LLL')
            : moment.utc(time).local().format('LLL')
        }
        </Time>
      </Content>
    </Wrapper>
  ) 
}

Message.defaultProps = {
  photo: undefined,
  onAvatarClick: undefined,
  scrollToBottom: undefined,
  price: 0,
  invoice: '',
  chatId: null,
}

Message.propTypes = {
  time: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  my: PropTypes.bool.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]).isRequired,
  photo: PropTypes.string,
  onAvatarClick: PropTypes.func,
  scrollToBottom: PropTypes.func,
  chatId: PropTypes.string,
  isPaymentCompleted: PropTypes.bool.isRequired,
  price: PropTypes.number,
  invoice: PropTypes.string,
  isDoctor: PropTypes.bool.isRequired,
}

const Wrapper = styled.div`
  width: auto;
  display: flex;
`

const Avatar = styled.div`
  width: 43px;
  height: 43px;
  box-sizing: border-box;
  border-radius: 100%;
  background: url(${props => props.photo || defaultAvatar}) ${Colors.lightGrey} center no-repeat; 
  cursor: ${props => (!props.onClick ? 'default' : 'pointer')};
  background-size: cover;
  border: 3px solid white;
  box-shadow: 0 4px 8px 0 rgba(6, 173, 255, 0.1);
  margin-right: 12px;
`

const Content = styled.div`
  max-width: calc(100% - 12px - 43px);
  position: relative;
`

const ChatContextMenu = styled(Paper)`
  position: absolute;
  left: calc(100% + 13px);
  min-width: 128px;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 30, 45, 0.12), 0 -1px 4px 0 rgba(0, 30, 45, 0.1);
  background-color: #f5f7fb;
`

const Time = styled.div`
  width: 100%;
  font-size: 11px;
  padding-top: 4px;
  color: ${Colors.blueGrey};
  text-align: right;
`
