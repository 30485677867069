import { mapProps } from 'recompose'
import PropTypes from 'prop-types'
import moment from 'moment'
import { PropertiesList } from '../PropertiesList'

const enhance = mapProps(props => ({
  list: [
    { label: 'hr.height', value: props.height || 'hr.unknown' },
    { label: 'hr.weight', value: props.weight || 'hr.unknown' },
    { label: 'hr.hospitalized', value: props.lastHospitalized ? moment(props.lastHospitalized).format('MMMM DD, YYYY') : 'hr.unknown' },
    {
      label: 'hr.profile',
      value: props.contraindication
        ? props.contraindication.map(c => c.toLowerCase().split('_').join(' ')).join(', ')
        : 'hr.unknown',
    },
    { label: 'hr.smoke', value: props.smoke ? 'global.yes' : 'global.not' },
    { label: 'hr.pregnant', value: props.pregnant ? 'global.yes' : 'global.not' },
  ],
}))

export const HealthDescription = enhance(PropertiesList)

HealthDescription.propTypes = {
  height: PropTypes.number,
  weight: PropTypes.number,
  lastHospitalized: PropTypes.string,
  diseases: PropTypes.arrayOf(PropTypes.string),
  smoke: PropTypes.bool,
  pregnant: PropTypes.bool,
}
