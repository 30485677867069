import React from 'react'
import PropTypes from 'prop-types'
import idx from 'idx'
import styled from 'styled-components'
import { Form, Field } from 'react-final-form'
import { compose, withProps, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import { Colors } from 'constants/colors'
import { TextField } from 'common/UiKit/TextField'
import { Paper } from 'common/UiKit/Paper'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Delete from '@material-ui/icons/Delete'
import { FormattedMessage, useIntl } from 'react-intl'
import { Page } from '../../../common/Page'
import { addAllergy, fetchAllergies, deleteAllergy } from '../../../../redux/modules/healthRecords'

const InputWrapper = styled.div`
  display: flex;
  align-items: flex-start;

  &>div:first-child {
    flex-grow: 1;
  }

  button {
    margin: 10px 0 0 20px;
    font-weight: 500;
  } `

const List = styled.ol`
  counter-reset: myCounter;
  padding-left: 0;
  margin: 0;

  &>li {
    display: flex;
    align-items: center;
    position: relative;
    list-style: none;
    margin-bottom: 20px;
    color: ${Colors.dark80};
    font-weight: 500;

    &>span {
      width: calc(100% - 100px);
      display: inline-block;
      word-wrap: break-word;
    }
  }

    button {
      margin-left: auto;
      padding: 3px;
    }
  }

  &>li:before {
    counter-increment: myCounter;
    content: counter(myCounter) ".";
    margin-right: 13px;
  }
`

const AllergiesComponent = ({
  onBackIconClick,
  allergies,
  addAllergy,
  deleteAllergy,
  loading,
}) => {
  const { formatMessage: f } = useIntl()
  return (
    <Page
      label='hr.allergies'
      onBackIconClick={onBackIconClick}
    >
      <Paper heading='hr.all'>
        <List>
          {allergies.map(a => (
            <li key={idx(a, _ => _.id)}>
              <span>{idx(a, _ => _.attributes.name)}</span>
              <IconButton
                color='primary'
                onClick={() => deleteAllergy({ id: idx(a, _ => _.id) })}
                disabled={loading}
              >
                <Delete />
              </IconButton>
            </li>
          ))}
        </List>
        <Form
          onSubmit={addAllergy}
          subscription={{}}
          render={({ handleSubmit, form }) => (
            <form
              onSubmit={(e) => {
                const res = handleSubmit(e)

                if (res) {
                  res.then(() => form.reset())
                }
              }}
              id='allergy'
            >
              <InputWrapper>
                <Field
                  component={TextField}
                  name='name'
                  label={f({ id: 'hr.add.allergy' })}
                  validate={value => !value && 'Please enter an allergy'}
                />
                <Button
                  color='primary'
                  type='submit'
                  disabled={loading}
                >
                  <FormattedMessage id='global.add' />
                </Button>
              </InputWrapper>
            </form>
          )}
        />
      </Paper>
    </Page>
  )
}

AllergiesComponent.defaultProps = {
  allergies: [],
}

AllergiesComponent.propTypes = {
  onBackIconClick: PropTypes.func.isRequired,
  allergies: PropTypes.arrayOf(PropTypes.object),
  addAllergy: PropTypes.func.isRequired,
  deleteAllergy: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

const enhance = compose(
  connect(
    state => ({
      allergies: idx(state, _ => _.healthRecords.allergies),
      loading: state.loading.allergies,
    }),
    { addAllergy, fetchAllergies, deleteAllergy },
  ),
  lifecycle({
    componentDidMount() {
      this.props.fetchAllergies()
    },
  }),
  withProps(props => ({
    onBackIconClick: () => props.history.push('/panel/health-records'),
  })),
)

export const Allergies = enhance(AllergiesComponent)
