import React from 'react'
import { lifecycle } from 'recompose'
import { Input } from '../Input'

const checkError = (touched, error, submitError) => {
  if (touched) {
    if (error) return error
    if (submitError) return submitError
  }

  return null
}

const TextFieldComponent = ({
  input,
  meta,
  disabled,
  ...rest
}) => (
  <Input
    {...rest}
    {...input}
    disabled={disabled}
    onChange={disabled ? input.onChange('') : input.onChange}
    errorMessage={checkError(meta.touched, meta.error, meta.submitError)}
  />
)

const enhance = lifecycle({
  componentWillUnmount() {
    this.props.input.onChange('')
  },
})

export const TextField = enhance(TextFieldComponent)
