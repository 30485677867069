import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose, withProps } from 'recompose'
import { connect } from 'react-redux'
import { Form, Field } from 'react-final-form'
import { EditPage } from 'common/UiKit/EditPage'
import { TextField } from 'common/UiKit/TextField'
import { Paper } from 'common/UiKit/Paper'
import { useIntl } from 'react-intl'
import { history } from '../../../utils/history'
import { externalFormSubmit } from '../../../helpers/externalFormSubmit'
import { changePassword } from '../../../redux/modules/auth'

const Wrapper = styled.div`
  height: 100%;

  & > form {
    height: 100%;
  }
`

const Content = styled(Paper)`
  margin-top: 24px;
  padding: 16px 24px 6px 24px;
`

const validate = (values) => {
  const errors = {}

  if (!values.currentPassword) errors.currentPassword = 'Please enter current password'
  if (!values.newPassword) errors.newPassword = 'Please eneter new password'
  if (values.currentPassword && values.currentPassword.length < 6) errors.currentPassword = 'Password must be at least 6 characters'
  if (values.newPassword && values.newPassword.length < 6) errors.newPassword = 'Password must be at least 6 characters'

  return errors
}

const ChangePasswordComponent = ({
  loading,
  onSubmit,
  onButtonClick,
  onBackIconClick,
}) => {
  const { formatMessage: f } = useIntl()
  return (
    <Wrapper>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        subscription={{}}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} id='change_password'>
            <EditPage
              background
              label='profile.change.password'
              buttonText='global.save'
              onBackIconClick={onBackIconClick}
              loading={loading}
              onButtonClick={onButtonClick}
            >
              <Content>
                <Field
                  component={TextField}
                  name='currentPassword'
                  label={`* ${f({ id: 'profile.current.password' })}`}
                  password
                />
                <Field
                  component={TextField}
                  name='newPassword'
                  label={`* ${f({ id: 'profile.new.password' })}`}
                  password
                />
              </Content>
            </EditPage>
          </form>
        )}
      />
    </Wrapper>
  )
}

ChangePasswordComponent.propTypes = {
  loading: PropTypes.bool.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  onBackIconClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

const enhance = compose(
  connect(state => ({ loading: state.loading.changePassword }), { changePassword }),
  withProps(props => ({
    onSubmit: values => props.changePassword(values),
    onButtonClick: () => externalFormSubmit('change_password'),
    onBackIconClick: () => history.goBack(),
  })),
)

export const ChangePassword = enhance(ChangePasswordComponent)
