import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSelector } from 'react-redux';
import { calendarColors, Colors } from '../../../../../../../constants/colors';
import { CONSULTATION_TYPES } from '../../../../../../../constants/consultation'

const NameToRender = (type, patient, creatorDoctor = {}, doctor = {}, myDoctorId) => {
  if (type === 'telexpertise' || !patient || !patient.name) {
    return Number(myDoctorId) !== Number(creatorDoctor.id) ? creatorDoctor.name : doctor.name;
  } 
  return patient.name;
}

export const MonthDayConsultation = ({
  consultation: {
    patient,
    status,
    doctor,
    creatorDoctor,
    type,
  },
  time,
  //myId,
  onClick,
}) => {
  const myDoctorId = useSelector(state => state.auth.id)
  return (
    <Wrapper
      status={type === CONSULTATION_TYPES.telexpertise ? CONSULTATION_TYPES.telexpertise : status}
      onClick={onClick}
    >
      {time}: {NameToRender(type, patient, creatorDoctor, doctor, myDoctorId)}
    </Wrapper>
  ) 
}

MonthDayConsultation.propTypes = {
  consultation: PropTypes.object.isRequired,
  time: PropTypes.string.isRequired,
}

const Wrapper = styled.div`
  flex-grow: 1;
  box-sizing: border-box;
  padding: 8px;
  font-size: 10px;
  font-weight: 700;
  color: black;
  margin-right: 4px;
  border-radius: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  
  border-left: 3px solid ${(props) => {
    switch (props.status) {
      case 'cancelled':
        return calendarColors.cancelledBorder
      case 'completed':
        return calendarColors.completedBorder
      case CONSULTATION_TYPES.telexpertise:
        return calendarColors.conciliumBorder
      default:
        return Colors.clearBlue
    }
  }};
  
  background-color: ${(props) => {
    switch (props.status) {
      case 'cancelled':
        return calendarColors.cancelledBg
      case 'completed':
        return calendarColors.completedBg
      case CONSULTATION_TYPES.telexpertise:
        return calendarColors.conciliumBg
      default:
        return calendarColors.confirmedBg
    }
  }};
`
