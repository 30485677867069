import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { TextButton } from 'common/UiKit/TextButton'

import { Page } from '../../../../common/Page'
import { history } from '../../../../../utils/history'
import pdf from '../../../../../assets/signature-form.pdf'

import downloadImg from '../../../../../images/panel/profile/signature-download.svg'
import uploadImg from '../../../../../images/panel/profile/signature-upload.svg'
import { Colors } from '../../../../../constants/colors'

const EmbeddedContainer = styled.div`
  padding: 24px;
  border-radius: 12px;
  background-color: white;
`

export const Signature = ({ embedded, onUploadClick }) => {
  const Wrapper = embedded ? EmbeddedContainer : Page
  return (
    <Wrapper
      label='profile.signature'
      onBackIconClick={() => history.goBack()}
    >
      <Title>
        <FormattedMessage id='signature.title' />
      </Title>
      <Content>
        <Item>
          <img src={downloadImg} alt='' />
          <TextButton variant='outlined' color='primary' href={pdf} target='_blank'>
            <FormattedMessage id='signature.formular.download' />
          </TextButton>
        </Item>
        <Item>
          <img src={uploadImg} alt='' />
          <TextButton
            variant='outlined'
            color='primary'
            onClick={() => {
              if (onUploadClick) {
                onUploadClick()
              } else {
                history.push('/panel/profile/signature/upload')
              }
            }}
          >
            <FormattedMessage id='signature.upload.button' />
          </TextButton>
        </Item>
      </Content>
    </Wrapper>
  )
}

const Title = styled.div`
  font-size: 22px;
  font-weight: 500;
  margin-top: 32px;
  text-align: center;
`

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Item = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin: 80px 32px;
  align-items: center;
  
  button, a {
    margin-top: 32px;
    background-color: transparent;
    border: 1px solid ${Colors.clearBlue};
    box-shadow: none;
    color: ${Colors.clearBlue};
    font-size: 14px;
    font-weight: 500;
    height: auto;
    width: 260px;
    padding: 16px;
    
    &:hover {
      color: white;
      background-color: ${Colors.clearBlue};
    }
  }
`
