import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import queryString from 'query-string'
import idx from 'idx'

import { Preloader } from '../../../../common/Preloader'
import { Session } from '../Session'
import { fetchConsultation } from '../../../../../redux/modules/consultations'
import { PaymentStatusDialog } from './PaymentStatusDialog'

export const ConsultationById = ({ match, location, ...props }) => {
  const { id } = match.params
  const dispatch = useDispatch()
  const isFetching = useSelector(state => state.loading.consultation)
  const consultation = useSelector(state => state.consultations.consultation)
  const [showStatusDialog, toggleStatusDialog] = useState(false)

  const queryStatus = 'payment_status' in queryString.parse(location.search)
  const status = idx(consultation, _ => _.attributes.invoice.paid)

  useEffect(() => {
    dispatch(fetchConsultation(id, null, true))
  }, [id, dispatch])

  useEffect(() => {
    if (queryStatus) {
      toggleStatusDialog(true)
    }
  }, [])

  if (isFetching || !consultation.id) {
    return <Loader />
  }

  return (
    <>
      <Session match={match} {...props} location={location} />
      {
        showStatusDialog && (
          <PaymentStatusDialog onClose={() => toggleStatusDialog(false)} status={status} />
        )
      }
    </>
  )
}

const Loader = styled(Preloader)`
  width: calc(100vw - 280px);
`
