import React from 'react'
import { useIntl } from 'react-intl'

import { AuthDialog } from '../AuthDialog'
import { FormWrapper } from '../Login'
import { PhoneConfirmForm } from './PhoneConfirmForm'

export const PhoneConfirm = () => {
  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })

  return (
    <AuthDialog
      heading={f('auth.confirm.title')}
    >
      <FormWrapper>
        <PhoneConfirmForm />
      </FormWrapper>
    </AuthDialog>
  )
}
