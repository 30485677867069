import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withProps } from 'recompose'
import Videocam from '@material-ui/icons/Videocam'
import LockOpen from '@material-ui/icons/LockOpen'
import Lock from '@material-ui/icons/Lock'
import HourglassEmpty from '@material-ui/icons/HourglassEmpty'
import HourglassFull from '@material-ui/icons/HourglassFull'
import CancelIcon from '@material-ui/icons/Cancel'
import moment from 'moment'
import idx from 'idx'
import { Colors } from 'constants/colors'
import { FormattedMessage } from 'react-intl'
import get from 'lodash/get';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import concilium from '../../../../../images/icons/concilium.svg'
import { CONSULTATION_TYPES } from '../../../../../constants/consultation';
import DefaultAvatar from '../../../../../images/panel/default-avatar.svg';
import { ConfirmWithReasonModal } from '../../../../common/Modal/ConfirmWithReasonModal';
import { refuseConsultation } from '../../../../../redux/modules/consultations';

const Wrapper = styled.div`
  padding: 16px 26px;
  background-color: ${Colors.white};
  border-radius: 4px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  ${({
    status, type, isMy, paid, me, 
  }) => {
    let colors = {}

    const isTcPatient = type === 'scheduled' && me.type === 'Doctor';
    const isTcDoctor = type === 'scheduled' && me.type !== 'Doctor';
    const isTeMeRequested = type === 'telexpertise' && !isMy;
    const isTeMeAnswered = type === 'telexpertise' && isMy;

    if (status === 'created') colors = { background: '#ffffff', color: '#b2b5b8', shadow: 'rgba(6, 173, 255, 0.1)' }
    //if (status === 'video_call') 
    //colors = { background: '#e8f9ef', color: '#54c375', shadow: '#cce9d8' }
    //if (status === 'waiting') 
    //colors = { background: '#fff9e6', color: '#e0b40a', shadow: '#efebde' }
    //if (status === 'access_opened') 
    //colors = { background: '#eff9ff', color: '#5eb9f1', shadow: 'rgba(6, 173, 255, 0.1)' }
    //if (status === 'access_closed') 
    //colors = { background: '#f5eeff', color: '#ad70ff', shadow: '#e2bded' }
    if (isTcPatient) colors = { background: '#e9f5ff', color: '#b2b5b8', shadow: 'rgba(6, 173, 255, 0.1)' }
    if (isTcDoctor) colors = { background: '#ece9ff', color: '#b2b5b8', shadow: 'rgba(105, 6, 255, 0.1)' }
    if (isTeMeRequested) colors = { background: '#e9f5ff', color: '#ec5b7c', shadow: 'rgba(6, 173, 255, 0.1)' }
    if (isTeMeAnswered) colors = { background: '#a4d7ff', color: '#ec5b7c', shadow: 'rgba(255, 6, 6, 0.1)' }
    if (status === 'completed') colors = { background: '#e7ffe7', color: '#ec5b7c', shadow: 'rgba(162, 255, 6, 0.1)' }
    if (paid && type === 'telexpertise') colors = { background: '#c4f5f5', color: '#ec5b7c', shadow: 'rgba(162, 255, 6, 0.1)' }
    if (status === 'cancelled') colors = { background: '#fff2f4', color: 'grey', shadow: 'rgba(255, 0, 0, 0.1)' }

    return `
      background-color: ${colors.background};
      border-left: 2px solid ${colors.color};
      box-shadow: 0 4px 8px 0 ${colors.shadow};

      & :last-child > span {
        color: ${colors.color};
      }
    `
  }}

  & :first-child {
    display: flex;
    align-items: center;
  }
`

const Photo = styled.div`
  border-radius: 50%;
  height: 62px;
  width: 62px;
  border: 3px solid ${Colors.white};
  box-shadow: 0 4px 8px 0 ${Colors.azure};
  background-image: url(${props => get(props, 'photo', DefaultAvatar)}), url(${DefaultAvatar});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`

const Patient = styled.div`
  flex: 1 1 auto;
  margin-left: 43px;
  
  & > div:first-child {
    font-size: 18px;
    font-weight: 500;
    color: ${Colors.dark};
    margin-bottom: 8px;
  }
  
  & > span:last-child {
    font-size: 14px;
    line-height: 1.86;
    display: flex;
    align-items: center;

    svg {
      font-size: 16px;
      margin-left: -20px;
    }
  }
`

const DateWrapper = styled.div`
  margin-right: 50px;
`

const Time = styled.div`
  font-size: 24px;
  font-weight: bold;
  opacity: 0.8;
  color: ${Colors.dark};
`

const UrgentLabel = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 184px;
  border-radius: 4px;
  background-color: #ffad00;
  font-size: 12px;
  color: #fff9e6;
  line-height: 2.17;
  text-transform: uppercase;
  text-align: center;
`

const AdditionalLabel = styled.span`
  font-size: 13px;
  margin-left: 6px;
  color: ${Colors.dark70} !important;
  font-weight: 400;
`

const CancelConsultation = styled.div`
  display: flex;
  flex-direction: column;
`

const CancelButton = styled(Button)`
  && {
    cursor: pointer;
    color: #fe1a39;
    text-transform: uppercase;
    font-size: 14px;
    display: flex;
    align-items: center;
  }
`

const StyledCloseIcon = styled(CloseIcon)`
  border-radius: 50px;
  margin-right: 8px;
  padding: 4px;
  && {
    background-color: #edeef0;
    font-size: 10px;
  }
`

const WrapButtons = styled.div`
  font-size: 14px;
`

const ItemComponent = ({
  patient,
  status,
  date,
  onClick,
  urgent,
  isConcilium,
  isMy,
  additionalMember,
  type,
  id,
  refreshConsultations,
  filters,
  invoice,
  me,
}) => {
  const isDoctor = idx(patient, _ => !!_.specialization)
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const isUpcoming = filters && filters.filter && filters.filter === 'upcoming';
  const [refuseReason, setRefuseReason] = useState('');

  return (
    <Wrapper
      status={status}
      type={type}
      onClick={onClick}
      isMy={isMy}
      paid={invoice.paid}
      me={me}
    >
      <div>
        <Photo photo={idx((isMy && additionalMember ? additionalMember : patient), _ => _.photo)} />
        <Patient>
          <div>
            {idx((isMy && additionalMember ? additionalMember : patient), _ => _.first_name) || 'Name'}
            &nbsp;
            {idx((isMy && additionalMember ? additionalMember : patient), _ => _.last_name) || 'Surname'}
            {
              type === CONSULTATION_TYPES.telexpertise && (
                <AdditionalLabel>
                  (
                  <FormattedMessage id={!isMy ? 'consultation.invited' : 'consultation.creator'} />
                  )
                </AdditionalLabel>
              )
            }
            <span>
              {}
            </span>
          </div>
          {status === 'created' && <span><FormattedMessage id='consultations.created' /></span>}
          {status === 'video_call' && (
            <span>
              <Videocam />
              &nbsp;
              <FormattedMessage id='consultations.video.call' />
            </span>
          )}
          {status === 'waiting' && (
            <span>
              <HourglassFull />
              &nbsp;
              <FormattedMessage id='consultations.waiting' />
            </span>
          )}
          {(status === 'access_opened' && !isDoctor) && (
            <span>
              <LockOpen />
              &nbsp;
              <FormattedMessage id='consultations.hr.access.opened' />
            </span>
          )}
          {(status === 'access_closed' && !isDoctor) && (
            <span>
              <Lock />
              &nbsp;
              <FormattedMessage id='consultations.hr.access.closed' />
            </span>
          )}
          {status === 'completed' && (
            <span>
              <HourglassEmpty />
              &nbsp;
              {
                type === CONSULTATION_TYPES.telexpertise
                  ? <FormattedMessage id='consultations.telexpertise.bar' />
                  : <FormattedMessage id='consultations.completed' />
              }
            </span>
          )}
          {status === 'cancelled' && (
            <span>
              <CancelIcon />
              &nbsp;
              <FormattedMessage id='consultation.canceled' />
            </span>
          )}
        </Patient>
      </div>
      {
        isConcilium && (
          <img src={concilium} alt='' />
        )
      }
      <CancelConsultation>
        <DateWrapper>
          <Time>{moment.utc(date).format('HH:mm')}</Time>
        </DateWrapper>
        {isUpcoming && (
        <CancelButton
          variant='outlined'
          onClick={(e) => {
            e.stopPropagation();
            return setShowDeleteModal(true)
          }}
        > 
          <StyledCloseIcon /> <span style={{ color: 'red' }}><FormattedMessage id='global.cancel' /></span>
        </CancelButton>
        )}
      </CancelConsultation>
      {urgent && <UrgentLabel><FormattedMessage id='consultations.urgent' /></UrgentLabel>}
      {isUpcoming && (
      <ConfirmWithReasonModal
        onClick={e => e.stopPropagation()}
        title={<FormattedMessage id='consultation.delteconfirm' />} 
        titleProps={{
          disableTypography: true,
          style: { maxWidth: '460px', fontSize: '16px', fontWeight: '400 !important' },
        }}
        maxWidth={false}
        open={showDeleteModal}
        confirmButtonText={<WrapButtons><FormattedMessage id='consultation.delteconfirmyes' /></WrapButtons>}
        cancelButtonText={<WrapButtons><FormattedMessage id='consultation.delteconfirmno' /></WrapButtons>}
        text={<FormattedMessage id='consultation.cancelreason' />}
        setRefuseReason={setRefuseReason}
        refuseReason={refuseReason}
        handleSubmit={(e) => {
          e.stopPropagation();
          setShowDeleteModal(false);
          return dispatch(refuseConsultation(id, refuseReason))
            .then(() => refreshConsultations())
            .catch(e => console.warn(e))
        }}
        handleClose={(e) => { 
          e.stopPropagation();
          return setShowDeleteModal(false)
        }}
      />
      )}
    </Wrapper>
  )
}

ItemComponent.propTypes = {
  patient: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  urgent: PropTypes.bool.isRequired,
}

const calcStatus = (status, accessHealthRecords) => {
  if (status === 'confirmed') {
    return accessHealthRecords ? 'access_opened' : 'access_closed'
  }

  return status
}

const enhance = withProps(props => ({
  status: calcStatus(props.status, props.accessHealthRecords),
}))

export const Item = enhance(ItemComponent)
