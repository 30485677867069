import React, { useEffect, useState } from 'react';
import axios from 'axios'
import Dialog from '@material-ui/core/Dialog'
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { TextButton } from 'common/UiKit/TextButton'
import ReactCodeInput from 'react-verification-code-input'
import icon from '../../../images/auth/2fa.svg'
import iconError from '../../../images/auth/2fa-error.svg'

export const TwoFactorAuth = () => {
  const phoneNumber = useSelector(state => state.auth.attributes.phoneNumber || '')
  const [code, onChange] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [open, toggle] = useState(false)
  const [resent, toggleResent] = useState(true)

  const sendCode = () => axios('/user/2fa/request')

  const auth = async (value) => {
    try {
      setLoading(true)
      await axios.post('/user/2fa/verify', { '2fa_code': value || code })
      setLoading(false)
      localStorage.setItem('2fa', 1)
      toggle(false)
    } catch (e) {
      setLoading(false)
      setError(true)
    }
  }

  const resend = () => {
    sendCode()
    toggleResent(false)
  }

  const handleChange = (value) => {
    onChange(value)
    if (value.length === 4) {
      auth(value)
    }
  }

  useEffect(() => {
    if (!localStorage.getItem('2fa')) {
      toggle(true)
      sendCode()
    } else {
      toggle(false)
    }
  }, [])

  if (!open) {
    return null
  }

  return (
    <Dialog open>
      <Content>
        <Title error={error}>
          <FormattedMessage id={error ? 'auth.2fa.error.title' : 'auth.2fa.title'} />
        </Title>
        <Image src={error ? iconError : icon} alt='' />
        <Text>
          <FormattedMessage id='auth.2fa.text' />
          {' '}
          {phoneNumber.substring(0, phoneNumber.length - 2).replace(/[0-9]/g, '•')}
          {phoneNumber.substring(phoneNumber.length - 2)}
        </Text>
        <Code
          fields={4}
          onChange={handleChange}
          autoFocus
        />
        <Button color='primary' disabled={code.length < 4} onClick={auth} isFetching={loading} error={error}>
          <FormattedMessage id={error ? 'auth.2fa.try.again' : 'auth.2fa.verify'} />
        </Button>
        <Description>
          <FormattedMessage id='auth.2fa.description' />
          {' '}
          <Resend onClick={resend} disabled={!resent}>
            <FormattedMessage id='auth.2fa.resend' />
          </Resend>
        </Description>
      </Content>
    </Dialog>
  )
}

const Content = styled.div`
  padding: 32px 48px;
  max-width: 272px;
  text-align: center;
`

const Title = styled.div`
  color: ${({ error }) => (error ? '#FF6161' : '#209DFF')};
  font-size: 24px;
  font-weight: 500;
  line-height: 31px;
  text-align: center;
  margin-bottom: 25px;
`

const Text = styled.div`
  color: #21293E;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`

const Code = styled(ReactCodeInput)`
  margin: 24px 0;
  width: 100% !important;
  
  & > div {
    display: flex;
    justify-content: space-between;
    
    input {
      border: none !important;
      border-radius: 0 !important;
      background-color: #F5F7FB;
      font-size: 36px;
    }
  }
`

const Button = styled(TextButton)`
  && {
    background-color: ${({ error }) => (error ? '#FF6161' : '#209DFF')};
    min-width: 165px;
    height: 40px;
    font-weight: normal;
    margin: 0 auto 26px;
    
    &:hover {
      background-color: ${({ error }) => (error ? '#FF6161' : '#209DFF')};
    }
  }
`

const Description = styled.div`
  color: #21293E;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
`

const Resend = styled.a`
  color: ${({ disabled }) => (disabled ? '#21293E' : '#4A90E2')};
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`

const Image = styled.img`
  margin-left: 24px;
  margin-bottom: 20px;
`
