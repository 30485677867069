import React, { useState } from 'react';
import { Form, Field } from 'react-final-form'
import { Link, useLocation } from 'react-router-dom'
import { TextButton } from 'common/UiKit/TextButton'
import { Colors } from 'constants/colors'
import { Paper } from 'common/UiKit/Paper'
import styled from 'styled-components'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { PatientCalendar } from '../../Patient/Doctors/AppointmentConsultation/Calendar'
import { AttachmentsField } from './AttachmentsField'
import { PatientField } from './PatientField'
import { createConcilium } from '../../../../redux/modules/consultations'
import { MultilineTextField } from '../../../common/MultilineTextField'
import { CheckboxField } from '../../../common/CheckboxField'

//#region Styled Components
const MultilineField = styled(MultilineTextField)`
  & .root {
    height: 88px;
  }
`

const StyledForm = styled.form`
  & > div {
    margin-bottom: 16px;
  }
`

const Terms = styled.label`
  display: flex;
  align-items: flex-start;
  margin-top: 24px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  color: ${Colors.blueGrey};
  line-height: 1.29;
  
  button {
    margin-left: 24px;
    font-size: 14px;
    width: 280px;
    height: 36px;
  }

  & > div {
    flex-grow: 1;
  }
`

const Checkbox = styled(CheckboxField)`
  && {
    height: 24px;
  }
`

const LinkStyled = styled(Link)`
  text-decoration: none;
  color: ${Colors.clearBlue};
`
//#endregion

export const CreateConciliumForm = ({ doctorId, isUrgent, available }) => {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const { formatMessage } = useIntl()
  const [access, toggleAccess] = useState(false)
  const dispatch = useDispatch()
  const query = useQuery();
  const proposedDate = query.get('proposedDate');
  const onSubmit = values => dispatch(createConcilium({ ...values, doctorId }, isUrgent))

  const validate = (values) => {
    const errors = {}
    if (!values.proposedDate && !isUrgent) {
      errors.proposedDate = <FormattedMessage id='error.te.time' />
    }

    if (!values.symptomExplanation) {
      errors.symptomExplanation = <FormattedMessage id='error.te.description' />
    }

    if (values.symptomExplanation && values.symptomExplanation.length > 1000) {
      errors.symptomExplanation = <FormattedMessage id='error.te.description.long' />
    }

    if (!values.accessPolicy) {
      errors.accessPolicy = <FormattedMessage id='error.access.required' />
    }

    if (!values.patient) {
      errors.patient = <FormattedMessage id='error.patient.required' />
    }

    return errors
  }

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      subscription={{ submitting: true, pristine: true }}
      initialValues={{ proposedDate }}
      render={({ handleSubmit, submitting }) => (
        <StyledForm onSubmit={handleSubmit}>
          {!isUrgent && (
            <Paper heading='concilium.select.time'>
              <Field
                available={available}
                name='proposedDate'
                component={PatientCalendar}
                cellType='Available'
                concilium
                setTypeOfPrice={() => {}}
              />
            </Paper>
          )}
          <Field name='patient' component={PatientField} access={access} toggleAccess={toggleAccess} />
          <Paper heading='concilium.describe'>
            <Field
              component={MultilineField}
              name='symptomExplanation'
              label={`*${formatMessage({ id: 'concilium.describe' })}`}
              multiline
              rowsMax='3'
              maxLength={1000}
            />
          </Paper>
          <Field name='files' component={AttachmentsField} />
          <Terms>
            <Field
              name='accessPolicy'
              component={Checkbox}
              color='primary'
              type='checkbox'
              label={(
                <FormattedMessage
                  id='consultations.agree'
                  values={{
                    policy: (
                      <LinkStyled to='/panel/help/terms'>
                        <FormattedMessage id='consultations.policy' />
                      </LinkStyled>
                    ),
                  }}
                />
              )}
            />
            <TextButton
              color='primary'
              type='submit'
              disabled={submitting}
              isFetching={submitting}
            >
              <FormattedMessage id='concilium.start' />
            </TextButton>
          </Terms>
        </StyledForm>
      )}
    />
  )
}
