import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import idx from 'idx'
import { connect } from 'react-redux'
import { Paper } from 'common/UiKit/Paper'
import { Colors } from 'constants/colors'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import WarningIcon from '@material-ui/icons/Warning'

const Wrapper = styled.div`
  margin-bottom: 24px;
`

const List = styled.ol`
  counter-reset: myCounter;
  padding-left: 0;
  margin: 0;

  &>li {
    position: relative;
    list-style: none;
    margin-bottom: 20px;
    color: ${Colors.dark80};
    font-weight: 500;
  }

  p {
    margin: 0 0 0 25px;
    color: ${Colors.blueGrey};
    font-weight: normal;
  }

  &>li:before {
    counter-increment: myCounter;
    content: counter(myCounter) ".";
    margin-right: 13px;
  }
`

const CompatibleText = styled.div`
  width: 130px;
  position: absolute;
  left: calc(100% - 135px);
  top: 0;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${Colors.blueGrey};

  svg {
    font-size: 19px;
  }
`

const MediumWarningIcon = styled(WarningIcon)`
  color: ${Colors.yellowOchre};
`

const getStatus = (icon) => {
  if (icon === 'dangerously') {
    return (
      <CompatibleText>
        NOT COMPATIBLE&nbsp;
        <WarningIcon color='error' />
      </CompatibleText>
    )
  }

  if (icon === 'warning') {
    return (
      <CompatibleText>
        WITH LIMITATIONS&nbsp;
        <MediumWarningIcon />
      </CompatibleText>
    )
  }

  return (
    <CompatibleText>
      FULLY COMPATIBLE&nbsp;
      <CheckCircleOutlineIcon color='primary' />
    </CompatibleText>
  )
}

const CheckedListComponent = ({ list }) => {
  if (!list.length) return null

  return (
    <Wrapper>
      <Paper heading='Checked medicines'>
        <List>
          {list.map(item => (
            <li key={item.names.join(',')}>
              {item.names[0]}
              <p>{item.names[1]}</p>
              {getStatus(item.icon)}
            </li>
          ))}
        </List>
      </Paper>
    </Wrapper>
  )
}

CheckedListComponent.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.arrayOf(PropTypes.string),
    icon: PropTypes.string,
  })).isRequired,
}

const enhance = connect(
  state => ({ list: idx(state, _ => _.medicines.checkedWithEachOhter) }),
)

export const CheckedList = enhance(CheckedListComponent)
