import { Colors } from 'constants/colors'
import { CONSULTATION_TYPES } from './consultation'

export const CALENDAR_TYPES_OPTIONS = [
  {
    value: 'day',
    label: 'schedule.day',
  },
  {
    value: 'week',
    label: 'schedule.week',
  },
  {
    value: 'month',
    label: 'schedule.month',
  },
]

export const STATUS_OPTIONS = [
  {
    value: 'created',
    label: 'schedule.status.new',
    color: Colors.red,
  },
  {
    value: 'confirmed',
    label: 'schedule.status.upcoming',
    color: Colors.clearBlue,
  },
  {
    value: 'completed',
    label: 'schedule.status.past',
    color: Colors.tealish,
  },
  {
    value: 'cancelled',
    label: 'schedule.status.failed',
    color: Colors.dark60,
  },
]

export const TYPES_OPTIONS = [
  {
    value: 'urgent',
    label: 'schedule.type.urgent',
  },
  {
    value: 'scheduled',
    label: 'schedule.type.appointment',
  },
  {
    value: CONSULTATION_TYPES.telexpertise,
    label: 'concilium.title',
  },
]

export const EXPORT_OPTIONS = [
  {
    value: 'google',
    label: 'schedule.export.google',
  },
  {
    value: 'apple',
    label: 'schedule.export.apple',
  },
  {
    value: 'outlook',
    label: 'schedule.export.outlook',
  },
]
