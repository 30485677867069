import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import moment from 'moment'

import { MonthCalendarHeader } from './Header'
import { getMonthDaysArray } from '../../../../../helpers/calendar'
import { MonthDay } from './Day'
import { changeFilter } from '../../../../../redux/modules/calendar/filters'

export const MonthCalendar = React.memo(() => {
  const {
    from: fromDate,
    to: toDate,
  } = useSelector(state => state.calendar.filters.values.date)
  const statusFilter = useSelector(state => state.calendar.filters.values.status)
  const data = useSelector(state => state.calendar.list.month)
  const monthDays = useMemo(() => getMonthDaysArray(fromDate, toDate), [fromDate, toDate])
  const dispatch = useDispatch()
  const onDayClick = (day) => {
    const date = day.format('YYYY-MM-DD')
    dispatch(changeFilter('date', { from: date, to: date }))
    dispatch(changeFilter('calendarType', 'day'))
  }

  return (
    <Wrapper>
      <MonthCalendarHeader />
      <DaysWrapper>
        {
          monthDays.map(day => (
            <MonthDay
              day={day}
              key={day.format()}
              grey={day.isBefore(moment(fromDate))}
              statusFilter={statusFilter}
              onClick={() => onDayClick(day)}
              data={data[day.format('YYYY-MM-DD')]}
            />
          ))
        }
      </DaysWrapper>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  width: 100%;
`

const DaysWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  border-left: 1px solid ${Colors.divider};
`
