import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components'
import { TextButton } from 'common/UiKit/TextButton'
import { Colors } from 'constants/colors'

import { AuthDialog } from '../AuthDialog'
import { FormWrapper } from '../Login'
import { history } from '../../../utils/history';

export const UserTypeSelect = () => {
  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })
  const [selected, select] = useState('doctor')

  useEffect(() => {
    localStorage.setItem('userRole', 'ROLE_PATIENT')
  }, [])

  const onClick = (type) => {
    select(type)
    localStorage.setItem('userRole', type === 'doctor' ? 'ROLE_DOCTOR' : 'ROLE_PATIENT')
  }

  return (
    <AuthDialog heading={f('auth.select.role')}>
      <FormWrapper>
        <Content>
          <Option onClick={() => onClick('doctor')} selected={selected === 'doctor'}>
            <Radio selected={selected === 'doctor'} />
            <FormattedMessage id='auth.doctor' />
          </Option>
          <Option onClick={() => onClick('patient')} selected={selected === 'patient'}>
            <Radio selected={selected === 'patient'} />
            <FormattedMessage id='auth.patient' />
          </Option>
          <Button color='primary' onClick={() => history.push(`/auth/${selected}`)}>
            <FormattedMessage id='auth.next' />
          </Button>
        </Content>
      </FormWrapper>
    </AuthDialog>
  )
}

const Content = styled.div`
  padding: 122px 0 137px;
  margin-bottom: 250px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
`

const Radio = styled.div`
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  margin-right: 12px;
  border-radius: 50%;
  background-color: ${({ selected }) => (selected ? Colors.clearBlue : 'transparent')};
  border: ${({ selected }) => (selected ? 'none' : '1px solid #949EB8')};
  position: relative;
  
  &:after {
    content: ${({ selected }) => (selected ? '""' : 'none')};
    position: absolute;
    box-sizing: border-box;
    left: 4px;
    top: 4px;
    bottom: 4px;
    right: 4px;
    border-radius: 50%;
    border: 4px solid white;
  }
`

const Option = styled.div`
  font-weight: ${({ selected }) => (selected ? 'bold' : 'normal')};
  color: ${({ selected }) => (selected ? '#21293E' : '#949EB8')};
  font-size: 32px;
  line-height: 38px;
  cursor: pointer;
  display: flex;
  align-items: center;
`

const Button = styled(TextButton)`
  && {
    position: absolute;
    bottom: -20px;
    min-width: 190px;
  }
`
