import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import ArrowBack from '@material-ui/icons/ArrowBack'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import { FormattedMessage } from 'react-intl'

import bgImg from '../../../images/auth/bg-login.svg'
import { paleGrey, waterBlue } from '../../../constants/colors'

const Wrapper = styled.div`
  width: 680px;
  background: ${paleGrey} url(${bgImg}) no-repeat;
  background-size: contain;
  padding: 36px 32px 32px;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
`

const Header = styled.h1`
  margin: 0 0 30px 0;
  text-shadow: 0 1px 2px ${waterBlue};
  font-family: Roboto, sans-serif;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-align: center;
  color: white;
`

const IconButtonStyled = styled(IconButton)`
  && {
    padding: 10px;
    font-size: 41px;
    color: white;
    position: absolute;
    margin-top: -10px
  }
`

export const AuthDialog = ({
  backIcon,
  children,
  heading,
  onIconClick,
}) => (
  <Wrapper>
    {!!onIconClick && (
      <IconButtonStyled color='inherit' onClick={onIconClick}>
        {
          backIcon
            ? <ArrowBack fontSize='inherit' />
            : <Clear fontSize='inherit' />
        }
      </IconButtonStyled>
    )}
    <Header>
      <FormattedMessage id={heading} defaultMessage={heading} />
    </Header>
    {children}
  </Wrapper>
);

AuthDialog.defaultProps = {
  backIcon: false,
  onIconClick: undefined,
}

AuthDialog.propTypes = {
  backIcon: PropTypes.bool,
  children: PropTypes.node.isRequired,
  heading: PropTypes.string.isRequired,
  onIconClick: PropTypes.func,
}
