import React, { useState } from 'react';
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import ReorderIcon from '@material-ui/icons/Reorder'
import AppsIcon from '@material-ui/icons/Apps'

import { LinkCard } from './LinkCard'
import { Search } from '../../../../common/Search'

const Wrapper = styled.div`
  margin-top: 5px;
  
  & > * {
    max-width: 100%;
  }
`

const List = styled.div`
  margin: 10px -4px 0;
`

const Heading = styled.h1`
  margin: 0;
  font-size: 32px;
  font-weight: 600;
  color: ${Colors.dark};
  font-family: Roboto, sans-serif;
  display: flex;
  align-items: center;
`

const Filters = styled.div`
  width: 100%;
  text-align: right;
  margin-right: -8px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  
  svg {
    width: 32px;
    height: 32px;
    margin-left: 12px;
    cursor: pointer;
    
    &:first-of-type {
      width: 28px;
      height: 28px;
    }
  }
`

const ListIcon = styled(AppsIcon)`
  && {
    fill: ${({ active }) => (active ? Colors.clearBlue : Colors.blueGrey)};
  }
`

const TableIcon = styled(ReorderIcon)`
  && {
    fill: ${({ active }) => (active ? Colors.clearBlue : Colors.blueGrey)};
  }
`

export const Specialists = ({ specializations, to }) => {
  const isDoctor = useSelector(state => state.auth.type === 'Doctor')
  const [search, onSearch] = useState('')
  const [isListView, toggleView] = useState(true)

  const filteredList = search
    ? specializations.filter(i => (
      i.attributes.name.toLowerCase().includes(search.toLowerCase())
      || i.attributes.key.toLowerCase().includes(search.toLowerCase())
    ))
    : specializations

  return (
    <Wrapper>
      <Heading>
        <span>
          <FormattedMessage id={isDoctor ? 'consultations.our.specialists' : 'consultations.by.appointment'} />
        </span>
      </Heading>
      <Search onSearch={value => onSearch(value)} />
      <Filters>
        <ListIcon active={!isListView ? 1 : 0} onClick={() => toggleView(false)} />
        <TableIcon active={isListView ? 1 : 0} onClick={() => toggleView(true)} />
      </Filters>
      <List>
        {filteredList
          .map(item => (
            <LinkCard
              key={`${item.label}-${item.id}`}
              to={`${to || '/panel/doctors/appointment/'}${item.id}`}
              label={item.attributes.name}
              isListView={isListView}
            />
          ))}
      </List>
    </Wrapper>
  )
}

Specialists.defaultProps = {
  to: null,
}

Specialists.propTypes = {
  specializations: PropTypes.array.isRequired,
  to: PropTypes.string,
}
