import React from 'react'
import PropTypes from 'prop-types'
import Downshift from 'downshift'
import { lifecycle } from 'recompose'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import _ from 'lodash'

import { Input } from '../Input'

const AutocompleteComponent = ({
  options,
  input,
  onChangeThrottled,
  ...rest
}) => (
  <Downshift
    {...input}
    onInputValueChange={(inputValue) => {
      input.onChange(inputValue || '')
      onChangeThrottled(inputValue)
    }}
    itemToString={item => (item || '')}
    selectedItem={input.value}
  >
    {({
      getInputProps,
      getItemProps,
      isOpen,
      selectedItem,
    }) => (
      <div>
        <Input
          {...rest}
          {...getInputProps({ name: input.name })}
          value={selectedItem && selectedItem.label}
        />
        <Paper>
          {isOpen
            ? options
              .slice(0, 5)
              .map((item, index) => (
                <MenuItem
                  {...getItemProps({
                    key: item.value,
                    index,
                    item,
                  })}
                >
                  {item.label}
                </MenuItem>
              ))
            : null}
        </Paper>
      </div>
    )}
  </Downshift>
)

AutocompleteComponent.defaultProps = {
  onChangeThrottled: () => null,
}

AutocompleteComponent.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
  })).isRequired,
  input: PropTypes.object.isRequired,
  onChangeThrottled: PropTypes.func,
}

const enhance = lifecycle({
  componentDidMount() {
    this.setState({
      onChangeThrottled: _.throttle(v => this.props.onChange(v), 50),
    })
  },
})

export const Autocomplete = enhance(AutocompleteComponent)
