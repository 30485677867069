import React, { useRef } from 'react'
import { Paper } from 'common/UiKit/Paper'
import { Colors } from 'constants/colors'
import Button from '@material-ui/core/Button'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import DescriptionIcon from '@material-ui/icons/Description'
import CloseIcon from '@material-ui/icons/Close'

const Input = styled.input`
  display: none;
`

const List = styled.div`
  display: flex;
  flex-flow: row wrap;
`

const FileWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-radius: 4px;
  background-color: ${Colors.lightGrey};
  font-weight: 500;
  color: ${Colors.dark60};
  margin-right: 12px;
  margin-bottom: 8px;
  font-size: 14px;
  
  svg {
    margin-right: 12px;
  }
`

const CloseWrapper = styled.div`
  width: 40px;
  text-align: right;
  color: ${Colors.red};
  
  svg {
    cursor: pointer;
    height: 18px;
    margin-top: -18px;
    margin-right: -6px;
  }
`

export const AttachmentsField = ({ input }) => {
  const inputRef = useRef(null)

  const onButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  const onDelete = index => input.onChange(input.value.filter((item, idx) => idx !== index))

  return (
    <Paper heading='concilium.attachments' optional>
      {input.value ? (
        <List>
          {
            input.value.map((item, index) => (
              <FileWrapper>
                <DescriptionIcon />
                <span>
                  {item.name}
                </span>
                <CloseWrapper>
                  <CloseIcon onClick={() => onDelete(index)} />
                </CloseWrapper>
              </FileWrapper>
            ))
          }
        </List>
      ) : null}
      <Button size='small' color='primary' onClick={onButtonClick}>
        <FormattedMessage id='concilium.add' />
      </Button>
      <Input type='file' multiple ref={inputRef} onChange={e => input.onChange([...input.value, ...e.target.files])} />
    </Paper>
  )
}
