import React, { useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { FormattedMessage } from 'react-intl'
import { Colors } from 'constants/colors'
import { useDispatch, useSelector } from 'react-redux'
import idx from 'idx'
import axios from 'axios'
import styled from 'styled-components'
import { fetchContraindications } from '../../../../redux/modules/content'
import { externalFormSubmit } from '../../../../helpers/externalFormSubmit'
import { HealthDescriptionForm } from '../../../common/HealthDescriptionForm'

const Wrapper = styled(DialogContent)`
  && {
    overflow-y: visible;
    background-color: ${Colors.background};
  }
`

const Title = styled(DialogTitle)`
  && {
    background-color: ${Colors.background};
  }
`

const Actions = styled(DialogActions)`
  && {
    background-color: ${Colors.background};
    margin: 0;
    padding: 16px 8px;
  }
`

export const CheckboxWrapper = styled.div`
  text-align: right;
  
  span:last-of-type {
    color: ${Colors.blueGrey};
  }
`

export const CreatePatient = ({ open, onClose, afterSubmit }) => {
  const dispatch = useDispatch()
  const [checked, toggleCheckbox] = useState(false)
  const contraindications = useSelector(state => idx(state, _ => _.content.contraindications || [])
    .map(({ attributes: a }) => ({ label: a.name, value: a.uid })))

  const onSubmit = values => axios
    .post('/api/doctor/virtualPatient', values)
    .then((response) => {
      afterSubmit(response.data.data)
      onClose()
    })

  useEffect(() => {
    dispatch(fetchContraindications())
  }, [dispatch])

  return (
    <Dialog open={open} onClose={onClose} maxWidth='md'>
      <Title>
        <FormattedMessage id='concilium.create.patient' />
      </Title>
      <Wrapper>
        <HealthDescriptionForm
          onSubmit={onSubmit}
          id='patient-form'
          afterSubmit={() => {}}
          locales={{
            smoke: 'hr.patient.smoke',
            pregnant: 'hr.patient.pregnant',
          }}
          additionalFields={[
            {
              label: 'concilium.name',
              name: 'fullName',
              placeholder: 'concilium.name.placeholder',
            },
            {
              label: 'profile.birth.date',
              name: 'birthday',
              type: 'date',
              placeholder: 'concilium.date.placeholder',
            },
            {
              label: 'profile.insurance',
              name: 'insurance',
              placeholder: '* 1234567890123 12',
              parse: value => value.substr(0, 16),
              validate: (value) => {
                if (!value || !value.length || value.length !== 15 || /[^A-B0-9]/.test(value)) {
                  return <FormattedMessage id='error.patient.insurance_13' />
                }
                return false;
              },
            },
          ]}
          contraindications={contraindications}
        />
        <CheckboxWrapper>
          <FormControlLabel
            control={(
              <Checkbox
                checked={checked}
                color='primary'
                onChange={e => toggleCheckbox(!!e.target.checked)}
                name='access'
              />
            )}
            label={<FormattedMessage id='concilium.patient.access' />}
          />
        </CheckboxWrapper>
      </Wrapper>
      <Actions>
        <Button
          size='small'
          color='primary'
          onClick={onClose}
        >
          <FormattedMessage id='global.cancel' />
        </Button>
        <Button
          size='small'
          color='primary'
          disabled={!checked}
          onClick={() => externalFormSubmit('patient-form')}
          variant='contained'
        >
          <FormattedMessage id='global.save' />
        </Button>
      </Actions>
    </Dialog>
  )
}
