import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'
import {
  compose,
  lifecycle,
  withStateHandlers,
} from 'recompose'
import { CircularProgress } from '@material-ui/core'
import { OTSubscriber, OTPublisher, OTStreams } from 'opentok-react'
import { useDispatch } from 'react-redux'
import { ControlPanel } from './ControlPanel'
import { setNotification } from '../../../redux/modules/notifications'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute; 
  top: 0;
  background-color: grey;
  z-index: 2;

  ${({ fullSize }) => !fullSize && `
    width: 248px;
    height: 186px;
    position: fixed;
    left: 16px;
    top: calc(100% - 186px - 16px);
  `}
`

const SubscriberWrapper = styled.div`
  width: 100%;
  height: 100%;
  
  & > div {
    display: flex;
    
    & > div {
      flex-grow: 1;
    }
  }

  &, div {
    height: 100%;
  }
`

const SpinnerWrapper = styled.div`
  position: absolute;
  width: 100%;
  max-width: calc(100vw - 280px);
  justify-content: center;
  height: 60px !important;
  top: calc(50vh - 30px);
  text-align: center;
  
  div {
    max-height: 100%;
    flex-grow: 0 !important;
  }
`

const PublisherWrapper = styled.div`
  width: 160px;
  height: 192px;
  position: absolute; 
  top: calc(100% - 192px - 24px);
  left: calc(100% - 160px - 24px);
  border-radius: 4px;
  overflow: hidden;
  ${({ hidden }) => hidden && 'opacity: 0;'}
`

// Have to wrap into OTSession component. See opentok-react package
const VideoChatComponent = ({
  firstName,
  lastName,
  duration,
  fullSize,
  toggleSize,
  toggleAudio,
  toggleVideo,
  onEndButtonClick,
  matchConsultationSession,
  setEndTimeout,
  endTimeout,
  access,
}) => {
  const dispatch = useDispatch()
  return (
    <Wrapper fullSize={fullSize}>
      <ControlPanel
        firstName={firstName}
        lastName={lastName}
        duration={duration}
        onChatButtonClick={toggleSize}
        onEndButtonClick={onEndButtonClick}
        onResizeButtonClick={toggleSize}
        onMicrophoneButtonClick={toggleAudio}
        onVideocamButtonClick={toggleVideo}
        fullSize={fullSize}
        publishAudio={access.audio}
        publishVideo={access.video}
        matchConsultationSession={matchConsultationSession}
      />
      <SubscriberWrapper>
        <OTStreams>
          <OTSubscriber
            retry
            properties={{
              height: '100%',
              width: '100%',
            }}
            onError={e => console.log(e)}
            eventHandlers={{
              connected: () => {
                if (endTimeout) {
                  // clearInterval(endTimeout)
                  setEndTimeout(null)
                }
              },
              destroyed: () => {
                setEndTimeout(
                  // setTimeout(() => onEndButtonClick(), 5000)
                  true,
                )
              },
              videoDisabled: () => {
                console.log('Subscriber video disabled!');
              },
              videoEnabled: () => {
                console.log('Subscriber video enabled!');
              },
            }}
          />
        </OTStreams>
        {
          !!endTimeout && (
            <SpinnerWrapper>
              <CircularProgress size={60} />
            </SpinnerWrapper>
          )
        }
      </SubscriberWrapper>
      <PublisherWrapper hidden={!fullSize || !access.video}>
        <OTPublisher
          properties={{
            height: '192px',
            width: '160px',
            showControls: false,
            publishAudio: access.audio,
            publishVideo: access.video,
          }}
          onError={(e) => {
            console.log(e.name, e);
            if (e.name === 'OT_USER_MEDIA_ACCESS_DENIED' || e.name === 'OT_NO_DEVICES_FOUND') {
              dispatch(setNotification({ title: 'error.call.denied', type: 'warning' }))
              toggleVideo(false)

              if (e.name === 'OT_NO_DEVICES_FOUND') {
                toggleAudio(false)
              }
            }
          }}
        />
      </PublisherWrapper>
    </Wrapper>
  )
}

VideoChatComponent.defaultProps = {
  endTimeout: null,
}

VideoChatComponent.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  duration: PropTypes.object.isRequired,
  toggleAudio: PropTypes.func.isRequired,
  toggleVideo: PropTypes.func.isRequired,
  toggleSize: PropTypes.func.isRequired,
  fullSize: PropTypes.bool.isRequired,
  onEndButtonClick: PropTypes.func.isRequired,
  matchConsultationSession: PropTypes.bool.isRequired,
  setEndTimeout: PropTypes.func.isRequired,
  endTimeout: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
}

const enhance = compose(
  withStateHandlers(props => ({
    fullSize: props.matchConsultationSession,
    duration: moment().minutes(0).seconds(0),
    endTimeout: null,
  }), {
    toggleSize: ({ fullSize }) => () => ({ fullSize: !fullSize }),
    increaseDuration: ({ duration }) => () => ({ duration: duration.add(1, 's') }),
    setEndTimeout: () => endTimeout => ({ endTimeout }),
  }),
  lifecycle({
    componentDidMount() {
      this.timerID = setInterval(() => this.props.increaseDuration(), 1000)
    },
    componentWillUnmount() {
      clearInterval(this.timerID)
      if (this.props.endTimeout) {
        clearInterval(this.props.endTimeout)
        this.props.setEndTimeout(null)
      }
    },
  }),
)

export const VideoChat = enhance(VideoChatComponent)
