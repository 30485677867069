import React from 'react'
import { Form, Field } from 'react-final-form'
import { mapProps } from 'recompose'
import PropTypes from 'prop-types'
import moment from 'moment'
import styled from 'styled-components'
import { useIntl } from 'react-intl'
import { RadioInputField } from '../RadioInputField'
import { CheckboxInputField, FieldWrapper } from '../CheckboxInputField'
import { Time } from './Time'
import { TextField } from '../TextField'
import { DateField } from '../DateField'

const DateWrapper = styled(FieldWrapper)`
  & > div {
    width: 100% !important;
    
    & > div {
      margin-bottom: 0 !important;
    }
  }
`

const normalizeInitialValues = values => ({
  ...values,
  height: values.height || null,
  weight: values.weight || null,
  smoke: values.smoke.toString(),
  pregnant: values.pregnant.toString(),
  lastHospitalized: values.lastHospitalized && moment(values.lastHospitalized).format('YYYY-MM-DD'),
})

const normalizeValues = values => ({
  ...values,
  height: values.height || 0,
  weight: values.weight || 0,
  lastHospitalized: values.lastHospitalized || '',
})


const validate = ({ height, weight }) => {
  const errors = {}

  if (height && (height > 300 || height < 10)) errors.height = 'Wrong height'
  if (weight && (weight > 500 || weight < 3)) errors.weight = 'Wrong weight'

  return errors
}

const enhance = mapProps(props => ({
  ...props,
  onSubmit: values => props.onSubmit(normalizeValues(values))
    .then(() => props.afterSubmit()),
}))

const RADIO_OPTIONS = [
  { label: 'not', value: 'false' },
  { label: 'yes', value: 'true' },
]

export const HealthDescriptionForm = enhance(({
  onSubmit,
  id,
  initialValues,
  contraindications,
  additionalFields,
  locales,
}) => {
  const { formatMessage: f } = useIntl()
  const options = RADIO_OPTIONS.map(item => ({ ...item, label: f({ id: `global.${item.label}` }) }))

  return (
    <Form
      onSubmit={onSubmit}
      subscription={{}}
      validate={validate}
      initialValues={initialValues && normalizeInitialValues(initialValues)}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} id={id}>
          {
            additionalFields && additionalFields.map((item) => {
              if (item.type === 'date') {
                return (
                  <DateWrapper headingTransparent={f({ id: item.label })}>
                    <Field
                      name={item.name}
                      component={DateField}
                      invalidLabel=''
                      label={f({ id: item.label })}
                      minDate={moment('1900', 'YYYY)')}
                      maxDate={Date.now()}
                      parse={value => value && moment(value).format('YYYY-MM-DD')}
                    />
                  </DateWrapper>
                )
              }
              return (
                <FieldWrapper headingTransparent={f({ id: item.label })}>
                  <Field
                    name={item.name}
                    component={TextField}
                    {...item}
                    label={f({ id: item.label })}
                  />
                </FieldWrapper>
              )
            })
          }
          <Time />
          <CheckboxInputField
            headingTransparent={f({ id: 'hr.diseases' })}
            name='contraindication'
            id='contraindication'
            options={contraindications}
          />
          <RadioInputField
            headingTransparent={f({ id: locales ? locales.smoke : 'hr.do.you.smoke' })}
            name='smoke'
            id='smoke'
            options={options}
          />
          <RadioInputField
            headingTransparent={f({ id: locales ? locales.pregnant : 'hr.are.pregnant' })}
            name='pregnant'
            id='pregnant'
            options={options}
          />
          <br />
        </form>
      )}
    />
  )
})

HealthDescriptionForm.defaultProps = {
  initialValues: false,
}

HealthDescriptionForm.propTypes = {
  afterSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  contraindications: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  initialValues: PropTypes.shape({
    height: PropTypes.number,
    weight: PropTypes.number,
    lastHospitalized: PropTypes.string,
    contraindication: PropTypes.arrayOf(PropTypes.string),
    smoke: PropTypes.bool,
  }).isRequired,
}
