import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import InsertInvitation from '@material-ui/icons/InsertInvitation'
import Place from '@material-ui/icons/Place'
import StarIcon from '@material-ui/icons/Star'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'
import GroupIcon from '@material-ui/icons/Group';
//import Popover from '@material-ui/core/Popover';
//import Typography from '@material-ui/core/Typography';
import { TextButton } from 'common/UiKit/TextButton'
import { Paper } from 'common/UiKit/Paper'
import CloseIcon from '@material-ui/icons/Close';
import { Colors } from '../../../constants/colors'
import avatar from '../../../images/panel/default-avatar.svg'
import { FavoriteButton } from './FavoriteButton'

const PaperStyled = styled(Paper)`
  padding: 20px 24px;
  position: relative;
`
// const PopoverStyled = styled(Popover)`
//   pointer-events: none;
//   p {
//     padding: 10px;
//   }
// `


const Wrapper = styled.div`
  display: flex;
  font-weight: normal;
  font-size: 16px
`

const Avatar = styled.div`
  min-width: 126px;
  width: 126px;
  height: 126px;
  border: 7px solid white;
  border-radius: 100%;
  background: url(${props => props.photo || avatar}) ${Colors.lightGrey} center no-repeat; 
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px 0 rgba(6, 173, 255, 0.1);
  cursor: pointer;
  position: relative;
`

export const Rating = styled.div`
  position: absolute;
  bottom: -12px;
  width: 64px;
  background-color: #fff;
  font-size: 16px;
  border-radius: 30px;
  color: ${Colors.dark80};
  box-shadow: 0 4px 8px 0 rgba(6, 173, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  height: 30px;
  padding-left: 2px;
  
  svg {
    fill: #ffcc00;
    width: 24px;
    height: 24px;
    margin-left: 4px;
  }
`

const AvatarWrapper = styled.div`
  & > p {
    margin: 16px 0 0;
    color: ${Colors.clearBlue};
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }
`

const Info = styled.div`
  flex-grow: 1;
  margin: 12px 32px 0;
  cursor: pointer;
`
const Name = styled.h1`
  margin: 0;
  font-size: 24px;
  font-weight: bold;
`

const Specialization = styled.p`
  margin: 0;
  opacity: 0.8;
  padding-top: 10px;
`

const Over = styled.p`
  margin: 0;
  color: ${Colors.blueGrey};
  display: flex;
  align-items: center;
  margin-top: 12px;
  text-transform: capitalize;

  && svg {
    font-size: 18px;
    margin-right: 12px;
  }
`
const TeamWrapper = styled.div`
  cursor: pointer;
  text-align: right;
  display: flex;
  align-items: flex-end;

  svg {
    fill: ${Colors.disabled};
    cursor: pointer;
  }
  justify-content: flex-end;
`
const Button = styled(TextButton)`
  && {
    height: auto;
    margin-top: 18px;
    padding: 9px 58px;
    font-size: 14px;
    font-weight: 500;
  }
`

const LanguageWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  text-align: center;
  display: flex;
  align-items: center;
`

const Language = styled.span`
  opacity: 0.5;
  font-size: 16px;
  color: ${Colors.blueGrey};
  text-transform: uppercase;
`

const Team = styled.span`
  opacity: 0.5;
  font-size: 16px;
  margin-right: 6px;
  color: ${Colors.blueGrey};
  text-transform: uppercase;
`

const TeamsWrapper = styled.div`
  position: absolute;
  top: 46px;
  right: 16px;
  max-height: 25px;
  overflow: hidden;
  z-index: 99;
`

const CloseTeam = styled(CloseIcon)`
  position: absolute;
  top: 0;
  right: 0;
`

const ClickableTE = styled.div`
  background-color: #61b72b;
  color: #fff;
  border-radius: 5px;
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
  transition: 0.4s;

  &:hover {
    background-color: #73d833;
  }
`

const expandStyle = {
  maxHeight: '500px',
  backgroundColor: '#fff',
  padding: '20px',
  boxShadow: '0px 0px 6px -1px',
}

export const DoctorCard = ({
  language,
  firstName,
  lastName,
  teams,
  // experience,
  specialization,
  photo,
  //available,
  price,
  location,
  onButtonClick,
  onAvatarClick,
  isFavorite,
  toggleFavorite,
  isFavoriteFetching,
  rating,
  buttonText,
  onTeamChange,
  isDoctor,
  calendarNearestTE,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [expandedTeams, setExpandexTeams] = React.useState(false);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <PaperStyled>
      <LanguageWrapper>
        <Language>
          {Array.isArray(language) ? language.sort().join(' ') : null}
        </Language>
        <FavoriteButton
          checked={isFavorite}
          onClick={() => toggleFavorite(!isFavorite)}
          isFetching={isFavoriteFetching}
        />

      </LanguageWrapper>
      <TeamsWrapper
        style={expandedTeams ? expandStyle : {}} 
        onClick={() => setExpandexTeams(!expandedTeams)}
      >
        { teams && teams.length && teams.length > 0 ? teams.filter(el => !!el).map(team => (
          <TeamWrapper
            onClick={expandedTeams ? () => onTeamChange(team.id) : () => {}}
            key={team.name}
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup='true'
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            <Team>
              {team.name}
              {/*<PopoverStyled
                open={open}
                id='mouse-over-popover'
                anchorEl={anchorEl}
                rrrrrrr
                disableRestoreFocus
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <Typography>
                  {' '}
                  <FormattedMessage id='profile.aboutTeam' />
                  {` "${team.name}"`}
                </Typography>
              </PopoverStyled>*/}
            </Team>
            <GroupIcon />
          </TeamWrapper>
        )) : ''}
        {expandedTeams ? <CloseTeam /> : ''}
      </TeamsWrapper>

      <Wrapper>
        <AvatarWrapper>
          <Avatar
            photo={photo && photo.includes('/file/') ? `http://backend-application.owndoctor.eu${photo}` : photo}
            onClick={onAvatarClick}
          >
            {
            !!rating && (
              <Rating>
                {rating}
                <StarIcon />
              </Rating>
            )
          }
          </Avatar>
          <p>
            {!isDoctor ? <>{price || '25'} &nbsp;&euro;</> : ''}
          </p>
        </AvatarWrapper>
        <Info onClick={onAvatarClick}>
          <Name>
            {`${firstName || 'Name'} ${lastName || 'Surname'}`}
          </Name>
          <Specialization>
            {specialization || 'Therapist'}
            {/*<FormattedMessage id='global.years.experience' />*/}
          </Specialization>
          <Over>
            <Place />
            {Object.keys(location).length
              ? `${location.name}, ${location.street}, ${location.city}`
              : <FormattedMessage id='global.location' />}
          </Over>
          {/*available 
            ? (
              <Over>
                <InsertInvitation />
                <FormattedMessage id='consultation.doctor.available' />
                {': '}
                {moment(available).calendar(null, {
                  sameElse: 'DD.MM.YYYY HH:mm',
                })}
              </Over>
            ) : ''*/}
          {calendarNearestTE && isDoctor
            ? (
              <Over onClick={e => onButtonClick(e, moment(calendarNearestTE).format('YYYY-MM-DD HH:mm'))}>
                <InsertInvitation />
                <FormattedMessage id='consultation.doctor.calendarNearestTE' />
                {': '}
                <ClickableTE>{moment(calendarNearestTE).calendar(null, {
                  sameElse: 'DD.MM.YYYY HH:mm',
                })}
                </ClickableTE>
              </Over>
            ) : ''}
          <Button
            color='primary'
            variant='extended'
            onClick={onButtonClick}
          >
            <FormattedMessage id={buttonText || 'consultation.make.appointment'} />
          </Button>
        </Info>
      </Wrapper>
    </PaperStyled>
  )
}

DoctorCard.defaultProps = {
  location: {},
  onAvatarClick: () => null,
  photo: '',
  language: [],
  teams: null,
  buttonText: null,
  rating: null,
}

DoctorCard.propTypes = {
  language: PropTypes.arrayOf(PropTypes.string),
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  teams: PropTypes.array,
  //experience: PropTypes.number.isRequired,
  specialization: PropTypes.string.isRequired,
  photo: PropTypes.string,
  //available: PropTypes.string,
  price: PropTypes.number.isRequired,
  location: PropTypes.object,
  onButtonClick: PropTypes.func.isRequired,
  onAvatarClick: PropTypes.func,
  isFavorite: PropTypes.bool.isRequired,
  toggleFavorite: PropTypes.func.isRequired,
  isFavoriteFetching: PropTypes.bool.isRequired,
  rating: PropTypes.number,
  buttonText: PropTypes.string,
}
