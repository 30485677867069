import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Paper } from 'common/UiKit/Paper'
import { FormattedMessage, useIntl } from 'react-intl'
import { questionsData } from '../../../../../helpers/Doctor/QuestionHelper'

const PaperStyled = styled(Paper)`
  padding: ${props => (props.blank ? 0 : '24px 24px 1px 24px')};
`
const QuestionName = {
  fontWeight: 'bold',
}

const Wrapper = styled.div`
  line-height: 1.38;
  margin-bottom: 0;

  & > p {
    opacity: 0.8;
    margin: 0;
  }
`

const Line = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:first-child {
    opacity: 0.87;
  }
`

export const Questions = ({
  editBtnPath,
}) => {
  const { formatMessage } = useIntl()
  const [questions, setQuestions] = useState([]);

  function getLLKey(key) {
    if (key === 1 || key === '1') {
      return 'yes';
    }

    return 'not';
  }

  useEffect(() => {
    questionsData().then(result => setQuestions(result))
  }, []);

  return (
    <PaperStyled
      heading={formatMessage({ id: 'profile.questions' })}
      changeButtonPath={editBtnPath}
    >
      {questions.map(({ attributes, id }) => (
        <Wrapper key={id}>
          <Line>
            <p>
              <span style={QuestionName}>
                <FormattedMessage id={`profile.${attributes.question}`} />
              </span>
              <br />
              {attributes.question === 'specialist.question.2'
                ? <FormattedMessage id={`global.${getLLKey(attributes.answer)}`} />
                : attributes.answer
              }
            </p>
          </Line>
        </Wrapper>
      ))}
    </PaperStyled>
  )
}
