import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Rating from 'react-rating'
import StarIcon from '@material-ui/icons/Star'
import { TextField } from 'common/UiKit/TextField'
import { Colors } from 'constants/colors'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core'
import { Form, Field } from 'react-final-form'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'

const RateTitle = styled.div`
  text-align: center;
  padding: 0 40px;
`

const EmptyStar = styled(StarIcon)`
  && {
    color: ${Colors.disabled};
    font-size: 36px;
    width: 36px;
    height: 36px;
  }
`

export const FullStar = styled(StarIcon)`
  && {
    color: #ffcc00;
    font-size: 36px;
    width: 36px;
    height: 36px;
  }
`

const ContentWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  overflow-x: hidden;
  width: 360px;
`

const CommentField = styled(TextField)`
  && {
    background-color: transparent;
    margin-top: 24px;
    margin-bottom: 40px;
    
    & .root {
      height: auto;
    }
  }
`

const HelperText = styled.span`
  display: block;
  text-align: right;
`

const ContentContainer = styled(DialogContent)`
  && {
    padding-top: 0 !important;
  }
`

export const RateModal = ({ onClose, onSubmit }) => {
  const validate = (values) => {
    const errors = {}

    if (values.rating !== 0 && !values.rating) {
      errors.rating = <FormattedMessage id='errors.required' />
    }

    if (!values.text) {
      errors.text = <FormattedMessage id='errors.required' />
    }

    return errors
  }

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>
        <RateTitle>
          <FormattedHTMLMessage id='consultation.review.rate' />
        </RateTitle>
      </DialogTitle>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, hasValidationErrors, submitting }) => (
          <form onSubmit={handleSubmit}>
            <ContentContainer>
              <ContentWrapper>
                <Field
                  name='rating'
                  render={({ input: { onChange, value }, meta }) => (
                    <>
                      <Rating
                        emptySymbol={<EmptyStar />}
                        fullSymbol={<FullStar />}
                        onChange={onChange}
                        initialRating={value}
                      />
                      {
                        (meta.touched && meta.error) && (
                          <span>{meta.error}</span>
                        )
                      }
                    </>
                  )}
                />
                <Field
                  name='text'
                  render={({ input: { onChange, ...input }, ...props }) => (
                    <CommentField
                      {...props}
                      input={{
                        ...input,
                        onChange: (e) => {
                          if (e && e.target) {
                            onChange(e.target.value.slice(0, 100))
                          }
                        },
                      }}
                      helperText={<HelperText>{input.value.length.toString()}/100</HelperText>}
                    />
                  )}
                  label={<FormattedMessage id='consultation.review.comment' />}
                  maxRows={4}
                  multiline
                />
              </ContentWrapper>
            </ContentContainer>
            <DialogActions>
              <Button
                size='small'
                color='primary'
                onClick={onClose}
              >
                <FormattedMessage id='consultation.review.later' />
              </Button>
              <Button
                size='small'
                color='primary'
                type='submit'
                disabled={hasValidationErrors || submitting}
              >
                <FormattedMessage id='consultation.review.done' />
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  )
}

RateModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}
