import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux'
import { logOut } from '../../../../redux/modules/auth'

export const ModalRequestDeletionComponent = (props) => {
  const {
    open,
    handleClose,
    logOut,
  } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => handleClose('requestDeletion', false)}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        <DialogTitle id='scroll-dialog-title'>
          <Typography variant='p' align='center'>
            <FormattedMessage id='gdpr.usage.request.csv.deletion' />
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id='scroll-dialog-description'
            tabIndex={-1}
          >
            <FormattedMessage id='gdpr.modal.request.deletion' />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid justify='center' direction='row' container>
            <Button onClick={logOut} color='primary'>
              <FormattedMessage id='global.delete' />
            </Button>
            <Button onClick={() => handleClose('requestDeletion', false)} color='default'>
              <FormattedMessage id='global.cancel' />
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ModalRequestDeletionComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  logOut: PropTypes.func.isRequired,
};

const enhance = connect(state => ({ id: state.auth.id }), { logOut })

export const ModalRequestDeletion = enhance(ModalRequestDeletionComponent);
