import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import moment from 'moment'

import { Page } from '../../../common/Page'
import { logOut } from '../../../../redux/modules/auth'
import { CalendarFilters } from './Filters'
import { WeekCalendar } from './Week'
import { DayCalendar } from './Day'
import { MonthCalendar } from './Month'
import { ConfirmDialog } from './ConfirmDialog'
import { fetchCalendar } from '../../../../redux/modules/calendar/list'
import { Title } from '../../../common/Title'
import { EventDialog } from './EventDialog';
import { DeleteDialog } from './DeleteDialog';

export const CalendarPage = () => {
  const dispatch = useDispatch()
  const [eventModal, setEventModal] = useState(null)
  const [deleteModal, setDeleteModal] = useState(null)
  const month = useSelector(state => moment(state.calendar.filters.values.date.from).format('MMMM YYYY'))
  const calendarType = useSelector(state => state.calendar.filters.values.calendarType)
  const dateFilter = useSelector(state => state.calendar.filters.values.date)
  const [editing, toggleEditing] = useState(false)
  const [confirmDialog, setConfirmDialog] = useState(null)
  const calendarProps = {
    confirm: setConfirmDialog,
    editing,
    setEventModal,
    setDeleteModal,
  }

  useEffect(() => {
    dispatch(fetchCalendar({ date: dateFilter, calendarType }))
  }, [dateFilter, calendarType])

  const refetch = () => dispatch(fetchCalendar({ date: dateFilter, calendarType }))

  return (
    <PageStyled
      label='schedule.title'
      fullWidth
      onLogout={() => dispatch(logOut())}
    >
      <Wrapper>
        <Title>{month}</Title>
        <CalendarFilters
          editing={editing}
          toggleEditing={toggleEditing}
          showEditing={calendarType !== 'month'}
        />
        {
          calendarType === 'week'
          && (
            <WeekCalendar
              {...calendarProps}
              refetch={refetch}
            />
          )
        }
        {
          calendarType === 'day'
          && <DayCalendar {...calendarProps} />
        }
        {
          calendarType === 'month'
          && <MonthCalendar />
        }
      </Wrapper>
      {
        !!confirmDialog && (
          <ConfirmDialog
            onClose={() => setConfirmDialog(null)}
            data={confirmDialog}
            afterSubmit={refetch}
          />
        )
      }
      {
        <EventDialog open={!!eventModal} onClose={() => setEventModal(null)} data={eventModal} />
      }
      <DeleteDialog open={!!deleteModal} onClose={() => setDeleteModal(null)} data={deleteModal} />
    </PageStyled>
  )
}

const Wrapper = styled.div`
  max-width: 1400px;
  min-width: 1100px;
  overflow: initial;
  margin: 16px auto 0;
  padding-right: 16px;
  animation: .2s fadeIn ease-out;
  
  .calendar__first-cell {
    top: 72px;
  }
`

const PageStyled = styled(Page)`
  overflow: initial;
  
  & > div {
    &, & > div {
      overflow: initial;
    }
  }
`
