import React from 'react';
import Select, { components } from 'react-select'
import { useSelector } from 'react-redux';
import styled from 'styled-components'
import SearchIcon from '@material-ui/icons/Search'
import { Colors } from 'constants/colors'
import { useIntl } from 'react-intl'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

const Control = props => (
  <ControlWrapper>
    <SearchIcon />
    <components.Control {...props} />
  </ControlWrapper>
)

const SelectContainer = ({ children, ...props }) => (
  <Container {...props}>
    {children}
  </Container>
)

const Placeholder = props => (
  <PlaceholderWrapper>
    <components.Placeholder {...props} />
  </PlaceholderWrapper>
)


const ValueContainer = ({ children, ...props }) => (
  <ValueWrapper>
    <components.ValueContainer {...props}>
      {children}
    </components.ValueContainer>
  </ValueWrapper>
)

const Menu = props => (
  <MenuWrapper>
    <components.Menu {...props} />
  </MenuWrapper>
)

const Option = ({ isSelected, ...props }) => (
  <OptionWrapper isSelected={isSelected}>
    <components.Option isSelected={isSelected} {...props} />
    <CheckCircleIcon />
  </OptionWrapper>
)

export const SpecializationsSelect = ({ meta = {}, ...rest }) => {
  const list = useSelector(state => state.content.specializations)
  const { formatMessage } = useIntl()

  const value = rest.value
    ? list.find((i) => {
      const value = rest.getOptionValue ? rest.getOptionValue(i) : i.id
      return value === rest.value || value === rest.value.toString()
    })
    : undefined

  return (
    <>
      <Select
        options={list}
        components={{
          Control,
          ValueContainer,
          SelectContainer,
          Menu,
          Option,
          Placeholder,
        }}
        getOptionValue={option => option.id}
        getOptionLabel={option => option.attributes.name}
        placeholder={formatMessage({ id: 'global.search' })}
        {...rest}
        onChange={option => rest
          .onChange(rest.getOptionValue ? rest.getOptionValue(option) : option.id)}
        value={value}
        defaultValue={value}
      />
      {
        meta.touched && meta.error
          ? (
            <Error>
              {meta.error}
            </Error>
          )
          : null
      }
    </>
  )
}

const ControlWrapper = styled.div`
  position: relative;
  color: ${Colors.clearBlue};
  
  & > div {
    padding: 9px 0 9px 54px;
    cursor: pointer;
    border-radius: 2px;
    border: none;
    box-shadow: 0 4px 8px 0 rgba(0, 30, 45, 0.12), 0 -1px 4px 0 rgba(0, 30, 45, 0.1);
    
    & > div:last-child {
      display: none;
    }
  }
  
  & > svg {
    position: absolute;
    left: 16px;
    top: 15px;
    z-index: 10;
  }
`

const ValueWrapper = styled.div`
  width: 80%;
  
  & > div div {
  }
`

const Container = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 20px;
`

const MenuWrapper = styled.div`
  & > div {
    border: none;
    box-shadow: 0 4px 8px 0 rgba(0, 30, 45, 0.12), 0 -1px 4px 0 rgba(0, 30, 45, 0.1);
  }
`

const OptionWrapper = styled.div`
  position: relative;
  
  & > div {
    font-size: 16px;
    font-weight: 500;
    padding: 16px 24px;
    cursor: pointer;
    background-color: white;
    color: ${({ isSelected }) => (isSelected ? Colors.clearBlue : Colors.dark)};
    
    &:hover {
      background-color: ${Colors.paleGrey};
    }
  }
    
  & > svg {
    position: absolute;
    display: ${({ isSelected }) => (isSelected ? 'block' : 'none')}; 
    right: 16px;
    top: 13px;
    z-index: 10;
    fill: ${Colors.clearBlue};
  }
`

const PlaceholderWrapper = styled.span`
  & > * {
    color: ${Colors.blueGrey};
    font-size: 18px;
  }
`

const Error = styled.div`
  margin-top: -20px;
  margin-bottom: 24px;
  padding-top: 8px;
  border-top: 2px solid ${Colors.red};
  padding-left: 12px;
  font-size: 12px;
  color: ${Colors.red};
`
