import React, { useState } from 'react';
import PropTypes from 'prop-types'
import { compose, withProps } from 'recompose'
import { connect, useDispatch, useSelector } from 'react-redux';
import idx from 'idx'
import { OTSession } from 'opentok-react';
import '@opentok/client'
import {
  finishOpentokSession,
  pushMessageSuccess,
  opentokSessionCreated,
  toggleVideoAccess, toggleAudioAccess,
} from '../../redux/modules/consultations';
import { CONSULTATION_TYPES } from '../../constants/consultation';
import { socket } from '../../services/socket';
import { WaitingRoom } from './WaitingRoom';
import { history } from '../../utils/history';
import { VideoChat } from './VideoChat'

const OpentokSessionComponent = ({
  onEndButtonClick,
  opentok,
  firstName,
  lastName,
  pushMessageSuccess,
  opentokSessionCreated,
  matchConsultationSession,
}) => {
  const access = useSelector(state => state.consultations.mediaAccess)
  const [connections, setConnections] = useState([])
  const dispatch = useDispatch()

  const toggleVideo = () => dispatch(toggleVideoAccess(!access.video))
  const toggleAudio = () => dispatch(toggleAudioAccess(!access.audio))

  const onEnd = () => {
    onEndButtonClick()
    setConnections([])
  }

  if (Object.keys(opentok.params).length) {
    return (
      <OTSession
        apiKey={idx(opentok, _ => _.params.attributes.api_key)}
        sessionId={idx(opentok, _ => _.params.attributes.session_id)}
        token={idx(opentok, _ => _.params.attributes.session_token)}
        eventHandlers={{
          'signal:msg': event => pushMessageSuccess(event.data),
          sessionConnected: event => opentokSessionCreated(event.target),
          connectionCreated: e => setConnections(value => (value
            .includes(e.connection.id) ? value : [...value, e.connection.id])),
          connectionDestroyed: e => setConnections(value => value
            .filter(i => i !== e.connection.id)),
        }}
      >
        {
          (connections.length > 1 || true)
            ? (
              <VideoChat
                firstName={firstName}
                lastName={lastName}
                onEndButtonClick={onEnd}
                matchConsultationSession={matchConsultationSession || true}
                access={access}
                toggleVideo={toggleVideo}
                toggleAudio={toggleAudio}
              />
            )
            : (
              <WaitingRoom onEnd={onEnd} matchUrl={matchConsultationSession} />
            )
        }
      </OTSession>
    )
  }

  return null
}

OpentokSessionComponent.defaultProps = {
  firstName: 'Name',
  lastName: 'Surname',
}

OpentokSessionComponent.propTypes = {
  onEndButtonClick: PropTypes.func.isRequired,
  opentok: PropTypes.object.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  pushMessageSuccess: PropTypes.func.isRequired,
  opentokSessionCreated: PropTypes.func.isRequired,
  matchConsultationSession: PropTypes.bool.isRequired,
}

const enhance = compose(
  connect(
    state => ({
      opentok: idx(state, _ => _.consultations.opentok),
      userType: idx(state, _ => _.auth.type),
      consultationId: idx(state, _ => _.consultations.consultation.id),
      doctor: idx(state, _ => _.consultations.consultation.relationships.doctor),
      patient: (() => {
        const isConcilium = idx(state, _ => _
          .consultations.consultation.attributes.type === CONSULTATION_TYPES.telexpertise)

        if (!isConcilium) {
          return idx(state, _ => _.consultations.consultation.relationships.patient)
        }

        if (idx(state, _ => _.consultations.consultation
          .relationships.additionalMember.data[0].attributes.doctorID === +_.auth.id)) {
          return idx(state, _ => _.consultations.consultation.relationships.doctor)
        }

        return {
          data: {
            attributes: idx(state, _ => _.consultations
              .consultation.relationships.additionalMember.data[0].attributes.doctor),
          },
        }
      })(),
    }),
    {
      finishOpentokSession,
      pushMessageSuccess,
      opentokSessionCreated,
    },
  ),
  withProps(props => ({
    matchConsultationSession: props.location.pathname === '/panel/consultations/session' || props.location.pathname === `/panel/consultations/${props.consultationId}`,
    firstName: idx(props, _ => _[props.userType === 'Doctor' ? 'patient' : 'doctor'].data.attributes.first_name),
    lastName: idx(props, _ => _[props.userType === 'Doctor' ? 'patient' : 'doctor'].data.attributes.last_name),
    onEndButtonClick: () => {
      props.finishOpentokSession()
      if (props.location.search) {
        history.push(props.location.pathname)
      }
      socket.cancelCall(props.consultationId)
    },
  })),
)

export const OpentokSession = enhance(OpentokSessionComponent)
