import { createAction, handleActions } from 'redux-actions'
import axios from 'axios'

//#region Actions
export const REVIEWS_ACTIONS = {
  fetchDoctorReviewsRequest: 'DOCTOR > REVIEWS > FETCH_REQUEST',
  fetchDoctorReviewsSuccess: 'DOCTOR > REVIEWS > FETCH_SUCCESS',
  fetchDoctorReviewsFailure: 'DOCTOR > REVIEWS > FETCH_FAILURE',

  sendReviewRequest: 'DOCTOR > REVIEWS > SEND_REQUEST',
  sendReviewSuccess: 'DOCTOR > REVIEWS > SEND_SUCCESS',
  sendReviewFailure: 'DOCTOR > REVIEWS > SEND_FAILURE',
}

export const fetchDoctorReviewsRequest = createAction(REVIEWS_ACTIONS.fetchDoctorReviewsRequest)
export const fetchDoctorReviewsSuccess = createAction(REVIEWS_ACTIONS.fetchDoctorReviewsSuccess)
export const fetchDoctorReviewsFailure = createAction(REVIEWS_ACTIONS.fetchDoctorReviewsFailure)

export const sendReviewRequest = createAction(REVIEWS_ACTIONS.sendReviewRequest)
export const sendReviewSuccess = createAction(REVIEWS_ACTIONS.sendReviewSuccess)
export const sendReviewFailure = createAction(REVIEWS_ACTIONS.sendReviewFailure)
//#endregion

//#region Reducer
export const reviewsReducer = handleActions({
  [fetchDoctorReviewsSuccess]: (state, action) => ({
    ...state,
    [action.payload.doctor]: action.payload.reviews,
  }),
  [sendReviewSuccess]: (state, action) => ({
    ...state,
    [action.payload.doctor]: [
      ...(state[action.payload.doctor] || []),
      action.payload.review,
    ],
  }),
}, {})
//#endregion

//#region Thunks
export const fetchDoctorReviews = (doctor, params) => (dispatch) => {
  dispatch(fetchDoctorReviewsRequest(doctor))

  return axios(`/general/doctor/review/${doctor}`, { params })
    .then(response => dispatch(fetchDoctorReviewsSuccess({ doctor, reviews: response.data.data })))
    .catch((err) => {
      dispatch(fetchDoctorReviewsFailure({ err, doctor }))
    })
}

export const sendReview = (doctor, consultation, values) => (dispatch) => {
  dispatch(sendReviewRequest({ doctor, values }))

  return axios(`/general/doctor/review/${consultation}`, { method: 'POST', data: values })
    .then(response => dispatch(sendReviewSuccess({ doctor, review: response.data.data })))
    .catch((err) => {
      dispatch(sendReviewFailure({ err, consultation }))
    })
}
//#endregion
