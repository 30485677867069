export const azure = 'rgba(6, 173, 255, 0.1)';
export const blueGrey = '#949eb8';
export const clearBlue = '#209dff';
export const blue = opacity => `rgba(32,157,255,${opacity})`;
export const cloudyBlue = 'rgba(191, 197, 212)';
export const cloudyBlue30 = 'rgba(191, 197, 212, 0.3)';
export const dark = '#21293e';
export const grey = '#e2e5ed';
export const paleGrey = 'rgba(245, 247, 251)';
export const paleGrey60 = 'rgba(245, 247, 251, 0.6)';
export const red = '#ee3c5b';
export const waterBlue = 'rgba(12, 112, 209, 0.5)';
export const white = '#fff';

export const calendarColors = {
  completedBg: '#eaffe8',
  completedBorder: '#4afe38',
  confirmedBg: '#e8f5ff',
  availableText: '#7b9eb9',
  cancelledBg: '#f4f5f7',
  cancelledBorder: '#adaeb2',
  confirmButton: '#50b25e',
  confirmButtonHover: '#138232',
  refuseButton: '#c76363',
  refuseButtonHover: '#933539',
  freeText: '#119a35',
  selectedBg: '#00dc72',
  blueHover: 'rgb(22, 109, 178)',
  conciliumBg: '#f5eeff',
  conciliumBorder: '#ad70ff',
}

export const yellowMember = '#f7bd40'

export const LEVEL_COLORS = {
  membre: '#FFFAFA',
  'membre actif': '#c3a041',
  parrain: '#DCDCDC',
  'parrain certifie': '#FFD700',
  ambassadeur: '#a0e1f5',

}

export const Colors = {
  azure,
  blueGrey,
  clearBlue,
  cloudyBlue,
  cloudyBlue30,
  dark,
  dark60: 'rgba(33, 41, 62, 0.6)',
  dark70: 'rgba(33, 41, 62, 0.7)',
  dark80: 'rgba(33, 41, 62, 0.8)',
  duskBlue: '#264f82',
  grey,
  paleGrey,
  paleGrey60,
  red,
  waterBlue,
  white: '#ffffff',
  white15: 'rgba(255, 255, 255, 0.15)',
  white10: 'rgba(255, 255, 255, 0.1)',
  black: '#000000',
  black10: 'rgba(0, 0, 0, 0.1)',
  blackBg: 'rgba(0, 12, 29, 0.4)',
  link: '#018cff',
  navyBlue: '#2c578c',
  lightGrey: '#f3f6f9',
  textMuted: '#9ba3bb',
  background: '#f5f7fb',
  disabled: '#b7bed2',
  disabledText: '#bfc5d4',
  divider: '#e2e5ed',
  steel: '#757f99',
  yellowOchre: '#d0a403',
  offWhite: '#fff9e6',
  tealish: '#21c5a9',
  seafomBlue: '#5bdac4',
  watermelon: '#fa4464',
  strawberry: '#f1353a',
  blue,
}
