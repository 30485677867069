import { createAction } from 'redux-actions';
import axios from 'axios';

export const sendChangeDecisionRequest = createAction(
  'SEND_CHANGE_DECISION_REQUEST',
);
export const sendChangeDecisionSuccess = createAction(
  'SEND_CHANGE_DECISION_SUCCESS',
);
export const sendChangeDecisionFailure = createAction(
  'SEND_CHANGE_DECISION_FAILURE',
);


// export const changeDesionReducer = handleActions(
//   {
//     [sendChangeDecisionSuccess]: (state, action) => ({
//       ...state,
//       [action.payload.data]: action.payload.data,
//     }),
//   },
//   {},
// );

export const sendPolicy = values => (dispatch) => {
  dispatch(sendChangeDecisionSuccess({ values }));

  return axios('/api/gdpr/accept', {
    method: 'POST',
    data: values,
  })
    .then(response => dispatch(sendChangeDecisionSuccess({ policy: response.data })))
    .catch((err) => {
      dispatch(sendChangeDecisionFailure({ err }));
    });
};

export const declinePolicy = values => (dispatch) => {
  dispatch(sendChangeDecisionSuccess({ values }));

  return axios('/api/gdpr/decline', {
    method: 'POST',
    data: values,
  })
    .then(response => dispatch(sendChangeDecisionSuccess({ policy: response.data })))
    .catch((err) => {
      dispatch(sendChangeDecisionFailure({ err }));
    });
};
