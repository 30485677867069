import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withStateHandlers } from 'recompose'
import { Paper } from 'common/UiKit/Paper'
import { TextButton } from 'common/UiKit/TextButton'
import { Colors } from 'constants/colors'
import { FormattedMessage } from 'react-intl'
import logoImg from '../../../../../images/panel/doctors/urgent-log.svg'
import { UrgentDialog } from './UrgentDialog'

const Wrapper = styled(Paper)`
  display:flex;
  justify-content: space-between;
  padding: 0 7px 0 24px;
  color: ${Colors.blueGrey};
  font-size: 14px;
  margin-bottom: 24px;
`

const Heading = styled.h1`
  margin: 21px 0 8px 0;
  font-size: 32px;
  color: ${Colors.dark};
  font-weight: bold;
`

// const Price = styled.div`
//   margin: 6px 0 13px;
//   line-height: 1.33;
//
//   & :first-child {
//     font-size: 15px;
//     color: ${Colors.dark};
//
//   }
//
//   & :last-child {
//     margin-left: 10px;
//     font-weight: bold;
//     font-size: 20px;
//     color: ${Colors.clearBlue};
//   }
// `

const Logo = styled.img`
  width: 190px;
  height: 180px;
`

const Button = styled(TextButton)`
  && {
    margin-top: 24px;
    display: block;
    height: 36px;
    width: 288px;
    margin-bottom: 24px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5px;
  }
`

const UrgentComponent = ({
  open,
  toggleDialog,
  specializations,
}) => (
  <Wrapper>
    <UrgentDialog
      open={open}
      onClose={toggleDialog}
      specializations={specializations}
    />
    <div>
      <Heading>
        <FormattedMessage id='consultations.urgent.title' />
      </Heading>
      <FormattedMessage id='consultations.doctor.contact' />
      {/*<Price>*/}
      {/*  <span>*/}
      {/*    <FormattedMessage id='consultations.price' />*/}
      {/*  </span>*/}
      {/*  <span>25 &euro;</span>*/}
      {/*</Price>*/}
      <Button
        color='primary'
        variant='extended'
        onClick={toggleDialog}
      >
        <FormattedMessage id='consultation.make.appointment' />
      </Button>
    </div>
    <Logo src={logoImg} />
  </Wrapper>
)

UrgentComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  specializations: PropTypes.array.isRequired,
}

const enhance = withStateHandlers({
  open: false,
}, {
  toggleDialog: state => () => ({ open: !state.open }),
})

export const Urgent = enhance(UrgentComponent)
