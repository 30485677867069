import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { Page } from '../../../common/Page'
import { Specialists } from '../../Patient/Doctors/Main/Specialists'
import { fetchSpecializations } from '../../../../redux/modules/content'

export const Specializations = ({ match }) => {
  const dispatch = useDispatch()
  const specializations = useSelector(state => state.content.specializations)

  useEffect(() => {
    dispatch(fetchSpecializations())
  }, [dispatch])

  return (
    <Page label='concilium.select.doctors'>
      <Content>
        <Specialists
          specializations={specializations}
          to={match.params.consultationId
            ? `/panel/concilium/${match.params.consultationId}/specializations/`
            : '/panel/concilium/specializations/'}
        />
      </Content>
    </Page>
  )
}

const Content = styled.div`
`
