import axios from 'axios';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper'

const locationHelper = locationHelperBuilder({})

export const doctorIsRecieved = connectedRouterRedirect({
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/panel/doctors',
  authenticatedSelector: state => !!state.doctors.doctor.id,
  wrapperDisplayName: 'DoctorIsRecieved',
  allowRedirectBack: false,
})

export const sortSpecializations = (items = []) => {
  const firstItem = items.find(i => i.attributes.key === 'Therapist')
  const list = items
    .filter(i => i.attributes.key !== 'Therapist')
    .sort((a, b) => a.attributes.name.localeCompare(b.attributes.name))
  return [firstItem, ...list]
}

export const getTutorialList = async () => {
  const result = await axios.get('/api/public/admin/attachment/list');
  return result.data.data;
}
