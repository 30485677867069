import React, { useCallback, useState } from 'react';
import styled from 'styled-components'
import Dropzone from 'react-dropzone'
import { FormattedMessage } from 'react-intl'
import { TextButton } from 'common/UiKit/TextButton'
import Cropper from 'react-easy-crop'
import axios from 'axios'
import idx from 'idx'
import { useDispatch } from 'react-redux'

import ZoomInIcon from '@material-ui/icons/ZoomIn'
import ZoomOutIcon from '@material-ui/icons/ZoomOut'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import RotateRightIcon from '@material-ui/icons/RotateRight'

import { Page } from '../../../../common/Page'
import { history } from '../../../../../utils/history'
import uploadImg from '../../../../../assets/upload.svg'
import { Colors } from '../../../../../constants/colors'
import { getCroppedImg } from '../../../../../helpers/image'
import { fetchSignature } from '../../../../../redux/modules/signature'
import { Preloader } from '../../../../common/Preloader'

const EmbeddedContainer = styled.div`
  padding: 24px;
  border-radius: 12px;
  background-color: white;
`

const Loader = styled(Preloader)`
  && {
    width: 100%;
    height: 300px;
  }
`

const EmbeddedComponent = ({ children, isFetching }) => (
  <EmbeddedContainer>
    {
      isFetching ? <Loader /> : children
    }
  </EmbeddedContainer>
)

export const SignatureUpload = React.memo(({ embedded, afterSubmit, onCancelClick }) => {
  const [file, selectFile] = useState(null)
  const [crop, setCrop] = useState({
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: 2 / 1,
    rotation: 0,
  })

  const [cropData, setCropData] = useState(null)
  const [preview, setPreview] = useState(null)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const WrapperComponent = embedded ? EmbeddedComponent : Page

  const onSubmit = async () => {
    setLoading(true)

    try {
      const data = new FormData()
      const file = await fetch(preview).then(r => r.blob())

      data.append('file', file, 'signature.png')

      await axios('/doctor/signature/set', {
        method: 'POST',
        data,
      })
      dispatch(fetchSignature())

      if (afterSubmit) {
        afterSubmit()
      } else {
        history.push('/panel/profile')
      }
    } finally {
      setLoading(false)
    }
  }

  const onDrop = async (files) => {
    if (!files || !files.length) return
    setLoading(true)

    try {
      const data = new FormData()
      data.append('file', new Blob([files[0]]))

      const res = await axios('doctor/signature/convert', {
        method: 'POST',
        data,
      })

      if (res && idx(res, _ => _.data.data.attributes.url)) {
        const file = await fetch(res.data.data.attributes.url)
        const blob = await file.blob()
        selectFile(blob)
      }
    } finally {
      setLoading(false)
    }
  }

  const onZoomChange = zoom => setCrop({ ...crop, zoom })
  const onRotationChange = rotation => setCrop({ ...crop, rotation })
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCropData(croppedAreaPixels)
  }, [])

  const onCrop = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        window.URL.createObjectURL(file),
        cropData,
        crop.rotation,
      )
      setPreview(croppedImage)
    } finally {
      setLoading(false)
    }
  }, [cropData, crop.rotation])

  if (!file) {
    return (
      <WrapperComponent label='profile.signature' onBackIconClick={() => history.goBack()} isFetching={loading}>
        <Title>
          <FormattedMessage id='signature.upload.title' />
        </Title>
        <div>
          <Dropzone
            accept='.jpg,.png,.pdf'
            multiple={false}
            onDrop={onDrop}
          >
            {
              ({ getRootProps, getInputProps }) => (
                <Zone
                  {...getRootProps()}
                >
                  <img src={uploadImg} alt='' />
                  <DragNDrop>
                    <FormattedMessage id='signature.drag.n.drop' />
                  </DragNDrop>
                  <To>
                    <FormattedMessage id='signature.to.upload' />
                  </To>
                  <Or>
                    <FormattedMessage id='signature.or' />
                  </Or>
                  <Button color='primary'>
                    <FormattedMessage id='signature.choose' />
                  </Button>
                  <input {...getInputProps()} />
                </Zone>
              )
            }
          </Dropzone>
        </div>
      </WrapperComponent>
    )
  }

  if (preview) {
    return (
      <WrapperComponent label='profile.signature' onBackIconClick={() => selectFile(null)} isFetching={loading}>
        <Title>
          <FormattedMessage id='signature.verify' />
        </Title>
        <Preview>
          <img src={preview} alt='' />
        </Preview>
        <SaveButton color='primary' onClick={onSubmit}>
          <FormattedMessage id='global.save' />
        </SaveButton>
        <CancelButton color='primary' onClick={() => onCancelClick()}>
          <FormattedMessage id='global.cancel' />
        </CancelButton>
      </WrapperComponent>
    )
  }

  return (
    <WrapperComponent label='profile.signature' onBackIconClick={() => selectFile(null)} isFetching={loading}>
      <Title>
        <FormattedMessage id='signature.move' />
      </Title>
      <CropContainer>
        <Cropper
          image={window.URL.createObjectURL(file)}
          crop={crop.crop}
          zoom={crop.zoom}
          rotation={crop.rotation}
          aspect={crop.aspect}
          onCropChange={newCrop => setCrop({ ...crop, crop: newCrop })}
          onCropComplete={onCropComplete}
          onRotationChange={onRotationChange}
          onZoomChange={onZoomChange}
          showGrid={false}
          maxZoom={10}
          minZoom={0}
          cropSize={{
            width: 160,
            height: 80,
          }}
        />
      </CropContainer>
      <Controls>
        <ControlButton color='primary' onClick={() => selectFile(null)}>
          <FormattedMessage id='signature.upload.image' />
        </ControlButton>
        <CropControls>
          <IconButton onClick={() => onZoomChange(Math.min(10, crop.zoom + 1))}>
            <ZoomInIcon />
          </IconButton>
          <IconButton onClick={() => onZoomChange(Math.max(0, crop.zoom - 0.5))}>
            <ZoomOutIcon />
          </IconButton>
          <IconButton onClick={() => onRotationChange((crop.rotation || 360) - 90)}>
            <RotateLeftIcon />
          </IconButton>
          <IconButton
            onClick={() => onRotationChange(crop.rotation === 270 ? 0 : crop.rotation + 90)}
          >
            <RotateRightIcon />
          </IconButton>
        </CropControls>
        <ControlButton color='primary' filled onClick={onCrop}>
          <FormattedMessage id='signature.crop' />
        </ControlButton>
      </Controls>
    </WrapperComponent>
  )
})

const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-top: 32px;
  text-align: center;
`

const Zone = styled.div`
  padding: 40px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  border: dashed 2px rgba(148,158,184,0.2);
  margin-top: 24px;
  cursor: pointer;
  outline: none;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  &:active, &:focus {
    border: dashed 2px rgba(148,158,184,0.2);
  }
`

const DragNDrop = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: ${Colors.blueGrey};
  margin-top: 16px;
`

const To = styled.div`
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: ${Colors.blueGrey};
  margin-top: 12px;
`

const Or = styled.div`
  display: inline-block;
  opacity: 0.3;
  font-size: 12px;
  font-weight: 500;
  color: ${Colors.blueGrey};
  margin-top: 16px;
  
  &:before, &:after {
    content: "";
    display: inline-block;
    margin-right: 6px;
    margin-left: 6px;
    height: 2px;
    width: 28px;
    background-color: ${Colors.blueGrey};
    margin-bottom: 3px;
  }
`

const Button = styled(TextButton)`
  && {
    margin-top: 32px;
    width: auto;
    min-width: 0;
    background-color: transparent;
    border: 1px solid ${Colors.clearBlue};
    box-shadow: none;
    color: ${Colors.clearBlue};
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    
    &:hover {
      color: white;
      background-color: ${Colors.clearBlue};
    }
  }
`

const CropContainer = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  margin-top: 16px;
  background-color: rgba(149, 159, 184, 0.41);
  border-radius: 8px;
  
  & > div > div {
    border: 2px solid ${Colors.clearBlue};
  }
`

const Controls = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ControlButton = styled(Button)`
  && {
    min-width: 200px;
    margin-top: 0;
    background-color: ${({ filled }) => (filled ? Colors.clearBlue : 'transparent')};
    color: ${({ filled }) => (filled ? 'white' : Colors.clearBlue)};
  }
`

const CropControls = styled.div`
  display: flex;
  
`

const IconButton = styled.button`
  outline: none;
  width: 24px;
  height: 24px;
  margin: 0 6px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${Colors.clearBlue};
  color: ${Colors.clearBlue};
  cursor: pointer;
  background-color: transparent;
  
  svg {
    width: 18px;
    height: 18px;
  }
  
  &:nth-child(2) {
    margin-right: 16px;
  }
  
  &:hover, &:active, &:focus {
    outline: none;
    background-color: ${Colors.clearBlue};
    color: white;
  }
`

const Preview = styled.div`
  text-align: center;
  
  img {
    margin-top: 32px;
    width: 210px;
    height: 105px;
    border: 2px solid ${Colors.clearBlue};
    box-sizing: border-box;
  }
`

const SaveButton = styled(TextButton)`
  && {
    box-shadow: none;
    position: fixed;
    bottom: 32px;
    right: 32px;
    font-weight: 500;
    font-size: 15px;
    height: 44px;
    width: 160px;
  }
`

const CancelButton = styled(SaveButton)`
  && {
    right: 220px;
    bottom: 32px;
    background-color: transparent;
    border: 1px solid ${Colors.clearBlue};
    color: ${Colors.clearBlue};
    
    &:hover, &:active, &:focus {
      background-color: ${Colors.clearBlue};
      color: white;
    }
  }
`
