import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import CloseIcon from '@material-ui/icons/Close';
import get from 'lodash/get';
import { withRouter } from 'react-router-dom';
import orderBy from 'lodash/orderBy';
import { useIntl } from 'react-intl';
import uniq from 'lodash/uniq';
import { blueGrey } from '../../../constants/colors';
import notification from '../../../assets/sounds/notification.mp3';
import { history } from '../../../utils/history';
import { TemporaryWrapper } from './temporaryComponent';

const audio = new Audio(notification)

const ChatNotificationsContainer = styled.div`
    position: absolute;
    right: 16px;
    width: 260px;
    z-index: 500;
    bottom: 0;
`

const Avatar = styled.div`
    width: 44px;
    height: 45px;
    margin-left: 12px;
    margin-top: 12px;
    margin-bottom: 12px;
    border-radius: 50px;
    background-image: ${({ photo }) => `url(${photo})`};
    margin-right: 22px;
    background-size: cover;
    background-position: center;
    box-shadow: 0px 4px 6px -3px;
    border: 2px solid #fff;
`

const RightSideNotification = styled.div`
    background-color: #fff;
`

const MessageContainer = styled(Paper)`
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 16px;
`

const TitleItem = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: #21293e;
    max-width: 145px;
    overflow: hidden;
    text-overflow: ellipsis;
`

const Message = styled.div`
    font-size: 14px;
    color: ${blueGrey};
    max-width: 145px;
    overflow: hidden;
    text-overflow: ellipsis;
`

const StyledClose = styled(CloseIcon)`
    && {
        position: absolute;
        top: 12px;
        right: 12px;
        font-size: 16px;
        color: ${blueGrey};
        cursor: pointer;
    }
`

export const ChatNotifications = withRouter(({ location }) => {
  const [closed, setClosed] = useState([]);
  const [alarmed, setAlarmed] = useState([]);
  const currentPage = location.pathname;
  const isConsultationOpen = /\/panel\/consultations\/.*/.test(currentPage);
  const currentConsultation = useSelector(state => get(state, 'consultations.consultation.id', false));
  const chatsLoaded = useSelector(state => state.chats);
  const currentOpenedChat = get(chatsLoaded, `${currentConsultation}.chat.id`, false);
  const currentUser = useSelector(state => state.auth)
  const currentUserId = get(currentUser, 'attributes.user_id', false);
  const chats = useSelector(state => state.chatsNotifications)
  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })

  const chatWithUsers = orderBy(chats
    .filter(el => el.chat_id && el.id
    && !closed.includes(el.id) 
    && el.sender !== currentUserId 
    && !(el.chat_id === currentOpenedChat && isConsultationOpen))
    .map(el => ({ ...el, senderAttributes: el.sender })), 'id')
    .slice(0, 5);

  useEffect(() => {
    if (currentOpenedChat && isConsultationOpen && chats && chats.length) {
      const allIdsFromChat = chats
        .filter(el => el && el.chat_id && el.chat_id === currentOpenedChat)
        .map(el => el.id);
      setClosed(uniq([...closed, ...allIdsFromChat]));
    }
  }, [chats])

  return (
    <ChatNotificationsContainer>

      {chatWithUsers.map((el) => {
        if (!alarmed.includes(el.id)) {
          audio.play()
            .catch(e => console.warn(e))
          setAlarmed([...alarmed, el.id]);
        }
        const senderName = get(el, 'sender_name', 'Unknown');
        const senderAvatar = get(el, 'avatar', '/static/media/default-avatar.b887eb6a.svg');
        const relatedChat = get(el, 'chat_relate_id', false);

        return (
          <TemporaryWrapper>
            <MessageContainer key={el.id} onClick={() => history.push(`/panel/consultations/${relatedChat}`)}>
              <Avatar photo={senderAvatar} />
              <RightSideNotification>
                <TitleItem>{senderName}</TitleItem>
                <Message>{typeof el.message === 'string' ? el.message : f('consultation.sentattachment')}</Message>
              </RightSideNotification>
              <StyledClose onClick={(e) => {
                e.stopPropagation();
                setClosed([...closed, el.id])
              }}
              />
            </MessageContainer>
          </TemporaryWrapper>)
      })}
    </ChatNotificationsContainer>
  )
})
