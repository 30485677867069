import React, { useEffect, useState } from 'react'
import { RadioInput } from 'common/UiKit/RadioInput'
import { TextField } from 'common/UiKit/TextField'
import { Paper } from 'common/UiKit/Paper'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import axios from 'axios'
import get from 'lodash/get';


import { isGeneralistSelector } from '../../../../../../selectors/doctor'
import { Colors } from '../../../../../../constants/colors'
import { PROFILE_FILL_STEPS } from '../../../../../../constants/ui'
// eslint-disable-next-line import/no-cycle
import { Territory } from './Territory'

const specialistFirstOptions = ['0 - 5', '5 - 10', '10 - 20', '20+']
const specialistSecondOptions = [{ value: '1', label: <FormattedMessage id='global.yes' /> }, { value: '0', label: <FormattedMessage id='global.not' /> }]

const firstGeneralistOptions = ['0 - 2', '2 - 4', '4 - 8', '8+']
const secondGeneralistOptions = ['-5%', '5 - 10%', '+10%']
const thirdGeneralistOptions = ['0 - 3', '3 - 7', '7+']

export const AnimationForm = ({
  onSubmit, afterSubmit, editMode = false, disableShadow = false, ...rest
}) => {
  const isGeneralist = useSelector(isGeneralistSelector)
  const [questions, setQuestions] = useState([])

  useEffect(() => {
    axios('/doctor/profile/question')
      .then(res => setQuestions(res.data.data))
  }, [])

  const getValueByTranslateId = () => questions.reduce((accum, el, i) => {
    const fromOne = i + 1;
    const question = `question${fromOne}`;
    const isNotTextField = isGeneralist ? 3 : 2;
    const answer = fromOne <= isNotTextField ? get(el, 'attributes.answer', '') : get(el, 'attributes.answer', '').split(',');
    if (!answer || !answer[0] || answer[0] === 'undefined' || answer === 'undefined') {
      return accum;
    }
    const newAnswer = { [question]: answer };
    return { ...accum, ...newAnswer };
  }, {})

  return (
    <Paper {...rest} heading={!editMode ? <FormattedMessage id='profile.animation' /> : null} disableShadow={disableShadow}>
      <Form
        initialValues={getValueByTranslateId()}
        onSubmit={(event) => {
          onSubmit(event, afterSubmit, isGeneralist)
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} id={PROFILE_FILL_STEPS[4]}>
            {
              !isGeneralist && (
                <>
                  <Title>
                    <FormattedMessage id='profile.specialist.animation.title' />
                  </Title>
                  <Line>
                    <Label>
                      <FormattedMessage id='profile.specialist.question.1' />
                    </Label>
                    <Field
                      name='question1'
                      component={TextField}
                      options={specialistFirstOptions.map(i => ({ value: i, label: i }))}
                    />
                  </Line>
                  <CheckboxLine>
                    <Label>
                      <FormattedMessage id='profile.specialist.question.2' />
                    </Label>
                    <Field
                      name='question2'
                      id='question2'
                      component={RadioInput}
                      options={specialistSecondOptions}
                    />
                  </CheckboxLine>
                </>
              )
            }
            {
              isGeneralist && (
                <>
                  <Title>
                    <FormattedMessage id='profile.generalist.animation.title' />
                  </Title>
                  <Line>
                    <Label>
                      <FormattedMessage id='profile.generalist.question.1' />
                    </Label>
                    <Field
                      name='question1'
                      component={TextField}
                      options={firstGeneralistOptions.map(i => ({ value: i, label: i }))}
                    />
                  </Line>
                  <Line>
                    <Label>
                      <FormattedMessage id='profile.generalist.question.2' />
                    </Label>
                    <Field
                      name='question2'
                      component={TextField}
                      options={secondGeneralistOptions.map(i => ({ value: i, label: i }))}
                    />
                  </Line>
                  <Line>
                    <Label>
                      <FormattedMessage id='profile.generalist.question.3' />
                    </Label>
                    <Field
                      name='question3'
                      component={TextField}
                      options={thirdGeneralistOptions.map(i => ({ value: i, label: i }))}
                    />
                  </Line>
                </>
              )
            }
            <Territory
              names={isGeneralist ? ['question4', 'question5'] : ['question3', 'question4']}
            />
          </form>
        )}
      />
    </Paper>
  )
}

const Title = styled.div`
  color: ${Colors.blueGrey};
  font-size: 18px;
  margin-bottom: 32px;
  font-weight: 400;
`

export const Line = styled.div`
  display: flex;
  align-items: ${({ start }) => (start ? 'flex-start' : 'center')};
`

export const Label = styled.div`
  width: 60%;
  min-width: 60%;
  margin-bottom: 24px;
  margin-right: 16px;
  opacity: 0.87;
  font-weight: bold;
  font-size: 16px;
  color: ${Colors.dark};
`

const CheckboxLine = styled.div`
  margin: 0 0 24px;
  display: flex;
  align-items: center;
  
  ${Label} {
    margin-bottom: 0;
  }
  
  & > div:last-child {
    margin-left: -8px;
  }
`
