import React from 'react'
import { Field } from 'react-final-form'
import styled from 'styled-components'
import moment from 'moment'
import { DateTimeField } from 'common/UiKit/DateTimeField'
import { Paper } from 'common/UiKit/Paper'
import { useIntl } from 'react-intl'

const Wrapper = styled(Paper)`
  padding: 24px 24px 12px;
  margin-bottom: 16px;
`

export const Time = ({ Component, setTypeOfPrice, available }) => {
  const { formatMessage } = useIntl()

  return (
    <Wrapper headingTransparent='consultation.select.time.title'>
      <Field
        available={available}
        name='proposedDate'
        component={Component || DateTimeField}
        label={`*${formatMessage({ id: 'consultation.select.time' })}`}
        minDate={moment().add(1, 'day')}
        setTypeOfPrice={setTypeOfPrice}
        parse={value => value && moment.utc(value).format('YYYY-MM-DD HH:mm:ss')}
      />
    </Wrapper>
  )
}
