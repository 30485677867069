import React from 'react'
import { Route, withRouter } from 'react-router-dom'
import { Profile } from './Profile'
import { HealthRecords } from './HealthRecords'
import { Doctors } from './Doctors'
import { Consultations } from './Consultations'


export const Patient = withRouter(() => (
  <>
    <Route path='/panel/doctors' component={Doctors} />
    <Route path='/panel/consultations' component={Consultations} />
    <Route path='/panel/health-records' component={HealthRecords} />
    <Route path='/panel/profile' component={Profile} />
  </>
))
