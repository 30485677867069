import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import idx from 'idx'
import { PrescriptionForm } from './PrescriptionForm'
import { MedicalReportForm } from './MedicalReportForm'
import { Main } from './Main'
import { consultationIsRecieved } from '../../../../../helpers/consultations'
import { UsefulModal } from './UsefulModal'
import { CONSULTATION_TYPES } from '../../../../../constants/consultation';

export const SessionComponent = ({ match }) => {
  const consultation = useSelector(state => state.consultations.consultation)
  const showUsefulModal = useSelector(state => idx(state, _ => _
    .consultations.consultation.attributes.type !== CONSULTATION_TYPES.telexpertise
    && _.consultations.consultation.attributes.useful === null
    && _.consultations.consultation.attributes.status === 'completed'
    && _.consultations.consultation.relationships.additionalMember.data[0].attributes
      .doctorID === +_.auth.id))
  const [usefulModal, toggleUsefulModal] = useState(true)

  useEffect(() => {
    toggleUsefulModal(showUsefulModal)
  }, [consultation])

  return (
    <>
      <Switch>
        <Route
          path={`${match.path}/prescription`}
          component={PrescriptionForm}
        />
        <Route
          path={`${match.path}/report`}
          component={MedicalReportForm}
        />
        <Route
          path={`${match.path}`}
          component={Main}
        />
      </Switch>
      {
        usefulModal && (
          <UsefulModal
            open={usefulModal}
            onClose={() => toggleUsefulModal(false)}
            consultationId={consultation.id}
          />
        )
      }
    </>
  )
}

SessionComponent.propTypes = {
  match: PropTypes.object.isRequired,
}

export const Session = consultationIsRecieved(SessionComponent)
