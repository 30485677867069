import React from 'react';
import { Form, Field } from 'react-final-form'
import { mapProps } from 'recompose'
import PropTypes from 'prop-types'
import moment from 'moment'
import styled from 'styled-components'
import { FormattedMessage, useIntl } from 'react-intl';
import { TextField } from 'common/UiKit/TextField'
import get from 'lodash/get';
import { RadioInputField } from '../RadioInputField'
import { CheckboxInputField, FieldWrapper } from '../CheckboxInputField'
import { Time } from './Time'

const DateWrapper = styled(FieldWrapper)`
  & > div {
    width: 100% !important;
    
    & > div {
      margin-bottom: 0 !important;
    }
  }
`

const normalizeInitialValues = values => ({
  ...values,
  height: values.height || null,
  weight: values.weight || null,
  smoke: get(values, 'smoke', '').toString(),
  pregnant: get(values, 'pregnant', '').toString(),
  lastHospitalized: values.lastHospitalized && moment(values.lastHospitalized).format('YYYY-MM-DD'),
})

const normalizeValues = values => ({
  ...values,
  height: values.height || 0,
  weight: values.weight || 0,
  lastHospitalized: values.lastHospitalized || '',
})


const validate = ({
  height, weight, fullName, birthday, insurance, 
}, withAdditionalFields) => {
  const errors = {}

  if (height && (height > 300 || height < 10)) errors.height = 'Wrong height'
  if (weight && (weight > 500 || weight < 3)) errors.weight = 'Wrong weight'

  if (withAdditionalFields) {
    if (!fullName) errors.fullName = <FormattedMessage id='error.patient.name' />
    if (!birthday) errors.birthday = <FormattedMessage id='error.patient.birthday' />
    if (!insurance) errors.insurance = <FormattedMessage id='error.patient.insurance' />
  }

  return errors
}

const enhance = mapProps(props => ({
  ...props,
  onSubmit: values => props.onSubmit(normalizeValues(values))
    .then(() => props.afterSubmit()),
}))

const RADIO_OPTIONS = [
  { label: 'not', value: 'false' },
  { label: 'yes', value: 'true' },
]

export const HealthDescriptionForm = enhance(({
  onSubmit,
  id,
  initialValues,
  contraindications,
  additionalFields,
  locales,
}) => {
  const { formatMessage: f } = useIntl()
  const options = RADIO_OPTIONS.map(item => ({ ...item, label: f({ id: `global.${item.label}` }) }))

  return (
    <Form
      onSubmit={onSubmit}
      subscription={{}}
      validate={values => validate(values, !!additionalFields)}
      initialValues={initialValues && normalizeInitialValues(initialValues)}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} id={id}>
          {
            additionalFields && additionalFields.map((item) => {
              if (item.type === 'date') {
                return (
                  <DateWrapper headingTransparent={f({ id: item.label })}>
                    <Field
                      name={item.name}
                      component={TextField}
                      type='date'
                      invalidLabel=''
                      label=''
                      minDate={moment('1900', 'YYYY)')}
                      maxDate={Date.now()}
                      parse={value => value && moment(value).format('YYYY-MM-DD')}
                    />
                  </DateWrapper>
                )
              }
              return (
                <FieldWrapper headingTransparent={f({ id: item.label })}>
                  <Field
                    name={item.name}
                    component={TextField}
                    label={f({
                      id: item.placeholder || item.label,
                      defaultMessage: item.placeholder || item.label,
                    })}
                    {...(item.parse ? { parse: item.parse } : {})}
                    {...(item.validate ? { validate: item.validate } : {})}
                  />
                </FieldWrapper>
              )
            })
          }
          <Time />
          <CheckboxInputField
            headingTransparent={f({ id: 'hr.diseases' })}
            name='contraindication'
            id='contraindication'
            options={contraindications}
          />
          <RadioInputField
            headingTransparent={f({ id: locales ? locales.smoke : 'hr.do.you.smoke' })}
            name='smoke'
            id='smoke'
            options={options}
          />
          <RadioInputField
            headingTransparent={f({ id: locales ? locales.pregnant : 'hr.are.pregnant' })}
            name='pregnant'
            id='pregnant'
            options={options}
          />
          <br />
        </form>
      )}
    />
  )
})

HealthDescriptionForm.defaultProps = {
  initialValues: {},
}

HealthDescriptionForm.propTypes = {
  afterSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  contraindications: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  initialValues: PropTypes.shape({
    height: PropTypes.number,
    weight: PropTypes.number,
    lastHospitalized: PropTypes.string,
    contraindication: PropTypes.arrayOf(PropTypes.string),
    smoke: PropTypes.bool,
  }).isRequired,
}
