import axios from 'axios';

const statuses = {
  '-1': 'rejected',
  0: 'moderated',
  1: 'verified',
}

export const getDoctorTeams = async () => {
  const result = await axios('/api/doctor/team/my');
  return result.data.data;
}

export const getStatus = (statusCode) => {
  const statusName = statuses[statusCode]
  return statusName
}

export const getTeamById = async (id) => {
  const result = await axios(`/api/doctor/team/members?team_id=${id}`);
  return result.data.data;
}

export const sendInvite = async ({ team_id, email }) => {
  const result = await axios.post('/api/doctor/team/invite', { team_id, email });
  return result.data.data;
}

export const confirmJoin = async ({ member_id }) => {
  const result = await axios.post('/api/doctor/team/join', { member_id });
  return result.data.data;
}

export const deleteMember = async ({ member_id }) => {
  const result = await axios.delete(`/api/doctor/team/${member_id}`);
  return result.data.data;
}
