import React, { useState } from 'react';
import styled from 'styled-components'
import { lifecycle } from 'recompose'
import IconButton from '@material-ui/core/IconButton'
import ArrowBack from '@material-ui/icons/ArrowBack'
// import Videocam from '@material-ui/icons/Videocam'
// import Call from '@material-ui/icons/Call'
import Lock from '@material-ui/icons/Lock'
import LockOpen from '@material-ui/icons/LockOpen'
import { FormattedMessage } from 'react-intl'
import PersonIcon from '@material-ui/icons/Person'
import { TextButton } from 'common/UiKit/TextButton'
import { Colors } from 'constants/colors'
// import { fade } from '@material-ui/core/styles/colorManipulator'
// import { Tooltip } from '@material-ui/core'
import { useSelector } from 'react-redux';

const Wrapper = styled.div`
  width: 100%;
  font-family: Roboto, sans-serif;
`

const Bar = styled.div`
  display: flex;
  padding: 4px 8px;
  border-bottom: solid 1px ${Colors.grey};
  align-items: center;
`

const Info = styled.div`
  flex-grow: 1;
  padding-top: 5px;
  margin-left: 16px;
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
`

const Name = styled.h1`
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: ${Colors.dark};
  line-height: 1.33;
`

const Status = styled.div`
  font-size: 11px;
  color: ${Colors.dark60};
  line-height: 1.45;
  display: flex;

  & :first-child {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: ${({ isOnline }) => (isOnline ? Colors.seafomBlue : Colors.cloudyBlue)};
    margin-right: 6px;
    margin-top: 3px;
  }
`

const IconButtonStyled = styled(IconButton)`
  && {
    margin-left: 8px;
    ${props => props.filled && `
      background-color: ${Colors.clearBlue};
      color: white;

      &:hover {
        color: ${Colors.clearBlue};
      }
    `}
  }
`

// const ToggleButton = styled(IconButton)`
//   && {
//     margin-left: 8px;
//     background-color: ${({ selected }) => (selected ? '#21c5a9' : '#a2a7bd')};
//     color: white;
//     width: 40px;
//     height: 40px;
//
//     &:hover {
//       background-color: ${({ selected }) => fade((selected ? '#21c5a9' : '#a2a7bd'), 0.85)};
//     }
//   }
// `

const BackButton = styled(IconButton)`
  && {
    margin: 0;
    color: ${Colors.blueGrey};
  }
`

const HealthRecords = styled.div`
  display: flex;
  align-items: center;
  padding: 11px 24px;
  border-bottom: solid 1px ${Colors.grey};
  background-color: ${Colors.paleGrey};
  color: ${Colors.dark70};
  font-size: 12px;
  line-height: 1.17;

  & :first-child {
    font-size: 15px;
    margin-right: 14px;
    color: ${({ healthRecordsOpened }) => (healthRecordsOpened ? Colors.clearBlue : Colors.watermelon)};
  }
`

const JoinButton = styled(TextButton)`
  && {
    font-weight: 500;
    height: 40px;
    box-shadow: none;
    font-size: 14px;
    margin-left: 20px;
  }
`

export const ConsultationBarComponent = ({
  firstName,
  lastName,
  online,
  healthRecords,
  healthRecordsOpened,
  onBackIconClick,
  onCallIconClick,
  onVideoIconClick,
  // iconsFilled,
  // isCallDisabled,
  onPatientClick,
}) => {
  const hasVideoAccess = useSelector(state => (state.consultations.mediaAccess.video ? 'video' : 'audio'))
  const isDoctor = useSelector(state => state.auth.type === 'Doctor')
  const callData = useSelector(state => state.call)
  const [selected] = useState(hasVideoAccess)
  return (
    <Wrapper>
      <Bar>
        <BackButton color='inherit' onClick={onBackIconClick}>
          <ArrowBack />
        </BackButton>
        <Info onClick={onPatientClick}>
          <Name>
            {firstName || 'Name'}


            &nbsp;
            {lastName || ' Surname'}
          </Name>
          <Status isOnline={online}>
            <div />
            <FormattedMessage id={online ? 'online' : 'offline'} />
          </Status>
        </Info>
        {/*<Tooltip title={<FormattedMessage id='waiting.audio.hint' />} arrow>*/}
        {/*  <ToggleButton*/}
        {/*    filled={iconsFilled ? 'true' : ''}*/}
        {/*    color='primary'*/}
        {/*    onClick={() => toggle('audio')}*/}
        {/*    disabled={isCallDisabled}*/}
        {/*    selected={selected === 'audio'}*/}
        {/*  >*/}
        {/*    <Call />*/}
        {/*  </ToggleButton>*/}
        {/*</Tooltip>*/}
        {/*<Tooltip title={<FormattedMessage id='waiting.video.hint' />} arrow>*/}
        {/*  <ToggleButton*/}
        {/*    filled={iconsFilled ? 'true' : ''}*/}
        {/*    color='primary'*/}
        {/*    onClick={() => toggle('video')}*/}
        {/*    selected={selected === 'video'}*/}
        {/*    disabled={isCallDisabled}*/}
        {/*  >*/}
        {/*    <Videocam />*/}
        {/*  </ToggleButton>*/}
        {/*</Tooltip>*/}
        {isDoctor || callData ? (
          <JoinButton color='primary' onClick={() => (selected === 'video' ? onVideoIconClick() : onCallIconClick())}>
            <FormattedMessage id='waiting.join' />
          </JoinButton>
        ) : ''}
        {
          onPatientClick && (
            <IconButtonStyled
              filled={0}
              color='primary'
              onClick={onPatientClick}
            >
              <PersonIcon />
            </IconButtonStyled>
          )
        }
      </Bar>
      {
        healthRecords && (
          <HealthRecords healthRecordsOpened={healthRecordsOpened}>
            {healthRecordsOpened ? <LockOpen /> : <Lock />}
            <FormattedMessage id={`consultations.hr.access.${healthRecordsOpened ? 'opened' : 'closed'}`} />
          </HealthRecords>
        )
      }
    </Wrapper>
  )
}

const enhance = lifecycle({
  componentDidUpdate(prevProps) {
    if (prevProps.isCall !== this.props.isCall && this.props.isCall) {
      this.timerID = setInterval(() => {
        const date = new Date()
        this.setState({ iconsFilled: !!(date.getSeconds() % 2) })
      }, 1000)
    }

    if (prevProps.isCall !== this.props.isCall && !this.props.isCall) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ iconsFilled: false })
      clearInterval(this.timerID)
    }
  },
  componentWillUnmount() {
    clearInterval(this.timerID)
  },
})

export const ConsultationBar = enhance(ConsultationBarComponent)
