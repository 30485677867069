import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import InsertInvitation from '@material-ui/icons/InsertInvitation'
import Place from '@material-ui/icons/Place'
import { Paper } from 'common/UiKit/Paper'
import { Colors } from 'constants/colors'
import { FormattedMessage, useIntl } from 'react-intl'
import moment from 'moment'
import GroupIcon from '@material-ui/icons/Group';
import PaymentIcon from '@material-ui/icons/Payment';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography'
import defaultAvatar from '../../../../../images/panel/default-avatar.svg'

const PaperStyled = styled(Paper)`
  padding: 20px 0 20px 24px;
  margin: 24px 0 16px;
  position: relative;
`

const PopoverStyled = styled(Popover)`
  pointer-events: none;
  p {
    padding: 10px;
  }
`

const Wrapper = styled.div`
  display: flex;
  font-weight: normal;
  font-size: 16px;
`

const PriceBox = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;
`

const PriceContainer = styled.div`
  font-weight: bold;
  display: flex;
  font-size: 18px;
  color: ${({ isActive }) => (isActive ? '#f7bd40' : '#909ebd')};
  margin-left: 4px;
`

const Avatar = styled.div`
  min-width: 126px;
  width: 126px;
  height: 126px;
  border: 7px solid white;
  border-radius: 100%;
  background: url(${props => props.photo || defaultAvatar}) ${Colors.lightGrey} center no-repeat; 
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px 0 rgba(6, 173, 255, 0.1);
`

const Info = styled.div`
  flex-grow: 1;
  margin: 12px 0 0 32px;
`

const GeneralInfo = styled.div`
  display: flex;
  justify-content: space-between;
`

const Name = styled.h1`
  margin: 0;
  font-size: 24px;
  font-weight: bold;
`

const Specialization = styled.p`
  margin: 0;
  opacity: 0.8;
  padding-top: 10px;
`

const Over = styled.p`
  margin: 0;
  color: ${Colors.blueGrey};
  display: flex;
  align-items: center;
  margin-top: 12px;
  text-transform: capitalize;

  && svg {
    font-size: 18px;
    margin-right: 12px;
  }
`

const TeamWrapper = styled.div`
  position: absolute;
  top: 23px;
  right: 16px;
  text-align: center;
  display: flex;
  align-items: center;

  svg {
    fill: ${Colors.disabled};
    cursor: pointer;
  }
`

const Team = styled.span`
  opacity: 0.5;
  font-size: 16px;
  margin-right: 6px;
  color: ${Colors.blueGrey};
  text-transform: uppercase;
`

export const DoctorInfo = ({
  firstName,
  lastName,
  // experience,
  specialization,
  photo,
  available,
  location,
  teamName,
  doctorPrices,
  currentPriceType,
}) => {
  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  };

  const handlePopoverClose = () => {
    setAnchorEl(null)
  };

  const open = Boolean(anchorEl)

  return (
    <PaperStyled>
      { teamName && (
        <TeamWrapper
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup='true'
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <Team>
            {teamName}
            <PopoverStyled
              open={open}
              id='mouse-over-popover'
              anchorEl={anchorEl}
              disableRestoreFocus
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Typography>
                {' '}
                <FormattedMessage id='profile.aboutTeam' />
                {` "${teamName}"`}
              </Typography>
            </PopoverStyled>
          </Team>
          <GroupIcon />
        </TeamWrapper>
      )}
      <Wrapper>
        <Avatar
          photo={photo && photo.includes('/file/') ? `http://backend-application.owndoctor.eu${photo}` : photo}
        />
        <Info>
          <GeneralInfo>
            <div>
              <Name>
                {`${firstName || 'Name'} ${lastName || 'Surname'}`}
              </Name>
              <Specialization>
                {specialization || 'Specialization'}
              </Specialization>
            </div>
          </GeneralInfo>
          <Over>
            <Place />
            {Object.keys(location).length ? `${location.name}, ${location.street}, ${location.city}` : f('global.location')}
          </Over>
          <Over>
            <InsertInvitation />
            <FormattedMessage id='consultation.doctor.available' />
            {': '}
            {available ? moment(available).calendar(null, {
              sameElse: 'DD.MM.YYYY HH:mm',
            }) : <FormattedMessage id='hr.unknown' />}
          </Over>
          <Over>
            <PaymentIcon />
            <PriceBox>
              Standart: 
              <PriceContainer isActive={currentPriceType === 'daily_price'}>
                {!doctorPrices || doctorPrices.daily_price < 1
                  ? 25 : doctorPrices.daily_price}&nbsp;&euro;
              </PriceContainer>
            </PriceBox>
            <PriceBox>
              Urgent: 
              <PriceContainer isActive={currentPriceType === 'urgent_price'}>
                {!doctorPrices || doctorPrices.urgent_price < 1
                  ? '-' : doctorPrices.urgent_price}&nbsp;&euro;
              </PriceContainer>
            </PriceBox>
            <PriceBox>
              Weekend: 
              <PriceContainer isActive={currentPriceType === 'weekend_price'}>
                {!doctorPrices || doctorPrices.weekend_price < 1
                  ? '-' : doctorPrices.weekend_price}&nbsp;&euro;
              </PriceContainer>
            </PriceBox>
          </Over>
        </Info>
      </Wrapper>
    </PaperStyled>
  )
}

DoctorInfo.defaultProps = {
  firstName: '',
  lastName: '',
  // experience: '0',
  specialization: '',
  photo: defaultAvatar,
  available: '',
  location: {},
}

DoctorInfo.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  // experience: PropTypes.number,
  specialization: PropTypes.string,
  photo: PropTypes.string,
  available: PropTypes.string,
  location: PropTypes.object,
}
