import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import PropTypes from 'prop-types'
import { Colors } from 'constants/colors'
import consultationLogo from '../../../../../images/panel/consultation/consultation-logo.svg'
import { FillProfileStub } from '../../FillProfileStub'
import { logOut } from '../../../../../redux/modules/auth'
import { setTab } from '../../../../../redux/modules/consultations'
import { Picker } from './Picker'
import { Past } from './Past'
import { Upcoming } from './Upcoming'
import { LanguageSwitcher } from '../../../../common/LanguageSwitcher'
import { NotificationsButton } from '../../../../common/Page/Notifications'
import { PanelBar } from '../../../../common/PanelBar'

const Wrapper = styled.div`
  background-color: ${Colors.background};
  min-height: 100%;
`

const ListComponent = ({
  filled,
  id,
  logOut,
  tab,
  setTab,
}) => (
  <Wrapper>
    <PanelBar
      label='consultation.title'
      onLogout={logOut}
      id={id}
      notification={<NotificationsButton />}
      languageSwitcher={<LanguageSwitcher />}
    />
    {
      filled
        ? (
          <>
            <Picker
              tab={tab}
              changeTab={(e, v) => setTab(v)}
            />
            {tab === 0 && <Upcoming />}
            {tab === 1 && <Past />}
          </>
        )
        : <FillProfileStub image={consultationLogo} heading='consultation.title' />
    }
  </Wrapper>
)

ListComponent.propTypes = {
  filled: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  logOut: PropTypes.func.isRequired,
  tab: PropTypes.number.isRequired,
}

const enhance = compose(
  connect(state => ({
    filled: !!state.auth.relationships.education.data.length,
    id: state.auth.id,
    tab: state.consultations.tab,
  }), { logOut, setTab }),
)
export const List = enhance(ListComponent)
