import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'
import AccessTime from '@material-ui/icons/AccessTime'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { Paper } from 'common/UiKit/Paper'
import { Colors } from 'constants/colors'
import { compose, lifecycle } from 'recompose'
import idx from 'idx'
import { FormattedMessage } from 'react-intl'
import concilium from '../../../../../../images/icons/concilium.svg'

import { ActionButton } from './ActionButton'
import defaultAvatar from '../../../../../../images/panel/default-avatar.svg'
import { FullStar } from './RateModal'

const Wrapper = styled(Paper)`
  position: relative;
  padding: 16px 14px;
  margin-bottom: 16px;
  border-left: 2px solid #b2b5b8;
  cursor: pointer;
  border-radius: 4px;
  text-align: right;

  & svg {
    color: ${Colors.blueGrey};
  }

  ${props => props.urgent && `
    border-left: 2px solid #ffad00;
    background-color: #fff9e6;
    box-shadow: 0 4px 8px 0 #efebde;
  `}

  ${props => props.disablePointer && `
    cursor: initial;
  `}
`

const Content = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
`

const Photo = styled.div`
  margin-left: 16px;
  border-radius: 50%;
  height: 62px;
  width: 62px;
  border: 3px solid ${Colors.white};
  box-shadow: 0 4px 8px 0 ${Colors.azure};
  background-image: url(${props => props.photo || defaultAvatar});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`

const Date = styled.div`
  width: 26px;

  & > * {
    margin: 0;
  }

  & :first-child {
    font-size: 18px;
    font-weight: 500;
    opacity: 0.87;
    color: ${Colors.dark};
  }

  & :last-child {
    font-size: 14px;
    line-height: 1.86;
    font-weight: normal;
    opacity: 0.6;
  }
`

const Info = styled(Date)`
  margin-left: 16px;
  width: 250px;
  word-wrap: break-word;

  & :first-child {
    opacity: 1;
  }
`

const Language = styled.div`
  flex-grow: 1;
  color: ${Colors.blueGrey};
  opacity: 0.5;
  text-transform: uppercase;
  font-size: 12px;
`

const Time = styled.div`
  margin-right: 24px;
  display: flex;
  align-items: center;

  & > :first-child {
    color: ${Colors.dark};
    font-size: 18px;
    font-weight: bold;
    opacity: 0.8;
    margin-right: 8px;
  }
`
const UrgentLabel = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 184px;
  border-radius: 4px;
  background-color: #ffad00;
  font-size: 12px;
  color: #fff9e6;
  line-height: 2.17;
  text-transform: uppercase;
  text-align: center;
`

const Actions = styled.div`
  display: inline-block;
  cursor: default;
`

const Concilium = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-left: -55px;
  text-transform: uppercase;
  color: ${Colors.clearBlue};
  img {
    margin-right: 8px;
  }
`

const Rating = styled.span`
  color: ${Colors.dark};
  line-height: 19px;
  display: flex;
  align-items: center;
  
  svg {
    width: 19px !important;
    height: 19px !important;
    margin-left: 2px;
  }
`

const CardComponent = ({
  firstName,
  lastName,
  photo,
  specialization,
  hospital,
  date,
  language,
  urgent,
  onClick,
  isCompleted,
  reviews,
  reviewsFetching,
  onRepeatClick,
  onRateClick,
  id,
  isConcilium,
}) => {
  const myReview = (reviews || [])
    .find(review => idx(review, _ => _.attributes.consultation_id) === id)

  return (
    <Wrapper
      urgent={urgent}
      onClick={onClick}
    >
      <Content>
        {urgent && <UrgentLabel>urgent consultation</UrgentLabel>}
        <Date>
          <p>{moment(date).format('D')}</p>
          <p>{moment(date).format('ddd')}</p>
        </Date>
        <Photo photo={photo} />
        <Info>
          <p>{`${firstName || 'Name'} ${lastName || 'Surname'}`}</p>
          <p>{`${specialization || 'Specialization'} ${hospital || ''}`}</p>
        </Info>
        {isConcilium && (
          <Concilium>
            <img src={concilium} alt='' />
            <FormattedMessage id='concilium.title' />
          </Concilium>
        )}
        {
          !isConcilium && (
            <Language>
              {language.length ? language.join(' ') : 'EN'}
            </Language>
          )
        }
        <Time>
          <span>{moment.utc(date).format('HH:mm')}</span>
          <AccessTime />
        </Time>
        <KeyboardArrowRight />
      </Content>
      {
        isCompleted && (
          <Actions onClick={e => e.stopPropagation()}>
            <ActionButton
              isFetching={reviewsFetching}
              onClick={onRateClick}
              disabled={!!myReview}
            >
              {
                myReview
                  ? (
                    <Rating>
                      {myReview.attributes.rating}
                      <FullStar />
                    </Rating>
                  )
                  : <FormattedMessage id='consultation.rate' />
              }
            </ActionButton>
            <ActionButton onClick={onRepeatClick}>
              <FormattedMessage id='consultation.repeat' />
            </ActionButton>
          </Actions>
        )
      }
    </Wrapper>
  )
}

CardComponent.defaultProps = {
  photo: '',
  firstName: '',
  lastName: '',
  specialization: '',
  hospital: '',
  date: '',
  language: [],
  reviews: undefined,
  reviewsFetching: false,
  onRepeatClick: () => {},
  onRateClick: () => {},
}

CardComponent.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  specialization: PropTypes.string,
  hospital: PropTypes.string,
  photo: PropTypes.string,
  date: PropTypes.string,
  language: PropTypes.arrayOf(PropTypes.string),
  urgent: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  isCompleted: PropTypes.bool.isRequired,
  reviews: PropTypes.array,
  reviewsFetching: PropTypes.bool,
  onRepeatClick: PropTypes.func,
  onRateClick: PropTypes.func,
  id: PropTypes.string.isRequired,
}

const enhance = compose(
  lifecycle({
    componentDidMount() {
      if (this.props.isCompleted) {
        if (!this.props.reviews) {
          this.props.fetchDoctorReviews()
        }
      }
    },
  }),
)

export const Card = enhance(CardComponent)
