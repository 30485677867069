import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import { FormattedMessage } from 'react-intl'
import LaunchIcon from '@material-ui/icons/Launch'

import { Select } from '../../../../../common/Select'
import { EXPORT_OPTIONS } from '../../../../../../constants/filters'

export const Export = ({ onClick }) => (
  <Select
    onChange={onClick}
    options={EXPORT_OPTIONS}
    ValueComponent={(
      <Value>
        <FormattedMessage id='schedule.export' />
        <LaunchIcon />
      </Value>
    )}
  />
)

Export.propTypes = {
  onClick: PropTypes.func.isRequired,
}

const Value = styled.div`
  color: ${Colors.clearBlue};
  display: flex;
  align-items: center;
  
  svg {
    height: 14px;
    width: 14px;
    margin-left: 8px;
  }
`
