import React from 'react'
import styled from 'styled-components'
import { FormHelperText } from '@material-ui/core'
import { DatePicker } from '../DatePicker'
import { Input } from '../Input'

const Wrapper = styled.div`
  width: 42% !important;
`

export const DateField = ({ input, meta, ...rest }) => (
  <Wrapper>
    <DatePicker {...input} {...rest} openToYearSelection format='DD MMMM YYYY' TextFieldComponent={Input} />
    {(meta.touched && meta.error) && <FormHelperText error>{meta.error}</FormHelperText>}
  </Wrapper>
)
