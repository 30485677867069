import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import IconButton from '@material-ui/core/IconButton'

export const DateFilterValue = ({
  value,
  to,
  onBackClick,
  onForwardClick,
  ...rest
}) => {
  const toFormatted = moment(to).format('DD.MM')
  return (
    <Wrapper>
      <ToggleButton size='small' onClick={onBackClick}>
        <ArrowForwardIosIcon fontSize='small' />
      </ToggleButton>
      <Value onClick={rest.onClick}>
        {value === toFormatted ? value : `${value} - ${toFormatted}`}
      </Value>
      <ToggleButton size='small' onClick={onForwardClick}>
        <ArrowForwardIosIcon fontSize='small' />
      </ToggleButton>
    </Wrapper>
  )
}

DateFilterValue.propTypes = {
  value: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  onBackClick: PropTypes.func.isRequired,
  onForwardClick: PropTypes.func.isRequired,
}

//#region Styled components
const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const Value = styled.div`
  font-weight: 700;
  font-size: .875rem;
  color: ${Colors.clearBlue};
  cursor: pointer;
  margin: 0 8px;
  text-align: center;
  white-space: nowrap;
`

const ToggleButton = styled(IconButton)`
  && {
    padding: 4px;
    
    &:first-of-type {
      transform: rotate(180deg);
    }
    
    svg {
    fill: ${Colors.clearBlue};
      width: 14px;
      height: 14px;
    }
  }
`

export {
  Wrapper,
  Value,
  ToggleButton,
}
//#endregion
