import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { FormattedMessage } from 'react-intl'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Checkbox from '@material-ui/core/Checkbox'
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined'

export const Select = ({
  value,
  options,
  onChange,
  allCheckedLabel,
  ValueComponent,
  ...rest
}) => {
  const [isOpened, toggle] = useState(false)
  const anchor = useRef()
  const isMultiSelect = Array.isArray(value)

  const onAllToggle = (e) => {
    e.stopPropagation()
    onChange(options.map(item => item.value))
  }

  const onCheckboxClick = (e, option) => {
    e.stopPropagation()
    const newValue = value.includes(option)
      ? value.filter(item => item !== option)
      : [...value, option]

    onChange(newValue.length ? newValue : options.map(item => item.value))
  }

  return (
    <Wrapper ref={anchor} onClick={() => toggle(!isOpened)} {...rest}>
      {
        !isMultiSelect
          ? (
            <>
              {
                !ValueComponent
                  ? <FormattedMessage id={options.find(item => item.value === value).label} />
                  : ValueComponent
              }
            </>
          )
          : (
            <MultiselectValue>
              {
                value.length === options.length
                  ? <FormattedMessage id={allCheckedLabel} />
                  : value.map((item, idx) => (
                    <React.Fragment key={item}>
                      <FormattedMessage id={options.find(option => option.value === item).label} />
                      {idx !== value.length - 1 ? ', ' : ''}
                    </React.Fragment>
                  ))
              }
            </MultiselectValue>
          )
      }
      {!ValueComponent && <SelectIcon reversed={isOpened} />}
      <StyledMenu
        open={isOpened}
        anchorEl={anchor.current}
        onClose={() => toggle(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        getContentAnchorEl={undefined}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {
          !isMultiSelect && options.map(option => (
            <Option key={option.value} onClick={() => onChange(option.value)}>
              <FormattedMessage id={option.label} defaultMessage={option.label} />
            </Option>
          ))
        }
        {
          isMultiSelect && (
            <MultiselectOption onClick={onAllToggle} key='all'>
              <ListItemIcon>
                <Checkbox
                  checked={value.length === options.length}
                  color='primary'
                />
              </ListItemIcon>
              <div>
                <FormattedMessage id={allCheckedLabel} />
              </div>
            </MultiselectOption>
          )
        }
        {
          isMultiSelect && options.map(option => (
            <MultiselectOption
              key={option.value}
              onClick={e => onCheckboxClick(e, option.value)}
              disabled={value.length === 1 && value.includes(option.value)}
            >
              <ListItemIcon>
                <BlackCheckbox
                  checked={value.includes(option.value)}
                  color='default'
                  checkedIcon={<CheckBoxOutlinedIcon />}
                />
              </ListItemIcon>
              <CheckboxText>
                <FormattedMessage id={option.label} />
                {
                  option.color
                  && <Badge color={option.color} />
                }
              </CheckboxText>
            </MultiselectOption>
          ))
        }
      </StyledMenu>
    </Wrapper>
  )
}

Select.defaultProps = {
  allCheckedLabel: undefined,
  ValueComponent: undefined,
  value: '',
}

Select.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  ValueComponent: PropTypes.node,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  allCheckedLabel: PropTypes.string,
}

//#region Styled components
const Wrapper = styled.div`
  padding: 8px 12px;
  border-radius: 6px;
  background-color: ${Colors.white};
  text-transform: capitalize;
  font-weight: 900;
  font-size: 12px;
  color: ${Colors.dark80};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 4px 0 rgba(6, 173, 255, 0.1);
  letter-spacing: 0.2px;
  box-sizing: border-box;
  height: 36px;
  margin-right: 12px;
`

const SelectIcon = styled(ArrowForwardIosIcon)`
  && {
    width: 14px;
    height: 14px;
    will-change: transform;
    transform: rotate(${props => (props.reversed ? 270 : 90)}deg);
    margin-left: 12px;
    margin-top: ${props => (props.reversed ? 0 : 2)}px;
    transition: all .1s ease-out;
  }
`

const Option = styled(MenuItem)`
  && {
    font-size: 14px;
    padding: 8px 16px;
  }
`

const StyledMenu = styled(Menu)`
  && {
    ul {
      padding-top: 0;
    }
  }
`

const MultiselectOption = styled(Option)`
  && {
    padding: 8px 8px 8px 0;
    font-size: 14px;
    color: ${Colors.dark};
    
    &:first-of-type {
      font-weight: 700;
    }
    
    & > div:first-of-type {
      margin-right: 0;
    }
  }
`

const MultiselectValue = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${Colors.dark80};
`

const BlackCheckbox = styled(Checkbox)`
  && {
    svg {
      fill: ${Colors.dark};
    }
  }
`

const Badge = styled.div`
  width: 8px;
  height: 8px;
  display: inline-block;
  margin-left: 8px;
  border-radius: 50%;
  background-color: ${props => props.color};
`

const CheckboxText = styled.div`
  display: flex;
  align-items: center;
`
//#endregion
