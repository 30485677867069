import axios from 'axios';

export const questionsData = async () => {
  const result = await axios('/doctor/profile/question');
  return result.data.data;
}

export const saveQuestions = (values, afterSubmit, isGeneralist) => {
  const generalistQuestions = ['generalist.question.1', 'generalist.question.2', 'generalist.question.3', 'general.region', 'general.department']
  const specialistQuestions = ['specialist.question.1', 'specialist.question.2', 'general.region', 'general.department']
  const data = (isGeneralist ? generalistQuestions : specialistQuestions).map((i, idx) => ({
    question: i,
    answer: values[`question${idx + 1}`],
  }))

  const formData = new FormData()
  data.forEach((i, idx) => {
    formData.append(`question[${idx}][question]`, i.question)
    formData.append(`question[${idx}][answer]`, i.answer)
  })

  return axios('/doctor/profile/question', {
    method: 'POST',
    data: formData,
  })
    .then(() => {
      if (afterSubmit) afterSubmit()
    })
}
