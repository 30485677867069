import React from 'react'
import styled from 'styled-components'
import { Field } from 'react-final-form'
import moment from 'moment'
import { useIntl } from 'react-intl'
import { TextField } from 'common/UiKit/TextField'
import { Paper } from 'common/UiKit/Paper'

const Wrapper = styled(Paper)`
  display: flex;
  justify-content: space-between;
  padding: 24px 24px 0 24px;
  margin-bottom: 16px;

  && > * {
    width: calc((100% - 128px) / 3);
  }
`

export const Time = () => {
  const { formatMessage: f } = useIntl()

  return (
    <Wrapper headingTransparent='global.hr.title'>
      <Field
        component={TextField}
        name='height'
        label={f({ id: 'hr.height' })}
        parse={value => value && (+value || '')}
      />
      <Field
        component={TextField}
        name='weight'
        label={f({ id: 'hr.weight' })}
        parse={value => value && (+value || '')}
      />
      <Field
        InputLabelProps={{ shrink: true }}
        name='lastHospitalized'
        component={TextField}
        type='date'
        invalidLabel=''
        label={f({ id: 'hr.hospitalized' })}
        minDate={moment('1990', 'YYYY)')}
        maxDate={Date.now()}
        parse={value => value && moment(value).format('YYYY-MM-DD')}
      />
    </Wrapper>
  )
}
