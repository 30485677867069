import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper'

const locationHelper = locationHelperBuilder({})

export const userIsAuthenticated = connectedRouterRedirect({
  redirectPath: '/auth',
  authenticatedSelector: state => (!!state.auth.id && !!state.auth.attributes.phoneNumber),
  // authenticatedSelector: state => !!state.auth.id,
  wrapperDisplayName: 'UserIsAuthenticated',
})

export const userIsNotAuthenticated = connectedRouterRedirect({
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/panel/consultations',
  authenticatedSelector: state => (!state.auth.id || !state.auth.attributes.phoneNumber),
  // authenticatedSelector: state => !state.auth.id,
  wrapperDisplayName: 'UserIsNotAuthenticated',
  allowRedirectBack: false,
})
