import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { LinkCard } from 'common/UiKit/LinkCard'
import { Colors } from 'constants/colors';
import { ModalPrivacyPolicy } from './ModalPrivacyPolicy';
import { ModalTerms } from './ModalTerms';
import { ModalRestrictUsage } from './ModalRestrictUsage';
import { ModalRequestCSV } from './ModalRequestCSV';
import { ModalRequestDeletion } from './ModalRequestDeletion';
import { ModalSuccess } from './ModalSuccess';
import { ModalChangeDecision } from './ModalChangeDecision';
import { ModalPayment } from './ModalPayment';
import { history } from '../../../utils/history'
import { PanelBar } from '../PanelBar'

const Wrapper = styled.div`
  width: 720px;
  margin: 24px auto 0;
`;

const LinkCardStyled = styled(LinkCard)`
  padding: 12px 16px 12px 24px;
  margin-bottom: 16px;
`;

const Field = styled.div`
  & > p {
    margin: 25px 0 0;
    font-family: inherit;
    font-weight: 500;
    color: ${Colors.dark};
    padding: 0 24px 10px;
    font-size: 16px;
  }
`;

export const PrivacyPolicy = ({ match }) => {
  const [modalStates, setModalStates] = useState({
    privacy: false,
    terms: false,
    restrict: false,
    requestCsv: false,
    requestDeletion: false,
    modalChange: false,
    payment: false,
  });

  const [modalSucces, setModalSucces] = useState(false);

  const changeModalState = (modal, state) => {
    setModalStates({ ...modalStates, [modal]: state });
  };

  const openSuccessModal = () => {
    setModalSucces(true)
  }

  return (
    <>
      <PanelBar label='profile.title' onBackIconClick={() => history.goBack()} />
      <Wrapper>
        <LinkCardStyled
          label='profile.termsOfUse'
          onClick={() => changeModalState('terms', true)}
        />
        <LinkCardStyled
          label='global.privacy-policy'
          onClick={() => changeModalState('privacy', true)}
        />
        <LinkCardStyled
          label='profile.paymentPolicy'
          onClick={() => changeModalState('payment', true)}
        />
        <LinkCardStyled label='profile.history' to={`${match.path}/history`} />
        <Field>
          <p>
            <FormattedMessage id='profile.personalData' />
          </p>
        </Field>
        <LinkCardStyled
          label='profile.changeData'
          onClick={() => changeModalState('modalChange', true)}
        />
        <LinkCardStyled
          label='profile.requestData'
          onClick={() => changeModalState('requestCsv', true)}
        />
        <LinkCardStyled
          label='profile.requestDeletion'
          onClick={() => changeModalState('requestDeletion', true)}
        />
        <LinkCardStyled
          label='profile.restrictData'
          onClick={() => changeModalState('restrict', true)}
        />
      </Wrapper>
      <ModalChangeDecision
        handleClickOpen={changeModalState}
        open={modalStates.modalChange}
        handleClose={changeModalState}
      />
      <ModalPrivacyPolicy
        handleClickOpen={changeModalState}
        open={modalStates.privacy}
        handleClose={changeModalState}
      />
      <ModalTerms
        handleClickOpen={changeModalState}
        handleClose={changeModalState}
        open={modalStates.terms}
      />
      <ModalPayment
        handleClickOpen={changeModalState}
        handleClose={changeModalState}
        open={modalStates.payment}
      />
      <ModalRestrictUsage
        handleClickOpen={changeModalState}
        handleClose={changeModalState}
        open={modalStates.restrict}
        openSuccessModal={openSuccessModal}
      />
      <ModalRequestCSV
        handleClickOpen={changeModalState}
        handleClose={changeModalState}
        open={modalStates.requestCsv}
        openSuccessModal={openSuccessModal}
      />
      <ModalRequestDeletion
        handleClickOpen={changeModalState}
        handleClose={changeModalState}
        open={modalStates.requestDeletion}
      />
      <ModalSuccess
        handleClose={setModalSucces}
        open={modalSucces}
      />
    </>
  );
};
