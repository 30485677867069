import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { socket } from '../../../../services/socket'
import { Chat } from '../../Chat';

export const AdministratorChat = () => {
  const userId = useSelector(state => state.auth.id)

  useEffect(() => {
    setTimeout(() => socket.connectChat(userId, true), 1000)
  }, [])

  return (
    <Chat isAdminChat />
  )
}
