import { createAction, handleActions, combineActions } from 'redux-actions'
import axios from 'axios'
import idx from 'idx'
import moment from 'moment'
import * as Sentry from '@sentry/browser'
import { history } from '../../utils/history'
import { setNotification } from './notifications'

const defaultState = {}

//#region Actions
export const authByTokenRequest = createAction('AUTH_BY_TOKEN_REQUEST')
export const authByTokenSuccess = createAction('AUTH_BY_TOKEN_SUCCESS')
export const authByTokenFailure = createAction('AUTH_BY_TOKEN_FAILURE')

export const authByToken = payload => (dispatch) => {
  dispatch(authByTokenRequest(payload))

  if (!payload.token && !localStorage.authToken) {
    return dispatch(authByTokenFailure())
  }

  if (!payload.type && !localStorage.userType) {
    return dispatch(authByTokenFailure())
  }

  if (payload.token && payload.type) {
    localStorage.setItem('authToken', payload.token)
    localStorage.setItem('userType', payload.type)
  }

  const token = `Bearer ${localStorage.authToken}`
  const { userType } = localStorage

  axios.defaults.headers.common.Authorization = token

  return axios(`/${userType}/profile`)
    .then((response) => {
      dispatch(authByTokenSuccess(response.data))
      Sentry.configureScope((scope) => {
        scope.setUser({
          email: response.data.data.attributes.email,
          id: response.data.data.attributes.user_id,
        })
      })
    })
    .catch((err) => {
      dispatch(authByTokenFailure(err))
      delete axios.defaults.headers.common.Authorization;
      localStorage.removeItem('authToken')
      localStorage.removeItem('2fa')
      localStorage.removeItem('userType')
    })
}

export const authRequest = createAction('AUTH_REQUEST')
export const authSuccess = createAction('AUTH_SUCCESS')
export const authFailure = createAction('AUTH_FAILURE')

export const auth = (values, isPatient) => (dispatch) => {
  dispatch(authRequest(values))

  const data = values
  const formData = new FormData()

  Object.keys(data).forEach((item) => {
    formData.append(item, data[item])
  })

  return axios(`/${isPatient ? 'patient' : 'doctor'}/${values.confirmation ? 'registration' : 'authorization'}`, {
    method: 'POST',
    data: formData,
  })
    .then((response) => {
      // TODO: fix token in storage and userType
      const token = idx(response, _ => _.data.data.attributes.token)
      const userId = idx(response, _ => _.data.data.attributes.id)
      const user = isPatient ? 'patient' : 'doctor'

      localStorage.setItem('authToken', token)
      localStorage.setItem('userType', user)
      localStorage.setItem('userId', userId)

      axios.defaults.headers.common.Authorization = token
      dispatch(authSuccess())
      dispatch(authByToken(token))
      history.push('/panel/consultations')
    })
    .catch((err) => {
      dispatch(authFailure(err))
      delete axios.defaults.headers.common.Authorization;
      // Final form submission error
      return { password: 'Email ou mot de passe incorrect.' }
    })
}

export const logOutRequest = createAction('LOG_OUT_REQUEST')
export const logOutSuccess = createAction('LOG_OUT_SUCCESS')
export const logOutFailure = createAction('LOG_OUT_FAILURE')

export const logOut = () => (dispatch) => {
  dispatch(logOutRequest())

  return new Promise((resolve) => {
    const lang = localStorage.getItem('language')
    localStorage.clear()

    if (lang) {
      localStorage.setItem('language', lang)
    }
    dispatch(logOutSuccess())
    resolve()
    history.push('/auth')
  })
}

export const passwordRecoveryRequest = createAction('PASSWORD_RECOVERY_REQUEST')
export const passwordRecoverySuccess = createAction('PASSWORD_RECOVERY_SUCCESS')
export const passwordRecoveryFailure = createAction('PASSWORD_RECOVERY_FAILURE')

export const passwordRecovery = payload => (dispatch) => {
  dispatch(passwordRecoveryRequest(payload))

  const data = payload.values
  const type = payload.type === 'doctor' ? 'doctor' : 'patient'
  const formData = new FormData()

  Object.keys(data).forEach((item) => {
    formData.append(item, data[item])
  })

  return axios(`/${type}/forgotPassword/send`, {
    method: 'POST',
    data: formData,
  })
    .then(() => {
      dispatch(passwordRecoverySuccess())
      dispatch(setNotification({ title: 'A letter was send on your email', type: 'success' }))
    })
    .catch((err) => {
      dispatch(passwordRecoveryFailure(err))
      const error = idx(err, _ => _.response.data.data.error_description)
      dispatch(setNotification(error || 'Invalid email'))
    })
}

export const setNewPasswordRequest = createAction('SET_NEW_PASSWORD_REQUEST')
export const setNewPasswordSuccess = createAction('SET_NEW_PASSWORD_SUCCESS')
export const setNewPasswordFailure = createAction('SET_NEW_PASSWORD_FAILURE')

export const setNewPassword = payload => (dispatch) => {
  dispatch(setNewPasswordRequest(payload))

  const data = payload.values
  const type = payload.type === 'doctor' ? 'doctor' : 'patient'
  const formData = new FormData()

  Object.keys(data).forEach((item) => {
    formData.append(item, data[item])
  })

  return axios(`/${type}/forgotPassword/check`, {
    method: 'POST',
    data: formData,
  })
    .then((response) => {
      const token = `${idx(response, _ => _.data.data.attributes.token)}`

      localStorage.setItem('authToken', token)
      localStorage.setItem('userType', type)

      axios.defaults.headers.common.Authorization = token
      dispatch(setNewPasswordSuccess())
      dispatch(authByToken(token))
      history.push('/panel/consultations')
    })
    .catch((err) => {
      if (idx(err, _ => _.response.data.errors.some(item => item.title === 'authorization_exception.role_is_not_defined'))) {
        dispatch(setNewPassword({ values: data, type: payload.type === 'doctor' ? 'patient' : 'doctor' }))
        return
      }
      dispatch(setNewPasswordFailure(err))
      dispatch(setNotification('An error occured'))
    })
}

export const changePasswordRequest = createAction('CHANGE_PASSWORD_REQUEST');
export const changePasswordSuccess = createAction('CHANGE_PASSWORD_SUCCESS');
export const changePasswordFailure = createAction('CHANGE_PASSWORD_FAILURE');

export const changePassword = values => (dispatch) => {
  dispatch(changePasswordRequest(values));

  const { userType } = localStorage
  const data = values
  const formData = new FormData()

  Object.keys(data).forEach((item) => {
    if (item === 'currentPassword') {
      formData.append('password', data[item])

      return
    }
    formData.append(item, data[item])
  })

  return axios(`/${userType}/changePassword`, {
    method: 'POST',
    data: formData,
  })
    .then((res) => {
      dispatch(changePasswordSuccess(res.data))
      history.push('/panel/profile')
    })
    .catch((err) => {
      dispatch(changePasswordFailure(err))

      // Final form submission error
      return { currentPassword: 'Incorrect password, please try again' }
    })
}

export const editProfileRequest = createAction('EDIT_PROFILE_REQUEST')
export const editProfileSuccess = createAction('EDIT_PROFILE_SUCCESS')
export const editProfileFailure = createAction('EDIT_PROFILE_FAILURE')

export const editProfile = values => (dispatch, getStore) => {
  dispatch(editProfileRequest(values))
  const profile = getStore().auth.attributes
  const { userType } = localStorage
  const data = values
  const formData = new FormData()

  data.email = profile.email
  //data.available_till = (moment().add(5, 'm').unix()).toString()
  // TODO: kostyl'
  if (!data.first_name && profile.first_name) data.first_name = profile.first_name
  if (!data.last_name && profile.last_name) data.last_name = profile.last_name
  if (!data.gender && profile.gender) data.gender = profile.gender
  if (
    !data.language && profile.language && profile.language.length
  ) data.language = profile.language

  if (!data.addition_email && profile.addition_email) {
    data.addition_email = profile.addition_email
  }

  Object.keys(data).forEach((item) => {
    if (item === 'work_place') return
    if (item === 'language') {
      data[item].map(lang => formData.append('language[]', lang))
    } else if (item === 'addition_email') {
      if (!data[item].length) return
      data[item].forEach(addition_email => (addition_email ? formData.append('addition_email[]', addition_email || '') : null))
    } else {
      formData.append(item, data[item])
    }
  })

  formData.delete('insurance')

  if (userType === 'doctor') {
    // formData.append('daily_price', 0)
    // formData.append('weekend_price', 0)
    // formData.append('urgent_price', 0)

    formData.append('experience', profile.experience || 0)

    if (!data.specialization_id) {
      formData.append('specialization_id', profile.specialization_id)
    }
  }

  return axios(`/${userType}/profile`, {
    method: 'POST',
    data: formData,
  })
    .then(() => axios(`/${userType}/profile`))
    .then(res => dispatch(editProfileSuccess(res.data)))
    .catch((err) => {
      dispatch(editProfileFailure(err))
    })
}

export const createWorkPlaceRequest = createAction('CREATE_WORKPLACE_REQUEST')
export const createWorkPlaceSuccess = createAction('CREATE_WORKPLACE_SUCCESS')
export const createWorkPlaceFailure = createAction('CREATE_WORKPLACE_FAILURE')

export const createWorkPlace = values => (dispatch, getStore) => {
  dispatch(createWorkPlaceRequest(values))
  const { id } = getStore().auth

  const formData = new FormData()

  // TODO: kostyl'
  values.workPlace.map((v, i) => (
    Object.keys(v).forEach(key => formData.append(`workPlace[${i}][${key}]`, v[key]))
  ))

  return axios(`/general/doctor/${id}/workPlace`, {
    method: 'POST',
    data: formData,
  })
    .then(() => axios('/doctor/profile'))
    .then(res => dispatch(createWorkPlaceSuccess(res.data)))
    .catch((err) => {
      dispatch(createWorkPlaceFailure(err))
    })
}

export const createEducationRequest = createAction('CREATE_EDUCATION_REQUEST')
export const createEducationSuccess = createAction('CREATE_EDUCATION_SUCCESS')
export const createEducationFailure = createAction('CREATE_EDUCATION_FAILURE')

export const createEducation = values => (dispatch, getStore) => {
  dispatch(createEducationRequest(values))
  const { id } = getStore().auth

  const formData = new FormData()

  values.education.filter(i => !!i).forEach((v, i) => {
    formData.append(`education[${i}][name]`, v.name)
    formData.append(`education[${i}][receivedAt]`, v.receivedAt)
  })

  return axios(`/general/doctor/${id}/education`, {
    method: 'POST',
    data: formData,
  })
    .then(() => axios('/doctor/profile'))
    .then(res => dispatch(createEducationSuccess(res.data)))
    .catch((err) => {
      dispatch(createEducationFailure(err))
    })
}

// prices
export const createPricesRequest = createAction('CREATE_PRICES_REQUEST')
export const createPricesSuccess = createAction('CREATE_PRICES_SUCCESS')
export const createPricesFailure = createAction('CREATE_PRICES_FAILURE')

export const createPrices = values => (dispatch, getStore) => {
  dispatch(createPricesRequest(values))
  const { id } = getStore().auth

  const data = values
  const formData = new FormData()

  Object.keys(data).forEach((item) => {
    formData.append(item, data[item])
  })

  return axios(`/general/doctor/price/${id}`, {
    method: 'POST',
    data: formData,
  })
    .then(() => axios('/doctor/profile'))
    .then(res => dispatch(createPricesSuccess(res.data)))
    .catch((err) => {
      dispatch(createPricesFailure(err))
    })
}

export const createCoursesRequest = createAction('CREATE_COURSES_REQUEST')
export const createCoursesSuccess = createAction('CREATE_COURSES_SUCCESS')
export const createCoursesFailure = createAction('CREATE_COURSES_FAILURE')

export const createCourses = values => (dispatch, getStore) => {
  dispatch(createCoursesRequest(values))
  const { id } = getStore().auth

  const formData = new FormData()

  // TODO: kostyl'
  values.qualificationCourses.map((v, i) => (
    Object.keys(v).forEach(key => formData.append(`qualificationCourses[${i}][${key}]`, v[key]))
  ))

  return axios(`/general/doctor/${id}/qualificationCourses`, {
    method: 'POST',
    data: formData,
  })
    .then(() => axios('/doctor/profile'))
    .then(res => dispatch(createCoursesSuccess(res.data)))
    .catch((err) => {
      dispatch(createCoursesFailure(err))
    })
}

export const createDiplomasRequest = createAction('CREATE_DIPLOMAS_REQUEST')
export const createDiplomasSuccess = createAction('CREATE_DIPLOMAS_SUCCESS')
export const createDiplomasFailure = createAction('CREATE_DIPLOMAS_FAILURE')

export const createDiplomas = values => (dispatch, getStore) => {
  dispatch(createDiplomasRequest(values))
  const { id } = getStore().auth
  const formData = new FormData()

  values.file.forEach((item) => {
    formData.append('file', item)
  })

  return axios(`/general/doctor/${id}/diploma`, {
    method: 'POST',
    data: formData,
  })
    .then(() => axios('/doctor/profile'))
    .then(res => dispatch(createDiplomasSuccess(res.data)))
    .catch((err) => {
      dispatch(createDiplomasFailure(err))
    })
}

export const deleteDiplomaRequest = createAction('DELETE_DIPLOMA_REQUEST')
export const deleteDiplomaSuccess = createAction('DELETE_DIPLOMA_SUCCESS')
export const deleteDiplomaFailure = createAction('DELETE_DIPLOMA_FAILURE')

export const deleteDiploma = value => (dispatch, getStore) => {
  dispatch(deleteDiplomaRequest(value))
  const { id } = getStore().auth

  return axios(`/general/doctor/${id}/diploma/${value}`, {
    method: 'DELETE',
  })
    .then(() => axios('/doctor/profile'))
    .then(res => dispatch(deleteDiplomaSuccess(res.data)))
    .catch((err) => {
      dispatch(deleteDiplomaFailure(err))
    })
}

export const createScheduleRequest = createAction('CREATE_SCHEDULE_REQUEST')
export const createScheduleSuccess = createAction('CREATE_SCHEDULE_SUCCESS')
export const createScheduleFailure = createAction('CREATE_SCHEDULE_FAILURE')

export const createSchedule = values => (dispatch, getStore) => {
  dispatch(createScheduleRequest(values))
  const { id } = getStore().auth

  const formData = new FormData()

  // TODO: kostyl'
  values.schedule.map((v, i) => (
    Object.keys(v).forEach(key => formData.append(`schedule[${i}][${key}]`, v[key]))
  ))

  return axios(`/general/doctor/${id}/schedule`, {
    method: 'POST',
    data: formData,
  })
    .then(() => axios('/doctor/profile'))
    .then(res => dispatch(createScheduleSuccess(res.data)))
    .catch((err) => {
      dispatch(createDiplomasFailure(err))
    })
}

export const deleteEducationRequest = createAction('DELETE_EDUCATION_REQUEST')
export const deleteEducationSuccess = createAction('DELETE_EDUCATION_SUCCESS')
export const deleteEducationFailure = createAction('DELETE_EDUCATION_FAILURE')

export const deleteEducation = value => (dispatch, getStore) => {
  dispatch(deleteEducationRequest(value))
  const { id } = getStore().auth

  return axios(`/general/doctor/${id}/education/${value}`, {
    method: 'DELETE',
  })
    .then(() => axios('/doctor/profile'))
    .then(res => dispatch(deleteEducationSuccess(res.data)))
    .catch((err) => {
      dispatch(deleteEducationFailure(err))
    })
}

export const editEducationRequest = createAction('EDIT_EDUCATION_REQUEST')
export const editEducationSuccess = createAction('EDIT_EDUCATION_SUCCESS')
export const editEducationFailure = createAction('EDIT_EDUCATION_FAILURE')

export const editEducation = (values, index) => (dispatch, getStore) => {
  dispatch(editEducationRequest(values))
  const { id } = getStore().auth

  return axios(`/general/doctor/${id}/education/${index}`, {
    method: 'POST',
    data: values,
  })
    .then(() => axios('/doctor/profile'))
    .then(res => dispatch(editEducationSuccess(res.data)))
    .catch((err) => {
      dispatch(editEducationFailure(err))
    })
}

export const deleteCoursesRequest = createAction('DELETE_COURSES_REQUEST')
export const deleteCoursesSuccess = createAction('DELETE_COURSES_SUCCESS')
export const deleteCoursesFailure = createAction('DELETE_COURSES_FAILURE')

export const deleteCourses = value => (dispatch, getStore) => {
  dispatch(deleteCoursesRequest(value))
  const { id } = getStore().auth

  return axios(`/general/doctor/${id}/qualificationCourses/${value}`, {
    method: 'DELETE',
  })
    .then(() => axios('/doctor/profile'))
    .then(res => dispatch(deleteCoursesSuccess(res.data)))
    .catch((err) => {
      dispatch(deleteCoursesFailure(err))
    })
}

export const editCoursesRequest = createAction('EDIT_COURSES_REQUEST')
export const editCoursesSuccess = createAction('EDIT_COURSES_SUCCESS')
export const editCoursesFailure = createAction('EDIT_COURSES_FAILURE')

export const editCourses = (values, index) => (dispatch, getStore) => {
  dispatch(editCoursesRequest(values))
  const { id } = getStore().auth

  return axios(`/general/doctor/${id}/qualificationCourses/${index}`, {
    method: 'POST',
    data: values,
  })
    .then(() => axios('/doctor/profile'))
    .then(res => dispatch(editCoursesSuccess(res.data)))
    .catch((err) => {
      dispatch(editCoursesFailure(err))
    })
}

export const updateUserTimestampRequest = createAction('UPDATE_USER_TIMESTAMP_REQUEST')
export const updateUserTimestampSuccess = createAction('UPDATE_USER_TIMESTAMP_SUCCESS')
export const updateUserTimestampFailure = createAction('UPDATE_USER_TIMESTAMP_FAILURE')

export const updateUserTimestamp = () => (dispatch) => {
  const { userType } = localStorage

  dispatch(updateUserTimestampRequest())
  return axios('/api/user/account/online', {
    method: 'POST',
    data: {
      available_till: moment().add(10, 'm').format(),
    },
  })
    .then(() => axios(`/${userType}/profile`))
    .then(res => dispatch(editProfileSuccess(res.data)))
    .catch((err) => {
      dispatch(updateUserTimestampFailure(err))
    })
}

// export const updateUserTimestamp = () => (dispatch, getStore) => {
//   dispatch(updateUserTimestampRequest())
//   const profile = getStore().auth.attributes
//   const { userType } = localStorage
//   const formData = new FormData()
//   const data = {
//     email: profile.email,
//     first_name: profile.first_name,
//     last_name: profile.last_name,
//     gender: profile.gender,
//     available_till: moment().add(10, 'm').format(),
//   }

//   if (!data.first_name || !data.last_name) return dispatch(updateUserTimestampFailure())

//   if (profile.addition_email) {
//     profile.addition_email.forEach(item => formData.append('addition_email[]', item))
//   }

//   Object.keys(data).forEach((item) => {
//     if (item === 'language') {
//       data[item].map(lang => formData.append('language[]', lang))
//     } else {
//       formData.append(item, data[item])
//     }
//   })

//   if (userType === 'doctor') {
//     formData.append('daily_price', 0)
//     formData.append('weekend_price', 0)
//     formData.append('urgent_price', 0)
//   }

//   return axios(`/${userType}/profile`, {
//     method: 'POST',
//     data: formData,
//   })
//     .then(() => axios(`/${userType}/profile`))
//     .then(res => dispatch(editProfileSuccess(res.data)))
//     .catch((err) => {
//       dispatch(updateUserTimestampFailure(err))
//     })
// }

export const sendPhoneRequest = createAction('SEND_PHONE_REQUEST')
export const sendPhoneSuccess = createAction('SEND_PHONE_SUCCESS')
export const sendPhoneFailure = createAction('SEND_PHONE_FAILURE')

export const sendPhone = phoneNumber => (dispatch) => {
  dispatch(sendPhoneRequest(phoneNumber))
  const { userType } = localStorage

  return axios(`/${userType}/phone/enter`, {
    method: 'POST',
    data: { phoneNumber },
  })
    .then(response => dispatch(sendPhoneSuccess(response.data)))
    .catch((err) => {
      dispatch(sendPhoneFailure(err))
      throw err
    })
}

export const sendCodeRequest = createAction('SEND_CODE_REQUEST')
export const sendCodeSuccess = createAction('SEND_CODE_SUCCESS')
export const sendCodeFailure = createAction('SEND_CODE_FAILURE')

export const sendCode = confirmationCode => (dispatch) => {
  dispatch(sendCodeRequest(confirmationCode))
  const { userType } = localStorage

  return axios(`/${userType}/phone/confirm`, {
    method: 'POST',
    data: { confirmationCode },
  })
    .then((response) => {
      localStorage.setItem('2fa', 1)
      return dispatch(sendCodeSuccess(response.data))
    })
    .then(() => axios(`/${userType}/profile`))
    .then(res => dispatch(editCoursesSuccess(res.data)))
    .then(() => history.push(userType === 'doctor' ? '/panel/profile/fill' : '/panel/profile/edit'))
    .catch((err) => {
      dispatch(sendCodeFailure(err))
      throw err
    })
}

export const changeLanguageRequest = createAction('CHANGE_LANGUAGE_REQUEST')
export const changeLanguageFailure = createAction('CHANGE_LANGUAGE_FAILURE')

export const changeLanguage = value => (dispatch) => {
  dispatch(changeLanguageRequest())
  return axios('/api/user/setting/interface_language', {
    method: 'POST',
    data: value,
  })
    .catch(e => dispatch(changeLanguageFailure(e)))
}

//#endregion

//#region Reducers
export const authReducer = handleActions({
  [authSuccess]: (state, action) => action.payload,
  [logOutSuccess]: () => ({}),
  [combineActions(
    authByTokenSuccess,
    editProfileSuccess,
    createWorkPlaceSuccess,
    createEducationSuccess,
    createPricesSuccess,
    createCoursesSuccess,
    createDiplomasSuccess,
    deleteDiplomaSuccess,
    createScheduleSuccess,
    deleteEducationSuccess,
    editEducationSuccess,
    deleteCoursesSuccess,
    editCoursesSuccess,
  )]: (state, action) => action.payload.data,
}, defaultState)
//#endregion
