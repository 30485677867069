import React from 'react';
import CookieConsent from 'react-cookie-consent';
import styled from 'styled-components'
import { FormattedMessage, useIntl } from 'react-intl'

const Link = styled.a`
  text-decoration: underline;
  color: #fff;
  font-size: 16px
`

export const BannerCookie = () => {
  const { formatMessage } = useIntl()
  return (
    <CookieConsent
      style={{
        background: '#3daefd',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px 0',
      }}
      contentStyle={{
        flex: 'none',
        margin: '0',
      }}
      buttonText={formatMessage({ id: 'profile.button.iAgree' })}
      buttonStyle={{
        backgroundColor: '#22436c',
        color: '#fff',
        fontSize: '16px',
        borderRadius: '15px',
        margin: '0 0 0 45px',
        padding: '7px 25px',
      }}
    >
      <FormattedMessage id='gdpr.banner.title' />{' '}
      <Link href='/'>
        <FormattedMessage id='gdpr.banner.link' />
      </Link>
    </CookieConsent>
  );
}
