import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { compose, withProps } from 'recompose'
import { connect } from 'react-redux'
import idx from 'idx'
import { Link } from 'react-router-dom'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { Paper } from 'common/UiKit/Paper'
import { Colors } from 'constants/colors'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import logoImg from '../../../../../images/panel/profile/health-records.png'

const LinkStyled = styled(Link)`
  text-decoration: none;
  width: 100%;
`

const Wrapper = styled(Paper)`
  padding: 20px 16px 20px 24px;
  color: ${Colors.blueGrey};
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
`

const Content = styled.div`
  margin: 0 16px;
  flex-grow: 1;
`

const Logo = styled.img`
  width: 64px;
  height: 64px;
  align-self: flex-start;
`

const Text = styled.p`
  margin: 8px 0 0;
  font-size: 16px;
  line-height: 1.5;
  opacity: 0.8;
`

const Label = styled.h1`
  margin: 0;
  font-size: 24px;
  font-weight: 500;
  color: ${Colors.dark};
  word-wrap: break-word;
  line-height: 1;
`

const HealthDescriptionLinkComponent = ({ filled }) => (
  <LinkStyled to='/panel/health-records/edit'>
    <Wrapper>
      <Logo src={logoImg} />
      <Content>
        <Label>
          <FormattedMessage id='hr.description' />
        </Label>
        <Text>
          <FormattedHTMLMessage id='hr.records.text' />
        </Text>
      </Content>
      {filled}
      <KeyboardArrowRight />
    </Wrapper>
  </LinkStyled>
)

HealthDescriptionLinkComponent.propTypes = {
  filled: PropTypes.string.isRequired,
}

const calcFilledAttributes = (payload) => {
  // smoke and pregnant fields always filled
  let length = 2
  const attr = payload || {}

  if (attr.height) length += 1
  if (attr.weight) length += 1
  if (attr.contraindication && attr.contraindication.length) length += 1
  if (attr.lastHospitalized) length += 1

  return length
}

const enhance = compose(
  connect(state => ({ attributes: idx(state, _ => _.healthRecords.attributes) })),
  withProps(({ attributes }) => ({
    filled: `${calcFilledAttributes(attributes)}/6`,
  })),
)

export const HealthDescriptionLink = enhance(HealthDescriptionLinkComponent)
