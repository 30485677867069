import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { FormattedMessage } from 'react-intl'

export const UrgentToggle = ({ checked, onChange, disabled }) => (
  <FormControlLabel
    control={(
      <Switch
        checked={checked}
        onChange={onChange}
        color='primary'
        disabled={disabled}
      />
    )}
    label={<FormattedMessage id='concilium.urgent.switch' />}
  />
)
