import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import idx from 'idx'
import { connect, useSelector } from 'react-redux'
import { compose, withProps } from 'recompose'
import { TextButton } from 'common/UiKit/TextButton'
import { Colors, calendarColors } from 'constants/colors'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { FormattedMessage } from 'react-intl'
import CheckIcon from '@material-ui/icons/Check';

import { history } from '../../../../../../utils/history'
//import { approveConsultation } from '../../../../../../redux/modules/consultations'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  height: 80px;
  padding: 20px 24px;
  background-color: ${Colors.paleGrey};
  border: 1px solid ${Colors.grey};

  button {
    font-size: 14px;
    font-weight: 500;
  }
`

const ReportButtons = styled.div`
  flex-grow: 1;

  button {
    margin-right: 16px;
  }
`

const ApproveButton = styled(TextButton)`
  && {
    margin-left: 16px;
    min-width: 258px;
    
    ${props => (props.color === 'default' ? css`
      background-color: ${calendarColors.refuseButton};
      color: white;
      
      &:hover {
        background-color: ${calendarColors.refuseButtonHover};
      }
    ` : '')}
  }
`
const CheckIconRegular = styled(CheckIcon)`
  && {
    margin-right: 12px;
  }
`
const CheckIconRound = styled(CheckCircleOutlineIcon)`
  && {
    margin-right: 12px;
  }
`

const CompletedLabel = styled.div`
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-right: 24px;
  color: ${Colors.tealish};
  
  span {
    margin-top: 2px;
  }
`

const ButtonsBarComponent = ({
  medicalReportFilled,
  consultationStatus,
  onReportButtonClick,
  onPrescriptionButtonClick,
  onCompleteButtonClick,
  // refuse,
  prescriptionFilled,
  isPatient,
  isPaymentCompleted,
}) => {
  const consultation = useSelector(state => idx(state, _ => _.consultations.consultation))
  const consultationId = consultation.id;
  const userId = useSelector(state => state.auth.attributes.user_id)
  const invoice = `${process.env.REACT_APP_API_URL}/general/consultation/${consultationId}/${userId}/pdf/invoice`
  const consultationType = idx(consultation, _ => _.attributes.type);

  return (
    <Wrapper>
      <ReportButtons>
        {(consultationStatus !== 'created' && !isPatient) && (
          <>
            <TextButton
              disabled={medicalReportFilled}
              variant='extended'
              color='primary'
              onClick={onReportButtonClick}
            >
              <FormattedMessage id='consultations.report' />
            </TextButton>
            <TextButton
              variant='extended'
              color='primary'
              onClick={onPrescriptionButtonClick}
              disabled={!medicalReportFilled || prescriptionFilled}
            >
              <FormattedMessage id='consultations.prescription' />
            </TextButton>
          </>
        )}
      </ReportButtons>
      {(consultationStatus !== 'completed') && (
        <TextButton
          variant='extended'
          color='secondary'
          onClick={onCompleteButtonClick}
        >
          <CheckIconRegular />
          {consultationType === 'telexpertise'
            ? <FormattedMessage id='concilium.complete' />
            : <FormattedMessage id='consultations.complete' />
          }
        </TextButton>
      )}
      {
        consultationStatus === 'completed' && medicalReportFilled && (
          <>
            <CompletedLabel>
              <CheckIconRound />
              <span>
                {consultationType === 'telexpertise' 
                  ? <FormattedMessage id='consultations.telexpertise.bar' />
                  : <FormattedMessage id='consultations.completed.bar' />
                }
              </span>
            </CompletedLabel>
            {!!invoice && (!isPatient || isPaymentCompleted) && (
              <ApproveButton
                variant='extended'
                color='secondary'
                onClick={() => window.open(invoice)}
              >
                <FormattedMessage id='payments.download' />
              </ApproveButton>
            )}
          </>
        )
      }
    </Wrapper>
  )
}

ButtonsBarComponent.propTypes = {
  medicalReportFilled: PropTypes.bool.isRequired,
  consultationStatus: PropTypes.string.isRequired,
  onReportButtonClick: PropTypes.func.isRequired,
  onPrescriptionButtonClick: PropTypes.func.isRequired,
  onCompleteButtonClick: PropTypes.func.isRequired,
  // refuse: PropTypes.func.isRequired,
}

const enhance = compose(
  connect(
    state => ({
      consultationStatus: idx(state, _ => _.consultations.consultation.attributes.status),
    }),
  ),
  withProps(props => ({
    onReportButtonClick: () => history.push('/panel/consultations/session/report'),
    onPrescriptionButtonClick: () => history.push('/panel/consultations/session/prescription'),
    onCompleteButtonClick: () => history.push(`/panel/consultations/session/${props.medicalReportFilled ? 'prescription' : 'report'}`),
  })),
)

export const ButtonsBar = enhance(ButtonsBarComponent)
