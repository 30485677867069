import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { compose, withStateHandlers, lifecycle } from 'recompose'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import CancelIcon from '@material-ui/icons/Cancel'
import ErrorIcon from '@material-ui/icons/Error'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import InfoIcon from '@material-ui/icons/Info'

import { setNotification } from '../../../redux/modules/notifications'

const StyledSnackbar = styled(Snackbar)`
  && {
  margin-bottom: 50vh;
    
    & > div {
      background-color: ${({ type }) => {
    switch (type) {
      case 'error':
        //return '#EA3A42'
        return '#b9c1d6'
      case 'warning':
        return '#f7bd40'
      case 'success':
        return '#16c6a0'
      case 'info':
        return '#5ab4c1'
      default:
        return '#EA3A42'
    }
  }
}
  }
`

const Text = styled.div`
  display: flex;
  align-items: flex-start;
  
  & > svg {
    margin-right: 12px;
  }
  
  & > span {
    margin-top: 3px;
    max-width: 240px;

  }
`

const renderIcon = (type) => {
  switch (type) {
    case 'error':
      return <CancelIcon />
    case 'warning':
      return <ErrorIcon />
    case 'success':
      return <CheckCircleIcon />
    case 'info':
      return <InfoIcon />
    default:
      return <CancelIcon />
  }
}

const defaultMessageError = `
  Votre session est expirée.  Veuillez renouveler la page et réessayez.  Si l'erreur persiste, merci de contacter support@occimed.fr
`;

const NotificationComponent = ({
  open,
  handleClose,
  title,
  type,
}) => (
  <StyledSnackbar
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    open={open}
    type={type}
    autoHideDuration={6000}
    onClose={handleClose}
    ContentProps={{
      'aria-describedby': 'message-id',
    }}
    message={(
      <Text>
        {renderIcon(type)}
        <span id='message-id'>
          {type === 'error' ? <span>{defaultMessageError}</span> : ''}
          {title && type !== 'error' && <FormattedMessage id={title} defaultMessage={title} />}
        </span>
      </Text>
    )}

    action={[
      <IconButton
        key='close'
        aria-label='Close'
        color='inherit'
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>,
    ]}
  />
)

NotificationComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
}

const enhance = compose(
  connect(state => ({
    title: state.notifications.title,
    type: state.notifications.type,
  }), { setNotification }),
  withStateHandlers({
    open: false,
  }, {
    handleClose: () => () => ({ open: false }),
    handleOpen: () => () => ({ open: true }),
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (this.props.title && this.props.title !== prevProps.title && !this.props.open) {
        this.props.handleOpen()
      }

      if (!this.props.open && prevProps.open) {
        setTimeout(() => this.props.setNotification(''), 500)
      }
    },
  }),
)

export const Notification = enhance(NotificationComponent)
