import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import idx from 'idx'
import styled from 'styled-components'
import queryString from 'query-string'

import get from 'lodash/get';
import { Page } from '../../../common/Page'
import { history } from '../../../../utils/history'
import { fetchDoctor } from '../../../../redux/modules/doctors'
import { DoctorInfo } from '../../Patient/Doctors/UrgentConsultation/DoctorInfo'
import { getDoctorPrice } from '../../../../helpers/consultations'
import { Preloader } from '../../../common/Preloader'
import { CreateConciliumForm } from './CreateForm'

export const CreateConciliumPage = ({ match, location }) => {
  const dispatch = useDispatch()
  const { doctorId } = match.params
  const doctor = useSelector(state => state.doctors.doctor)
  const workPlace = useSelector(state => idx(
    state,
    _ => _.doctors.doctor.relationships.workPlace.data.slice(-1)[0].attributes,
  ))
  const isDoctor = useSelector(state => state.auth.type === 'Doctor')
  const keyNearest = isDoctor ? 'TE' : 'TC';

  const isUrgent = !!queryString.parse(location.search).urgent

  useEffect(() => {
    dispatch(fetchDoctor(doctorId))
  }, [doctorId])

  return (
    <Page label='concilium.create' onBackIconClick={history.goBack}>
      {
        doctor.id
          ? (
            <>
              <Content>
                <DoctorInfo
                  firstName={doctor.attributes.first_name}
                  lastName={doctor.attributes.last_name}
                  experience={doctor.attributes.experience}
                  specialization={doctor.attributes.specialization}
                  photo={doctor.attributes.photo}
                  language={doctor.attributes.language}
                  location={workPlace}
                  available={get(doctor, `attributes.calendar_nearest.${keyNearest}`, null)}

                  /* TODO: add attributes to doctor in API */
                  price={getDoctorPrice(doctor, 'urgent')}
                />
              </Content>
              <CreateConciliumForm
                available={get(doctor, `attributes.calendar_nearest.${keyNearest}`, null)}
                doctorId={doctorId} 
                isUrgent={isUrgent}
              />
            </>
          )
          : (
            <StyledPreloader />
          )
      }
    </Page>
  )
}

CreateConciliumPage.propTypes = {
  match: PropTypes.object.isRequired,
}

const StyledPreloader = styled(Preloader)`
  height: calc(100vh - 106px);
  width: 100%;
`

const Content = styled.div`
  & div > div:nth-child(2) > div > div:nth-child(2) {
    display: none;
  }
`
