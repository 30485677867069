import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { List } from './List'
import { Session } from './Session'
import { CurrentConsultation } from './Current'

export const Consultations = ({ match }) => (
  <Switch>
    <Route
      path={match.path}
      exact
      component={List}
    />
    <Route
      path={`${match.path}/session`}
      component={Session}
    />
    <Route
      path={`${match.path}/:id`}
      component={CurrentConsultation}
    />
  </Switch>
)

Consultations.propTypes = {
  match: PropTypes.object.isRequired,
}
