import React, { useEffect } from 'react';
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { withProps } from 'recompose'
import { Form, Field } from 'react-final-form'
import { SwitchField } from 'common/UiKit/SwitchField'
import { Colors } from 'constants/colors'
import { Paper } from 'common/UiKit/Paper'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { RadioInput } from '../FinalFormFields/RadioInput';
import { fetchSettings, updateSettings } from '../../../redux/modules/settings';
import { history } from '../../../utils/history';

const PaperStyled = styled(Paper)`
  margin-bottom: 16px;
  padding: 12px 24px;
`

const Line = styled.div`
  margin-bottom: 24px;
  font-size: 16px;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: space-between;
`

const CheckboxContainer = styled.div`
  font-size: 14px;
  color: ${Colors.blueGrey};
`

const Label = styled.span`
  opacity: 0.8;
  font-weight: 500;
  font-size: 16px;
  color: ${Colors.dark};
  line-height: 2;
`

const RadioWrapper = styled.div`
  margin-top: 8px;
`

// const NotificationSound = styled(Paper)`
//   height: 48px;
//   padding: 0 24px;
//   margin-bottom: 16px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// `

const REMINDER_TIME = [
  { label: <FormattedMessage id='global.15.min' />, value: '15' },
  { label: <FormattedMessage id='global.30.min' />, value: '30' },
  { label: <FormattedMessage id='global.45.min' />, value: '45' },
  { label: <FormattedMessage id='global.1.h' />, value: '60' },
]

const PILL = [
  { label: <FormattedMessage id='global.none' />, value: 'none' },
  { label: '9:00', value: '9' },
  { label: '14:00', value: '14' },
  { label: '9:00, 18:00', value: '918' },
  { label: '9:00, 14:00, 18:00', value: '91418' },
]


const NotificationsComponent = ({
  pills,
}) => {
  const dispatch = useDispatch()

  const onSubmit = values => dispatch(updateSettings(values))
    .then(() => dispatch(fetchSettings()))
    .then(() => history.goBack())
  const initialValues = useSelector(state => state.settings.values)

  useEffect(() => {
    dispatch(fetchSettings())
  }, [dispatch])

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ 
        ...initialValues,
        notifications_enabled: get(initialValues, 'notifications_enabled', 0) === '1',
        web_incoming_calls_sound_enabled: get(initialValues, 'web_incoming_calls_sound_enabled', 0) === '1',
      }}
      subscription={{}}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} id='notifications'>
          {/*<NotificationSound>*/}
          {/*  <Label>*/}
          {/*    <FormattedMessage id='profile.receive.notifications' />*/}
          {/*  </Label>*/}
          {/*  <Field*/}
          {/*    name='consultations'*/}
          {/*    component={SwitchField}*/}
          {/*    color='primary'*/}
          {/*    type='checkbox'*/}
          {/*  />*/}
          {/*</NotificationSound>*/}
          {/*<NotificationSound>*/}
          {/*  <Label>*/}
          {/*    <FormattedMessage id='profile.notification.sound' />*/}
          {/*  </Label>*/}
          {/*  <Field*/}
          {/*    name='sound'*/}
          {/*    component={SwitchField}*/}
          {/*    color='primary'*/}
          {/*    type='checkbox'*/}
          {/*  />*/}
          {/*</NotificationSound>*/}
          <PaperStyled heading='profile.reminders'>
            <Line>
              <CheckboxContainer>
                <Label>
                  <FormattedMessage id='consultation.title' />
                </Label>
                <br />
                <FormattedMessage id='profile.reminders.text' />
              </CheckboxContainer>
              <Field
                name='notifications_enabled'
                component={SwitchField}
                color='primary'
                type='checkbox'
              />
            </Line>
            <Line>
              <CheckboxContainer>
                <Label>
                  <FormattedMessage id='auth.web_incoming_calls_sound_enabled' />
                </Label>
                <br />
                <FormattedMessage id='auth.web_incoming_calls_sound_enabled_desc' />
              </CheckboxContainer>
              <Field
                name='web_incoming_calls_sound_enabled'
                component={SwitchField}
                color='primary'
                type='checkbox'
              />
            </Line>
            <Line>
              <div>
                <Label>
                  <FormattedMessage id='profile.reminder.time' />
                </Label>
                <RadioWrapper>
                  <Field
                    id='notifications_reminder_time'
                    name='notifications_reminder_time'
                    component={RadioInput}
                    options={REMINDER_TIME}
                  />
                </RadioWrapper>
              </div>
            </Line>
            {
              (pills && false) && (
                <Line>
                  <div>
                    <Label>
                      <FormattedMessage id='profile.take.pill' />
                    </Label>
                    <RadioWrapper>
                      <Field
                        id='pill'
                        name='pill'
                        component={RadioInput}
                        options={PILL}
                      />
                    </RadioWrapper>
                  </div>
                </Line>
              )
            }
          </PaperStyled>
        </form>
      )}
    />
  )
}

NotificationsComponent.defaultProps = {
  pills: false,
}

NotificationsComponent.propTypes = {
  pills: PropTypes.bool,
}

const enhance = withProps(props => ({
  onSubmit: v => props.onSubmit(v).then(() => props.afterSubmit()),
}))

export const Notifications = enhance(NotificationsComponent)
