import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import { useDispatch, useSelector } from 'react-redux'
import { TextButton } from 'common/UiKit/TextButton'
import { Input } from 'common/UiKit/Input'
import styled from 'styled-components'
import { socket } from '../../../services/socket'
import { toggleCallAnswerModal } from '../../../redux/modules/content'

export const AnswerModal = () => {
  const [value, onChange] = useState('waiting.answer.soon.2')
  const [customAnswer, onCustomAnswerChange] = useState('')
  const dispatch = useDispatch()

  const consultationId = useSelector(state => state.content.isCallAnswerOpened)

  const handleChange = (e) => {
    const { value } = e.target

    if (value !== 'waiting.answer.other') {
      onCustomAnswerChange('')
    }

    onChange(value)
  }

  const handleAnswer = () => {
    const answer = value === 'waiting.answer.other' ? customAnswer : value
    socket.cancelCall(consultationId, answer)
    dispatch(toggleCallAnswerModal(false))
  }

  return (
    <Dialog open={consultationId} maxWidth='xs'>
      <DialogTitle>
        <FormattedMessage id='waiting.answer.title' />
      </DialogTitle>
      <DialogContent>
        <FormControl component='fieldset'>
          <RadioGroup name='answer' value={value} onChange={handleChange}>
            <RadioItem
              value='waiting.answer.soon.2'
              control={<Radio color='primary' />}
              label={<Label><FormattedMessage id='waiting.answer.soon' values={{ count: 2 }} /></Label>}
            />
            <RadioItem
              value='waiting.answer.soon.5'
              control={<Radio color='primary' />}
              label={<Label><FormattedMessage id='waiting.answer.soon' values={{ count: 5 }} /></Label>}
            />
            <RadioItem
              value='waiting.answer.soon.10'
              control={<Radio color='primary' />}
              label={<Label><FormattedMessage id='waiting.answer.soon' values={{ count: 10 }} /></Label>}
            />
            <RadioItem
              value='waiting.answer.chat'
              control={<Radio color='primary' />}
              label={<Label><FormattedMessage id='waiting.answer.chat' /></Label>}
            />
            <RadioItem
              value='waiting.answer.other'
              control={<Radio color='primary' />}
              label={<Label><FormattedMessage id='waiting.answer.other' /></Label>}
            />
          </RadioGroup>
          {
            value === 'waiting.answer.other' && (
              <Input
                value={customAnswer}
                onChange={e => onCustomAnswerChange(e.target.value)}
                label={<FormattedMessage id='waiting.answer.other.placeholder' />}
              />
            )
          }
          <Button
            color='primary'
            disabled={value === 'waiting.answer.other' && !customAnswer}
            onClick={handleAnswer}
          >
            <FormattedMessage id='waiting.answer.send' />
          </Button>
        </FormControl>
      </DialogContent>
    </Dialog>
  )
}

const Button = styled(TextButton)`
  && {
    font-size: 14px;
    box-shadow: none;
    min-width: 140px;
    width: auto;
    height: 40px;
    margin: 0 auto;
  }
`

const Label = styled.span`
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
`

const RadioItem = styled(FormControlLabel)`
  && {
    margin-bottom: 20px;
    align-items: flex-start;
    
    & > span:first-child {
      padding-top: 0;
    }
  }
`
