import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Colors } from 'constants/colors'

export const TextMessage = ({
  message,
  my,
  system,
}) => {
  let messageBG = `${Colors.paleGrey}`;
  if (my) {
    messageBG = `${Colors.azure}`;
  }

  console.log(messageBG);

  if (system) {
    messageBG = '#ffe1e1';
  }


  return (
    <Wrapper bg={messageBG}>
      {message}
    </Wrapper>
  )
}

TextMessage.defaultProps = {
  message: '',
  system: false,
}

TextMessage.propTypes = {
  my: PropTypes.bool.isRequired,
  message: PropTypes.string,
  system: PropTypes.bool,
}

const Wrapper = styled.div`
  height: auto;
  padding: 11px 18px;
  word-wrap: break-word;
  font-size: 16px;
  line-height: 1.5;
  color: ${Colors.dark};
  background-color: ${props => props.bg};
  border-radius: 0 4px 4px 4px;
`
