import React from 'react'
import PropTypes from 'prop-types'
import { compose, lifecycle, withStateHandlers } from 'recompose'
import styled from 'styled-components'
import { Document, Page, pdfjs } from 'react-pdf'
import Delete from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import DescriptionIcon from '@material-ui/icons/Description'
// import EditIcon from '@material-ui/icons/Edit'

import { FormattedMessage } from 'react-intl'
import { Colors } from '../../../constants/colors'
import { readFileType } from '../../../utils/readFileType'

// Enable PDF.js worker for perfomance reason. Watch https://github.com/wojtekmaj/react-pdf#enable-pdfjs-worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const ImageWrapper = styled.a`
  height: 158px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;

  > svg {
    color: ${Colors.blueGrey};
    width: 70%;
    height: 70%;
  }
`

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`

const DeleteField = styled.div`
  height: calc(100% - 158px);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${Colors.grey};
`

const ButtonStyled = styled(IconButton)`
  && {
    width: 32px;
    height: 32px;
    padding: 0;
  }
`

const Pdf = styled(Document)`
  && :first-child {
    max-width: 146px;
    max-height: 146px;
  }
`

const Wrapper = styled.div`
  height: 100%;
`

const EditField = styled(DeleteField)`
  justify-content: space-between;
`

const Name = styled.div`
  height: 100%;
  flex-grow: 1;
  color: ${Colors.blueGrey};
  font-size: 0.875rem;
  padding-left: 6px;
  cursor: default;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 34px;
`

const EditButton = styled(ButtonStyled)`
  && {
    border-radius: 0;
    border-left: 1px solid ${Colors.grey};
    height: 100%;
    
    svg {
      width: 20px;
      height: 20px;
    }
  }
`

const Unnamed = styled.span`
  font-size: 0.75rem;
  color: ${Colors.red};
  opacity: .6;
  font-weight: 500;
`

const PhotoComponent = ({
  file,
  fileType,
  onDelete,
  resetPdfSize,
  setWrapperRef,
  disableEdit,
  extension,
  withFilenames,
  name,
  // onEditClick,
}) => (
  <Wrapper ref={setWrapperRef}>
    <ImageWrapper>
      {['jpg', 'png'].includes(extension) && (
        <Image
          alt='img'
          src={file}
        />
      )}
      {['xlsx', 'xls', 'docx', 'doc'].includes(extension) && (
        <DescriptionIcon />
      )}
      {fileType === 'application/pdf' && (
        <Pdf file={file}>
          <Page
            renderTextLayer={false}
            pageNumber={1}
            onLoadSuccess={resetPdfSize}
          />
        </Pdf>
      )}
    </ImageWrapper>
    {
      !disableEdit
        && (
          <>
            {
              withFilenames
                ? (
                  <EditField>
                    <Name title={name}>
                      {name || <Unnamed><FormattedMessage id='hr.unnamed.file' /></Unnamed>}
                    </Name>
                    {/*<EditButton color='primary' onClick={() => */}
                    {/* onEditClick({ image: file, extension, custom_name: name })}>*/}
                    {/*  <EditIcon />*/}
                    {/*</EditButton>*/}
                    <EditButton color='primary' onClick={onDelete}>
                      <Delete />
                    </EditButton>
                  </EditField>
                )
                : (
                  <DeleteField>
                    <ButtonStyled color='primary' onClick={onDelete}>
                      <Delete />
                    </ButtonStyled>
                  </DeleteField>
                )
            }
          </>
        )
    }
  </Wrapper>
)

// Needed to override width and heigth passed to Pdf through ReactElement.style
/* eslint-disable no-param-reassign */
const resetNodeSize = (node) => {
  const pages = node.querySelectorAll('canvas')
  pages.forEach((page) => {
    page.style.height = 'auto'
    page.style.width = 'auto'
  })
}
/* eslint-disable no-param-reassign */

const enhance = compose(
  withStateHandlers({
    wrapperRef: null,
  }, {
    setWrapperRef: () => wrapperRef => ({ wrapperRef }),
    resetPdfSize: ({ wrapperRef }) => () => resetNodeSize(wrapperRef),
  }),
  lifecycle({
    componentDidMount() {
      readFileType(this.props.file).then(fileType => this.setState({ fileType }))
    },
  }),
)

PhotoComponent.defaultProps = {
  fileType: 'jpg',
  extension: '',
  name: '',
}

PhotoComponent.propTypes = {
  file: PropTypes.string.isRequired,
  fileType: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  resetPdfSize: PropTypes.func.isRequired,
  setWrapperRef: PropTypes.func.isRequired,
  disableEdit: PropTypes.bool.isRequired,
  extension: PropTypes.string,
  withFilenames: PropTypes.bool.isRequired,
  name: PropTypes.string,
}

export const Photo = enhance(PhotoComponent)
