import React from 'react'
import { Link, Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'

import { Colors } from 'constants/colors'

const ButtonStyled = styled(props => (
  <Button {...props} classes={{ label: 'label' }} />
))`
  && {
    height: 56px;
    margin-bottom: 10px;
    font-size: 15px;
    font-family: Roboto, sans-serif;
    text-transform: none;
    font-weight: ${props => (props.selected ? 500 : 'normal')}
    background-color: ${props => (props.selected ? Colors.white : Colors.white15)};
    color: ${props => (props.selected ? Colors.dark : Colors.white)};
    padding-left: 16px;
    padding-right: 16px;
    line-height: 22.5px;

    ${props => props.selected
        && `
          border-left: 6px solid ${Colors.clearBlue};
          padding-left: 10px;
        `}
  }

  ${props => props.selected && `&&:hover { background-color: ${Colors.white}; }`}

  & .label {
    justify-content: start;
    text-align: left;
  }
`

const IconStyled = styled.div`
  margin-right: 12px;
  display: flex;
  align-items: center;
  ${props => props.selected && `color: ${Colors.clearBlue};`}
`

export const MenuButton = ({
  icon,
  label,
  path,
  ...rest
}) => (
  <Route
    path={path}
    children={({ match }) => (
      <Link to={path}>
        <ButtonStyled
          selected={match}
          {...rest}
        >
          {icon && <IconStyled selected={match}>{icon}</IconStyled>}
          {label}
        </ButtonStyled>
      </Link>
    )}
  />
)

MenuButton.defaultProps = {
  icon: null,
}

MenuButton.propTypes = {
  icon: PropTypes.element,
  label: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
}
