import React from 'react'
import PropTypes from 'prop-types'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import { FormattedMessage } from 'react-intl'

const Label = styled(({ ...props }) => (
  <StepLabel {...props} classes={{ label: 'label', iconContainer: 'icon', disabled: 'disabled' }} />
))`
  && .label {
    font-size: 14px;
    font-weight: 500;
    margin-top: 11px;
  }
  
  && {
    flex-direction: column;
    width: 100px;
    text-align: center;
  }
  
  && .icon {
    padding-right: 0;
  
    svg {
      width: 40px;
      height: 40px;
    }
  }
  
  &&.disabled {
    .icon svg {
      color: rgba(183, 190, 210, .3);
      
      text {
        fill: ${Colors.disabledText};
      }
    }
    
    .label {
      color: ${Colors.disabledText};
    }
  }
`

const StyledStepper = styled(Stepper)`
  && {
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(6, 173, 255, 0.1);
    padding: 24px 16px;
    margin-bottom: 24px;
    align-items: flex-start;
    
    & > div:nth-child(even) {
      margin-top: 20px;
    }
    
    & > div {
      padding-left: 0;
      padding-right: 0;
    }
  }
`

const STEPS = ['profile.personal', 'profile.about', 'profile.education', 'profile.animation', 'profile.identity', 'profile.signature']


export const FillFormStepper = ({ step }) => (
  <StyledStepper activeStep={step}>
    {
      STEPS.map(item => (
        <Step key={item}>
          <Label>
            <FormattedMessage id={item} />
          </Label>
        </Step>
      ))
    }
  </StyledStepper>
)

FillFormStepper.propTypes = {
  step: PropTypes.number.isRequired,
}
