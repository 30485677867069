import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import InsertInvitation from '@material-ui/icons/InsertInvitation'
import Place from '@material-ui/icons/Place'
import { Paper } from 'common/UiKit/Paper'
import { Colors } from 'constants/colors'
import { FormattedMessage, useIntl } from 'react-intl'
import moment from 'moment'
import defaultAvatar from '../../../../../images/panel/default-avatar.svg'

const PaperStyled = styled(Paper)`
  padding: 20px 0 20px 24px;
  margin: 24px 0 16px;
`

const Wrapper = styled.div`
  display: flex;
  font-weight: normal;
  font-size: 16px
`

const Avatar = styled.div`
  min-width: 126px;
  width: 126px;
  height: 126px;
  border: 7px solid white;
  border-radius: 100%;
  background: url(${props => props.photo || defaultAvatar}) ${Colors.lightGrey} center no-repeat; 
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px 0 rgba(6, 173, 255, 0.1);
`

const Info = styled.div`
  flex-grow: 1;
  margin: 12px 0 0 32px;
`

const GeneralInfo = styled.div`
  display: flex;
  justify-content: space-between;
`

const Price = styled.div`
  height: 68px;
  box-sizing: border-box;
  padding: 4px 20px 0 20px;
  color: ${Colors.clearBlue};
  font-size: 32px;
  font-weight: bold;
  border: solid 1px ${Colors.cloudyBlue};
  border-radius: 20px 0 0 20px;

  & > :first-child {
    margin: 0;
    font-size: 16px;
    color: ${Colors.blueGrey};
    font-weight: normal;
  }
`

const Name = styled.h1`
  margin: 0;
  font-size: 24px;
  font-weight: bold;
`

const Specialization = styled.p`
  margin: 0;
  opacity: 0.8;
  padding-top: 10px;
`

const Over = styled.p`
  margin: 0;
  color: ${Colors.blueGrey};
  display: flex;
  align-items: center;
  margin-top: 12px;
  text-transform: capitalize;

  && svg {
    font-size: 18px;
    margin-right: 12px;
  }
`

export const DoctorInfo = ({
  firstName,
  lastName,
  specialization,
  photo,
  available,
  price,
  location,
}) => {
  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })

  return (
    <PaperStyled>
      <Wrapper>
        <Avatar
          photo={photo && photo.includes('/file/') ? `http://backend-application.owndoctor.eu${photo}` : photo}
        />
        <Info>
          <GeneralInfo>
            <div>
              <Name>
                {`${firstName || 'Name'} ${lastName || 'Surname'}`}
              </Name>
              <Specialization>
                {`${specialization || 'Specialization'}`}
              </Specialization>
            </div>
            <Price>
              <p>
                <FormattedMessage id='global.cost' />
              </p>
              {price || '0'}
              &nbsp;&euro;
            </Price>
          </GeneralInfo>
          <Over>
            <Place />
            {Object.keys(location).length ? `${location.name}, ${location.street}, ${location.city}` : f('global.location')}
          </Over>
          <Over>
            <InsertInvitation />
            {available ? moment(available).calendar(null, { sameElse: 'DD.MMMM.YYYY, HH:mm' }) : ''}
          </Over>
        </Info>
      </Wrapper>
    </PaperStyled>
  )
}

DoctorInfo.defaultProps = {
  firstName: '',
  lastName: '',
  specialization: '',
  photo: defaultAvatar,
  available: '',
  price: '0',
  location: {},
}

DoctorInfo.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  specialization: PropTypes.string,
  photo: PropTypes.string,
  available: PropTypes.string,
  price: PropTypes.number,
  location: PropTypes.object,
}
