import React, { useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { FormattedMessage, useIntl } from 'react-intl'
import { Colors } from 'constants/colors'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import CloseIcon from '@material-ui/icons/Close';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import get from 'lodash/get';
import moment from 'moment';
import { getVirtualPatients } from '../../../../helpers/consultations';

const Wrapper = styled(DialogContent)`
  && {
    overflow-y: visible;
    background-color: ${Colors.background};
    min-width: 500px;
    padding-bottom: 12px;
    padding-left: 0px;
    padding-right: 0px;
  }
`

const Title = styled(DialogTitle)`
  && {
    line-height: 1;
    padding-top: 40px;
    padding-bottom: 40px;
    border-bottom: solid 1px #d4dae6;
    margin-bottom: 12px;
  }

  & > h6 {
    line-height: 1;
  }
`

const Actions = styled(DialogActions)`
  && {
    background-color: ${Colors.background};
    margin: 0;
    padding: 16px 8px;
    justify-content: center;
    border-top: solid 1px #d4dae6;
    padding-top: 18px;
    padding-bottom: 24px;
  }
`

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  color: #a2a7bd;
`

const SearchWrapper = styled.div`
  padding-left: 12px;
  padding-right: 12px;
  margin-right: 15px;
  margin-bottom: 10.5px;
`

const SearchPatient = styled(TextField)`
  & input {
    padding-top: 10.5px;
    padding-bottom: 10.5px;
  }
`

const Patients = styled.div`
  max-height: 300px;
  overflow-y: scroll;
`

const Patient = styled.div`
  height: 48px;
  padding: 8px 4px 0 16px;
  border-radius: 1px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
`

const AddPatientButton = styled(Button)`
  && {
    border-radius: 20px;
  }
`

const StyledSearchIcon = styled(SearchIcon)`
  color: #a2a7bd;
`

export const PatientList = ({
  open, onClose, openCreatePatientModal, afterSubmit,
}) => {
  const [virtualPatients, setVirtualPatients] = useState([]);

  const fetchVirtualPatient = async (filter = {}) => {
    const patients = await getVirtualPatients({ ...filter });
    setVirtualPatients(patients);
  }

  useEffect(() => {
    fetchVirtualPatient();
  }, [])

  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })

  return (
    <Dialog open={open} onClose={onClose} maxWidth='md'>
      <StyledCloseIcon onClick={onClose} />
      <Title>
        <FormattedMessage id='concilium.create.patientlist' />
      </Title>
      <SearchWrapper>
        <SearchPatient
          onChange={el => fetchVirtualPatient(el.target.value ? { name: el.target.value } : {})}
          size='small'
          fullWidth
          variant='outlined'
          placeholder={f('global.search')}
          InputProps={{
            endAdornment: <InputAdornment position='end'><StyledSearchIcon /></InputAdornment>,
          }}
        />
      </SearchWrapper>
      <Wrapper>
        <Patients>
          {virtualPatients.map((el) => {
            const patient = get(el, 'attributes', {});
            return (
              <Patient key={patient.id + patient.last_name} onClick={() => afterSubmit(el)}>
                {patient.first_name} {patient.last_name} ({moment(patient.date_of_birth).format('DD/MM/YYYY')})
              </Patient>
            ) 
          })}
        </Patients>
      </Wrapper>
      <Actions>
        <AddPatientButton
          color='primary'
          onClick={() => openCreatePatientModal(true)}
          variant='contained'
        >
          <FormattedMessage id='concilium.create.addpatient' />
        </AddPatientButton>
      </Actions>
    </Dialog>
  )
}
