import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { AuthForm } from '../AuthForm'

export const LoginForm = ({
  onSubmit,
  loading,
  registration,
  togglePolicy,
  toggleForm,
  initialValues,
  role,
}) => (
  <AuthForm
    onSubmit={onSubmit}
    loading={loading}
    registration={registration}
    togglePolicy={togglePolicy}
    toggleForm={toggleForm}
    initialValues={initialValues}
    role={role}
  >
    <Link to={`/auth/password-recovery?user=${role}`}>
      <FormattedMessage id='auth.forgot.password' />
    </Link>
  </AuthForm>
)

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  registration: PropTypes.bool.isRequired,
  togglePolicy: PropTypes.func.isRequired,
  toggleForm: PropTypes.func.isRequired,
}
