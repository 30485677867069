import auth from './auth'
import profile from './profile'
import global from './global'
import healthRecords from './healthRecords'
import consultation from './consultation'
import gdpr from './gdpr'
import schedule from './schedule'
import errors from './errors'
import faq from './faq'
import gdprRules from './gdprRules'
import payments from './payments'
import signature from './signature'
import concilium from './concilium'
import waitingRoom from './waitingRoom'
import status from './status'
import roso from './roso'

export const fr = {
  ...auth,
  ...profile,
  ...global,
  ...healthRecords,
  ...consultation,
  ...gdpr,
  ...schedule,
  ...errors,
  ...faq,
  ...gdprRules,
  ...payments,
  ...signature,
  ...concilium,
  ...waitingRoom,
  ...status,
  ...roso,
}
