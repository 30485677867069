import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import idx from 'idx'
import { ConsultationsList } from './ConsultationsList'
import { fetchConsultations } from '../../../../../redux/modules/consultations'
import { history } from '../../../../../utils/history'

export const Upcoming = compose(
  connect(
    state => ({
      consultations: idx(state, _ => _.consultations.list),
      afterConsultationClick: () => history.push('/panel/consultations/session'),
    }),
    ({ fetchConsultations }),
  ),
  lifecycle({
    componentDidMount() {
      this.props.fetchConsultations({ filter: 'upcoming' })
    },
  }),
)(ConsultationsList)
