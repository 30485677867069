import React from 'react'
import { Form, Field } from 'react-final-form'
import styled from 'styled-components'
import { withStateHandlers } from 'recompose'
import { FormattedMessage, useIntl } from 'react-intl'
import { PROFILE_FILL_STEPS } from '../../../../../constants/ui'
import {
  EducationForm,
} from './EducationForm'

const FormWrapper = styled.div`
  margin-bottom: 16px;
`

const validate = () => (values) => {
  const errors = {}

  if (!values.education || !values.education.length 
    || values.education.some(i => !i.name || !i.receivedAt)) {
    const toErr = values.education.map((el, i) => {
      if (!el.name || !el.receivedAt) {
        return i;
      }
      return false
    }).filter(el => el !== false)
    toErr.forEach((index) => {
      if (!errors.education) {
        errors.education = {};
      }
      errors.education[index] = <FormattedMessage id='errors.required' />
    })
  }

  return errors
}

// This form splits into 3 forms because of edit-profile page
const EducationAndCoursesComponent = ({
  educationForms,
  onSubmit,
}) => {
  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate(educationForms)}
      initialValues={{ education: [{ name: '', receivedAt: '2000-01-01' }] }}
      keepDirtyOnReinitialize
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} id={PROFILE_FILL_STEPS[3]}>
          <FormWrapper>
            <Field
              name='education'
              heading={`*${f('profile.education')}`}
              component={EducationForm}
            />
          </FormWrapper>
        </form>
      )}
    />
  )
}


const enhance = withStateHandlers({
  educationForms: [0],
}, {
  onSubmit: (state, props) => values => props.onSubmit(values).then(() => props.afterSubmit()),
})

export const EducationAndCourses = enhance(EducationAndCoursesComponent)
