import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { Colors, calendarColors } from 'constants/colors'
import { useSelector } from 'react-redux'
import { Copy } from './Copy'

const LINKS = {
  google: 'https://calendar.google.com/',
  apple: 'https://www.icloud.com/',
  outlook: 'https://outlook.live.com/',
}

export const ExportModal = ({ data, onClose }) => {
  const doctorId = useSelector(state => state.auth.id)
  return (
    <Dialog open={!!data} onClose={onClose}>
      <DialogTitle>
        <Title><FormattedMessage id={`schedule.${data}.title`} /></Title>
      </DialogTitle>
      <DialogContent>
        <Text>
          1.{' '}
          <FormattedMessage id={`schedule.${data}.copy`} />
        </Text>
        <Copy
          link={`${process.env.REACT_APP_API_URL}/api/doctor/${doctorId}/calendar/synchronization/ical`}
        />
        <Text>
          2.{' '}
          <FormattedHTMLMessage id={`schedule.${data}.paste`} values={{ link: LINKS[data] }} />
        </Text>
        <Buttons>
          <CancelButton onClick={onClose}>
            <FormattedMessage id='global.cancel' />
          </CancelButton>
        </Buttons>
      </DialogContent>
    </Dialog>
  )
}

ExportModal.propTypes = {
  data: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

//#region Styled components
const Title = styled.div`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 32px;
`

const Buttons = styled.div`
  margin-top: 24px;
  text-align: center;
`

const CancelButton = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  padding: 16px;
  border-radius: 12px;
  background-color: ${Colors.clearBlue};
  color: ${Colors.white};
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: .4px;
  font-size: 12px;
  transition: all .1s ease-out;
  margin-right: 12px;
  
  &:hover {
    background-color: ${calendarColors.blueHover};
  }
`

const Text = styled.div`
  font-weight: 500;
  font-size: 14px;
  
  a {
    color: inherit;
    text-decoration: underline;
  }
`
//#endregion
