import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Clear from '@material-ui/icons/Clear'
import Publish from '@material-ui/icons/Publish'
import IconButton from '@material-ui/core/IconButton'
import { Colors } from 'constants/colors'
import { FormattedMessage } from 'react-intl'
import bg from '../../../../images/consultations/bg-drawer.svg'

const Wrapper = styled.div`
  height: 100%;
  overflow: auto;
`

const Content = styled.div`
  box-sizing: border-box;
  min-height: 100%;
  padding: 16px;
  background: ${Colors.paleGrey} url(${bg}) no-repeat;
  background-size: 100% 180px;
  font-family: Roboto, sans-serif;
`

const Panel = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`

const IconButtonStyled = styled(IconButton)`
  && {
    color: white;
    padding: 4px;
    transform: rotate(180deg);
  }
`

const Heading = styled.h1`
  flex-grow: 1;
  margin: 0 0 0 24px;
  font-size: 18px;
  font-weight: 500;
  color: ${Colors.white};
`

export const ConsultationProfilePanel = ({
  onCloseIconClick,
  onDownloadIconClick,
  heading,
  children,
}) => (
  <Wrapper>
    <Content>
      <Panel>
        <IconButtonStyled onClick={onCloseIconClick} color='inherit'>
          <Clear />
        </IconButtonStyled>
        {heading && <Heading><FormattedMessage id={heading} defaultMessage={heading} /></Heading>}
        {
          onDownloadIconClick && (
            <IconButtonStyled onClick={onDownloadIconClick} color='inherit'>
              <Publish />
            </IconButtonStyled>
          )
        }
      </Panel>
      {children}
    </Content>
  </Wrapper>
)

ConsultationProfilePanel.defaultProps = {
  onDownloadIconClick: false,
  heading: '',
}

ConsultationProfilePanel.propTypes = {
  onCloseIconClick: PropTypes.func.isRequired,
  onDownloadIconClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  heading: PropTypes.string,
}
