import { handleActions, createActions } from 'redux-actions'
import axios from 'axios'
import idx from 'idx'
import moment from 'moment'

//#region Actions
export const CALENDAR_LIST_ACTIONS = {
  fetchCalendarByDayRequest: 'FETCH_CALENDAR_BY_DAY_REQUEST',
  fetchCalendarByDaySuccess: 'FETCH_CALENDAR_BY_DAY_SUCCESS',
  fetchCalendarByDayFailure: 'FETCH_CALENDAR_BY_DAY_FAILURE',

  fetchCalendarByWeekRequest: 'FETCH_CALENDAR_BY_WEEK_REQUEST',
  fetchCalendarByWeekSuccess: 'FETCH_CALENDAR_BY_WEEK_SUCCESS',
  fetchCalendarByWeekFailure: 'FETCH_CALENDAR_BY_WEEK_FAILURE',

  fetchCalendarByMonthRequest: 'FETCH_CALENDAR_BY_MONTH_REQUEST',
  fetchCalendarByMonthSuccess: 'FETCH_CALENDAR_BY_MONTH_SUCCESS',
  fetchCalendarByMonthFailure: 'FETCH_CALENDAR_BY_MONTH_FAILURE',

  editEventRequest: 'EDIT_EVENT_REQUEST',
  editEventSuccess: 'EDIT_EVENT_SUCCESS',
  editEventFailure: 'EDIT_EVENT_FAILURE',
}

export const {
  fetchCalendarByDayRequest,
  fetchCalendarByDaySuccess,
  fetchCalendarByDayFailure,

  fetchCalendarByWeekRequest,
  fetchCalendarByWeekSuccess,
  fetchCalendarByWeekFailure,

  fetchCalendarByMonthRequest,
  fetchCalendarByMonthSuccess,
  fetchCalendarByMonthFailure,

  editEventRequest,
  editEventSuccess,
  editEventFailure,
} = createActions(...(Object.keys(CALENDAR_LIST_ACTIONS).map(item => CALENDAR_LIST_ACTIONS[item])))
//#endregion

//#region Reducer
const initialValues = {
  day: {},
  week: {},
  month: {},
}

export const calendarListReducer = handleActions({
  [fetchCalendarByDaySuccess]: (state, action) => ({ ...state, day: action.payload }),
  [fetchCalendarByWeekSuccess]: (state, action) => ({ ...state, week: action.payload }),
  [fetchCalendarByMonthSuccess]: (state, action) => ({ ...state, month: action.payload }),
  [editEventRequest]: (state, { payload }) => (payload.time ? ({
    ...state,
    [payload.calendarType]: {
      ...state[payload.calendarType],
      [payload.day]: {
        ...state[payload.calendarType][payload.day],
        [payload.time]: {
          ...state[payload.calendarType][payload.day][payload.time],
          type: payload.value,
        },
      },
    },
  }) : state),
}, initialValues)
//#endregion

//#region Thunks
const fetchCalendarRequest = (doctorId, data) => axios.post(`/api/doctor/calendar/${doctorId}`, data)

export const fetchCalendarByDay = (params, doctorId) => (dispatch, getStore) => {
  const id = doctorId || getStore().auth.id
  dispatch(fetchCalendarByDayRequest(params))
  return fetchCalendarRequest(id, params)
    .then(response => dispatch(fetchCalendarByDaySuccess(idx(
      response, _ => _.data.data.attributes.data,
    ))))
    .catch(err => dispatch(fetchCalendarByDayFailure(err)))
}

export const fetchCalendarByWeek = (params, doctorId) => (dispatch, getStore) => {
  const id = doctorId || getStore().auth.id
  dispatch(fetchCalendarByWeekRequest(params))
  return fetchCalendarRequest(id, params)
    .then(response => dispatch(fetchCalendarByWeekSuccess(idx(
      response, _ => _.data.data.attributes.data,
    ))))
    .catch(err => dispatch(fetchCalendarByWeekFailure(err)))
}

export const fetchCalendarByMonth = (params, doctorId) => (dispatch, getStore) => {
  const id = doctorId || getStore().auth.id
  dispatch(fetchCalendarByMonthRequest(params))
  return fetchCalendarRequest(id, params)
    .then(response => dispatch(fetchCalendarByMonthSuccess(idx(
      response, _ => _.data.data.attributes.data,
    ))))
    .catch(err => dispatch(fetchCalendarByMonthFailure(err)))
}

export const fetchCalendar = (filters, doctorId) => (dispatch) => {
  const {
    calendarType,
    date,
  } = filters

  const params = { dateFrom: date.from, dateTo: moment(date.to).add(1, 'days').format('YYYY-MM-DD') }

  switch (calendarType) {
    case 'day':
      return dispatch(fetchCalendarByDay(params, doctorId))
    case 'month':
      return dispatch(fetchCalendarByMonth(params, doctorId))
    case 'week':
    default:
      return dispatch(fetchCalendarByWeek(params, doctorId))
  }
}

export const editEvent = (day, time, value, oldValue, av = {}) => (dispatch, getStore) => {
  const { calendarType } = getStore().calendar.filters.values
  const filters = getStore().calendar.filters.values

  dispatch(editEventRequest({
    day,
    time,
    value,
    calendarType, 
    te_available: av.te_available || 0, 
    tc_available: av.tc_available || 0,
  }))
  const data = new FormData()
  data.append('te_available', av.te_available || 0);
  data.append('tc_available', av.tc_available || 0);

  if (Array.isArray(day)) {
    day.forEach(i => data.append('date[]', i))
  } else {
    data.append('date[0]', `${day} ${time}`)
  }

  if (Array.isArray(value)) {
    value.forEach(i => data.append('type[]', i))
  } else if (value !== 'Empty') {
    data.append('type', value)
  } else if (oldValue) {
    data.append('type', oldValue)
  }

  return axios(`/api/doctor/calendar/event${value === 'Empty' ? '.delete' : ''}`, {
    method: 'POST',
    data,
  })
    .then((response) => {
      dispatch(editEventSuccess(response.data))
      if (value === 'Free' && oldValue === 'Holiday') {
        data.append('type', value)
        axios('/api/doctor/calendar/event', {
          method: 'POST',
          data,
        })
      }
      if (!time) {
        dispatch(fetchCalendar(filters))
      }
    })
    .catch(err => dispatch(editEventFailure(err)))
}
//#endregion
