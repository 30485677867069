import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose, mapProps, withStateHandlers } from 'recompose'
import Delete from '@material-ui/icons/Delete'
import Button from '@material-ui/core/Button'
import { FormattedMessage, useIntl } from 'react-intl'
import { TextButton } from 'common/UiKit/TextButton'
import { DialogWindow } from 'common/UiKit/DialogWindow'
import { PanelBar } from '../PanelBar'
import { Colors } from '../../../constants/colors'

const Wrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  min-height: 100%;
  padding-bottom: 60px;
  background-color: ${props => (props.background ? Colors.background : Colors.white)};
`

const Content = styled.div`
  width: 720px;
  margin: 0 auto;
`

const Continue = styled.div`
  position: fixed;
  bottom: 0;
  background-color: ${Colors.white};
  right: 0;
  left: 280px;
  padding: 10px 24px;
  text-align: right;
  box-shadow: 0 -1px 8px 0 ${Colors.azure};
  
  button {
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    min-width: 148px;
  }
`

const CancelButton = styled(Button)`
  && {
    margin-right: 12px;
    border-radius: 30px;
  }
`

const EditPageComponent = ({
  label,
  onBackIconClick,
  onDeleteIconClick,
  buttonText,
  onButtonClick,
  loading,
  isDialogOpen,
  toggleDialog,
  dialogTitle,
  children,
  background,
  cancelButtonText,
  onCancelButtonClick,
  confirmButtonsProps = {},
  cancelButtonProps = {},
}) => {
  const { formatMessage } = useIntl()
  return (
    <Wrapper background={background}>
      <DialogWindow
        open={isDialogOpen}
        title={dialogTitle}
        buttonText={formatMessage({ id: 'global.delete' })}
        closeButtonText={formatMessage({ id: 'global.cancel' })}
        onButtonClick={onDeleteIconClick}
        onClose={toggleDialog}
      />
      <PanelBar
        label={label}
        onBackIconClick={onBackIconClick}
        onIconClick={onDeleteIconClick && toggleDialog}
        Icon={Delete}
      />
      <Content>
        {children}
        <Continue>
          {
            !!cancelButtonText && (
              <CancelButton onClick={onCancelButtonClick} color='secondary' variant='text' {...cancelButtonProps}>
                <FormattedMessage id={cancelButtonText} defaultMessage={cancelButtonText} />
              </CancelButton>
            )
          }
          <TextButton color='secondary' variant='extended' onClick={onButtonClick} isFetching={loading} {...confirmButtonsProps}>
            <FormattedMessage id={buttonText} defaultMessage={buttonText} />
          </TextButton>
        </Continue>
      </Content>
    </Wrapper>
  )
}

EditPageComponent.defaultProps = {
  onDeleteIconClick: false,
  loading: false,
  dialogTitle: '',
  background: false,
  cancelButtonText: undefined,
}

EditPageComponent.propTypes = {
  label: PropTypes.string.isRequired,
  onBackIconClick: PropTypes.func.isRequired,
  onDeleteIconClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onButtonClick: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  isDialogOpen: PropTypes.bool.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  dialogTitle: PropTypes.string,
  children: PropTypes.node.isRequired,
  background: PropTypes.bool,
  cancelButtonText: PropTypes.string,
}

const enhance = compose(
  withStateHandlers({
    isDialogOpen: false,
  }, {
    toggleDialog: ({ isDialogOpen }) => () => ({ isDialogOpen: !isDialogOpen }),
  }),
  mapProps(props => ({
    ...props,
    onDeleteIconClick: !props.onDeleteIconClick ? false : () => {
      props.toggleDialog()
      props.onDeleteIconClick(props.id)
    },
  })),
)

export const EditPage = enhance(EditPageComponent)
