import React, { useState } from 'react';
import PropTypes from 'prop-types'
import idx from 'idx'
import { compose, withProps } from 'recompose'
import styled from 'styled-components'
import { connect, useSelector } from 'react-redux'
import { UrgentConsultationForm } from 'common/UiKit/UrgentConsultationForm';
import { EditPage } from 'common/UiKit/EditPage'
import { FormattedMessage } from 'react-intl'
import get from 'lodash/get';
import { externalFormSubmit } from '../../../../../helpers/externalFormSubmit'
import { history } from '../../../../../utils/history'
import { DoctorInfo } from './DoctorInfo'
import { createUrgentConsultation } from '../../../../../redux/modules/consultations'
import { setNotification } from '../../../../../redux/modules/notifications'
import { doctorIsRecieved } from '../../../../../helpers/doctors'
import { getDoctorPrice } from '../../../../../helpers/consultations'
import { AccessToggle } from '../AccessToggle';

const Content = styled.div`
  div div div div div > div:nth-child(2) {
    display: block;
  }
`

const UrgentConsultationComponent = ({
  onButtonClick,
  onBackIconClick,
  onSubmit,
  afterSubmit,
  loading,
  doctor,
  workPlace,
  onAccessError,
  doctorId,
}) => {
  const [modal, toggleModal] = useState(false)
  const [access, toggleAccess] = useState(false)
  const isDoctor = useSelector(state => state.auth.type === 'Doctor')
  const keyNearest = isDoctor ? 'TE' : 'TC';

  return (
    <EditPage
      label='consultations.urgent'
      buttonText='consultations.pay'
      onBackIconClick={onBackIconClick}
      onButtonClick={() => toggleModal(true)}
      loading={loading}
      background
    >
      <Content>
        <DoctorInfo
          firstName={doctor.first_name}
          lastName={doctor.last_name}
          experience={doctor.experience}
          specialization={doctor.specialization}
          photo={doctor.photo}
          language={doctor.language}
          location={workPlace}
          available={get(doctor, `calendar_nearest.${keyNearest}`, null)}

          /* TODO: add attributes to doctor in API */
          price={getDoctorPrice(doctor, 'urgent')}
        />
      </Content>
      <UrgentConsultationForm
        afterSubmit={afterSubmit}
        onSubmit={values => onSubmit({
          ...values,
          doctorId,
          proposedDate: get(doctor, `calendar_nearest.${keyNearest}`, null),
          accessHealthRecord: access,
        })}
        id='urgent-consultation'
        onAccessError={() => onAccessError()}
      />
      <AccessToggle
        isOpened={modal}
        toggle={toggleModal}
        access={access}
        toggleAccess={toggleAccess}
        onClick={() => {
          toggleModal(false)
          onButtonClick()
        }}
      />
    </EditPage>)
}

UrgentConsultationComponent.defaultProps = {
  workPlace: {},
}

UrgentConsultationComponent.propTypes = {
  onButtonClick: PropTypes.func.isRequired,
  onBackIconClick: PropTypes.func.isRequired,
  afterSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  doctor: PropTypes.object.isRequired,
  workPlace: PropTypes.object,
  onAccessError: PropTypes.func.isRequired,
  doctorId: PropTypes.number.isRequired,
}

const enhance = compose(
  doctorIsRecieved,
  connect(state => ({
    loading: state.loading.createConsultation,
    doctor: idx(state, _ => _.doctors.doctor.attributes),
    doctorId: state.doctors.doctor.id,
    workPlace: idx(
      state,
      _ => _.doctors.doctor.relationships.workPlace.data.slice(-1)[0].attributes,
    ),
  }),
  dispatch => ({
    createUrgentConsultation: v => dispatch(createUrgentConsultation(v)),
    onAccessError: () => dispatch(setNotification(<FormattedMessage id='error.policy' />)),
  })),
  withProps(props => ({
    onButtonClick: () => externalFormSubmit('urgent-consultation'),
    onBackIconClick: () => history.goBack(),
    onSubmit: values => props.createUrgentConsultation(values),
    afterSubmit: () => history.push('/panel/consultations'),
  })),
)

export const UrgentConsultation = enhance(UrgentConsultationComponent)
