import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import idx from 'idx'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import { Colors } from 'constants/colors'
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import { ProfileCompabilityField } from './ProfileCompabilityField'
// import { MedicineCompabilityField } from './MedicineCompabilityField'
import {
  checkMedicineCompabilityWithProfile,
  fetchPatientMedicines,
  checkMedicineCompabilityWithAnother,
} from '../../../../../../redux/modules/medicines'

const List = styled.ol`
  counter-reset: myCounter;
  padding-left: 0;
  margin: 0;
  position:sticky;

  &>li {
    position: relative;
    margin-left:30px;
    list-style: none;
    margin-bottom: 20px;
    color: ${Colors.dark80};
    font-weight: 500;
  }

  span {
    word-break: break-word;
    display: inline-block;
    margin-left: 25px;
    width: auto;
  }

  button{
    width: 20px;
    position: absolute;
    padding: 10px;
  }

  p {
    margin: 0 0 0 25px;
    color: ${Colors.blueGrey};
    font-weight: normal;
  }

  &>li:before {
    counter-increment: myCounter;
    content: counter(myCounter) ".";
    margin-right: 13px;
  }
  svg {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 19px;
  }
`

const MedicinesComponent = ({
  drugs,
  consultation,
  checkWith,
  checkedWithProfile,
  // checkedWithMedicines,
  // fetchPatientMedicines,
  // patientDrugs,
  // checkMedicineCompabilityWithAnother,
}) => (
  <List>
    {drugs.map(({ attributes }) => (
        <>
          <IconButton onClick={() => { window.open(`${process.env.REACT_APP_API_URL}/general/consultation/${consultation.id}/pdf/prescription/${attributes.id}/${attributes.medical_report_id}`) }} color='primary' aria-label='delete' size='small'>
            <GetAppIcon fontSize='inherit' />
          </IconButton>
          <li key={attributes.id}>
            {attributes.name}
            {/*<p>*/}
            {/*  {idx(attributes, _ => _.type)}*/}
            {/*  ,&nbsp;*/}
            {/*  {idx(attributes, _ => _.quantity)*/}
            {/*    ? `${attributes.quantity} pills, `*/}
            {/*    : ''*/}
            {/*  }*/}
            {/*  {attributes.dosage}*/}
            {/*  &nbsp;*/}
            {/*  {idx(attributes, _ => _.unitType)}*/}
            {/*  ,&nbsp;*/}
            {/*  {attributes.iteration}*/}
            {/*  &nbsp;times per day,*/}
            {/*  <br />*/}
            {/*  {attributes.duration}*/}
            {/*  &nbsp;weeks&nbsp;*/}
            {/*</p>*/}
            {checkWith === 'profile' && (
              <ProfileCompabilityField
                id={attributes.api_id}
                checkedDrugs={checkedWithProfile}
              />
            )}
            {/*{checkWith === 'medicine' && (*/}
            {/*  <MedicineCompabilityField*/}
            {/*    id={attributes.api_id}*/}
            {/*    checkedDrugs={checkedWithMedicines}*/}
            {/*    fetchDrugs={fetchPatientMedicines}*/}
            {/*    options={patientDrugs}*/}
            {/*    checkMedicineCompabilityWithAnother={checkMedicineCompabilityWithAnother}*/}
            {/*  />*/}
            {/*)}*/}
          </li>
        </>
    ))}
  </List>
)

MedicinesComponent.propTypes = {
  drugs: PropTypes.arrayOf(PropTypes.object).isRequired,
  consultation: PropTypes.arrayOf(PropTypes.object).isRequired,
  checkWith: PropTypes.string.isRequired,
  checkedWithProfile: PropTypes.objectOf(PropTypes.array).isRequired,
  // checkedWithMedicines: PropTypes.objectOf(PropTypes.array).isRequired,
  // fetchPatientMedicines: PropTypes.func.isRequired,
  // patientDrugs: PropTypes.arrayOf(PropTypes.object).isRequired,
  // checkMedicineCompabilityWithAnother: PropTypes.func.isRequired,
}

const enhance = compose(
  connect(
    state => ({
      drugs: idx(state, _ => _.consultations.consultation.relationships.drugs.data),
      consultation: idx(state, _ => _.consultations.consultation),
      checkedWithProfile: idx(state, _ => _.medicines.checkedWithProfile),
      checkedWithMedicines: idx(state, _ => _.medicines.checkedWithMedicines),
      patientDrugs: idx(state, _ => _.medicines.patientList.map(d => ({
        value: d.attributes.api_id,
        label: d.attributes.name,
      }))),
    }),
    {
      checkMedicineCompabilityWithProfile,
      fetchPatientMedicines,
      checkMedicineCompabilityWithAnother,
    },
  ),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (
        (prevProps.checkWith === '' || prevProps.checkWith === 'medicine')
        && this.props.checkWith === 'profile'
      ) {
        this.props.checkMedicineCompabilityWithProfile()
      }
    },
  }),
)

export const Medicines = enhance(MedicinesComponent)
