import { createAction, handleActions } from 'redux-actions'
import axios from 'axios'

//#region Actions
export const createSignatureRequest = createAction('CREATE_SIGNATURE_REQUEST')
export const createSignatureSuccess = createAction('CREATE_SIGNATURE_SUCCESS')
export const createSignatureFailure = createAction('CREATE_SIGNATURE_FAILURE')

export const fetchSignatureRequest = createAction('FETCH_SIGNATURE_REQUEST')
export const fetchSignatureSuccess = createAction('FETCH_SIGNATURE_SUCCESS')
export const fetchSignatureFailure = createAction('FETCH_SIGNATURE_FAILURE')
//#endregion

//#region Reducer
export const signatureReducer = handleActions({
  [createSignatureSuccess]: (state, action) => action.payload,
  [fetchSignatureSuccess]: (state, action) => action.payload,
}, {})

//#endregion

//#region Thunks
export const createSignature = values => (dispatch) => {
  dispatch(createSignatureRequest(values))
  const formData = new FormData()

  values.file.forEach((item) => {
    formData.append('file', item)
  })

  return axios('doctor/signature/set', {
    method: 'POST',
    data: formData,
  })
    .then(res => dispatch(createSignatureSuccess(res.data.data.attributes)))
    .catch((err) => {
      dispatch(createSignatureFailure(err))
    })
}

export const fetchSignature = () => (dispatch) => {
  dispatch(fetchSignatureRequest())

  return axios('/doctor/signature/get', {
    method: 'POST',
  })
    .then(res => dispatch(fetchSignatureSuccess(res.data.data.attributes)))
    .catch((err) => {
      dispatch(fetchSignatureFailure(err))
    })
}
//#endregion
