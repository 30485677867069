import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import idx from 'idx'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import WarningIcon from '@material-ui/icons/Warning'
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import { FormattedMessage } from 'react-intl'
import { Colors } from 'constants/colors'
import { ConsultationReportPanel } from '../ConsultationReportPanel'
import { TextButton } from '../TextButton'


const Field = styled.div`
  margin: 40px 0; font-size: 14px;
  line-height: 1.43;
  color: ${Colors.blueGrey};

  & > :first-child {
    color: ${Colors.dark80};
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 8px;
  }
`

const List = styled.ol`
  counter-reset: myCounter;
  padding-left: 0;
  margin: 0;
  position:sticky;

  &>li {
    position: relative;
    margin-left:30px;
    list-style: none;
    margin-bottom: 20px;
    color: ${Colors.dark80};
    font-weight: 500;
  }

  span {
    word-break: break-word;
    display: inline-block;
    margin-left: 25px;
    width: auto;
  }
  button{
    width: 20px;
    position: absolute;
    padding: 10px;
  }
  p {
    margin: 0 0 0 25px;
    color: ${Colors.blueGrey};
    font-weight: normal;
  }

  &>li:before {
    counter-increment: myCounter;
    content: counter(myCounter) ".";
    margin-right: 13px;
    position: absolute;
    top: 0;
  }

  svg {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 19px;
  }
`

const ButtonsField = styled.div`
  margin-top: 24px;

  button {
    display: block;
    width: 281px;
    height: 40px;
    margin: 0 auto 16px;
    font-weight: 500;
  }
`

const MediumWarningIcon = styled(WarningIcon)`
  color: ${Colors.yellowOchre};
`

const getIcon = (id, checkedDrugs) => {
  if (!Object.keys(checkedDrugs).length || !checkedDrugs[id]) {
    return null
  }

  const hightIncompability = checkedDrugs[id].find(d => d.attributes.type === 'HIGH')
  const mediumIncompaility = checkedDrugs[id].find(d => d.attributes.type === 'MEDIUM')

  if (hightIncompability) {
    return <WarningIcon color='error' />
  }

  if (mediumIncompaility) {
    return <MediumWarningIcon />
  }

  return <CheckCircleOutlineIcon color='primary' />
}

export const MedicalReport = ({
  onCloseIconClick,
  onDownloadIconClick,
  // onCheckCompabilityButtonClick,
  consultation,
  isPatient,
  checkedMedicinesWithProfile,
  // loading,
  isPrescription,
  doctorId,
}) => {
  const report = idx(consultation, _ => _
    .relationships.medicalReport.data
    .find(item => item.attributes.doctor === +doctorId)) || { attributes: {} }
  return (
    <ConsultationReportPanel
      onCloseIconClick={onCloseIconClick}
      onDownloadIconClick={onDownloadIconClick}
      heading={isPrescription ? 'consultations.prescription' : 'consultations.report'}
      patientFirstName={idx(consultation, _ => _.relationships.patient.data.attributes.first_name)}
      patientLastName={idx(consultation, _ => _.relationships.patient.data.attributes.last_name)}
      doctorFirstName={idx(consultation, _ => _.relationships.doctor.data.attributes.first_name)}
      doctorLastName={idx(consultation, _ => _.relationships.doctor.data.attributes.last_name)}
      specialization={idx(consultation, _ => _.relationships.doctor.data.attributes.specialization)}
      date={idx(consultation, _ => _.attributes.creation_time)}
    >
      {!isPrescription && (
        <>
          <Field>
            <p>
              <FormattedMessage id='consultations.symptoms' />
            </p>
            {idx(consultation, _ => _.attributes.symptom_explanation)}
          </Field>
          <Field>
            <p>
              <FormattedMessage id='consultations.diagnosis' />
            </p>
            {report.attributes.diagnosis}
          </Field>
          <Field>
            <p>
              <FormattedMessage id='consultations.referral' />
            </p>
            {report.attributes.referral}
          </Field>
          <Field>
            <p>
              <FormattedMessage id='consultations.restrictions' />
            </p>
            {report.attributes.restrictions}
          </Field>
        </>
      )}
      <Field>
        <p>
          <FormattedMessage id='consultations.prescribed' />
        </p>
        <List>
          {(idx(consultation, _ => _.relationships.drugs.data) || []).map(({ attributes }) => (
            <>
              <IconButton onClick={() => { window.open(`${process.env.REACT_APP_API_URL}/general/consultation/${consultation.id}/pdf/prescription/${attributes.id}/${attributes.medical_report_id}`) }} color='primary' aria-label='delete' size='small'>
                <GetAppIcon fontSize='inherit' />
              </IconButton>

              <li key={attributes.id}>
                <span>{attributes.name}</span>
                {/*<p>*/}
                {/*  {attributes.iteration}*/}
                {/*  &nbsp;*/}
                {/*  <FormattedMessage id='consultations.times.per.day' />*/}
                {/*  ,*/}
                {/*  <br />*/}
                {/*  {attributes.duration}*/}
                {/*  &nbsp;*/}
                {/*  <FormattedMessage id='global.weeks' />*/}
                {/*  &nbsp;*/}
                {/*</p>*/}
                {isPatient && getIcon(attributes.api_id, checkedMedicinesWithProfile)}
              </li>
            </>
          ))}
        </List>
      </Field>
      {isPatient
        ? (
          <ButtonsField>
            {/*<TextButton*/}
            {/*  variant='extended'*/}
            {/*  onClick={onCheckCompabilityButtonClick}*/}
            {/*  color='primary'*/}
            {/*  isFetching={loading}*/}
            {/*>*/}
            {/*  <FormattedMessage id='global.check.compatibility' />*/}
            {/*</TextButton>*/}
            {onDownloadIconClick && (
              <TextButton
                variant='extended'
                onClick={onDownloadIconClick}
                color='primary'
              >
                <FormattedMessage id='global.download' />
              </TextButton>
            )}
          </ButtonsField>
        )
        : null}
    </ConsultationReportPanel>
  )
}

MedicalReport.defaultProps = {
  isPatient: false,
  //onCheckCompabilityButtonClick: () => null,
  onDownloadIconClick: false,
  checkedMedicinesWithProfile: {},
  //loading: false,
  isPrescription: false,
}

MedicalReport.propTypes = {
  onCloseIconClick: PropTypes.func.isRequired,
  //onCheckCompabilityButtonClick: PropTypes.func,
  onDownloadIconClick: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  consultation: PropTypes.object.isRequired,
  isPatient: PropTypes.bool,
  checkedMedicinesWithProfile: PropTypes.objectOf(PropTypes.array),
  //loading: PropTypes.bool,
  isPrescription: PropTypes.bool,
}
