import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Switch, Route, Redirect } from 'react-router-dom'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { DateProvider } from 'common/UiKit/DatePicker'
import { IntlProvider } from 'react-intl'
import {
  connect,
  useDispatch,
} from 'react-redux'
import moment from 'moment'
import idx from 'idx';
import * as Sentry from '@sentry/browser'
import { changeLocale } from './utils/changeLocale'

import { Auth } from './components/Auth'
import { Panel } from './components/Panel'
import { theme } from './utils/muiTheme'
import { GlobalStyle } from './constants/globalStyles'
import { Notification } from './components/common/Notification'
import { ChatNotifications } from './components/common/Notification/chat'
import { SocketErrorNotification } from './components/common/Notification/socketError'
import { BannerCookie } from './components/common/BannerCookie'
import { HEALTH_RECORDS_TEXTS, SuccessScreen } from './components/SuccessScreen'

import { en } from './locales/en'
import { ru } from './locales/ru'
import { de } from './locales/de'
import { fr } from './locales/fr'
import { ErrorBoundary } from './components/common/ErrorBoundary';
import { ErrorPage } from './components/ErrorPage';
import { IGNORED_ERRORS } from './constants/errors';
import { setNotification } from './redux/modules/notifications';

const messages = {
  en,
  ru,
  de,
  fr,
}

Sentry.init({
  dsn: 'https://bc762c18cc464736b6dce9da64c53619@sentry.owndoctor.eu/5',
  environment: process.env.NODE_ENV,
  beforeSend(event) {
    if (idx(event, _ => IGNORED_ERRORS.includes(_.exception.values[0].value))) return null
    console.log(event)
    if (event.level === 'error' && false) {
      window.location.pathname = '/error'
    }
    return event
  },
})

const AppComponent = ({ locale }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (typeof document !== 'undefined' && document.documentElement && document.documentElement.lang !== locale) {
      document.documentElement.lang = locale;
    }
    moment.locale(locale)
    changeLocale(locale)
    axios.interceptors.response.use(response => response, (error) => {
      const message = idx(error, _ => _.response.data.data.trace[0].message) || 'Something went wrong, please try again later'
      const code = idx(error, _ => _.response.data.data.trace[0].code)
      if (message && !IGNORED_ERRORS.includes(message) && code !== 403) {
        dispatch(setNotification(message))
      }
      return Promise.reject(error)
    })
  }, [])
  return (
    <MuiThemeProvider theme={theme}>
      <IntlProvider locale={locale} messages={messages[locale]}>
        <ErrorBoundary>
          <DateProvider>
            <GlobalStyle />
            <Notification />
            <ChatNotifications />
            <SocketErrorNotification />
            <Switch>
              <Route path='/drugs/feedback' component={SuccessScreen} />
              <Route path='/error' component={ErrorPage} />
              <Route path='/access/allow' component={props => <SuccessScreen texts={HEALTH_RECORDS_TEXTS} {...props} />} />
              <Route path='/auth' component={Auth} />
              <Route path='/panel' component={Panel} />
              <Redirect from='/' to='/auth' />
            </Switch>
          </DateProvider>
          <BannerCookie />
        </ErrorBoundary>
      </IntlProvider>
    </MuiThemeProvider>
  )
}

AppComponent.propTypes = {
  locale: PropTypes.string.isRequired,
}

export const App = connect(
  state => ({ locale: state.localization }),
  null,
  null,
  { pure: false },
)(AppComponent)
