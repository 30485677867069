import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  compose,
  lifecycle,
  branch,
  renderComponent,
} from 'recompose'
import { Menu } from 'common/UiKit/Menu'
import { Colors } from 'constants/colors'
import idx from 'idx'
import { doctorMenuButtons, patientMenuButtons } from '../../constants/menuButtons'
import { MenuProfileInfo } from './MenuProfileInfo'
import { Doctor } from './Doctor'
import { Patient } from './Patient'
import { OpentokSession } from '../common/OpentokSession'
import { userIsAuthenticated } from '../../utils/userIsAuthenticated'
import { updateUserTimestamp } from '../../redux/modules/auth'
import { Preloader } from '../common/Preloader'
import { socket } from '../../services/socket'
import { handleMessageAction } from '../../redux/modules/chats'
import { fetchSignature } from '../../redux/modules/signature'
import { fetchNotificationCount } from '../../redux/modules/notification'
import { CallModal } from '../common/CallModal'
import { TwoFactorAuth } from './2FA'

const Wrapper = styled.div`
  height: 100vh;
  background-color: ${Colors.white};
  display: flex;
`

const Content = styled.div`
  height: 100%;
  flex-grow: 1;
  overflow: auto;
  position: relative;
`

const enhance = compose(
  userIsAuthenticated,
  connect(state => ({
    user: idx(state, _ => _.auth.type),
    authenticating: state.loading.authByToken,
    isSessionActive: Object.keys(state.consultations.opentok.params).length
      ? state.consultations.consultation.id
      : null,
  }), {
    updateUserTimestamp,
    handleMessageAction,
    fetchSignature,
    fetchNotificationCount,
  }),
  lifecycle({
    componentDidMount() {
      const token = localStorage.getItem('authToken')

      this.props.updateUserTimestamp()
      this.timerID = setInterval(() => this.props.updateUserTimestamp(), 60000)
      socket.initialize(token, message => this.props.handleMessageAction(message))

      setInterval(() => socket.keepAllive(), 30000)

      if (this.props.user === 'Doctor') {
        this.props.fetchSignature()
      }

      this.props.fetchNotificationCount()
    },
    componentWillUnmount() {
      clearInterval(this.timerID)
    },
  }),
  branch(
    props => props.authenticating,
    renderComponent(() => <Preloader />),
  ),
)

export const Panel = enhance(({ user, location, isSessionActive }) => {
  const doctorButtons = isSessionActive ? doctorMenuButtons.map((item) => {
    if (item.label === 'concilium.title') {
      return { ...item, path: `/panel/concilium/${isSessionActive}/specializations` }
    }
    return item
  }) : doctorMenuButtons

  return (
    <Wrapper>
      <Menu menuButtons={user === 'Doctor' ? doctorButtons : patientMenuButtons}>
        <MenuProfileInfo />
      </Menu>
      <Content>
        <OpentokSession
          location={location}
        />
        {user === 'Doctor' ? <Doctor /> : <Patient />}
      </Content>
      <CallModal />
      <TwoFactorAuth />
    </Wrapper>
  )
})

Panel.defaultProps = {
  user: 'Patient',
}

Panel.propTypes = {
  user: PropTypes.string,
  location: PropTypes.object.isRequired,
}
