import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import ErrorIcon from '@material-ui/icons/Error'
import CloseIcon from '@material-ui/icons/Close'
import { socket } from '../../../services/socket'

const SocketErrorWrapper = styled.div`
    position: absolute;
    background-color: #fff9e4;
    z-index: 9;
    right: 21px;
    height: 56px;
    color: #ffbb00;
    display: flex;
    align-items: center;
    border-radius: 4px;
    top: 9px;
`

const IconHolder = styled.div`
    display: flex;
    padding: 16px;
`

const CloseHolder = styled.div`
    padding-bottom: 10px;
    padding-left: 8px;
    padding-right: 8px;
`

const CustomCloseIcon = styled(CloseIcon)`
    && {
        font-size: 16px;
        margin-bottom: 15px;
        cursor: pointer;
    }
`

const MessageContainer = styled.div`
    display: block;
`

export const SocketErrorNotification = () => {
  const [ignore, setIgnore] = useState(false)
  const [timer, setTimer] = useState(socket.timeToSocketReconnect / 1000)

  useEffect(() => {
    const closedSocket = (e) => {
      setTimer(e.time);
    };

    const openedSocket = () => {
      setTimer(0);
    }

    document.addEventListener('socketClosed', closedSocket, false);
    document.addEventListener('socketConnected', openedSocket, false);

    return () => {
      document.addEventListener('socketClosed', closedSocket, false);
      document.removeEventListener('socketConnected', openedSocket, false);
    }
  }, [])

  useEffect(() => {
    const timer2 = setTimeout(() => {
      setTimer((timer - 1) > 0 ? timer - 1 : socket.timeToSocketReconnect / 1000);
    }, 1000);
    return () => clearTimeout(timer2);
  });

  return ignore || !timer || timer <= 0 ? null : (
    <SocketErrorWrapper>
      <IconHolder>
        <ErrorIcon />
      </IconHolder>
      <MessageContainer>
        <b>Oops</b>. Sorry. You connection with server is close.<br />
        Reconnection in <b>{timer}s.</b>
      </MessageContainer>
      <CloseHolder>
        <CustomCloseIcon onClick={() => setIgnore(true)} />
      </CloseHolder>
    </SocketErrorWrapper>
  ) 
}
