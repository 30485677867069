/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components'

const Text = styled(DialogContentText)`
  && {
    h1, strong {
      color: rgb(75,172,198);
      margin-bottom: 16px;
    }
    
    strong {
      display: inline-block;
    }
    
    ul {
      margin: 0;
    }
  }
`

export const ModalTerms = (props) => {
  const {
    open,
    handleClose,
  } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => handleClose('terms', false)}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        <DialogTitle id='scroll-dialog-title'>
          <Typography variant='p' align='center'>
            <FormattedMessage id='profile.termsOfUse' />
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Text
            id='scroll-dialog-description'
            tabIndex={-1}
          >
            <FormattedHTMLMessage id='gdpr.modal.terms' />
          </Text>
        </DialogContent>
        <DialogActions>
          <Grid justify='center' direction='row' container>
            <Button onClick={() => handleClose('terms', false)} variant='contained' color='primary'>
              <FormattedMessage id='profile.button.iAgree' />
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ModalTerms.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
